export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const withMinimumDelay = <T, P extends unknown[]>(
    func: (...args: P) => Promise<T>,
    delay: number,
): (...args: P) => Promise<T> => {
    return async (...args: P) => {
        const minimumDelay = new Promise<void>(resolve => setTimeout(resolve, delay));
        const result = func(...args);
        await Promise.all([minimumDelay, result]);
        return result;
    };
};
