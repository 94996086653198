import React from 'react';
import {useDateSegment} from '@react-aria/datepicker';
import {DateFieldState, DateSegment} from '@react-stately/datepicker';

export interface DateSegmentProps {
    segment: DateSegment;
    state: DateFieldState;
}

const DateSegment: React.FC<DateSegmentProps> = ({segment, state}) => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const {segmentProps} = useDateSegment(segment, state, ref);

    return (
        <div
            {...segmentProps}
            ref={ref}
            style={{
                ...segmentProps.style,
                minWidth: segment.maxValue != null ? `${String(segment.maxValue).length}ch` : undefined,
            }}
            className={`px-0.5 box-content tabular-nums text-right outline-none rounded-sm focus:bg-blue-600 focus:text-white group ${
                !segment.isEditable ? 'text-gray-500' : 'text-gray-800'
            } ${String(segment.maxValue).length == 4 ? 'w-4ch' : String(segment.maxValue).length == 2 ? 'w-2ch' : ''}`}
        >
            {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
            <span
                aria-hidden="true"
                className="block w-full text-center italic text-gray-500 group-focus:text-white"
                style={{
                    visibility: segment.isPlaceholder ? undefined : 'hidden',
                    height: segment.isPlaceholder ? '' : 0,
                    pointerEvents: 'none',
                }}
            >
                {segment.isPlaceholder ? segment.placeholder : ''}
            </span>
            {segment.isPlaceholder ? '' : segment.text}
        </div>
    );
};

export default DateSegment;
