import SelectBox, {Item, MultiSelectProps, OtherSelectBoxProps, SingleSelectProps} from '@/components/SelectBox';
import {AllDaysOfTheWeek, DayOfTheWeek} from '@/models/DayOfTheWeek';

type DayOfTheWeekSelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        daysOfTheWeek?: DayOfTheWeek[];
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        daysOfTheWeek?: DayOfTheWeek[];
    }
);

export interface DayOfTheWeekItem extends Item {
    id: string;
    name: string;
    dayOfTheWeek: DayOfTheWeek;
}

const DayOfTheWeekSelectBox = (props: DayOfTheWeekSelectBoxProps<DayOfTheWeekItem>) => {
    const {
        label = 'Day:',
        placeholder= 'Select day',
        daysOfTheWeek = AllDaysOfTheWeek,
    } = props;

    const listDaysOfTheWeek = (): Promise<DayOfTheWeekItem[]> => {
        return new Promise((resolve) => {
            const items: DayOfTheWeekItem[] = daysOfTheWeek.map(dayOfTheWeek => {
                return {
                    id: dayOfTheWeek,
                    name: dayOfTheWeek,
                    dayOfTheWeek: dayOfTheWeek,
                };
            });

            resolve(items);
        });
    };

    return (
        <SelectBox<DayOfTheWeekItem>
            {...props}
            name="day"
            label={label}
            placeholder={placeholder}
            queryFn={listDaysOfTheWeek}
        />
    );
};

export default DayOfTheWeekSelectBox;
