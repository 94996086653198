import {formatDate} from 'date-fns/format';
import {useEffect, useState} from 'react';
import {OrganizationsApi} from '@/api';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import StatusActiveIndicator from '@/components/StatusActiveIndicator';
import {SearchProps} from '@/hooks/useSearch';
import {Organization, Organizations} from '@/models/Organization';
import {EmptyResult} from '@/models/Pagination';
import {isStatusActive} from '@/models/Status';
import {OrganizationEditPath} from '@/modules/admin/paths';
import {getCountryName} from '@/utils/getCountryName';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

const initialState = EmptyResult<Organization>();

const OrganizationTable = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
}: SearchProps) => {
    const [organizations, setOrganizations] = useState<Organizations>(initialState);

    const organizationsApi = new OrganizationsApi();

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['organizations', page, searchQuery],
        queryFn: async () => {
            const limit = 10;
            return organizationsApi.findOrganizations(
                searchQuery,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setOrganizations((prevState): Organizations => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Country</th>
                        <th>Timezone</th>
                        <th>Created At</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {organizations.items.map((organization, index) => {
                        if (organization.id === undefined) {
                            return;
                        }
                        return (
                            <tr key={index}>
                                <td>{organization.id}</td>
                                <td>
                                    <Link to={OrganizationEditPath.withId(organization.id)}
                                          className="text-blue-500 hover:underline">
                                        {organization.name}
                                    </Link>
                                </td>
                                <td>{getCountryName(organization.countryCode)}</td>
                                <td>{organization.timezone}</td>
                                <td>{organization.createdAt && formatDate(organization.createdAt, 'd MMM yyyy')}</td>
                                <td><StatusActiveIndicator isActive={isStatusActive(organization)}/></td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={organizations.totalCount || 0}
                    estimatedTotalCount={organizations.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default OrganizationTable;
