import '@/App.css';
import SettingsProvider from '@/contexts/SettingsProvider';
import Home from '@/Home';
import Layout from '@/layouts/Layout';
import {AdminRoutes} from '@/modules/admin/Routes';
import AuthProvider from '@/modules/auth/contexts/AuthProvider';
import Login from '@/modules/auth/pages/Login';
import Logout from '@/modules/auth/pages/Logout';
import Signup from '@/modules/auth/pages/Signup';
import {LoginPath, LogoutPath, SignupPath, SignupStepPath} from '@/modules/auth/paths';
import NotificationProvider from '@/modules/notification/contexts/NotificationProvider';
import {RetailerRoutes} from '@/modules/retailer';
import CartProvider from '@/modules/retailer/contexts/CartProvider';
import {SupplierRoutes} from '@/modules/supplier';
import Verification from '@/modules/user/pages/Verification';
import {VerificationPath} from '@/modules/user/paths';
import {HomePath} from '@/paths';
import {OverlayContainer} from '@react-aria/overlays';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Toaster} from 'react-hot-toast';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';

const queryClient = new QueryClient();

function App() {
    return (
        <OverlayContainer>
            <QueryClientProvider client={queryClient}>
                <SettingsProvider>
                    <AuthProvider>
                        <Router>
                            <Routes>
                                <Route index path={HomePath} Component={Home}/>
                                <Route path={LogoutPath} Component={Logout}/>
                                <Route path={LoginPath} Component={Login}/>
                                <Route path={SignupStepPath.path} Component={Signup}/>
                                <Route path={SignupPath} Component={Signup}/>
                                <Route path={VerificationPath.path} Component={Verification}/>
                                <Route path="*" element={
                                    <NotificationProvider>
                                        <CartProvider>
                                            <Layout>
                                                <Toaster
                                                    position="bottom-center"
                                                    reverseOrder={false}
                                                    toastOptions={{
                                                        duration: 3000,
                                                    }}
                                                />
                                                <RetailerRoutes/>
                                                <SupplierRoutes/>
                                                <AdminRoutes/>
                                            </Layout>
                                        </CartProvider>
                                    </NotificationProvider>
                                }/>
                            </Routes>
                        </Router>
                    </AuthProvider>
                </SettingsProvider>
                <ReactQueryDevtools initialIsOpen/>
            </QueryClientProvider>
        </OverlayContainer>
    );
}

export default App;
