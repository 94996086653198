import {formatDate} from 'date-fns/format';
import React, {useEffect, useState} from 'react';
import {Configuration, UsersApi} from '@/api';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import StatusActiveIndicator from '@/components/StatusActiveIndicator';
import {SearchProps} from '@/hooks/useSearch';
import {EmptyResult} from '@/models/Pagination';
import {isStatusActive} from '@/models/Status';
import {User, Users} from '@/models/User';
import {UserEditPath} from '@/modules/admin/paths';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

const initialState = EmptyResult<User>();

const UserTable: React.FC<SearchProps> = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
}) => {
    const [users, setUsers] = useState<Users>(initialState);

    const usersApi = new UsersApi(new Configuration(useAuth));

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['users', page, searchQuery],
        queryFn: async () => {
            const limit = 10;
            return usersApi.findUsers(
                searchQuery,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setUsers((prevState): Users => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Created At</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.items.map((user, index) => {
                        if (user.id === undefined) {
                            return;
                        }
                        return (
                            <tr key={index}>
                                <td>{user.id}</td>
                                <td>
                                    <Link to={UserEditPath.withId(user.id)}
                                          className="text-blue-500 hover:underline">
                                        {user.username}
                                    </Link>
                                </td>
                                <td>{user.fullName}</td>
                                <td>{user.role}</td>
                                <td>{user.createdAt && formatDate(user.createdAt, 'd MMM yyyy')}</td>
                                <td><StatusActiveIndicator isActive={isStatusActive(user)}/></td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={users.totalCount || 0}
                    estimatedTotalCount={users.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default UserTable;
