import React from 'react';
import {Description, Dialog, DialogPanel, DialogTitle} from '@headlessui/react';

interface ConfirmDialogProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    title?: string;
    message?: string;
    cancelText?: string;
    confirmText?: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    open,
    onCancel,
    onConfirm,
    title = 'Confirm',
    message = 'Are you sure you want to proceed?',
    cancelText = 'No',
    confirmText = 'Yes',
}) => {
    const handleConfirmClick = () => {
        onConfirm();
        onCancel();
    };

    return (
        <Dialog open={open} onClose={onCancel} className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen bg-black bg-opacity-50">
                <DialogPanel className="max-w-lg w-full bg-white rounded-lg shadow-lg">
                    <DialogTitle className="text-lg font-semibold text-gray-900 p-4">{title}</DialogTitle>
                    <div className="px-4 pb-4">
                        <Description className="text-gray-700">{message}</Description>
                        <div className="mt-4 flex justify-end space-x-4">
                            <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300" onClick={onCancel}>{cancelText}</button>
                            <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700" onClick={handleConfirmClick}>{confirmText}</button>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
