import {FormEvent, useState} from 'react';
import Button from '@/components/Button';
import CustomerSelectBox from '@/components/CustomerSelectBox';
import {Customer} from '@/models/Customer';
import {Supplier} from '@/models/Supplier';
import {CheckIcon} from '@heroicons/react/24/solid';

export interface SupplierToCustomer {
    customer?: Customer | null;
    supplier: Supplier;
}

interface SupplierToCustomerFormProps {
    supplier: Supplier;
    onSubmit: (value: SupplierToCustomer) => Promise<void>;
}

const SupplierToCustomerForm = ({supplier, onSubmit}: SupplierToCustomerFormProps) => {
    const [formData, setFormData] = useState<SupplierToCustomer>({
        supplier,
    });

    const handleCustomerChange = (customer?: Customer | null) => {
        setFormData(prevData => ({...prevData, customer: customer}));
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label className="block font-medium text-gray-700 mb-1">
                        Supplier:
                    </label>
                    <div className="w-full border shadow rounded-md py-2 pl-3 cursor-default">
                        {supplier.name}
                    </div>
                </div>
                <div className="col-span-2">
                    <CustomerSelectBox
                        value={formData.customer || null}
                        onSelect={handleCustomerChange}
                    />
                </div>
            </div>
            <div className="mt-8 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default SupplierToCustomerForm;
