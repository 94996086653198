import {formatDate} from 'date-fns/format';
import React, {useEffect, useState} from 'react';
import {Configuration, ProductCategoriesApi} from '@/api';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import StatusActiveIndicator from '@/components/StatusActiveIndicator';
import {SearchProps} from '@/hooks/useSearch';
import {EmptyResult} from '@/models/Pagination';
import {ProductCategories, ProductCategory} from '@/models/ProductCategory';
import {isStatusActive} from '@/models/Status';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {ProductCategoryEditPath, ProductCategoryProductListPath} from '@/modules/supplier/paths';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

const initialState = EmptyResult<ProductCategory>();

const ProductCategoryTable: React.FC<SearchProps> = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
}) => {
    const [productCategories, setProductCategories] = useState<ProductCategories>(initialState);

    const productCategoriesApi = new ProductCategoriesApi(new Configuration(useAuth));

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['productCategories', page, searchQuery],
        queryFn: async () => {
            const limit = 10;
            return productCategoriesApi.findProductCategories(
                searchQuery,
                undefined,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setProductCategories((prevState): ProductCategories => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productCategories.items.map((productCategory, index) => {
                        if (productCategory.id === undefined) {
                            return;
                        }

                        return (
                            <tr key={index}>
                                <td>{productCategory.id}</td>
                                <td>
                                    <Link to={ProductCategoryEditPath.withId(productCategory.id)}
                                          className="text-blue-500 hover:underline">
                                        {productCategory.name}
                                    </Link>
                                </td>
                                <td>{productCategory.createdAt && formatDate(productCategory.createdAt, 'd MMM yyyy')}</td>
                                <td><StatusActiveIndicator isActive={isStatusActive(productCategory)}/></td>
                                <td>
                                    <Button
                                        to={ProductCategoryProductListPath.withId(productCategory.id)}
                                        variant="secondary"
                                        size="xs"
                                    >
                                        View Products
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={productCategories.totalCount || 0}
                    estimatedTotalCount={productCategories.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default ProductCategoryTable;
