import {formatDate} from 'date-fns/format';
import React from 'react';
import useFindProductListsQuery from '@/api/queries/useFindProductListsQuery';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import {SearchProps} from '@/hooks/useSearch';
import {ProductListEditPath, ProductListProductListPath} from '@/modules/supplier/paths';
import {Link} from 'react-router-dom';

const ProductListTable: React.FC<SearchProps> = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
}) => {
    const {
        productLists,
        isLoading,
    } = useFindProductListsQuery(page, searchQuery);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productLists.items.map((productList, index) => {
                        if (!productList.id) {
                            return;
                        }

                        return (
                            <tr key={index}>
                                <td>{productList.id}</td>
                                <td>
                                    <Link to={ProductListEditPath.withId(productList.id)}
                                          className="text-blue-500 hover:underline">
                                        {productList.name}
                                    </Link>
                                </td>
                                <td>{productList.createdAt && formatDate(productList.createdAt, 'd MMM yyyy')}</td>
                                <td>
                                    <Button
                                        to={ProductListProductListPath.withProductListId(productList.id)}
                                        variant="secondary"
                                        size="xs"
                                    >
                                        View Products
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={productLists.totalCount || 0}
                    estimatedTotalCount={productLists.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default ProductListTable;
