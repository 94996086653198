import {Amount, Price} from '@/models/Price';
import {Product} from '@/models/Product';
import {DiscountFixedAmount, DiscountPercentage, DiscountType, Promotion} from '@/models/Promotion';
import {Sku} from '@/models/Sku';
import {CalculatedPrice} from '@/modules/retailer/models/Price';

interface Item {
    quantity?: number;
    prices?: Price[];
    promotions?: Promotion[];
    product?: Product;
    sku?: Sku;
}

interface OrderItem {
    quantity: number;
    confirmedQuantity?: number;
    price: Amount;
    vatRate: number;
}

interface PriceService {
    calculateUnitPrice(item: Item): CalculatedPrice | undefined;
    calculateLineItemPrice(item: Item, quantity: number): CalculatedPrice | undefined;
    calculateSubtotal(items: Item[]): CalculatedPrice | undefined;
    calculateGrandTotal(items: Item[]): CalculatedPrice | undefined;
    calculateOrderSubtotal(items: OrderItem[]): CalculatedPrice | undefined;
    calculateOrderGrandTotal(items: OrderItem[]): CalculatedPrice | undefined;
    calculateOrderLineItemPrice(item: OrderItem): CalculatedPrice | undefined;
}

class PriceServiceImpl implements PriceService {
    private readonly decimalPrecision = 2;

    calculateUnitPrice(item: Item): CalculatedPrice | undefined {
        return this.calculatePrice(item.prices, 1, item.promotions);
    }

    calculateLineItemPrice(item: Item, quantity: number): CalculatedPrice | undefined {
        return this.calculatePrice(item.prices, quantity, item.promotions);
    }

    calculateSubtotal(items: Item[]): CalculatedPrice | undefined {
        return this.calculateTotal(items, false);
    }

    calculateGrandTotal(items: Item[]): CalculatedPrice | undefined {
        return this.calculateTotal(items, true);
    }

    calculateOrderLineItemPrice(item: OrderItem): CalculatedPrice | undefined {
        const originalPrice: Amount = {
            currencyCode: item.price.currencyCode,
            amount: item.quantity * item.price.amount,
        };

        return {
            ...originalPrice,
            originalPrice: originalPrice,
        };
    }

    calculateOrderSubtotal(items: OrderItem[]): CalculatedPrice | undefined {
        return this.calculateOrderTotal(items, false);
    }

    calculateOrderGrandTotal(items: OrderItem[]): CalculatedPrice | undefined {
        return this.calculateOrderTotal(items, true);
    }

    private findPrice(prices: Price[], quantity: number): Price | undefined {
        const price = findItemInQuantityRange<Price>(prices, quantity);
        if (!price) {
            return;
        }

        return price;
    }

    private findPromotion(promotions: Promotion[], quantity: number): Promotion | undefined {
        const promotion = findItemWithMinimumQuantity(promotions, quantity);
        if (!promotion) {
            return;
        }

        return promotion;
    }

    private calculatePrice(
        prices?: Price[],
        quantity?: number,
        promotion?: Promotion | Promotion[],
    ): CalculatedPrice | undefined {
        if (!prices || prices.length === 0 || quantity === undefined) {
            return;
        }

        if (isNaN(quantity)) {
            quantity = 0;
        }

        const price = this.findPrice(prices, quantity);

        if (!price) {
            return;
        }

        const amount = price.price;

        if (!amount || amount.amount < 0) {
            return;
        }

        if (promotion) {
            return this.calculateDiscountedPrice(price, quantity, promotion);
        }

        const originalPrice: Amount = {
            ...amount,
            amount: quantity * amount.amount,
        };

        return {
            ...originalPrice,
            originalPrice: originalPrice,
        };
    }

    private calculateTotal(items: Item[], includingVat?: boolean): CalculatedPrice | undefined {
        let totalAmount = 0;
        let totalDiscountAmount = 0;

        const currencyCode = items?.[0]?.sku?.prices?.[0]?.price.currencyCode;
        if (!currencyCode) {
            return;
        }

        for (const item of items) {
            if (!item.sku || !item.quantity) {
                continue;
            }

            const lineItemPrice = this.calculateLineItemPrice(item.sku, item.quantity);
            const lineItemAmount = lineItemPrice?.amount || 0;

            totalAmount += lineItemAmount;
            if (lineItemPrice?.discountedPrice) {
                totalDiscountAmount += lineItemPrice.discountedPrice.amount;
            }

            if (includingVat && item.product && item.product.vatRate && item.product.vatRate > 0) {
                const vatAmount = lineItemAmount * (item.product.vatRate / 100);
                totalAmount += vatAmount;
            }
        }

        const originalPrice = {
            currencyCode: currencyCode,
            amount: totalAmount,
        };

        return {
            ...originalPrice,
            originalPrice: originalPrice,
            discountedPrice: totalDiscountAmount > 0 ? {
                currencyCode: currencyCode,
                amount: totalDiscountAmount,
            } : undefined,
        };
    }

    private calculateOrderTotal(items: OrderItem[], includingVat?: boolean): CalculatedPrice | undefined {
        let totalAmount = 0;

        const currencyCode = items?.[0]?.price?.currencyCode;
        if (!currencyCode) {
            return;
        }

        for (const item of items) {
            const quantity = item.confirmedQuantity !== undefined
                ? item.confirmedQuantity
                : item.quantity;
            const itemAmount = item.price.amount;
            const lineItemAmount = quantity * itemAmount;
            totalAmount += lineItemAmount;

            if (includingVat && item.vatRate && item.vatRate > 0) {
                const vatAmount = lineItemAmount * (item.vatRate / 100);
                totalAmount += vatAmount;
            }
        }

        const originalPrice = {
            currencyCode: currencyCode,
            amount: totalAmount,
        };

        return {
            ...originalPrice,
            originalPrice: originalPrice,
        };
    }

    private calculateDiscountedPrice(originalPrice: Price, quantity: number, promotion?: Promotion | Promotion[]): CalculatedPrice | undefined {
        const originalAmount = originalPrice.price;

        promotion = Array.isArray(promotion)
            ? this.findPromotion(promotion, quantity)
            : promotion;

        if (!promotion) {
            return;
        }

        let discountedPrice: Amount | undefined;
        let fixedAmount: DiscountFixedAmount | undefined;
        let percentage: DiscountPercentage | undefined;

        if (promotion.discountType === DiscountType.FixedAmount && promotion.fixedAmounts) {
            fixedAmount = findItemInQuantityRange(promotion.fixedAmounts, quantity);
            if (fixedAmount) {
                discountedPrice = {
                    currencyCode: fixedAmount.amount.currencyCode,
                    amount: quantity * (originalAmount.amount - fixedAmount.amount.amount),
                };
            }
        } else if (promotion.discountType === DiscountType.Percentage && promotion.percentages) {
            percentage = findItemInQuantityRange(promotion.percentages, quantity);
            if (percentage) {
                const discountedUnitPrice = Number((originalAmount.amount * (1 - percentage.percentage / 100)).toFixed(this.decimalPrecision));
                discountedPrice = {
                    currencyCode: originalAmount.currencyCode,
                    amount: quantity * discountedUnitPrice,
                };
            }
        }

        return discountedPrice ? {
            ...discountedPrice,
            originalPrice: {
                ...originalAmount,
                amount: quantity * originalAmount.amount,
            },
            discountedPrice: discountedPrice,
            promotion: promotion,
            discountFixedAmount: fixedAmount,
            discountPercentage: percentage,
        } : undefined;
    }
}

interface QuantityRange {
    quantityFrom?: number;
    quantityTo?: number;
}

export const findItemInQuantityRange = <T extends QuantityRange>(items: T[], quantity: number): T | undefined => {
    return items.find((item) => {
        if (item.quantityFrom !== undefined && item.quantityTo !== undefined) {
            return quantity >= item.quantityFrom && quantity <= item.quantityTo;
        }
        return true;
    });
};

interface MinimumQuantity {
    minimumQuantity?: number;
}

const findItemWithMinimumQuantity = <T extends MinimumQuantity>(items: T[], quantity: number): T | undefined => {
    return items.find((item) => {
        if (item.minimumQuantity !== undefined) {
            return quantity >= item.minimumQuantity;
        }
        return true;
    });
};

export default PriceServiceImpl;
