import React, {useState} from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/20/solid';

interface PaginationProps {
    currentPage?: number;
    onClick: (page: number) => void;
    totalCount?: number;
    estimatedTotalCount?: number;
    pageCount?: number;
    itemsPerPage: number;
    showNumberOfPages?: number;
}

const generatePageNumbers = (currentPage: number, pageCount: number, showNumberOfPages: number): number[] => {
    const halfShowNumberOfPages = Math.floor(showNumberOfPages / 2);

    let startPage = Math.max(1, currentPage - halfShowNumberOfPages);
    const endPage = Math.min(pageCount, startPage + showNumberOfPages - 1);

    if (endPage - startPage + 1 < showNumberOfPages) {
        startPage = Math.max(1, endPage - showNumberOfPages + 1);
    }

    return Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);
};

const Pagination: React.FC<PaginationProps> = ({
    currentPage = 1,
    itemsPerPage,
    totalCount,
    estimatedTotalCount= 0,
    showNumberOfPages = 5,
    onClick = () => {}},
) => {
    const [
        localCurrentPage,
        setLocalCurrentPage,
    ] = useState<number>(currentPage);

    const localTotalCount = Math.max(totalCount || 0, estimatedTotalCount);

    const pageCount = Math.ceil(localTotalCount / itemsPerPage);
    const pageNumbers = generatePageNumbers(localCurrentPage, pageCount, showNumberOfPages);
    const isFirstPage = localCurrentPage === 1;
    const isLastPage = localCurrentPage === pageCount || pageCount === 0;
    const startItemNumber = (localCurrentPage - 1) * itemsPerPage + 1;
    const endItemNumber = Math.min(startItemNumber - 1 + itemsPerPage, localTotalCount);

    const handleOnClick = (page: number) => {
        if (page < 1 || page > pageCount) return;

        onClick(page);
        setLocalCurrentPage(page);
    };

    return (
        <div className="flex items-center justify-between px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <button
                    type="button"
                    onClick={() => handleOnClick(localCurrentPage - 1)}
                    className={`relative inline-flex items-center rounded-md border ${
                        isFirstPage
                            ? 'border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed'
                            : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
                    } px-4 py-2 text-sm font-medium`}
                >
                    Previous
                </button>
                <button
                    type="button"
                    onClick={() => handleOnClick(localCurrentPage + 1)}
                    className={`relative ml-3 inline-flex items-center rounded-md border ${
                        isLastPage
                            ? 'border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed'
                            : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
                    } px-4 py-2 text-sm font-medium`}
                >
                    Next
                </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    {localTotalCount > 0 ? (
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{startItemNumber}</span> to <span
                            className="font-medium">{endItemNumber}</span>
                            {totalCount || isLastPage ? <> of <span className="font-medium">
                                {isLastPage ? localTotalCount : totalCount}
                            </span> results</> : ''}
                        </p>
                    ) : ''}
                </div>
                <div>
                    {pageCount > 0 ? (
                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                            <button
                                type="button"
                                onClick={() => handleOnClick(localCurrentPage - 1)}
                                className={`relative inline-flex items-center justify-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 h-11 min-w-11 focus:z-20 focus:outline-offset-0 ${
                                    isFirstPage
                                        ? 'cursor-not-allowed bg-gray-200 text-gray-500'
                                        : 'hover:bg-gray-100'
                                }`}
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                            {pageNumbers.map((page) => (
                                <button
                                    key={page}
                                    type="button"
                                    onClick={() => handleOnClick(page)}
                                    aria-current="page"
                                    className={`${
                                        page === localCurrentPage
                                            ? 'bg-blue-600 text-white font-semibold'
                                            : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100'
                                    } text-sm text-center focus:z-20 h-11 w-11 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
                                >
                                    {page}
                                </button>
                            ))}
                            <button
                                type="button"
                                onClick={() => handleOnClick(localCurrentPage + 1)}
                                className={`relative inline-flex items-center justify-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 h-11 min-w-11 focus:z-20 focus:outline-offset-0 ${
                                    isLastPage
                                        ? 'cursor-not-allowed bg-gray-200 text-gray-500'
                                        : 'hover:bg-gray-100'
                                }`}
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </nav>
                    ) : ''}
                </div>
            </div>
        </div>
    );
};

export default Pagination;
