import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import {SupplierCalendarEventAddPath} from '@/modules/admin/paths';
import CalendarEventTable from '@/modules/supplier/components/CalendarEventTable';
import {CalendarEventAddPath} from '@/modules/supplier/paths';
import {PlusIcon} from '@heroicons/react/24/solid';
import {useParams} from 'react-router-dom';

const CalendarEventList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const {supplierId, calendarId} = useParams();

    if (!calendarId) {
        return;
    }

    const addPath = supplierId
        ? SupplierCalendarEventAddPath.withParams(supplierId, calendarId)
        : CalendarEventAddPath.withCalendarId(calendarId);

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Calendar Events">
                {supplierId && <Button
                    to={addPath}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Event
                </Button>}
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by name"
            />

            <CalendarEventTable
                calendarId={calendarId}
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                supplierId={supplierId}
            />
        </div>
    );
};

export default CalendarEventList;
