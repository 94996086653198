import {Configuration, UsersApi} from '@/api';
import CustomToast from '@/components/CustomToast';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import LanguageForm, {LanguageFormData} from '@/components/LanguageForm';
import {useSettings} from '@/contexts/SettingsContext';
import {User} from '@/models/User';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import useUserQuery from '@/modules/settings/hooks/useUserQuery';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {LanguageIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

const Language = () => {
    const {
        user,
        isLoading,
        invalidateQuery,
    } = useUserQuery();

    const usersApi = new UsersApi(new Configuration(useAuth));

    const {languageCode} = useSettings();

    const handleSubmitLanguage = async (user: User, language: LanguageFormData) => {
        if (language.value === undefined) {
            return;
        }

        try {
            await usersApi.updateUser(user.id, {
                ...user,
                languageCode: language.value,
            });

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Language settings updated successfully!'}
                    visible={t.visible}
                />
            ));

            await invalidateQuery();
        } catch (error) {
            console.error('Error while updating user language:', error);
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!user) {
        return;
    }

    return (
        <>
            <SettingsHeader
                title="Language"
                icon={<LanguageIcon className="w-6 h-6"/>}
            />

            <LanguageForm
                value={languageCode}
                onSubmit={(value) => handleSubmitLanguage(user, value)}
                hideLabel
                description={
                    <span className="block mt-4 text-gray-700 text-sm">
                        Choose your preferred language from the options above.
                    </span>
                }
            />
        </>
    );
};

export default Language;
