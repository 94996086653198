interface WarehouseIconProps {
    size?: number;
    strokeWidth?: number;
    className?: string;
}

const WarehouseIcon = ({
    size = 6,
    strokeWidth = 1.5,
    className = 'text-gray-600 group-hover:text-indigo-600',
}: WarehouseIconProps) => {
    const sizeClass = `h-${size} w-${size}`;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 -960 960 960"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            aria-hidden="true"
            className={`${sizeClass} ${className}`}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M160-200h80v-320h480v320h80v-426L480-754 160-626v426Zm-80 80v-560l400-160 400 160v560H640v-320H320v320H80Zm280 0v-80h80v80h-80Zm80-120v-80h80v80h-80Zm80 120v-80h80v80h-80ZM240-520h480-480Z"
            />
        </svg>
    );
};

export default WarehouseIcon;
