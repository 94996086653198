import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {Warehouse, WarehouseCreate} from '@/models/Warehouse';
import WarehouseForm from '@/modules/supplier/components/WarehouseForm';

const WarehouseAdd: React.FC = () => {
    const handleOnSubmit = (warehouse: WarehouseCreate | Warehouse): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(warehouse);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Warehouse"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <WarehouseForm
                    onSubmit={handleOnSubmit}
                />
            </div>
        </div>
    );
};

export default WarehouseAdd;
