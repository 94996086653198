import React from 'react';
import {Configuration, StoresApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {Store, StoreCreate} from '@/models/Store';
import StoreForm from '@/modules/admin/components/StoreForm';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const StoreEdit: React.FC = () => {
    const {storeId} = useParams();

    const storesApi = new StoresApi(new Configuration(useAuth));

    const {
        isLoading,
        data: store,
    } = useQuery({
        queryKey: ['store', storeId],
        queryFn: async () => {
            return storeId
                ? storesApi.getStore(storeId)
                : null;
        },
    });

    const handleOnSubmit = (store: StoreCreate | Store): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(store);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!store) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Store">
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Store
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <StoreForm
                    onSubmit={handleOnSubmit}
                    store={store}
                />
            </div>
        </div>
    );
};

export default StoreEdit;
