import {formatDate} from 'date-fns/format';
import React, {useEffect, useState} from 'react';
import {Configuration, OrdersApi} from '@/api';
import Checkbox from '@/components/Checkbox';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import useCheckboxes from '@/hooks/useCheckboxes';
import {SearchProps} from '@/hooks/useSearch';
import {Order, OrderAssociation, Orders} from '@/models/Order';
import {getCustomerOrderStatusTitle, getSupplierOrderStatusTitle, isSuccessOrderStatus} from '@/models/OrderStatus';
import {EmptyResult} from '@/models/Pagination';
import {TimeWindow} from '@/models/TimeWindow';
import {isSupplier} from '@/models/User';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {OrderDetailsPath} from '@/modules/retailer/paths';
import {arrayEqual} from '@/utils/arrayEqual';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

interface OrderTableProps extends SearchProps {
    supplierId?: string | null;
    status?: string | null;
    period?: TimeWindow | null;
    onChangeCheckedItems?: (items: string[]) => void;
}

const initialState = EmptyResult<Order>();

const OrderTable: React.FC<OrderTableProps> = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    supplierId,
    status,
    period,
    onChangeCheckedItems = () => {},
}) => {
    const {role} = useAuth();

    const {
        checkboxes,
        handleMasterToggle,
        handleRowToggle,
        areAllChecked,
        updateCheckboxes,
    } = useCheckboxes({
        onChange: onChangeCheckedItems,
    });

    const [orders, setOrders] = useState<Orders>(initialState);

    const ordersApi = new OrdersApi(new Configuration(useAuth));

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['orders', page, searchQuery, supplierId, status, period],
        queryFn: async () => {
            const limit = 10;
            return ordersApi.findOrders(
                searchQuery,
                {
                    supplierId: supplierId ?? undefined,
                    status: status ?? undefined,
                    period: period ?? undefined,
                },
                page * limit - limit,
                limit,
                [
                    OrderAssociation.SUPPLIER,
                    OrderAssociation.STORE,
                ],
            );
        },
    });

    useEffect(() => {
        if (!isLoading && data?.items && data?.items.length > 0) {
            updateCheckboxes(data?.items.map(item => item.id));
        }

        setOrders((prevState): Orders => {
            if (isLoading) {
                return prevState;
            }

            if ((prevState.items.length === 0 && !data) ||
                (data?.items && arrayEqual(data.items, prevState.items))
            ) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount ||  0,
                estimatedTotalCount: data?.estimatedTotalCount ||  0,
            };
        });
    }, [data, isLoading, isFetching, updateCheckboxes]);

    const isSupplierRole = role && isSupplier(role);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="cell-checkbox min-w-14">
                            <Checkbox
                                checked={!isLoading && areAllChecked(data?.items)}
                                onChange={handleMasterToggle}
                            />
                        </th>
                        <th className="">Order ID</th>
                        {isSupplierRole
                            ? <th className="min-w-40">Retailer</th>
                            : <th className="min-w-40">Supplier</th>
                        }
                        <th className="">Created At</th>
                        <th className="min-w-40">Scheduled Delivery Date</th>
                        <th className="">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.items.map((order, index) => {
                        return (
                            <tr key={index}>
                                <td className="cell-checkbox">
                                    <Checkbox
                                        checked={checkboxes.checked(order.id)}
                                        onChange={() => handleRowToggle(order.id)}
                                    />
                                </td>
                                <td>
                                    <Link to={OrderDetailsPath.withId(order.id)} className="text-blue-500 hover:underline">
                                        {order.code}
                                    </Link>
                                </td>
                                {isSupplierRole
                                    ? <td>{order.store ? order.store.name : ''}</td>
                                    : <td>{order.supplier ? order.supplier.name : ''}</td>
                                }
                                <td>{formatDate(order.createdAt, 'd MMM yyyy')}</td>
                                <td>
                                {order.deliveryWindow?.start && order.deliveryWindow?.end && (
                                        <>
                                            {formatDate(order.deliveryWindow.start, 'EEEE, d MMM yyyy')},<br/>
                                            {formatDate(order.deliveryWindow.start, 'h:mm a')} -{' '}
                                            {formatDate(order.deliveryWindow.end, 'h:mm a')}
                                        </>
                                    )}
                                </td>
                                <td>
                                    <span className={isSuccessOrderStatus(order.status) ? 'text-green-700' : ''}>
                                        {isSupplierRole
                                            ? getSupplierOrderStatusTitle(order)
                                            : getCustomerOrderStatusTitle(order)}
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={orders.totalCount || 0}
                    estimatedTotalCount={orders.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default OrderTable;
