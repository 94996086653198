import {Configuration, ProductCategoriesApi} from '@/api';
import SelectBox, {
    Item,
    MultiSelectProps,
    OtherSelectBoxProps,
    SingleSelectProps,
} from '@/components/SelectBox';
import {useAuth} from '@/modules/auth/contexts/AuthContext';

type ProductCategorySelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        supplierIds?: string[];
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        supplierIds?: string[];
    }
);

const ProductCategorySelectBox = <T extends Item>(props: ProductCategorySelectBoxProps<T>) => {
    const productCategoriesApi = new ProductCategoriesApi(new Configuration(useAuth));

    const {supplierIds = []} = props;

    const listProductCategories = async (): Promise<T[]> => {
        if (supplierIds.length > 0) {
            const response = await productCategoriesApi.findProductCategories(undefined, {supplierIds}, 0, 100);
            return response.items as unknown as T[];
        }

        const response = await productCategoriesApi.listProductCategories(0, 100);
        return response.items as unknown as T[];
    };

    const {
        label = 'Category:',
        placeholder = 'Select category',
    } = props;

    return (
        <SelectBox<T>
            {...props}
            name="productCategory"
            label={label}
            placeholder={placeholder}
            queryFn={listProductCategories}
            queryKey={['productCategory', ...supplierIds]}
        />
    );
};

export default ProductCategorySelectBox;
