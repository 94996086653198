export const CustomerListPath = '/customers';
export const CustomerAddPath = '/customers/add';
export const CustomerEditPath = {
    path: '/customers/:customerId',
    withId: (id: string): string => {
        return CustomerEditPath.path.replace(':customerId', id);
    },
};
export const CustomerStoreListPath = {
    path: '/customers/:customerId/stores',
    withCustomerId: (customerId: string): string => {
        return CustomerStoreListPath.path.replace(':customerId', customerId);
    },
};
export const CustomerStoreAddPath = {
    path: '/customers/:customerId/stores/add',
    withCustomerId: (customerId: string): string => {
        return CustomerStoreAddPath.path.replace(':customerId', customerId);
    },
};
export const CustomerStoreEditPath = {
    path: '/customers/:customerId/stores/:storeId',
    withParams: (customerId: string, storeId: string): string => {
        return CustomerStoreEditPath.path
            .replace(':customerId', customerId)
            .replace(':storeId', storeId);
    },
};
export const OrganizationAddPath = '/organizations/add';
export const OrganizationEditPath = {
    path: '/organizations/:organizationId',
    withId: (id: string): string => {
        return OrganizationEditPath.path.replace(':organizationId', id);
    },
};
export const OrganizationListPath = '/organizations';
export const SupplierAddPath = '/suppliers/add';
export const SupplierEditPath = {
    path: '/suppliers/:supplierId',
    withId: (id: string): string => {
        return SupplierEditPath.path.replace(':supplierId', id);
    },
};
export const SupplierListPath = '/suppliers';
export const StoreEditPath = {
    path: '/stores/:storeId',
    withId: (id: string): string => {
        return StoreEditPath.path.replace(':storeId', id);
    },
};
export const SupplierCalendarListPath = {
    path: '/suppliers/:supplierId/calendars',
    withSupplierId: (supplierId: string): string => {
        return SupplierCalendarListPath.path.replace(':supplierId', supplierId);
    },
};
export const SupplierCalendarAddPath = {
    path: '/suppliers/:supplierId/calendars/add',
    withSupplierId: (supplierId: string): string => {
        return SupplierCalendarAddPath.path.replace(':supplierId', supplierId);
    },
};
export const SupplierCalendarEditPath = {
    path: '/suppliers/:supplierId/calendars/:calendarId',
    withParams: (supplierId: string, calendarId: string): string => {
        return SupplierCalendarEditPath.path
            .replace(':supplierId', supplierId)
            .replace(':calendarId', calendarId);
    },
};

export const SupplierCalendarEventListPath = {
    path: '/suppliers/:supplierId/calendars/:calendarId/events',
    withParams: (supplierId: string, calendarId: string): string => {
        return SupplierCalendarEventListPath.path
            .replace(':supplierId', supplierId)
            .replace(':calendarId', calendarId);
    },
};
export const SupplierCalendarEventAddPath = {
    path: '/suppliers/:supplierId/calendars/:calendarId/events/add',
    withParams: (supplierId: string, calendarId: string): string => {
        return SupplierCalendarEventAddPath.path
            .replace(':supplierId', supplierId)
            .replace(':calendarId', calendarId);
    },
};
export const SupplierCalendarEventEditPath = {
    path: '/suppliers/:supplierId/calendars/:calendarId/events/:calendarEventId',
    withParams: (supplierId: string, calendarId: string, calendarEventId: string): string => {
        return SupplierCalendarEventEditPath.path
            .replace(':supplierId', supplierId)
            .replace(':calendarId', calendarId)
            .replace(':calendarEventId', calendarEventId);
    },
};
export const SupplierWarehouseListPath = {
    path: '/suppliers/:supplierId/warehouses',
    withSupplierId: (supplierId: string): string => {
        return SupplierWarehouseListPath.path.replace(':supplierId', supplierId);
    },
};
export const SupplierWarehouseAddPath = {
    path: '/suppliers/:supplierId/warehouses/add',
    withSupplierId: (supplierId: string): string => {
        return SupplierWarehouseAddPath.path.replace(':supplierId', supplierId);
    },
};
export const SupplierWarehouseEditPath = {
    path: '/suppliers/:supplierId/warehouses/:warehouseId',
    withParams: (supplierId: string, warehouseId: string): string => {
        return SupplierWarehouseEditPath.path
            .replace(':supplierId', supplierId)
            .replace(':warehouseId', warehouseId);
    },
};
export const UserAddPath = '/users/add';
export const UserEditPath = {
    path: '/users/:userId',
    withId: (id: string): string => {
        return UserEditPath.path.replace(':userId', id);
    },
};
export const UserListPath = '/users';
export const SupplierToCustomerListPath = {
    path: '/suppliers/:supplierId/customers',
    withSupplierId: (supplierId: string) => {
        return SupplierToCustomerListPath.path.replace(':supplierId', supplierId);
    },
};
export const SupplierToCustomerAddPath = {
    path: '/suppliers/:supplierId/customers/add',
    withSupplierId: (supplierId: string) => {
        return SupplierToCustomerAddPath.path.replace(':supplierId', supplierId);
    },
};
export const SupplierToStoreListPath = {
    path: '/suppliers/:supplierId/stores',
    withSupplierId: (supplierId: string) => {
        return SupplierToStoreListPath.path.replace(':supplierId', supplierId);
    },
};
export const SupplierToStoreAddPath = {
    path: '/suppliers/:supplierId/stores/add',
    withSupplierId: (supplierId: string) => {
        return SupplierToStoreAddPath.path.replace(':supplierId', supplierId);
    },
};
