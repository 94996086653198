import {Configuration, OrdersApi} from '@/api';
import useCartQuery from '@/api/queries/useCartQuery';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {useSettings} from '@/contexts/SettingsContext';
import {findStoreAddress, StoreAddress, StoreAddressType} from '@/models/Store';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import OrderReviewTable from '@/modules/retailer/components/OrderReviewTable';
import {useCart} from '@/modules/retailer/contexts/CartContext';
import CartEmpty from '@/modules/retailer/pages/CartEmpty';
import {CartPath, OrderSuccessPath} from '@/modules/retailer/paths';
import CartService from '@/modules/retailer/services/CartService';
import {formatTimeWindow} from '@/utils/formatTimeWindow';
import {useNavigate} from 'react-router-dom';

const OrderReview = () => {
    const {currentUser} = useAuth();
    const {currencyCode} = useSettings();
    const navigate = useNavigate();

    const {
        getSelectedCartsAndItems,
        cartItemCount,
        removeSelectedCartsAndItems,
        addedSkuIds,
        currentSkuIds,
        isInitialized,
    } = useCart();

    const storeId = currentUser()?.storeId;
    const ordersApi = new OrdersApi(new Configuration(useAuth));

    const {
        isLoading,
        skus,
        suppliers,
        store,
    } = useCartQuery(
        isInitialized ? addedSkuIds : undefined,
        isInitialized ? currentSkuIds : undefined,
        storeId,
    );

    if (!store) {
        return;
    }

    if (isLoading || !isInitialized) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!isLoading && cartItemCount == 0) {
        return <CartEmpty/>;
    }

    const carts = getSelectedCartsAndItems();

    const cartService = new CartService();

    const orders = cartService.getCartDetails(carts, skus, suppliers, currencyCode);

    const handleCheckoutOnClick = async () => {
        try {
            const result = await ordersApi.bulkCreateOrders(
                orders
                    .map(order => ({
                        ...order,
                        storeId: store.id,
                    })),
            );

            const orderCodes = result.map(item => item.code);
            const supplierIds = orders.map(cart => cart.supplierId);

            removeSelectedCartsAndItems();

            navigate(OrderSuccessPath.withIds(
                orderCodes,
                supplierIds.slice(0, carts.length),
            ));
        } catch (e) {
            console.log('Error', e);
        }
    };

    const address = (address?: StoreAddress) => address && (
        <>
            {address.building}<br/>
            {address.street1}<br/>
            {address.street2 !== '' && (<>{address.street2}<br/></>)}
            {address.city}, {address.region} {address.postalCode}<br/>
            {address.countryCode}
        </>
    );

    return (
        <>
            <PageHeader title="Order Confirmation">
                <Button to={CartPath} variant="secondary">
                    Back to Checkout
                </Button>
                <Button onClick={handleCheckoutOnClick}>
                    Submit Order
                </Button>
            </PageHeader>

            <div className="flex flex-row w-full rounded-lg bg-white px-3 py-6 border-1 border-gray-500 shadow mb-8">
                <div className="flex-1 px-3">
                    <span className="block font-bold mb-1">Billing Address:</span>

                    <p className="block mb-1 ">
                        {address(findStoreAddress(
                            store?.addresses,
                            [StoreAddressType.BILLING, StoreAddressType.BUSINESS],
                        ))}
                    </p>
                </div>
                <div className="flex-1 px-3">
                    <span className="block font-bold mb-1">Delivery Address:</span>

                    <p className="block mb-1">
                        {address(findStoreAddress(
                            store?.addresses,
                            [StoreAddressType.DELIVERY, StoreAddressType.BUSINESS],
                        ))}
                    </p>
                </div>
            </div>

            <div>
                {orders.map((order, index) => (
                    <div key={index} className="mb-8">
                        <h2 className="text-xl font-bold mb-4">{order.supplierName}</h2>
                        <div className="mb-4 lg:w-96">
                            <span className="block mb-1 font-semibold">Delivery date:</span>
                            <div>
                                {formatTimeWindow(order.deliveryWindow)}
                            </div>
                        </div>

                        <OrderReviewTable order={order}/>
                    </div>
                ))}
            </div>
        </>
    );
};

export default OrderReview;
