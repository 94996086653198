import {jwtDecode, JwtPayload} from 'jwt-decode';
import {AuthApi} from '@/api';
import {parseUserRole, UserRole} from '@/models/User';
import {mapSignupUser} from '@/modules/auth/mappers/mapSignupUser';
import {LoginResult} from '@/modules/auth/models/Login';
import {SignupAccount, SignupUser} from '@/modules/auth/models/Signup';
import CookieStorage from '@/modules/auth/services/CookieStorage';

interface CustomClaims {
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    username?: string;
    roles?: string[];
    organizationId?: string;
    customerId?: string;
    storeId?: string;
    supplierId?: string;
}

interface Token {
    accessToken: string;
    idToken: string;
    role: UserRole;
    roles: UserRole[];
    exp: number | null; // Expiration time (Unix timestamp)
    sub?: string; // Subject (usually user ID)
    iss?: string; // Issuer
    aud?: string | string[]; // Audience
}

interface AuthService {
    getToken(): Promise<Token | undefined>;
    login(username: string, password: string): Promise<Token | undefined>;
    logout(): Promise<void>;
    changeRole(newRole: string): Promise<void>;
    signUp(user: SignupUser, account: SignupAccount): Promise<void>;
}

export type AccessToken = JwtPayload & CustomClaims;
export type IdToken = JwtPayload & CustomClaims;

class AuthServiceImpl implements AuthService {
    private readonly authApi: AuthApi;
    private readonly storage: CookieStorage;
    private readonly cookieName: string;

    constructor() {
        this.authApi = new AuthApi();
        this.storage = new CookieStorage();
        this.cookieName = '_auth';
    }

    async getToken(): Promise<Token | undefined> {
        return this.storage.getCookie<Token>(this.cookieName);
    }

    async login(username: string, password: string): Promise<Token | undefined> {
        if (username === '' || password === '') {
            return;
        }

        let result: LoginResult | undefined;

        try {
            result = await this.authApi.login(username, password);
        } catch (error) {
            console.error('Login failed:', error);
        }

        if (!result || !result.accessToken) {
            return;
        }

        const decodedAccessToken = this.decodeAccessToken(result.accessToken);

        if (!decodedAccessToken || !decodedAccessToken.roles) {
            return;
        }

        const roles = decodedAccessToken.roles.map(parseUserRole);

        if (roles.length < 1) {
            return;
        }

        const accessToken = result.accessToken;
        const idToken = result.idToken;
        const [role] = roles;

        this.storage.createCookie(this.cookieName, {
            accessToken,
            idToken,
            role,
            roles,
        });

        return {
            accessToken,
            idToken,
            role,
            roles,
            exp: decodedAccessToken.exp || null,
        };
    }

    async logout(): Promise<void> {
        this.storage.removeCookie(this.cookieName);
    }

    async changeRole(newRole: UserRole): Promise<void> {
        const token = this.storage.getCookie<Token>(this.cookieName);
        if (token?.roles.includes(newRole)) {
            this.storage.createCookie(this.cookieName, {
                ...token,
                role: newRole,
            });
        }
    }

    decodeAccessToken(accessToken: string): IdToken {
        return jwtDecode<AccessToken>(accessToken);
    }

    decodeIdToken(idToken: string): IdToken {
        return jwtDecode<IdToken>(idToken);
    }

    async signUp(user: SignupUser, account: SignupAccount): Promise<void> {
        console.log('signUp');
        try {
            return this.authApi.signUp(mapSignupUser(user, account));
        } catch (error) {
            console.error('Error while signing up user:', error);
            throw error;
        }
    }
}

export default AuthServiceImpl;
