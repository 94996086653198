import {CustomersApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {CustomerCreate, CustomerUpdate} from '@/models/Customer';
import CustomerForm from '@/modules/admin/components/CustomerForm';
import {CustomerStoreListPath} from '@/modules/admin/paths';
import {BuildingStorefrontIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const CustomerEdit = () => {
    const {customerId} = useParams();

    const customersApi = new CustomersApi();

    const {
        isLoading,
        data: customer,
    } = useQuery({
        queryKey: ['customer', customerId],
        queryFn: async () => {
            return customerId
                ? customersApi.getCustomer(customerId)
                : null;
        },
    });

    const handleOnSubmit = (customer: CustomerCreate | CustomerUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(customer);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!customer) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Customer">
                <Button
                    to={CustomerStoreListPath.withCustomerId(customer.id)}
                    icon={<BuildingStorefrontIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    variant="secondary"
                >
                    Stores
                </Button>
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Customer
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <CustomerForm
                    onSubmit={handleOnSubmit}
                    customer={customer}
                />
            </div>
        </div>
    );
};

export default CustomerEdit;
