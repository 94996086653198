import React, {useState} from 'react';
import Button from '@/components/Button';
import CalendarSelectBox from '@/components/CalendarSelectBox';
import ToggleSwitch from '@/components/ToggleSwitch';
import {NullIsland} from '@/models/Geolocation';
import {isStatusActive} from '@/models/Status';
import {
    Warehouse,
    WarehouseAddress,
    WarehouseCreate,
    WarehouseOperatingWindow,
} from '@/models/Warehouse';
import {SupplierCalendarListPath} from '@/modules/admin/paths';
import WarehouseAddressForm from '@/modules/supplier/components/WarehouseAddressForm';
import WarehouseOperatingWindowsForm from '@/modules/supplier/components/WarehouseOperatingWindowsForm';
import {CalendarListPath} from '@/modules/supplier/paths';
import {CheckIcon} from '@heroicons/react/24/solid';
import {Link} from 'react-router-dom';

interface WarehouseFormProps {
    warehouse?: Warehouse;
    onSubmit: (warehouse: WarehouseCreate | Warehouse) => Promise<void>;
    supplierId?: string;
}

const WarehouseForm: React.FC<WarehouseFormProps> = ({
    warehouse,
    onSubmit,
    supplierId,
}) => {
    const [formData, setFormData] = useState<WarehouseCreate | Warehouse>(
        warehouse ? {...warehouse} : {
            id: '',
            name: '',
            description: '',
            externalCode: '',
            contactNumber: '',
            address: {
                building: '',
                street1: '',
                street2: '',
                city: '',
                postalCode: '',
                region: '',
                countryCode: '',
                location: {...NullIsland},
            },
            status: 'ACTIVE',
            supplierId: '',
            operatingWindows: [],
            nonWorkingDays: [],
            createdAt: new Date(),
        },
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleAddressChange = (address: WarehouseAddress) => {
        setFormData(prevData => ({...prevData, address: address}));
    };

    const handleOperatingWindowsChange = (windows: WarehouseOperatingWindow[]) => {
        setFormData(prevData => ({...prevData, operatingWindows: windows}));
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="description" className="block font-medium text-gray-700">
                        Description:
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChangeTextArea}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full h-24 resize-none"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="externalCode" className="block font-medium text-gray-700">
                        External Code:
                    </label>
                    <input
                        type="text"
                        id="externalCode"
                        name="externalCode"
                        value={formData.externalCode}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="contactNumber" className="block font-medium text-gray-700">
                        Contact Number:
                    </label>
                    <input
                        type="text"
                        id="contactNumber"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="supplierId" className="block font-medium text-gray-700">
                        Supplier ID:
                    </label>
                    <input
                        type="text"
                        id="supplierId"
                        name="supplierId"
                        value={formData.supplierId}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>

                <div className="col-span-2">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Address
                    </span>
                    <WarehouseAddressForm address={formData.address} onChange={handleAddressChange}/>
                </div>

                <div className="col-span-2">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Operating Windows
                    </span>
                    <div className="pb-6 border-b border-gray-300">
                        <WarehouseOperatingWindowsForm
                            operatingWindow={formData.operatingWindows}
                            onChange={handleOperatingWindowsChange}
                        />
                    </div>
                </div>

                <div className="col-span-2 border-b border-gray-300">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Non Working Days
                    </span>
                    <div className="pb-6">
                        <CalendarSelectBox
                            value={[]}
                            label="Calendars:"
                            onSelect={(items) => {console.log(items);}}
                            multiple
                        />
                        <div className="mt-6">
                            Click <Link
                                to={supplierId ? SupplierCalendarListPath.withSupplierId(supplierId) : CalendarListPath}
                                className="text-blue-500 hover:underline"
                            >
                                here
                            </Link> to manage calendars, including holidays and non-working days.
                        </div>
                    </div>
                </div>

                <div className="col-span-2">
                    <ToggleSwitch
                        checked={isStatusActive(formData)}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default WarehouseForm;
