import {Order} from '@/models/Order';
import {isSuccessOrderStatus} from '@/models/OrderStatus';
import formatPrice from '@/utils/formatPrice';

interface OrderItemTableProps {
    order: Order;
}

const OrderItemTable = ({order}: OrderItemTableProps) => {
    const isAccepted = isSuccessOrderStatus(order.status);

    return (
        <div className="shadow-md rounded-lg">
            <div className="relative overflow-x-auto border rounded-t-lg">
                <table className="min-w-full">
                    <thead>
                    <tr className="bg-gray-50 border-b border-gray-300 text-gray-500 font-medium text-sm">
                        <th className="px-6 py-3 pr-3 h-12 w-20 text-left">
                            #
                        </th>
                        <th className="px-6 py-3 text-left w-96">
                            SKU
                        </th>
                        <th className="px-6 py-3 text-left w-40">
                            SKU Code
                        </th>
                        <th className="px-6 py-3 text-left">
                            Ordered Quantity
                        </th>
                        {isAccepted && <th className="px-6 py-3 text-left">
                            Confirmed Quantity
                        </th>}
                        <th className="px-6 py-3 text-left">
                            VAT Rate
                        </th>
                        <th className="px-3 py-3 pr-6 text-right w-40">
                            Subtotal (ex-VAT)
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {order.items.map((orderItem, index) => (
                        <tr key={index} className="text-sm lg:text-base">
                            <td className="px-6 py-4">
                                {orderItem.lineNumber}
                            </td>
                            <td className="px-6 py-4">
                                {orderItem.skuName}
                            </td>
                            <td className="px-6 py-4">
                                {orderItem.skuCode}
                            </td>
                            <td className="px-6 py-4">
                                {orderItem.quantity}
                            </td>
                            {isAccepted && <td className="px-6 py-4">
                                {orderItem.confirmedQuantity}
                            </td>}
                            <td className="px-6 py-4">
                                {(orderItem.vatRate || 0) * 100}%
                            </td>
                            <td className="px-3 py-4 pr-6 text-right">
                                {formatPrice(orderItem.lineItemPrice, orderItem.lineItemPrice?.currencyCode)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    <tbody className="border-t border-gray-300">
                    <tr>
                        <td colSpan={isAccepted ? 6 : 5} className="text-right px-3 pr-1 py-1 pt-6 font-semibold text-sm">
                            Subtotal (ex-VAT)
                        </td>
                        <td className="px-3 pt-6 pr-6 text-right">{formatPrice(order?.subtotal, order.subtotal?.currencyCode)}</td>
                    </tr>
                    <tr>
                        <td colSpan={isAccepted ? 6 : 5} className="text-right px-3 pr-1 py-1 font-semibold text-sm">Total (ex-VAT)</td>
                        <td className="px-3 py-1 pr-6 text-right">{formatPrice(order?.subtotal, order.subtotal?.currencyCode)}</td>
                    </tr>
                    <tr>
                        <td colSpan={isAccepted ? 6 : 5} className="text-right px-3 pr-1 py-1 pb-6 font-semibold text-sm">
                            Grand Total (VAT inclusive)
                        </td>
                        <td className="px-3 py-1 pb-6 pr-6 text-right">{formatPrice(order?.grandTotal, order.grandTotal?.currencyCode)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderItemTable;
