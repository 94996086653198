import React from 'react';
import useGetProductListQuery from '@/api/queries/useGetProductListQuery';
import Breadcrumbs from '@/components/Breadcrumbs';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {Sku} from '@/models/Sku';
import ProductListProductForm from '@/modules/supplier/components/ProductListProductForm';
import {useParams} from 'react-router-dom';

const ProductListProductAdd: React.FC = () => {
    const {productListId} = useParams();

    const {
        isLoading,
        productList,
    } = useGetProductListQuery(productListId);

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!productList) {
        return (
            <div className="flex justify-center py-20">
                Something went wrong ...
            </div>
        );
    }

    const handleOnSubmit = (sku: Sku): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(sku);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Add Product"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <ProductListProductForm
                    onSubmit={handleOnSubmit}
                    productList={productList}
                />
            </div>
        </div>
    );
};

export default ProductListProductAdd;
