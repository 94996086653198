export type Status = 'ACTIVE' | 'INACTIVE' | 'UNKNOWN';

export enum UserRole {
    Admin = 'ADMIN',
    Customer = 'CUSTOMER',
    Retailer = 'RETAILER',
    Supplier = 'SUPPLIER',
    Unknown = 'UNKNOWN'
}

export interface User {
    id: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    role: UserRole;
    mobileCountryCode?: string;
    mobileNumber?: string;
    currencyCode: string;
    languageCode: string;
    timezone: string;
    status: Status;
    organizationId?: string;
    customerId?: string;
    storeId?: string;
    supplierId?: string;
}
