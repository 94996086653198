import {ChangeEvent} from 'react';
import CountrySelectBox, {CountryItem} from '@/components/CountrySelectBox';
import {WarehouseAddress} from '@/models/Warehouse';

interface WarehouseAddressProps {
    address: WarehouseAddress;
    onChange: (address: WarehouseAddress) => void;
}

const WarehouseAddressForm = ({address, onChange}: WarehouseAddressProps) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        onChange({...address, [name]: value});
    };

    const handleCountryOnChange = (country: CountryItem | null) => {
        onChange({...address, countryCode: country?.id || ''});
    };

    return (
        <div className="grid grid-cols-2 gap-4 lg:gap-8">
            <div className="col-span-2">
                <label htmlFor="building" className="block font-medium text-gray-700">
                    Building:
                </label>
                <input
                    type="text"
                    id="building"
                    name="building"
                    value={address.building}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
            <div className="col-span-2">
                <label htmlFor="street1" className="block font-medium text-gray-700">
                    Street 1:
                </label>
                <input
                    type="text"
                    id="street1"
                    name="street1"
                    value={address.street1}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
            <div className="col-span-2">
                <label htmlFor="street2" className="block font-medium text-gray-700">
                    Street 2:
                </label>
                <input
                    type="text"
                    id="street2"
                    name="street2"
                    value={address.street2}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
            <div className="col-span-1">
                <label htmlFor="postalCode" className="block font-medium text-gray-700">
                    Postal Code:
                </label>
                <input
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    value={address.postalCode}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
            <div className="col-span-1">
                <label htmlFor="region" className="block font-medium text-gray-700">
                    Region:
                </label>
                <input
                    type="text"
                    id="region"
                    name="region"
                    value={address.region}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
            <div className="col-span-1">
                <label htmlFor="city" className="block font-medium text-gray-700">
                    City:
                </label>
                <input
                    type="text"
                    id="city"
                    name="city"
                    value={address.city}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
            <div className="col-span-1">
                <CountrySelectBox
                    value={address.countryCode}
                    onSelect={handleCountryOnChange}
                />
            </div>
            <div className="col-span-1">
                <label htmlFor="latitude" className="block font-medium text-gray-700">
                    Latitude:
                </label>
                <input
                    type="text"
                    id="latitude"
                    name="latitude"
                    value={address.location.latitude}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
            <div className="col-span-1">
                <label htmlFor="longitude" className="block font-medium text-gray-700">
                    Longitude:
                </label>
                <input
                    type="text"
                    id="longitude"
                    name="longitude"
                    value={address.location.longitude}
                    onChange={handleChange}
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                />
            </div>
        </div>
    );
};

export default WarehouseAddressForm;
