import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import StoreTable from '@/modules/admin/components/StoreTable';
import {CustomerStoreAddPath} from '@/modules/admin/paths';
import {PlusIcon} from '@heroicons/react/24/solid';
import {useParams} from 'react-router-dom';

const StoreList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const {customerId} = useParams();

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Stores">
               {customerId && <Button
                    to={CustomerStoreAddPath.withCustomerId(customerId)}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Store
                </Button>}
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by name"
            />

            <StoreTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                customerId={customerId}
            />
        </div>
    );
};

export default StoreList;
