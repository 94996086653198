import {SignupAccount, SignupUser, User, UserRole} from '@/modules/auth/models/Signup';

export const mapSignupUser = (user: SignupUser, account: SignupAccount): User => {
    return {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: account.businessType === 'supplier' ? UserRole.Supplier : UserRole.Retailer,
        mobileCountryCode: user.mobileCountryCode,
        mobileNumber: user.mobileNumber,
        password: user.password,
        confirmPassword: user.confirmPassword,
        businessName: account.businessName,
        street1: account.street1,
        street2: account.street2,
        city: account.city,
        countryCode: account.countryCode || '',
    };
};
