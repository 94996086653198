export const CartPath = '/cart';
export const OrderReviewPath = '/orders/review';
export const OrderSuccessPath = {
    path: '/orders/success',
    withIds: (ids: string[], supplierIds: string[]): string => {
        const idParams = ids.map(id => `id=${encodeURIComponent(id)}`).join('&');
        const supplierIdParams = supplierIds.map(supplierId => `supplierId=${encodeURIComponent(supplierId)}`).join('&');
        const queryParams = [idParams, supplierIdParams].filter(param => param).join('&');
        return `${OrderSuccessPath.path}?${queryParams}`;
    },
};

export const ShopPath = {
    path: '/shop',
    withSupplierId(supplierId: string): string {
        return ShopPath.path + `?supplierId=${supplierId}`;
    },
};
export const OrderListPath = '/orders';
export const OrderDetailsPath = {
    path: '/orders/:orderId',
    withId: (id: string): string => {
        return OrderDetailsPath.path.replace(':orderId', id);
    },
};
export const SettingsPath = {
    profile: '/settings/profile',
    address: '/settings/address',
    businessAddress: '/settings/business-address',
    deliveryAddress: '/settings/delivery-address',
    language: '/settings/language',
    notification: '/settings/notifications',
    password: '/settings/password',
    supplier: '/settings/suppliers',
    product: '/settings/products',
};
export const SettingsSupplierAddPath = '/settings/supplier/add';
export const SettingsSupplierEditPath = {
    path: '/settings/supplier/:supplierId',
    withId: (supplierId: string): string => {
        return SettingsSupplierEditPath.path.replace(':supplierId', supplierId);
    },
};
export const SettingsProductAddPath = '/settings/products/add';
export const SettingsProductEditPath = {
    path: '/settings/products/:skuId',
    withId: (skuId: string): string => {
        return SettingsProductEditPath.path.replace(':skuId', skuId);
    },
};
export const SettingsProductImportPath = '/settings/products/import';
