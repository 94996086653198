import {Configuration, NotificationsApi} from '@/api';
import {toNotification} from '@/api/mappers/mapNotification';
import {Notification} from '@/modules/notification/models/Notification';
import NotificationStorage from '@/modules/notification/services/NotificationStorage';
import {OrderDetailsPath} from '@/modules/retailer/paths';

interface NotificationService {
    loadNotifications(refresh?: boolean, limit?: number): Promise<Notification[]>;
    storeNotifications(notifications: Notification[]): Promise<void>;
    removeNotifications(): Promise<void>;
    generateText(notification: Notification): string;
    generateHref(notification: Notification): string | undefined;
}

class NotificationServiceImpl implements NotificationService {
    private readonly api: NotificationsApi;
    private readonly storage: NotificationStorage;

    constructor(api?: NotificationsApi, storage?: NotificationStorage) {
        this.api = api || new NotificationsApi(new Configuration());
        this.storage = storage || new NotificationStorage();
    }
    async loadNotifications(refresh = false, limit: number = 5): Promise<Notification[]> {
        if (refresh) {
            try {
                const notifications = await this.api.listNotifications(0, limit);
                return notifications.items.map(toNotification);
            } catch (error) {
                console.error('Error loading notifications from API:', error);
                throw error;
            }
        }

        try {
            return this.storage.loadNotifications();
        } catch (error) {
            console.error('Error loading notifications from storage:', error);
            throw error;
        }
    }

    async storeNotifications(notifications: Notification[]): Promise<void> {
        return this.storage.storeNotifications(notifications);
    }

    async removeNotifications(): Promise<void> {
        return this.storage.removeNotifications();
    }

    generateText(notification: Notification): string {
        if (notification.text) {
            return notification.text;
        }

        const {
            entity,
            action,
            status,
            entityCode,
        } = notification;

        if (entity === 'order' && entityCode) {
            switch (`${action}_${status}`) {
                case 'REJECT_REJECTED':
                    return `Order Rejected: ${entityCode}`;
                case 'SUBMIT_SUBMITTED':
                    return `Order Received: ${entityCode}`;
                case 'UPDATE,ACCEPT_ACCEPTED':
                    return `Supplier updated and accepted order ${entityCode}. Confirmed quantities have been updated.`;
                case 'ACCEPT_ACCEPTED':
                    return `Order accepted by supplier: ${entityCode}`;
                case 'COMPLETE_COMPLETED':
                    return `Order completed: ${entityCode}`;
            }
        }

        return '';
    }

    generateHref(notification: Notification): string | undefined {
        if (notification.href) {
            return notification.href;
        }

        const {
            entity,
            entityId,
            entityCode,
        } = notification;

        if (entity === 'order' && (entityId || entityCode)) {
            return OrderDetailsPath.withId(entityCode || entityId || '');
        }
    }
}

export default NotificationServiceImpl;
