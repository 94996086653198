interface Item {
    id: string | {value: string};
}

interface PaginationResult<T extends Item> {
    items: T[];
    totalCount?: number;
    estimatedTotalCount?: number;
}

export const setPaginationResultIfNotLoading = <T extends Item>(
    data: PaginationResult<T> | undefined,
    setter: (callback: (prevData: PaginationResult<T>) => PaginationResult<T>) => void,
    isLoading: boolean,
): void => {
    if (!isLoading) {
        setter(prevData => {
            const newData = createPaginationResult(data);

            if (isEqualPaginationResult(newData, prevData)) {
                return prevData;
            }

            return newData;
        });
    }
};

const createPaginationResult = <T extends Item>(data?: Partial<PaginationResult<T>>): PaginationResult<T> => {
    return {
        items: data?.items || [],
        totalCount: data?.totalCount || 0,
        estimatedTotalCount: data?.estimatedTotalCount || 0,
    };
};

const isEqualPaginationResult = <T extends Item>(
    list1: Partial<PaginationResult<T>>,
    list2: Partial<PaginationResult<T>>,
): boolean => {
    if (list1.items?.length !== list2.items?.length) {
        return false;
    }

    const areItemsEqual = list1.items?.every((item1, index) => {
        const item2 = list2.items?.[index];
        return item2 && isEqualItem(item1, item2);
    }) || false;

    const areCountsEqual =
        list1.totalCount === list2.totalCount &&
        list1.estimatedTotalCount === list2.estimatedTotalCount;

    return areItemsEqual && areCountsEqual;
};

const isEqualItem = (item1: Item, item2: Item): boolean => {
    if (typeof item1.id === 'string' && typeof item2.id === 'string') {
        return item1.id === item2.id;
    }

    if (typeof item1.id === 'object' && typeof item2.id === 'object') {
        return item1.id.value === item2.id.value;
    }

    return false;
};
