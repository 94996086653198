interface CheckboxProps {
    id?: string;
    checked: boolean;
    onChange: (isChecked: boolean) => void;
    className?: string;
}

const Checkbox = ({
    id,
    checked,
    onChange,
    className = '',
}: CheckboxProps) => {
    const widthHeightClass = !className?.match(/[wh]-/) ? 'w-5 h-5' : '';

    return (
        <div className={`${widthHeightClass} relative inline-block`}>
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                className={`${className} ${widthHeightClass} relative peer appearance-none bg-white border border-gray-500 rounded checked:border-2 checked:border-blue-600 checked:bg-blue-600`}
            />
            <div className="absolute top-0 hidden peer-checked:block pointer-events-none p-0.5">
                <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
            </div>
        </div>
    );
};

export default Checkbox;
