import {formatDate} from 'date-fns/format';
import React from 'react';
import useFindProductsQuery from '@/api/queries/useFindProductsQuery';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import StatusActiveIndicator from '@/components/StatusActiveIndicator';
import {SearchProps} from '@/hooks/useSearch';
import {isStatusActive} from '@/models/Status';
import {ProductEditPath, ProductProductVariantListPath} from '@/modules/supplier/paths';
import {Link} from 'react-router-dom';

interface ProductTableProps extends SearchProps {
    productCategoryId?: string;
}

const ProductTable: React.FC<ProductTableProps> = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    productCategoryId,
}) => {
    const {
        products,
        isLoading,
    } = useFindProductsQuery(page, searchQuery, {productCategoryId});

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.items.map((product, index) => {
                        if (product.id === undefined) {
                            return;
                        }

                        return (
                            <tr key={index}>
                                <td>{product.id}</td>
                                <td>
                                    <Link to={ProductEditPath.withId(product.id)}
                                          className="text-blue-500 hover:underline">
                                        {product.name}
                                    </Link>
                                </td>
                                <td>{product.createdAt && formatDate(product.createdAt, 'd MMM yyyy')}</td>
                                <td><StatusActiveIndicator isActive={isStatusActive(product)}/></td>
                                <td>
                                    <Button
                                        to={ProductProductVariantListPath.withProductId(product.id)}
                                        variant="secondary"
                                        size="xs"
                                    >
                                        View Variants
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={products.totalCount || 0}
                    estimatedTotalCount={products.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default ProductTable;
