import React from 'react';
import {WarehousesApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {Warehouse, WarehouseCreate} from '@/models/Warehouse';
import WarehouseForm from '@/modules/supplier/components/WarehouseForm';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const WarehouseEdit: React.FC = () => {
    const {warehouseId, supplierId} = useParams();

    const warehousesApi = new WarehousesApi();

    const {
        isLoading,
        data: warehouse,
    } = useQuery({
        queryKey: ['warehouse', warehouseId],
        queryFn: async () => {
            return warehouseId
                ? warehousesApi.getWarehouse(warehouseId)
                : null;
        },
    });

    const handleOnSubmit = (warehouse: WarehouseCreate | Warehouse): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(warehouse);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!warehouse) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Warehouse">
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Warehouse
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <WarehouseForm
                    onSubmit={handleOnSubmit}
                    warehouse={warehouse}
                    supplierId={supplierId}
                />
            </div>
        </div>
    );
};

export default WarehouseEdit;
