import React, {useEffect, useState} from 'react';
import {useSearchParams as useURLSearchParams} from 'react-router-dom';

export interface SearchProps {
    searchQuery?: string;
    page?: number;
    onChangePage?: (page: number) => void;
}

export interface Search {
    searchQuery: string;
    initialSearchQuery: string;
    currentSearchQuery: string;
    page: number;
    handleSearchSubmit: React.FormEventHandler<HTMLFormElement>;
    handlePageChange: (page: number) => void;
    setSearchQuery: (value: string) => void;
}

interface SearchParams {
    searchQuery: string;
    page: number;
}

const useSearch = (): Search => {
    const [searchParams, setSearchParams] = useURLSearchParams();

    const searchQueryParam = searchParams.get('query') || '';
    const pageParam = Number(searchParams.get('page')) || 1;

    const [searchQuery, setSearchQuery] = useState<string>(searchQueryParam);

    const updateSearchParams = (params: Partial<SearchParams>): void => {
        const newParams = new URLSearchParams(searchParams);

        if (params.searchQuery !== undefined) {
            newParams.set('query', params.searchQuery);
            setSearchQuery(params.searchQuery);
        }

        if (params.page !== undefined) {
            newParams.set('page', String(params.page));
        }

        setSearchParams(newParams);
    };

    const handleSearchSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        updateSearchParams({searchQuery: searchQuery, page: 1});
    };

    const handlePageChange = (page: number) => {
        updateSearchParams({page: page});
    };

    useEffect(() => {
        setSearchQuery(prevSearchQuery => {
            if (searchQueryParam === prevSearchQuery) {
                return prevSearchQuery;
            } else {
                return searchQueryParam;
            }
        });
    }, [searchQueryParam]);


    return {
        searchQuery: searchQueryParam,
        initialSearchQuery: searchQueryParam,
        currentSearchQuery: searchQuery,
        page: pageParam,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    };
};

export default useSearch;
