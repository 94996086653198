import {Notification} from '@/modules/notification/models/Notification';

export interface NotificationStorage {
    loadNotifications(): Promise<Notification[]>;
    storeNotifications(notifications: Notification[]): Promise<void>;
    removeNotifications(): Promise<void>;
}

interface LocalStorageNotificationStorageParams {
    storageKey?: string;
    key?: string;
}

export class LocalStorageNotificationStorage implements NotificationStorage {
    private readonly storageKey: string;
    private readonly key: string;

    constructor({storageKey = '_notifications', key = ''}: LocalStorageNotificationStorageParams = {}) {
        this.storageKey = storageKey || '_notifications';
        this.key = key || '';
    }

    async loadNotifications(): Promise<Notification[]> {
        const storedData = localStorage.getItem(this.storageKey);
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            if (parsedData.key !== this.key) {
                await this.removeNotifications();
                return [];
            }
            const {notifications} = parsedData;
            if (Array.isArray(notifications) && notifications.every(this.isValidNotification)) {
                return notifications.map(notification => ({
                    ...notification,
                    createdAt: new Date(notification.createdAt),
                }));
            } else {
                await this.removeNotifications();
            }
        }
        return [];
    }

    async storeNotifications(notifications: Notification[]): Promise<void> {
        const key = this.key;
        localStorage.setItem(this.storageKey, JSON.stringify({notifications, key}));
    }

    async removeNotifications(): Promise<void> {
        localStorage.removeItem(this.storageKey);
    }

    private isValidNotification(notification: unknown): notification is Notification {
        if (typeof notification !== 'object' || notification === null) {
            return false;
        }

        const obj = notification as Record<string, unknown>;

        return obj && typeof obj.id === 'string' && typeof obj.text === 'string' && typeof obj.createdAt === 'string';
    }
}

export default LocalStorageNotificationStorage;
