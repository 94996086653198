import {Configuration, SuppliersApi} from '@/api';
import SelectBox, {
    Item,
    MultiSelectProps,
    OtherSelectBoxProps,
    SingleSelectProps,
} from '@/components/SelectBox';
import {Supplier} from '@/models/Supplier';
import {useAuth} from '@/modules/auth/contexts/AuthContext';

type SupplierSelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        createdByStoreId?: string;
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        createdByStoreId?: string;
    }
);

const SupplierSelectBox = ({createdByStoreId, ...props}: SupplierSelectBoxProps<Supplier>) => {
    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const listSuppliers = async (): Promise<Supplier[]> => {
        const response = await suppliersApi.findSuppliers(
            undefined,
            {createdByStoreId},
            0,
            100,
        );
        return response.items;
    };

    const {
        label = 'Supplier:',
        placeholder= 'Select supplier',
    } = props;

    return (
        <SelectBox<Supplier>
            {...props}
            name="supplier"
            label={label}
            placeholder={placeholder}
            queryFn={listSuppliers}
            queryKey={['supplier']}
        />
    );
};

export default SupplierSelectBox;
