interface CurrencyInfo {
    locale: string;
    decimals: number;
}

interface CurrencyLocaleMap {
    [currencyCode: string]: CurrencyInfo;
}

const currencyLocaleMap: CurrencyLocaleMap = {
    'USD': {locale: 'en-US', decimals: 2},
    'EUR': {locale: 'en-GB', decimals: 2},
    'JPY': {locale: 'ja-JP', decimals: 0},
    'GBP': {locale: 'en-GB', decimals: 2},
    'SGD': {locale: 'en-SG', decimals: 2},
    'PHP': {locale: 'fil-PH', decimals: 2},
    'THB': {locale: 'th-TH', decimals: 2},
    'IDR': {locale: 'id-ID', decimals: 0},
    'AUD': {locale: 'en-AU', decimals: 2},
    'NZD': {locale: 'en-NZ', decimals: 2},
    'MYR': {locale: 'ms-MY', decimals: 2},
    'HKD': {locale: 'zh-HK', decimals: 2},
    'KRW': {locale: 'ko-KR', decimals: 0},
    'TWD': {locale: 'zh-TW', decimals: 2},
    'CNY': {locale: 'zh-CN', decimals: 2},
    'AED': {locale: 'ar-AE', decimals: 2},
    'SAR': {locale: 'ar-SA', decimals: 2},
    'QAR': {locale: 'ar-QA', decimals: 2},
    'OMR': {locale: 'ar-OM', decimals: 3},
    'KWD': {locale: 'ar-KW', decimals: 3},
    'BHD': {locale: 'ar-BH', decimals: 3},
    'JOD': {locale: 'ar-JO', decimals: 3},
    'IQD': {locale: 'ar-IQ', decimals: 3},
};

const defaultCurrencyInfo: CurrencyInfo = {
    locale: 'en-US',
    decimals: 2,
};

const currencyFormatters: Record<string, Intl.NumberFormat> = {};

export const getCurrencyFormatter = (currencyCode: string): Intl.NumberFormat => {
    if (!currencyFormatters[currencyCode]) {
        const currencyInfo = currencyLocaleMap[currencyCode];
        const {locale, decimals} = currencyInfo || defaultCurrencyInfo;
        currencyFormatters[currencyCode] = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: decimals,
        });
    }
    return currencyFormatters[currencyCode];
};

const currencySymbols: Record<string, string> = {};

export const getCurrencySymbol = (currencyCode: string): string => {
    if (currencyCode === '') {
        return currencyCode;
    }

    if (currencySymbols[currencyCode]) {
        return currencySymbols[currencyCode];
    }

    let currencySymbol = currencyCode;

    try {
        const formatter = getCurrencyFormatter(currencyCode);
        const currencyPart = formatter
            .formatToParts()
            .find(part => part.type === 'currency');
        if (currencyPart) {
            currencySymbol = currencyPart.value;
        }
    } catch (e) {
        console.error(`Error retrieving currency symbol for ${currencyCode}:`, e);
    }

    currencySymbols[currencyCode] = currencySymbol;

    return currencySymbol;
};
