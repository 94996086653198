import {Configuration, SkusApi} from '@/api';
import {SkuAssociation} from '@/models/Sku';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery, useQueryClient} from '@tanstack/react-query';

const useGetSkuQuery = (skuId?: string, include?: SkuAssociation[]) => {
    const queryClient = useQueryClient();

    const skusApi = new SkusApi(new Configuration(useAuth));

    const {
        isLoading,
        data: sku,
        error,
    } = useQuery({
        queryKey: ['sku', skuId, include],
        queryFn: () => {
            return skuId
                ? skusApi.getSku(skuId, include)
                : null;
        },
        enabled: skuId !== '',
    });

    const invalidateQuery = async () => {
        return queryClient.invalidateQueries({queryKey: ['sku', skuId]});
    };

    return {
        sku,
        isLoading,
        error,
        invalidateQuery,
    };
};

export default useGetSkuQuery;
