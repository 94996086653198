import {ComponentProps, ComponentType, ElementType, PropsWithoutRef} from 'react';
import CartButton from '@/components/Header/CartButton';
import NotificationButton from '@/components/Header/NotificationButton';
import {languages as supportedLanguages} from '@/config';
import {UserRole} from '@/models/User';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {LogoutPath} from '@/modules/auth/paths';
import {CartPath} from '@/modules/retailer/paths';
import {HomePath} from '@/paths';
import {classNames} from '@/utils/classNames';
import {Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react';
import {
    ArrowRightStartOnRectangleIcon,
    ChevronDownIcon,
    LanguageIcon,
    ShoppingCartIcon,
    UserCircleIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

type HeroIcon = ComponentType<
    PropsWithoutRef<ComponentProps<'svg'>> & {
    title?: string | undefined;
    titleId?: string | undefined;
}
>;

interface MenuItem {
    name: string;
    href?: string;
    onClick?: () => void;
    items?: MenuItem[];
    role?: UserRole;
    description?: string;
    icon?: HeroIcon | ElementType;
}

interface MobileMenuProps {
    open: boolean;
    close: () => void;
    menu: MenuItem[];
    userMenu: MenuItem[];
    cartItemCount: number;
}

const MobileMenu = ({
    open,
    close,
    menu,
    userMenu,
    cartItemCount,
}: MobileMenuProps) => {
    const {role} = useAuth();

    const isRetailer = role === UserRole.Retailer;

    const withClose = (fn?: () => void) => () => {
        fn?.();
        close();
    };

    return (
        <Dialog as="div" className="lg:hidden" open={open} onClose={close}>
            <div className="fixed inset-0 z-10"/>
            <DialogPanel
                className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
            >
                <div className="flex items-center justify-between min-h-14">
                    <Link to={HomePath} onClick={close} className="-m-1.5 p-1.5 flex gap-3">
                        <img className="h-6 w-auto mb-0.5" src="/logo.svg" alt=""/>
                        <div className="flex">
                            <span className="font-semibold self-center text-lg">Simpler</span>
                            <span className="font-semibold self-center text-lg ml-[1px]">Stock</span>
                        </div>
                    </Link>
                    <div className="flex flex-1 justify-end gap-3">
                        <NotificationButton onClick={close}/>
                        {isRetailer && <CartButton onClick={close} count={cartItemCount}/>}
                    </div>
                    <div className="ml-4">
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={close}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-2 py-6">
                            {menu.map((item) => (
                                item.items ? (
                                    <Disclosure key={item.name} as="div" className="-mx-3">
                                        {({open}) => (
                                            <>
                                                <DisclosureButton
                                                    className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                    {item.name}
                                                    <ChevronDownIcon
                                                        className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                        aria-hidden="true"
                                                    />
                                                </DisclosureButton>
                                                <DisclosurePanel className="mt-2 space-y-2">
                                                    {item.items?.map((item) => (
                                                        item.href ? (
                                                            <Link
                                                                key={item.name}
                                                                to={item.href}
                                                                onClick={close}
                                                                className="block rounded-lg py-1 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                            >
                                                                {item.name}
                                                                <p className="mt-1 text-gray-600 text-xs font-normal">{item.description}</p>
                                                            </Link>
                                                        ) : (
                                                            <span key={item.name}>
                                                                {item.name}
                                                            </span>
                                                        )
                                                    ))}
                                                </DisclosurePanel>
                                            </>
                                        )}
                                    </Disclosure>
                                ) : item.href ? (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        onClick={close}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        {item.name}
                                    </Link>
                                ) : (
                                    <div key={item.name}>
                                        {item.name}
                                    </div>
                                )
                            ))}
                        </div>
                        <div className="py-6">
                            {isRetailer && <Link
                                to={CartPath}
                                onClick={close}
                                className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                                <div className="flex items-center gap-2">
                                    <ShoppingCartIcon className="h-8 w-8 text-black"/>
                                    <span>Cart</span>
                                </div>
                            </Link>}
                            <Disclosure as="div" className="-mx-3">
                                {({open}) => (
                                    <>
                                        <DisclosureButton
                                            className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            <div className="flex items-center gap-2">
                                                <UserCircleIcon className="h-8 w-8 text-black"/>
                                                <span>Account</span>
                                            </div>
                                            <ChevronDownIcon
                                                className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                aria-hidden="true"
                                            />
                                        </DisclosureButton>
                                        <DisclosurePanel className="mt-2 space-y-2">
                                            {userMenu.filter(item => !item.role || item.role === role).map((item) => {
                                                if (!item.href) {
                                                    return (
                                                        <span key={item.name}>
                                                            {item.name}
                                                        </span>
                                                    );
                                                }

                                                return (
                                                    <Link
                                                        key={item.name}
                                                        to={item.href}
                                                        onClick={withClose(item.onClick)}
                                                        className="rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex items-center"
                                                    >
                                                        <span>{item.name}</span>
                                                    </Link>
                                                );
                                            })}
                                        </DisclosurePanel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure as="div" className="-mx-3">
                                {({open}) => (
                                    <>
                                        <DisclosureButton
                                            className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            <div className="flex items-center gap-2">
                                                <LanguageIcon className="h-8 w-8 text-black"/>
                                                <span>Language</span>
                                            </div>
                                            <ChevronDownIcon
                                                className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                aria-hidden="true"
                                            />
                                        </DisclosureButton>
                                        <DisclosurePanel className="mt-2 space-y-2">
                                            {supportedLanguages.map((item) => (
                                                <DisclosureButton
                                                    key={item.name}
                                                    as="a"
                                                    href="#"
                                                    className="rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex items-center"
                                                >
                                                    <img className="h-6 w-6 object-cover rounded-full mr-2" src={item.flag} alt=""/>
                                                    {item.name}
                                                </DisclosureButton>
                                            ))}
                                        </DisclosurePanel>
                                    </>
                                )}
                            </Disclosure>
                            <Link
                                to={LogoutPath}
                                className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                                <div className="flex items-center gap-2">
                                    <ArrowRightStartOnRectangleIcon className="h-8 w-8 text-black"/>
                                    <span>Sign out</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    );
};

export default MobileMenu;
