import SelectBox, {Item, OtherSelectBoxProps} from '@/components/SelectBox';
import {MultiSelectProps, SingleSelectProps} from '@/hooks/useSelectBox';
import {CustomerOrderStatusTitles, SupplierOrderStatusTitles} from '@/models/OrderStatus';

type OrderStatusSelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        supplier?: boolean;
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        supplier?: boolean;
    }
);

export interface OrderStatusItem {
    id: string;
    name: string;
}

const OrderStatusSelectBox = (props: OrderStatusSelectBoxProps<OrderStatusItem>) => {
    const {
        label = 'Status:',
        placeholder= 'Select status',
        supplier = false,
    } = props;

    const listOrderStatuses = (): Promise<OrderStatusItem[]> => {
        return new Promise((resolve) => {
            const statuses = Object
                .entries(supplier ? SupplierOrderStatusTitles : CustomerOrderStatusTitles)
                .map(([status, title]) => ({status, title}));

            const items = statuses.map(({status, title}) => {
                return {
                    id: status,
                    name: title,
                };
            });

            resolve(items);
        });
    };

    return (
        <SelectBox<OrderStatusItem>
            {...props}
            name="orderStatus"
            label={label}
            placeholder={placeholder}
            queryFn={listOrderStatuses}
        />
    );
};

export default OrderStatusSelectBox;
