import React, {useState} from 'react';
import {Menu} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';

interface DropdownOption {
    label: string;
    value: string;
    description?: string;
}

interface Props {
    label: string;
    options: DropdownOption[];
}

const ButtonWithDropdown: React.FC<Props> = ({label, options}) => {
    const [, setSelectedOption] = useState<DropdownOption | null>(null);

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="bg-white text-gray-900 font-medium text-center border border-gray-300 px-4 py-2 h-[40px] rounded-lg text-sm hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 inline-flex items-center">
                    {label}
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
                </Menu.Button>
            </div>
            <Menu.Items
                className="z-10 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {options.map((option) => (
                        <Menu.Item key={option.value}>
                            {({active}) => (
                                <button
                                    onClick={() => setSelectedOption(option)}
                                    className={`${
                                        active ? 'bg-gray-100' : ''
                                    } block px-4 py-2 text-sm text-gray-800 w-full text-left`}
                                >
                                    {option.label}
                                    {option.description && <p className="mt-1 text-gray-600">{option.description}</p>}
                                </button>
                            )}
                        </Menu.Item>
                    ))}
                </div>
            </Menu.Items>
        </Menu>
    );
};

export default ButtonWithDropdown;
