import {useEffect, useState} from 'react';

interface Item {
    id: number | string;
    checked?: boolean;
}

interface CheckboxesOptions<T> {
    checkedItems?: Item[] | T[];
    onChange?: (checkedItems: T[]) => void;
}

class CheckedItemsState<T extends string | number> extends Map<T, boolean> {
    checked(key: T): boolean {
        return this.get(key) || false;
    }
}

const createCheckedItems = <T extends string | number>(checkedItems: (Item | T)[]): CheckedItemsState<T> => {
    const state = new CheckedItemsState<T>();

    for (const item of checkedItems) {
        if (item === null) {
            continue;
        }

        if (typeof item === 'object' && 'id' in item) {
            state.set(item.id as T, item.checked !== undefined ? item.checked : true);
        } else if (typeof item === 'number' || typeof item === 'string') {
            state.set(item, true);
        }
    }

    return state;
};

interface Checkboxes<T extends string | number> {
    checkboxes: CheckedItemsState<T>;
    handleMasterToggle: () => void;
    handleRowToggle: (item: T) => void;
    updateCheckboxes: (item: T | T[], checked?: boolean) => void;
    areAllChecked: (items?: Item[]) => boolean;
}

const useCheckboxes = <T extends string | number>({
    checkedItems: checkedItemsProp = [],
    onChange = () => {},
}: CheckboxesOptions<T>): Checkboxes<T> => {
    const initialCheckedItems = createCheckedItems(checkedItemsProp);

    const [checkboxes, setCheckboxes] = useState<CheckedItemsState<T>>(initialCheckedItems);

    const handleMasterToggle = () => {
        setCheckboxes(prevCheckboxes => {
            const masterChecked = !areAllChecked();

            const updatedCheckboxes = new CheckedItemsState(prevCheckboxes);

            updatedCheckboxes.forEach((_value, key) => {
                updatedCheckboxes.set(key, masterChecked);
            });

            return updatedCheckboxes;
        });
    };

    const handleRowToggle = (item: T): void => {
        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = new CheckedItemsState(prevCheckboxes);

            if (updatedCheckboxes.has(item as T)) {
                const prevState = updatedCheckboxes.get(item as T);
                updatedCheckboxes.set(item as T, !prevState);
            }

            return updatedCheckboxes;
        });
    };

    const updateCheckboxes = (item: T | T[], checked?: boolean): void => {
        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = new CheckedItemsState(prevCheckboxes);

            const items = Array.isArray(item) ? item : [item];
            let shouldUpdate = false;

            for (const item of items) {
                if (!updatedCheckboxes.has(item) ||
                    (checked !== undefined &&
                        updatedCheckboxes.get(item) !== checked)
                ) {
                    updatedCheckboxes.set(item, checked !== undefined && checked);
                    shouldUpdate = true;
                }
            }

            return shouldUpdate ? updatedCheckboxes : prevCheckboxes;
        });
    };

    const areAllChecked = (items?: Item[]): boolean => {
        if (items) {
            return items.length > 0
                ? items.every(item => checkboxes.get(item.id as T))
                : false;
        }

        return Array.from(checkboxes.values()).every(item => item);
    };

    useEffect(() => {
        const updatedCheckedItems: T[] = Array.from(checkboxes.entries())
            .filter(([, checked]) => checked)
            .map(([item]) => item)
            .filter(item => initialCheckedItems.has(item));

        onChange(updatedCheckedItems);
    }, [checkboxes, onChange, initialCheckedItems]);

    return {
        checkboxes,
        handleMasterToggle,
        handleRowToggle,
        updateCheckboxes,
        areAllChecked,
    };
};

export default useCheckboxes;
