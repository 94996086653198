import Button from '@/components/Button';
import useSearch from '@/hooks/useSearch';
import ProductTable from '@/modules/settings/components/ProductTable';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {SettingsProductAddPath, SettingsProductImportPath} from '@/modules/settings/paths';
import {CubeIcon, DocumentArrowDownIcon, PlusIcon} from '@heroicons/react/24/outline';

const ProductList = () => {
    const {
        page,
        handlePageChange,
    } = useSearch();

    return (
        <>
            <SettingsHeader
                title="Products"
                icon={<CubeIcon className="w-6 h-6"/>}
            >
                <Button
                    to={SettingsProductImportPath}
                    icon={<DocumentArrowDownIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Import
                </Button>
                <Button
                    to={SettingsProductAddPath}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Product
                </Button>
            </SettingsHeader>

            <ProductTable
                page={page}
                onChangePage={handlePageChange}
            />
        </>
    );
};

export default ProductList;
