import React from 'react';
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/24/solid';

interface StatusActiveIndicatorProps {
    isActive: boolean;
}

const StatusActiveIndicator: React.FC<StatusActiveIndicatorProps> = ({isActive}) => {
    return (
        <div className="flex items-center">
            {isActive ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500 mr-1"/>
            ) : (
                <XCircleIcon className="h-5 w-5 text-red-500 mr-1"/>
            )}
            <span className={isActive ? 'text-green-500' : 'text-red-500'}>
                {isActive ? 'Active' : 'Inactive'}
            </span>
        </div>
    );
};

export default StatusActiveIndicator;
