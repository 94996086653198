import React, {useEffect, useRef, useState} from 'react';
import {getCurrencySymbol} from '@/utils/currency';

interface PriceInputProps {
    name?: string;
    id?: string;
    value: number | string;
    currencyCode: string;
    onChange: (value: number) => void;
    className?: string;
}

const PriceInput: React.FC<PriceInputProps> = ({
    name,
    id = name,
    value,
    currencyCode,
    onChange,
    className = '',
}) => {
    const currencySpanRef = useRef<HTMLSpanElement>(null);
    const [currencySymbolWidth, setCurrencySymbolWidth] = useState<number>(0);

    useEffect(() => {
        if (currencySpanRef.current) {
            const width = currencySpanRef.current.getBoundingClientRect().width;
            setCurrencySymbolWidth(width);
        }
    }, [currencyCode]);

    const paddingLeftClass = currencySymbolWidth > 40
        ? 'pl-10'
        : currencySymbolWidth > 30
            ? 'pl-8'
            : 'pl-6';

    return (
        <div className="flex items-center relative">
            <span ref={currencySpanRef} className="absolute inline-block px-2 pointer-events-none text-gray-600">
                {getCurrencySymbol(currencyCode)}
            </span>
            <input
                name={name}
                id={id}
                type="number"
                value={value}
                onChange={(e) => onChange(parseFloat(e.target.value))}
                className={`${className} flex-grow p-2 px-6 ${paddingLeftClass} border border-gray-300 rounded-md input-no-spinner`}
                placeholder="Amount"
            />
            <span className="absolute right-0 inline-block px-2 pointer-events-none text-gray-600">
                {currencyCode}
            </span>
        </div>
    );
};

export default PriceInput;
