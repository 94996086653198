import React, {ReactElement, ReactNode, useEffect, useState} from 'react';
import PromptDialog from '@/components/PromptDialog';
import {handleWithLoading} from '@/utils/loading';

interface DialogProps {
    open: boolean;
    title: string;
    message: string;
    onConfirm: (message: string) => void;
    onCancel: () => void;
    cancelText: string;
    confirmText: string;
}

interface PromptProps {
    onConfirm: ((message: string) => Promise<void>) | ((message: string) => void);
    onCancel?: () => void;
    title?: string;
    message: string;
    condition?: boolean;
    dialog?: ReactElement<DialogProps>;
    children: ((handleClick: () => void, loading: boolean) => ReactNode) | ReactNode;
    cancelText?: string;
    confirmText?: string;
}

const Prompt: React.FC<PromptProps> = ({
    onConfirm,
    onCancel,
    title = 'Prompt',
    message,
    condition = false,
    dialog,
    children,
    cancelText = 'Cancel',
    confirmText = 'OK',
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (condition) {
            setShowDialog(true);
        }
    }, [condition]);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = async (message: string) => {
        setShowDialog(false);
        await handleWithLoading(
            async () => onConfirm(message),
            setLoading,
        );
    };

    const handleCancel = () => {
        onCancel?.();
        setShowDialog(false);
    };

    return (
        <>
            {typeof children === 'function' ? (
                children(handleClick, loading)
            ) : (
                children
            )}
            {dialog ? (
                <>
                    {React.cloneElement(dialog, {
                        open: showDialog,
                        title: title,
                        message: message,
                        onConfirm: handleConfirm,
                        onCancel: handleCancel,
                        cancelText: cancelText,
                        confirmText: confirmText,
                    })}
                </>
            ) : (
                <PromptDialog
                    open={showDialog}
                    title={title}
                    message={message}
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    cancelText={cancelText}
                    confirmText={confirmText}
                />
            )}
        </>
    );
};

export default Prompt;
