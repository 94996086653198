import {FC, ReactNode} from 'react';
import Header from '@/components/Header';
import {appVersion} from '@/config';

interface LayoutProps {
    children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({children}) => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header/>
            <main className="flex flex-col flex-1">
                <div className="flex flex-col flex-1 w-full mx-auto max-w-7xl px-4 py-10 pb-12 lg:px-8">
                    {children}
                </div>
            </main>
            <footer className="p-2 py-6 lg:px-4 bg-gray-800 text-gray-100 text-sm">
                <div className="flex flex-col justify-end p-3 mx-auto max-w-7xl">
                    <div className="flex justify-end">
                        <span>&copy; {new Date().getFullYear()}</span>
                        <img className="h-5 sm:h-6 w-auto ml-1 mr-1 -mt-0.5" src="/logo-white.svg" alt=""/>
                        <span>Simpler</span><span className="ml-[1px]">Supply Inc. All rights reserved.</span>
                    </div>
                    <div className="text-right text-xs">
                        <span>Version: {appVersion}</span>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Layout;
