import React from 'react';
import {PromotionsApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {Promotion, PromotionCreate} from '@/models/Promotion';
import PromotionForm from '@/modules/supplier/components/PromotionForm';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const PromotionAdd: React.FC = () => {
    const {promotionId} = useParams();

    const promotionsApi = new PromotionsApi();

    const {
        isLoading,
        data: promotion,
    } = useQuery({
        queryKey: ['promotion', promotionId],
        queryFn: async () => {
            return promotionId
                ? promotionsApi.getPromotion(promotionId)
                : null;
        },
    });

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!promotion) {
        return (
            <div className="flex justify-center py-20">
                Something went wrong ...
            </div>
        );
    }

    const handleOnSubmit = (price: PromotionCreate | Promotion): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(price);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Promotion"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <PromotionForm
                    onSubmit={handleOnSubmit}
                    promotion={promotion}
                />
            </div>
        </div>
    );
};

export default PromotionAdd;
