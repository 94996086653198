import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {SupplierCreate, SupplierUpdate} from '@/models/Supplier';
import SupplierForm from '@/modules/admin/components/SupplierForm';

const SupplierAdd = () => {
    const handleOnSubmit = (supplier: SupplierCreate | SupplierUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(supplier);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Supplier"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <SupplierForm
                    onSubmit={handleOnSubmit}
                />
            </div>
        </div>
    );
};

export default SupplierAdd;
