import {AuthApi, Configuration} from '@/api';
import CustomToast from '@/components/CustomToast';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {User} from '@/models/User';
import ChangePasswordForm, {ChangePassword} from '@/modules/auth/components/ChangePasswordForm';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import useUserQuery from '@/modules/settings/hooks/useUserQuery';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {delay} from '@/utils/delay';
import {LockClosedIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

const Password = () => {
    const {
        user,
        isLoading,
    } = useUserQuery();

    const authApi = new AuthApi(new Configuration(useAuth));

    const handleSubmitChangePassword = async (user: User, changePassword: ChangePassword) => {
        await delay(2000);

        if (changePassword.oldPassword === undefined ||
            changePassword.newPassword === undefined ||
            changePassword.confirmPassword === undefined
        ) {
            return;
        }

        try {
            await authApi.changePassword(
                user.id,
                changePassword.oldPassword,
                changePassword.newPassword,
                changePassword.confirmPassword,
            );

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Password updated successfully!'}
                    visible={t.visible}
                />
            ));
        } catch (error) {
            console.error('Error while updating user language:', error);
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!user) {
        return;
    }

    return (
        <>
            <SettingsHeader
                title="Change password"
                icon={<LockClosedIcon className="w-6 h-6"/>}
            />

            <ChangePasswordForm
                onSubmit={(changePassword) => handleSubmitChangePassword(user, changePassword)}
            />
        </>
    );
};

export default Password;
