import React, {useState} from 'react';
import Button from '@/components/Button';
import CalendarEventTypeSelectBox, {CalendarEventTypeItem} from '@/components/CalendarEventTypeSelectBox';
import DatePicker from '@/components/datepicker/DatePicker';
import {toDateValue, toTimeValue} from '@/components/datepicker/mappers';
import TimeField from '@/components/datepicker/TimeField';
import {useSettings} from '@/contexts/SettingsContext';
import {
    CalendarEvent,
    CalendarEventCreate,
    CalendarEventType,
    CalendarEventUpdate,
} from '@/models/CalendarEvent';
import {CheckIcon} from '@heroicons/react/24/solid';
import {DateValue} from '@react-aria/calendar';
import {TimeValue} from '@react-aria/datepicker';

interface CalendarEventFormProps {
    calendarEvent?: CalendarEvent;
    onSubmit: (calendarEvent: CalendarEventCreate | CalendarEventUpdate) => Promise<void>;
}

interface FormData extends CalendarEventCreate, CalendarEventUpdate {
    startDate?: DateValue | null;
    startTime?: TimeValue | null;
    endDate?: DateValue | null;
    endTime?: TimeValue | null;
}

const CalendarEventForm: React.FC<CalendarEventFormProps> = ({calendarEvent, onSubmit}) => {
    const {timezone} = useSettings();

    const [formData, setFormData] = useState<FormData>(
        calendarEvent ? {
            ...calendarEvent,
            startDate: toDateValue(calendarEvent.start, timezone),
            startTime: toTimeValue(calendarEvent.start, timezone),
            endDate: toDateValue(calendarEvent.end, timezone),
            endTime: toTimeValue(calendarEvent.end, timezone),
        } : {
            calendarId: '',
            name: '',
            start: new Date(),
            end: new Date,
            type: CalendarEventType.DEFAULT,
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
        },
    );

    console.log(formData);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleDateChange = (name: 'startDate' | 'endDate', value: DateValue) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleCalendarEventTypeChange = (eventType?: CalendarEventTypeItem | null) => {
        setFormData(prevData => ({
            ...prevData,
            type: eventType ? eventType.type : CalendarEventType.DEFAULT,
        }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <CalendarEventTypeSelectBox
                        value={formData.type}
                        onSelect={handleCalendarEventTypeChange}
                    />
                </div>
                <div className="col-span-2">
                    <div className="md:grid md:grid-cols-2 gap-4 lg:gap-8">
                        <div className="flex flex-row items-end colspan-1 gap-4">
                            <div className="flex-1">
                                <DatePicker
                                    label="Start:"
                                    onChange={(value) => handleDateChange('startDate', value)}
                                    value={formData.startDate}
                                />
                            </div>
                            <div className="flex-1">
                                <TimeField
                                    value={formData.startTime}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="md:grid md:grid-cols-2 gap-4 lg:gap-8">
                        <div className="flex flex-row items-end colspan-1 gap-4">
                            <div className="flex-1">
                                <DatePicker
                                    label="End:"
                                    onChange={(value) => handleDateChange('endDate', value)}
                                    value={formData.endDate}
                                />
                            </div>
                            <div className="flex-1">
                                <TimeField
                                    value={formData.endTime}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-12 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default CalendarEventForm;
