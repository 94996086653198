import {useState} from 'react';
import Button from '@/components/Button';
import CurrencySelectBox from '@/components/CurrencySelectBox';
import DatePicker from '@/components/datepicker/DatePicker';
import {toDateValue, toTimeValue} from '@/components/datepicker/mappers';
import TimeField from '@/components/datepicker/TimeField';
import ToggleSwitch from '@/components/ToggleSwitch';
import {useSettings} from '@/contexts/SettingsContext';
import {PriceList, PriceListCreate} from '@/models/PriceList';
import {isStatusActive} from '@/models/Status';
import {CheckIcon} from '@heroicons/react/24/outline';
import {DateValue} from '@react-aria/calendar';
import {TimeValue} from '@react-aria/datepicker';

interface PriceListFormProps {
    onSubmit: (priceList: PriceListCreate | PriceList) => void;
    priceList?: PriceList;
}

interface FormData extends PriceListCreate, PriceList {
    effectivePeriodStartDate?: DateValue | null;
    effectivePeriodStartTime?: TimeValue | null;
    effectivePeriodEndDate?: DateValue | null;
    effectivePeriodEndTime?: TimeValue | null;
}

const PriceListForm = ({onSubmit, priceList}: PriceListFormProps) => {
    const {timezone} = useSettings();

    const [formData, setFormData] = useState<FormData>(
        priceList
            ? {
                ...priceList,
                effectivePeriodStartDate: toDateValue(priceList.effectivePeriod?.start, timezone),
                effectivePeriodStartTime: toTimeValue(priceList.effectivePeriod?.start, timezone),
                effectivePeriodEndDate: toDateValue(priceList.effectivePeriod?.end, timezone),
                effectivePeriodEndTime: toTimeValue(priceList.effectivePeriod?.end, timezone),
            }
            : {
                id: '',
                name: '',
                description: '',
                currencyCode: '',
                status: 'ACTIVE',
                supplierId: '',
                isDefault: false,
                createdAt: new Date(),
                effectivePeriodStartDate: null,
                effectivePeriodStartTime: null,
                effectivePeriodEndDate: null,
                effectivePeriodEndTime: null,
            });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleDateChange = (name: 'effectivePeriodStartDate' | 'effectivePeriodEndDate', value: DateValue) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(formData);
        onSubmit(formData);
    };

    const handleIsDefaultChange = (isDefault: boolean) => {
        setFormData(prevData => ({...prevData, isDefault: isDefault}));
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="description" className="block font-medium text-gray-700">
                        Description:
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-1">
                    <label htmlFor="externalCode" className="block font-medium text-gray-700">
                        External Code:
                    </label>
                    <input
                        type="text"
                        id="externalCode"
                        name="externalCode"
                        value={formData.externalCode}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-1">
                    <CurrencySelectBox
                        value={formData.currencyCode}
                        onSelect={(currency) => {console.log(currency);}}
                    />
                </div>
                <div className="col-span-2">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Effective Period
                    </span>
                    <div className="md:grid md:grid-cols-2 gap-4 lg:gap-8">
                        <div className="flex flex-row items-end colspan-1 gap-4">
                            <div className="flex-1">
                                <DatePicker
                                    label="Start:"
                                    onChange={(value) => handleDateChange('effectivePeriodStartDate', value)}
                                    value={formData.effectivePeriodStartDate}
                                />
                            </div>
                            <div className="flex-1">
                                <TimeField
                                    value={formData.effectivePeriodStartTime}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-end colspan-1 gap-4">
                            <div className="flex-1">
                                <DatePicker
                                    label="End:"
                                    onChange={(value) => handleDateChange('effectivePeriodEndDate', value)}
                                    value={formData.effectivePeriodEndDate}
                                />
                            </div>
                            <div className="flex-1">
                                <TimeField
                                    value={formData.effectivePeriodEndTime}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="mb-2">
                        <ToggleSwitch
                            checked={formData.isDefault}
                            onChange={handleIsDefaultChange}
                            labelText="Default:"
                        />
                    </div>
                    <span className="text-sm text-gray-800">Indicate whether this price list serves as the default choice among the available alternatives.</span>
                </div>
                <div className="col-span-2">
                    <ToggleSwitch
                        checked={isStatusActive(formData)}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default PriceListForm;
