import {MouseEventHandler} from 'react';
import {CartPath} from '@/modules/retailer/paths';
import {Menu, MenuButton} from '@headlessui/react';
import {ShoppingCartIcon} from '@heroicons/react/24/outline';
import {Link, useNavigate} from 'react-router-dom';

interface CartButtonProps {
    count: number;
    onClick?: () => void;
}

const CartButton = ({count, onClick}: CartButtonProps) => {
    const navigate = useNavigate();

    const handleClick: MouseEventHandler<HTMLAnchorElement> = () => {
        onClick?.();
        navigate(CartPath);
    };

    return (
        <Menu as="div" className="relative">
            <MenuButton
                onClick={handleClick}
                as={Link}
                to={CartPath}
                className="relative flex rounded-full text-sm hover:bg-gray-100 transition duration-300 ease-in-out"
            >
                <div className="flex items-center gap-1.5 px-1 py-2 pr-2">
                    <div className="relative">
                        <ShoppingCartIcon className="h-7 w-7 text-black"/>
                        {count > 0 && (
                            <span className="bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs absolute -top-1.5 -right-2">
                                {count}
                            </span>
                        )}
                    </div>
                    <span className="hidden md:block">Cart</span>
                </div>
            </MenuButton>
        </Menu>
    );
};

export default CartButton;
