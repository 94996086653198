import React from 'react';
import {CalendarsApi} from '@/api';
import SelectBox, {SelectBoxProps} from '@/components/SelectBox';
import {Calendar} from '@/models/Calendar';

export interface CalendarItem {
    id: string;
    name: string;
    calendar: Calendar;
}

const CalendarSelectBox: React.FC<SelectBoxProps<CalendarItem>> = (props) => {
    const calendarsApi = new CalendarsApi();

    const listProductCategories = async (): Promise<CalendarItem[]> => {
        const response = await calendarsApi.listCalendars();
        return response.items.map((calendar) => {
            return {
                id: calendar.id,
                name: calendar.name,
                calendar,
            };
        });
    };

    const {
        label = 'Calendar:',
        placeholder= 'Select calendars',
    } = props;

    return (
        <SelectBox<CalendarItem>
            {...props}
            name="calendar"
            label={label}
            placeholder={placeholder}
            queryFn={listProductCategories}
        />
    );
};

export default CalendarSelectBox;
