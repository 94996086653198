import * as admin from '@/modules/admin/paths';
import * as retailer from '@/modules/retailer/paths';
import * as supplier from '@/modules/supplier/paths';
import * as paths from '@/paths';
import {Location, matchRoutes} from 'react-router-dom';

type Paths = {
    [key: string]: string | { path: string } | { [key: string]: string };
};

interface Route {
    path: string;
}

const allPaths: Paths = {
    ...paths,
    ...admin,
    ...retailer,
    ...supplier,
};

const routes: Route[] = Object.values(allPaths).map(path => {
    if (typeof path === 'object' && 'path' !in path) {
        return Object.entries(path).map(path => ({path}));
    }
    if (typeof path === 'string') {
        return {path};
    }
    if (path) {
        return {path: path.path};
    }
    return null;
}).filter(route => route !== null) as Route[];

export const getCurrentPath = (location: Location) => {
    const matches = matchRoutes(routes, location);
    return matches !== null && matches.length > 0 ? matches[0].route.path : undefined;
};
