import React, {forwardRef, ReactElement, useEffect, useState} from 'react';
import Button from '@/components/Button';
import LanguageSelectBox, {LanguageItem} from '@/components/LanguageSelectBox';
import {languages as supportedLanguages} from '@/config';

export interface LanguageFormData {
    value?: string;
}

interface LanguageFormProps {
    value?: string;
    onSubmit: (formData: LanguageFormData) => Promise<void>;
    loading?: boolean;
    onChangeLoading?: (loading: boolean) => void;
    hideLabel?: true;
    description?: string | ReactElement;
}

const LanguageForm = forwardRef<HTMLFormElement, LanguageFormProps>(function LanguageForm({
    value,
    onSubmit,
    loading: loadingProp,
    onChangeLoading,
    hideLabel,
    description,
}, ref) {
    const [formData, setFormData] = useState<LanguageFormData>({value: value});

    const handleLanguageSelect = (language?: LanguageItem | null) => {
        setFormData({value: language?.id});
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(loadingProp || false);
    }, [loadingProp]);

    useEffect(() => {
        if (onChangeLoading) {
            onChangeLoading(loading);
        }
    }, [loading, onChangeLoading]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);
        console.log('submit');
        await onSubmit(formData);
        setLoading(false);
    };

    return (
        <form ref={ref} onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <div className="pb-2">
                        <LanguageSelectBox
                            value={formData.value || ''}
                            onSelect={handleLanguageSelect}
                            languages={supportedLanguages}
                            hideLabel={hideLabel}
                        />
                        {description  && typeof description !== 'string'
                            ? description
                            : <span className="block mb-1">{description}</span>}
                    </div>
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    type="submit"
                    variant="primary"
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
});

export default LanguageForm;
