import useGetSupplierQuery from '@/api/queries/useGetSupplierQuery';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {SupplierCreate, SupplierUpdate} from '@/models/Supplier';
import SupplierForm from '@/modules/admin/components/SupplierForm';
import {
    SupplierCalendarListPath,
    SupplierToCustomerListPath,
    SupplierToStoreListPath,
    SupplierWarehouseListPath,
} from '@/modules/admin/paths';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useParams} from 'react-router-dom';

const SupplierEdit = () => {
    const {supplierId} = useParams();

    const {
        isLoading,
        supplier,
    } = useGetSupplierQuery(supplierId);

    const handleOnSubmit = (supplier: SupplierCreate | SupplierUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(supplier);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!supplier) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Supplier">
                <Button
                    to={SupplierCalendarListPath.withSupplierId(supplier.id)}
                    variant="secondary"
                >
                    Calendars
                </Button>
                <Button
                    to={SupplierToCustomerListPath.withSupplierId(supplier.id)}
                    variant="secondary"
                >
                    Customers
                </Button>
                <Button
                    to={SupplierToStoreListPath.withSupplierId(supplier.id)}
                    variant="secondary"
                >
                    Stores
                </Button>
                <Button
                    to={SupplierWarehouseListPath.withSupplierId(supplier.id)}
                    variant="secondary"
                >
                    Warehouses
                </Button>
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Supplier
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <SupplierForm
                    onSubmit={handleOnSubmit}
                    supplier={supplier}
                />
            </div>
        </div>
    );
};

export default SupplierEdit;
