import React, {ReactNode, useEffect, useState} from 'react';
import {DeliveryWindow} from '@/models/DeliveryWindow';
import {PickupWindow} from '@/models/PickupWindow';
import CartContext from '@/modules/retailer/contexts/CartContext';
import {Cart, CartImpl} from '@/modules/retailer/models/Cart';
import {CartItem} from '@/modules/retailer/models/CartItem';
import CartService from '@/modules/retailer/services/CartService';
import {arrayEqual} from '@/utils/arrayEqual';

interface CartState {
    carts: Cart[];
    addedSkuIds: string[];
    currentSkuIds: string[];
    hasLoadedFromLocalStorage: boolean;
    nextCartItemId: number;
}

const initialCartState = {
    carts: [],
    addedSkuIds: [],
    currentSkuIds: [],
    hasLoadedFromLocalStorage: false,
    nextCartItemId: 1,
};

const cartService = new CartService();

export const CartProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [state, setState] = useState<CartState>(initialCartState);

    const addToCart = (
        supplierId: string,
        item: CartItem | CartItem[],
        deliveryWindow?: DeliveryWindow,
        pickupWindow?: PickupWindow,
    ) => {
        setState(prevState => {
            const prevCarts = prevState.carts.map(CartImpl.fromObject);

            const result = cartService.addToCart(
                prevCarts,
                supplierId,
                item,
                deliveryWindow,
                pickupWindow,
            );

            const {
                carts,
                currentSkuIds,
                nextCartItemId,
            } = result;

            const addedSkuIds = Array.from(new Set([
                ...prevState.currentSkuIds,
                ...currentSkuIds,
            ]));

            return {
                ...prevState,
                carts: carts,
                addedSkuIds: addedSkuIds,
                currentSkuIds: currentSkuIds,
                nextCartItemId: nextCartItemId,
            };
        });
    };

    const updateItemFromCart = (supplierId: string, itemId: number, quantity: number) => {
        setState(prevState => {
            const carts = cartService.updateItemFromCart(
                prevState.carts,
                supplierId,
                itemId,
                quantity,
            );

            return {
                ...prevState,
                carts: carts,
            };
        });
    };

    const removeItemFromCart = (supplierId: string, itemId: number) => {
        setState(prevState => {
            const carts = cartService.removeItemFromCart(
                prevState.carts,
                supplierId,
                itemId,
            );

            return {
                ...prevState,
                carts: carts,
            };
        });
    };

    const updateDeliveryWindow = (supplierId: string, deliveryWindow: DeliveryWindow | null) => {
        setState(prevState => {
            const currentDeliveryWindow = cartService
                .getDeliveryWindow(prevState.carts, supplierId);

            if (currentDeliveryWindow && deliveryWindow?.equals(currentDeliveryWindow)) {
                return prevState;
            }

            const carts = cartService.updateDeliveryWindow(
                prevState.carts,
                supplierId,
                deliveryWindow,
            );

            return {
                ...prevState,
                carts: carts,
            };
        });
    };

    const updateSelectedCartItems = (items: number[], supplierId: string) => {
        setState(prevState => {
            const currentItems = cartService.getSelectedItems(prevState.carts, supplierId);

            if (arrayEqual(items, currentItems)) {
                return prevState;
            }

            return {
                ...prevState,
                carts: cartService.updateSelectedItems(
                    prevState.carts,
                    supplierId,
                    items,
                ),
            };
        });
    };

    const resetCart = () => {
        setState({
            ...initialCartState,
            hasLoadedFromLocalStorage: true,
        });
        void cartService.removeCart();
    };

    const {
        carts,
        addedSkuIds,
        currentSkuIds,
        hasLoadedFromLocalStorage,
    } = state;

    useEffect(() => {
        const loadCartFromLocalStorage = async () => {
            const carts = await cartService.loadCart();
            const uniqueSkuIds = cartService.getSkuIds(carts);

            setState({
                carts: carts,
                addedSkuIds: uniqueSkuIds,
                currentSkuIds: uniqueSkuIds,
                nextCartItemId: cartService.nextItemId(carts),
                hasLoadedFromLocalStorage: true,
            });
        };

        // Load cart from local storage on component mount only
        if (!hasLoadedFromLocalStorage) {
            void loadCartFromLocalStorage();
            return;
        }

        void cartService.storeCart(carts);
    }, [carts, hasLoadedFromLocalStorage]);

    const cartItemCount = state.carts.reduce(
        (totalCount, cart) => totalCount + cart.items.length, 0);

    const getCarts = (): Cart[] => {
        return carts.map(CartImpl.fromObject);
    };

    const getSelectedCartsAndItems = (): Cart[] => {
        return cartService.getSelectedCartsAndItems(carts);
    };

    const removeSelectedCartsAndItems = () => {
        setState(prevState => {
            const newCarts = cartService.removeSelectedCartsAndItems(carts);

            return {
                ...prevState,
                carts: newCarts,
            };
        });
    };

    return (
        <CartContext.Provider
            value={{
                getCarts,
                getSelectedCartsAndItems,
                cartItemCount,
                addedSkuIds,
                currentSkuIds,
                addToCart,
                updateItemFromCart,
                removeItemFromCart,
                updateDeliveryWindow,
                updateSelectedCartItems,
                resetCart,
                removeSelectedCartsAndItems,
                isInitialized: hasLoadedFromLocalStorage,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
