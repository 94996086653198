import {Configuration, SuppliersApi} from '@/api';
import SelectBox, {Item, MultiSelectProps, OtherSelectBoxProps, SingleSelectProps} from '@/components/SelectBox';
import {DeliveryWindow} from '@/models/DeliveryWindow';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {formatTimeWindow} from '@/utils/formatTimeWindow';

type DeliveryWindowSelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        supplierId?: string | string[];
        storeId?: string;
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        supplierId?: string | string[];
        storeId?: string;
    }
);

export interface DeliveryWindowItem {
    id: string;
    name: string;
    deliveryWindow: DeliveryWindow;
}

const DeliveryWindowSelectBox = (props: DeliveryWindowSelectBoxProps<DeliveryWindowItem>) => {
    const {currentUser} = useAuth();

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const {
        supplierId,
        storeId = currentUser()?.storeId,
        label = 'Delivery date:',
        placeholder = 'Select delivery date',
    } = props;

    const toDeliveryWindowItem = (deliveryWindow: DeliveryWindow): DeliveryWindowItem => {
        return {
            id: deliveryWindow.id,
            name: formatTimeWindow(deliveryWindow),
            deliveryWindow,
        };
    };

    const getDeliveryWindows = async (): Promise<DeliveryWindowItem[]> => {
        if (!supplierId || !storeId || (Array.isArray(supplierId) && supplierId.length === 0)) {
            return [];
        }

        const deliveryWindows = await suppliersApi.getDeliveryWindows(supplierId, storeId);

        return deliveryWindows.map(toDeliveryWindowItem);
    };

    return (
        <SelectBox<DeliveryWindowItem>
            {...props}
            name="deliveryWindow"
            label={label}
            placeholder={placeholder}
            queryKey={[
                'deliveryWindow',
                Array.isArray(supplierId) ? supplierId.join(',') : supplierId || '',
                storeId || '',
            ]}
            queryFn={getDeliveryWindows}
        />
    );
};

export default DeliveryWindowSelectBox;
