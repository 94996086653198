import React, {useState} from 'react';
import Button from '@/components/Button';
import ProductVariantComboBox, {ProductVariantItem} from '@/components/ProductVariantComboBox';
import {ProductList} from '@/models/ProductList';
import {Sku} from '@/models/Sku';
import {CheckIcon} from '@heroicons/react/24/outline';

interface ProductListProductFormProps {
    onSubmit: (sku: Sku) => Promise<void>;
    productList: ProductList;
}

interface FormData {
    sku: Sku | null;
}

const ProductListProductForm = ({
    onSubmit,
    productList,
}: ProductListProductFormProps) => {
    const [formData, setFormData] = useState<FormData>({
        sku: null,
    });

    const handleProductChange = (item?: ProductVariantItem | null) => {
        console.log(item);
        setFormData(prevState => ({
            ...prevState,
            sku: item ? item.sku : null,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        void submit();
    };

    const submit = async () => {
        if (formData.sku) {
            await onSubmit(formData.sku);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4 lg:gap-8 mb-12">
                <div className="col-span-2">
                    <label className="block font-medium text-gray-700 mb-1">
                        Product List:
                    </label>
                    <div className="w-full border shadow rounded-md py-2 pl-3 cursor-default">
                        {productList.name}
                    </div>
                </div>
                <div className="col-span-2">
                    <ProductVariantComboBox
                        value={formData.sku?.id || null}
                        onSelect={handleProductChange}
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => submit()}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default ProductListProductForm;
