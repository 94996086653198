import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import {Status} from '@/models/Status';
import InventoryListTable from '@/modules/supplier/components/InventoryListTable';

const InventoryList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const handleStatusChange = (status: Status, skuId: string) => {
        console.log(status, skuId);
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Inventory"/>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by SKU"
            />

            <InventoryListTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                onChangeStatus={handleStatusChange}
            />
        </div>
    );
};

export default InventoryList;
