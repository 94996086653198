import React from 'react';
import {CustomersApi} from '@/api';
import SelectBox, {SelectBoxProps} from '@/components/SelectBox';
import {Customer} from '@/models/Customer';

const CustomerSelectBox: React.FC<SelectBoxProps<Customer>> = (props) => {
    const customersApi = new CustomersApi();

    const listCustomers = async (): Promise<Customer[]> => {
        const response = await customersApi.listCustomers();
        return response.items;
    };

    const {
        label = 'Customer:',
        placeholder= 'Select customer',
    } = props;

    return (
        <SelectBox<Customer>
            {...props}
            name="customer"
            label={label}
            placeholder={placeholder}
            queryFn={listCustomers}
            queryKey={['customer']}
        />
    );
};

export default CustomerSelectBox;
