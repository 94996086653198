import React from 'react';
import {PriceListsApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {PriceList, PriceListCreate} from '@/models/PriceList';
import PriceListForm from '@/modules/supplier/components/PriceListForm';
import {PriceListPriceListPath} from '@/modules/supplier/paths';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const PriceListEdit: React.FC = () => {
    const {priceListId} = useParams();

    const priceListsApi = new PriceListsApi();

    const {
        isLoading,
        data: priceList,
    } = useQuery({
        queryKey: ['priceLists', priceListId],
        queryFn: async () => {
            return priceListId
                ? priceListsApi.getPriceList(priceListId)
                : null;
        },
    });

    const handleOnSubmit = (price: PriceListCreate | PriceList): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(price);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!priceList) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Price List">
                <Button
                    to={PriceListPriceListPath.withId(priceList.id)}
                    variant="secondary"
                >
                    View Prices
                </Button>

                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Price List
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <PriceListForm
                    onSubmit={handleOnSubmit}
                    priceList={priceList}
                />
            </div>
        </div>
    );
};

export default PriceListEdit;
