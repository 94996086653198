import React, {useRef} from 'react';
import {AriaButtonOptions, useButton} from '@react-aria/button';
import {useFocusRing} from '@react-aria/focus';
import {mergeProps} from '@react-aria/utils';

interface ButtonProps extends AriaButtonOptions<'button'> {
    children: React.ReactNode;
    isDisabled?: boolean;
}

export const CalendarButton: React.FC<ButtonProps> = (props) => {
    const ref = useRef<HTMLButtonElement>(null);

    const {buttonProps} = useButton(props, ref);
    const {focusProps, isFocusVisible} = useFocusRing();

    const {isDisabled, children} = props;

    return (
        <button
            {...mergeProps(buttonProps, focusProps)}
            ref={ref}
            className={`p-2 rounded-full ${isDisabled ? 'text-gray-400' : ''} ${
                !isDisabled ? 'hover:bg-blue-100 active:bg-blue-200' : ''
            } outline-none ${
                isFocusVisible ? 'ring-2 ring-offset-2 ring-blue-600' : ''
            }`}
        >
            {children}
        </button>
    );
};

interface FieldButtonProps extends AriaButtonOptions<'button'> {
    children: React.ReactNode;
    isPressed?: boolean;
}

export const FieldButton: React.FC<FieldButtonProps> = (props) => {
    const ref = useRef<HTMLButtonElement>(null);
    const {buttonProps, isPressed} = useButton(props, ref);
    return (
        <button
            {...buttonProps}
            ref={ref}
            className={`px-2 -ml-px border transition-colors rounded-r-md group-focus-within:border-blue-600 group-focus-within:group-hover:border-blue-600 outline-none ${
                isPressed || props.isPressed
                    ? 'bg-gray-200 border-gray-400'
                    : 'bg-gray-50 border-gray-300 group-hover:border-gray-400'
            }`}
        >
            {props.children}
        </button>
    );
};
