import {Configuration, SuppliersApi} from '@/api';
import CustomToast from '@/components/CustomToast';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {findSupplierAddress, Supplier, SupplierAddress, SupplierAddressType} from '@/models/Supplier';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import SupplierAddressForm from '@/modules/supplier/components/SupplierAddressForm';
import {BuildingOfficeIcon} from '@heroicons/react/24/outline';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import toast from 'react-hot-toast';

const Address = () => {
    const queryClient = useQueryClient();

    const {currentUser} = useAuth();

    const {supplierId} = currentUser() || {};

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const queryKey = ['supplier', supplierId];

    const invalidateQuery = async () => {
        return queryClient.invalidateQueries({queryKey});
    };

    const {
        isLoading,
        data: supplier,
    } = useQuery({
        queryKey,
        queryFn: async () => {
            return supplierId ? await suppliersApi.getSupplier(supplierId) : null;
        },
    });

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!supplier) {
        return;
    }

    const handleSubmitSupplierAddress = async (supplier: Supplier, address: SupplierAddress) => {
        const messages = {
            [SupplierAddressType.BUSINESS]: 'Business address updated successfully!',
            [SupplierAddressType.BILLING]: 'Billing address updated successfully!',
            [SupplierAddressType.UNKNOWN]: 'Address updated successfully!',
        };

        try {
            const updatedAddresses = supplier.addresses.map((addr) =>
                addr.id === address.id ? {...addr, ...address} : addr,
            );

            await suppliersApi.updateSupplier(supplier.id, {
                ...supplier,
                addresses: updatedAddresses,
            });

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={messages[address.type] || 'Address updated successfully!'}
                    visible={t.visible}
                />
            ));

            await invalidateQuery();
        } catch (error) {
            console.error('Error while updating supplier address:', error);
        }
    };

    const businessAddress = findSupplierAddress(supplier.addresses, [SupplierAddressType.BUSINESS]);

    if (!businessAddress) {
        return;
    }

    return (
        <>
            `<SettingsHeader
                title="Business Address"
                icon={<BuildingOfficeIcon className="w-6 h-6"/>}
            />

            <SupplierAddressForm
                address={businessAddress}
                onSubmit={(value) => handleSubmitSupplierAddress(supplier, value)}
                supplierId={supplier.id}
                supplierAddressType={SupplierAddressType.BUSINESS}
            />`
        </>
    );
};

export default Address;
