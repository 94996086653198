import {useState} from 'react';
import Button from '@/components/Button';
import SignupAccountCreateForm from '@/modules/auth/components/SignupAccountCreateForm';
import {SignupAccount} from '@/modules/auth/models/Signup';
import {withLoading} from '@/utils/loading';
import {Radio, RadioGroup} from '@headlessui/react';
import {CheckCircleIcon} from '@heroicons/react/24/solid';
import {useForm} from 'react-hook-form';

interface SignupAccountFormProps {
    onSubmit(data: SignupAccount): Promise<void>;
}

const SignupAccountForm = ({onSubmit}: SignupAccountFormProps) => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors},
    } = useForm<SignupAccount>({
        values: {
            businessType: 'retailer',
            businessName: '',
            action: 'create',
            street1: '',
            street2: '',
            city: '',
        },
    });

    const [loading, setLoading] = useState(false);

    const countryCode = watch('countryCode');

    register('countryCode', {required: true});

    const [
        selectedBusinessType,
        setSelectedBusinessType,
    ] = useState<'retailer' | 'supplier'>('retailer');

    const handleSignup = withLoading(onSubmit, setLoading);

    const setCountryCode = (countryCode?: string) => {
        setValue('countryCode', countryCode);
    };

    const businessTypes = [
        {name: 'Retailer', value: 'retailer'},
        {name: 'Supplier', value: 'supplier'},
    ];

    return (
        <form onSubmit={handleSubmit(handleSignup)} className="w-full px-3">
            <div className="w-full sm:w-[580px] mx-auto">
                <h2 className="text-2xl font-semibold mb-8 text-center">Create an account</h2>
                <div className="flex flex-col gap-4 bg-white p-6 rounded-lg shadow-md w-full sm:w-full sm:p-12 sm:gap-8">
                    <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-600 mb-1.5">
                            Choose Your Business Type:
                        </label>
                        <RadioGroup
                            value={selectedBusinessType}
                            onChange={(value) => {
                                setSelectedBusinessType(value);
                                setValue('businessType', value);
                            }}
                            className="grid grid-cols-2 gap-2"
                        >
                            {businessTypes.map((type) => (
                                <Radio
                                    key={type.value}
                                    value={type.value}
                                    className={({checked}) =>
                                        `relative flex cursor-pointer rounded-lg px-5 py-4 ${
                                            checked ? 'bg-blue-500 text-white' : 'bg-gray-200'
                                        }`
                                    }
                                >
                                    {({checked}) => (
                                        <>
                                            <div className="flex w-full items-center justify-between">
                                                <div className="text-sm">
                                                    <p className="font-semibold">
                                                        {type.name}
                                                    </p>
                                                </div>
                                                {checked && (
                                                    <CheckCircleIcon className="w-6 h-6 text-white"/>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </Radio>
                            ))}
                        </RadioGroup>
                        {errors.businessType && <p className="text-red-700 text-sm">Business type is required.</p>}
                    </div>

                    <SignupAccountCreateForm
                        register={register}
                        errors={errors}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                    />

                    <Button type="submit" variant="primary" loading={loading} className="mt-4">
                        Continue
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default SignupAccountForm;
