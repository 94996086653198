import {FormEvent, useEffect, useState} from 'react';
import Alert from '@/components/Alert';
import ButtonWithDropdown from '@/components/ButtonWithDropdown';
import DateRangePicker from '@/components/datepicker/DateRangePicker';
import OrderStatusSelectBox, {OrderStatusItem} from '@/components/OrderStatusSelectBox';
import OrderTable from '@/components/OrderTable';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import SupplierSelectBox from '@/components/SupplierSelectBox';
import useSearch from '@/hooks/useSearch';
import {Supplier} from '@/models/Supplier';
import {isSupplier} from '@/models/User';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useSearchParams} from 'react-router-dom';

interface State {
    supplierId: string | null;
    orderStatus: string | null;
}

const OrderList = () => {
    const {role} = useAuth();
    const isSupplierRole = role && isSupplier(role);

    const [searchParams, setSearchParams] = useSearchParams();

    const {
        searchQuery,
        currentSearchQuery,
        page,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const supplierIdParam = searchParams.get('supplierId');
    const orderStatusParam = searchParams.get('status');

    const [state, setState] = useState<State>({
        supplierId: supplierIdParam,
        orderStatus: orderStatusParam,
    });

    const search = (searchQuery: string | null, supplierId: string | null, orderStatus: string | null) => {
        setSearchParams(new URLSearchParams({
            page: '1',
            query: searchQuery || '',
            supplierId: supplierId || '',
            status: orderStatus || '',
        }));
    };

    const {
        supplierId,
        orderStatus,
    } = state;

    const handleSupplierSelect = (supplier?: Supplier | null) => {
        setState(prevState => ({
            ...prevState,
            supplierId: supplier?.id || null,
        }));
        search(
            currentSearchQuery,
            supplier?.id || null,
            orderStatus,
        );
    };

    const handleOrderStatusSelect = (orderStatus?: OrderStatusItem | null) => {
        setState(prevState => ({
            ...prevState,
            orderStatus: orderStatus?.id || null,
        }));
        search(
            currentSearchQuery,
            supplierId,
            orderStatus?.id || null,
        );
    };

    const handleSearchSubmit= (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        search(
            currentSearchQuery,
            supplierId,
            orderStatus,
        );
    };

    useEffect(() => {
        setState(prevState => {
            if (supplierIdParam === prevState.supplierId &&
                orderStatusParam === prevState.orderStatus
            ) {
                return prevState;
            }

            return {
                supplierId: supplierIdParam,
                orderStatus: orderStatusParam,
            };
        });
    }, [supplierIdParam, orderStatusParam]);

    return (
        <>
            <PageHeader
                title="Orders"
                alert={isSupplierRole && <Alert type="info" message="&#128075; Welcome! How can we assist you today? It's great to have you here!"/>}
            >
                <ButtonWithDropdown label="Export" options={[
                    {label: 'All Orders', value: 'All Orders'},
                    {label: 'Filtered Orders', value: 'Filtered Orders'},
                    {label: 'Selected Orders', value: 'Selected Orders'},
                ]}/>
            </PageHeader>

            <div className="flex flex-col gap-4">
                <div className="flex-1">
                    <SearchForm
                        as="Fragment"
                        value={searchQuery}
                        onSubmit={handleSearchSubmit}
                        onChange={setSearchQuery}
                        placeholder="Search by order ID, e.g. 123456"
                    />
                </div>
                <div className="flex flex-col lg:flex-row gap-4 mb-8 lg:items-end">
                    <div className="sm:w-96 lg:w-80">
                        <SupplierSelectBox
                            value={supplierId}
                            onSelect={handleSupplierSelect}
                        />
                    </div>
                    <div className="sm:w-96 lg:w-80">
                        <OrderStatusSelectBox
                            value={orderStatus}
                            onSelect={handleOrderStatusSelect}
                        />
                    </div>
                    <div className="sm:w-96 lg:w-80">
                        <DateRangePicker
                            onChange={(value) => console.log(value)}
                            label="Date:"
                            locale="en-GB"
                        />
                    </div>
                </div>
            </div>

            <OrderTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                supplierId={supplierIdParam}
                status={orderStatusParam}
            />
        </>
    );
};

export default OrderList;
