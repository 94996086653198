import {OrganizationsApi} from '@/api';
import SelectBox, {SelectBoxProps} from '@/components/SelectBox';
import {Organization} from '@/models/Organization';

export interface OrganizationItem {
    id: string;
    name: string;
    organization: Organization;
}

const OrganizationSelectBox = (props: SelectBoxProps<OrganizationItem>) => {
    const organizationsApi = new OrganizationsApi();

    const listOrganizations = async (): Promise<OrganizationItem[]> => {
        const response = await organizationsApi.listOrganizations();
        return response.items.map((organization) => {
            return {
                id: organization.id,
                name: organization.name,
                organization,
            };
        });
    };

    const {
        label = 'Organization:',
        placeholder= 'Select organization',
    } = props;

    return (
        <SelectBox<OrganizationItem>
            {...props}
            name="organization"
            label={label}
            placeholder={placeholder}
            queryFn={listOrganizations}
        />
    );
};

export default OrganizationSelectBox;
