import React, {useState} from 'react';
import Button from '@/components/Button';
import ToggleSwitch from '@/components/ToggleSwitch';
import {ProductCategory, ProductCategoryCreate, ProductCategoryUpdate} from '@/models/ProductCategory';
import {isStatusActive} from '@/models/Status';
import {CheckIcon} from '@heroicons/react/24/outline';

interface ProductCategoryFormProps {
    onSubmit: (productCategory: ProductCategoryCreate | ProductCategoryUpdate) => void;
    productCategory?: ProductCategory;
}

const ProductCategoryForm: React.FC<ProductCategoryFormProps> = ({onSubmit, productCategory}) => {
    const [formData, setFormData] = useState<ProductCategoryCreate | ProductCategoryUpdate>(
        productCategory ? {...productCategory} : {
            name: '',
            description: '',
            status: 'ACTIVE',
            supplierId: '',
        });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevProduct => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="description" className="block font-medium text-gray-700">
                        Description:
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="externalCode" className="block font-medium text-gray-700">
                        External Code:
                    </label>
                    <input
                        type="text"
                        id="externalCode"
                        name="externalCode"
                        value={formData.externalCode}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <ToggleSwitch
                        checked={isStatusActive(formData)}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default ProductCategoryForm;
