import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {CustomerCreate, CustomerUpdate} from '@/models/Customer';
import CustomerForm from '@/modules/admin/components/CustomerForm';

const CustomerAdd: React.FC = () => {
    const handleOnSubmit = (customer: CustomerCreate | CustomerUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(customer);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Customer"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <CustomerForm
                    onSubmit={handleOnSubmit}
                />
            </div>
        </div>
    );
};

export default CustomerAdd;
