import React from 'react';
import {Switch} from '@headlessui/react';

interface ToggleSwitchProps {
    checked: boolean;
    onChange: (value: boolean) => void;
    id?: string;
    labelText?: string;
    label?: React.ReactNode;
    className?: string;
    as?: 'div' | 'Fragment';
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    checked,
    onChange,
    id,
    labelText,
    label,
    className,
    as = 'div',
}) => {
    const Component = as === 'Fragment' ? React.Fragment : as;
    const props = as !== 'Fragment'
        ? {className: className ? className : ''}
        : {};

    return (
        <Component {...props}>
            {label || (labelText && <label className="block font-medium text-gray-700 mb-1" htmlFor={id}>{labelText}</label>)}
            <Switch
                id={id}
                checked={checked}
                onChange={onChange}
                className={`mt-1 ${checked ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-200`}
            >
                <span className="sr-only">Toggle</span>
                <span
                    className={`${checked ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200`}
                />
            </Switch>
        </Component>
    );
};

export default ToggleSwitch;
