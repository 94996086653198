interface InventoryIconProps {
    size?: number;
    strokeWidth?: number;
    className?: string;
}

const InventoryIcon = ({
    size = 6,
    strokeWidth = 1.5,
    className = 'text-gray-600 group-hover:text-indigo-600',
}: InventoryIconProps) => {
    const sizeClass = `h-${size} w-${size}`;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 -960 960 960"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            aria-hidden="true"
            className={`${sizeClass} ${className}`}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M620-163 450-333l56-56 114 114 226-226 56 56-282 282Zm220-397h-80v-200h-80v120H280v-120h-80v560h240v80H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v200ZM480-760q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z"
            />
        </svg>
    );
};

export default InventoryIcon;
