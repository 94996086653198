import {
    CalendarAddPath,
    CalendarEditPath,
    CalendarEventAddPath,
    CalendarEventEditPath,
    CalendarEventListPath,
    CalendarListPath,
    InventoryListPath,
    PriceAddPath,
    PriceEditPath,
    PriceListAddPath,
    PriceListEditPath,
    PriceListListPath,
    PriceListPath,
    PriceListPriceAddPath,
    PriceListPriceEditPath,
    PriceListPriceListPath,
    ProductAddPath,
    ProductCategoryAddPath,
    ProductCategoryEditPath,
    ProductCategoryListPath,
    ProductCategoryProductListPath,
    ProductEditPath,
    ProductListAddPath,
    ProductListEditPath,
    ProductListListPath,
    ProductListPath,
    ProductListProductAddPath,
    ProductListProductListPath,
    ProductProductVariantAddPath,
    ProductProductVariantEditPath,
    ProductProductVariantListPath,
    ProductVariantAddPath,
    ProductVariantEditPath,
    ProductVariantListPath,
    PromotionAddPath,
    PromotionEditPath,
    PromotionListPath,
    WarehouseAddPath,
    WarehouseEditPath,
    WarehouseListPath,
} from '@/modules/supplier/paths';
import {
    HomePath,
} from '@/paths';

const home = {label: 'Home', path: HomePath};

const calendarList = {label: 'Calendars', path: CalendarListPath};
const calendarAdd = {label: 'Add', path: CalendarAddPath};
const calendarEdit = {label: 'Edit', path: CalendarEditPath.path};
const calendarEventList = {label: 'Events', path: CalendarEventListPath.path};
const calendarEventAdd = {label: 'Add', path: CalendarEventAddPath.path};
const calendarEventEdit = {label: 'Edit', path: CalendarEventEditPath.path};
const inventoryList = {label: 'Inventory', path: InventoryListPath};
const productList = {label: 'Products', path: ProductListPath};
const productAdd = {label: 'Add', path: ProductAddPath};
const productEdit = {label: 'Edit', path: ProductEditPath.path};
const productCategoryList = {label: 'Product Categories', path: ProductCategoryListPath};
const productCategoryAdd = {label: 'Add', path: ProductCategoryAddPath};
const productCategoryEdit = {label: 'Edit', path: ProductCategoryEditPath.path};
const productCategoryProductList = {label: 'Products', path: ProductCategoryProductListPath.path};
const productListList = {label: 'Product Lists', path: ProductListListPath};
const productListAdd = {label: 'Add', path: ProductListAddPath};
const productListEdit = {label: 'Edit', path: ProductListEditPath.path};
const productListProductList = {label: 'Products', path: ProductListProductListPath.path};
const productListProductAdd = {label: 'Add', path: ProductListProductAddPath.path};
const productProductVariantList = {label: 'Product Variants', path: ProductProductVariantListPath.path};
const productProductVariantAdd = {label: 'Add', path: ProductProductVariantAddPath.path};
const productProductVariantEdit = {label: 'Edit', path: ProductProductVariantEditPath.path};
const productVariantList = {label: 'Product Variants', path: ProductVariantListPath};
const productVariantAdd = {label: 'Add', path: ProductVariantAddPath};
const productVariantEdit = {label: 'Edit', path: ProductVariantEditPath.path};
const priceList = {label: 'Price Lists', path: PriceListListPath};
const priceAdd = {label: 'Add', path: PriceListAddPath};
const priceEdit = {label: 'Edit', path: PriceListEditPath.path};
const priceListList = {label: 'Pricing', path: PriceListListPath};
const priceListAdd = {label: 'Add', path: PriceListAddPath};
const priceListEdit = {label: 'Edit', path: PriceListEditPath.path};
const priceListPriceList = {label: 'Prices', path: PriceListPriceListPath.path};
const priceListPriceAdd = {label: 'Add', path: PriceListPriceAddPath.path};
const priceListPriceEdit = {label: 'Edit', path: PriceListPriceEditPath.path};
const promotionList = {label: 'Promotions', path: PromotionListPath};
const promotionAdd = {label: 'Add', path: PromotionAddPath};
const promotionEdit = {label: 'Edit', path: PromotionEditPath.path};
const warehouseList = {label: 'Warehouses', path: WarehouseListPath};
const warehouseAdd = {label: 'Add', path: WarehouseAddPath};
const warehouseEdit = {label: 'Edit', path: WarehouseEditPath.path};

const breadcrumbs = [
    {
        path: CalendarListPath,
        items: [home, calendarList],
    },
    {
        path: CalendarAddPath,
        items: [home, calendarList, calendarAdd],
    },
    {
        path: CalendarEditPath.path,
        items: [home, calendarList, calendarEdit],
    },
    {
        path: CalendarEventListPath.path,
        items: [home, calendarList, calendarEdit, calendarEventList],
    },
    {
        path: CalendarEventAddPath,
        items: [home, calendarList, calendarEdit, calendarEventList, calendarEventAdd],
    },
    {
        path: CalendarEventEditPath.path,
        items: [home, calendarList, calendarEdit, calendarEventList, calendarEventEdit],
    },
    {
        path: InventoryListPath,
        items: [home, inventoryList],
    },
    {
        path: ProductListPath,
        items: [home, productList],
    },
    {
        path: ProductAddPath,
        items: [home, productList, productAdd],
    },
    {
        path: ProductEditPath.path,
        items: [home, productList, productEdit],
    },
    {
        path: ProductCategoryListPath,
        items: [home, productCategoryList],
    },
    {
        path: ProductCategoryAddPath,
        items: [home, productCategoryList, productCategoryAdd],
    },
    {
        path: ProductCategoryEditPath.path,
        items: [home, productCategoryList, productCategoryEdit],
    },
    {
        path: ProductCategoryProductListPath.path,
        items: [home, productCategoryList, productCategoryEdit, productCategoryProductList],
    },
    {
        path: ProductListListPath,
        items: [home, productListList],
    },
    {
        path: ProductListAddPath,
        items: [home, productListList, productListAdd],
    },
    {
        path: ProductListEditPath.path,
        items: [home, productListList, productListEdit],
    },
    {
        path: ProductListProductListPath.path,
        items: [home, productListList, productListEdit, productListProductList],
    },
    {
        path: ProductListProductAddPath.path,
        items: [home, productListList, productListEdit, productListProductList, productListProductAdd],
    },
    {
        path: ProductProductVariantListPath.path,
        items: [home, productList, productEdit, productProductVariantList],
    },
    {
        path: ProductProductVariantAddPath.path,
        items: [home, productList, productEdit, productProductVariantList, productProductVariantAdd],
    },
    {
        path: ProductProductVariantEditPath.path,
        items: [home, productList, productEdit, productProductVariantList, productProductVariantEdit],
    },
    {
        path: ProductVariantListPath,
        items: [home, productVariantList],
    },
    {
        path: ProductVariantAddPath,
        items: [home, productVariantList, productVariantAdd],
    },
    {
        path: ProductVariantEditPath.path,
        items: [home, productVariantList, productVariantEdit],
    },
    {
        path: PriceListPath,
        items: [home, priceList],
    },
    {
        path: PriceAddPath,
        items: [home, priceList, priceAdd],
    },
    {
        path: PriceEditPath.path,
        items: [home, priceList, priceEdit],
    },
    {
        path: PriceListListPath,
        items: [home, priceListList],
    },
    {
        path: PriceListAddPath,
        items: [home, priceListList, priceListAdd],
    },
    {
        path: PriceListEditPath.path,
        items: [home, priceListList, priceListEdit],
    },
    {
        path: PriceListPriceListPath.path,
        items: [home, priceListList, priceListEdit, priceListPriceList],
    },
    {
        path: PriceListPriceAddPath.path,
        items: [home, priceListList, priceListEdit, priceListPriceList, priceListPriceAdd],
    },
    {
        path: PriceListPriceEditPath.path,
        items: [home, priceListList, priceListEdit, priceListPriceList, priceListPriceEdit],
    },
    {
        path: PromotionListPath,
        items: [home, promotionList],
    },
    {
        path: PromotionAddPath,
        items: [home, promotionList, promotionAdd],
    },
    {
        path: PromotionEditPath.path,
        items: [home, promotionList, promotionEdit],
    },
    {
        path: WarehouseListPath,
        items: [home, warehouseList],
    },
    {
        path: WarehouseAddPath,
        items: [home, warehouseList, warehouseAdd],
    },
    {
        path: WarehouseEditPath.path,
        items: [home, warehouseList, warehouseEdit],
    },
];

export default breadcrumbs;
