import {useEffect, useState} from 'react';
import {Configuration, ProductListsApi} from '@/api';
import {EmptyResult} from '@/models/Pagination';
import {ProductList, ProductLists} from '@/models/ProductList';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {setPaginationResultIfNotLoading} from '@/utils/pagination';
import {useQuery} from '@tanstack/react-query';

const initialState = EmptyResult<ProductList>();

interface FindProductListsQueryResult {
    productLists: ProductLists;
    isLoading: boolean;
}

const useFindProductListsQuery = (page: number, searchQuery?: string): FindProductListsQueryResult => {
    const [productLists, setProductLists] = useState<ProductLists>(initialState);
    const productsApi = new ProductListsApi(new Configuration(useAuth));

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['productLists', page, searchQuery],
        queryFn: () => {
            const limit = 10;
            return productsApi.findProductLists(
                searchQuery,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setPaginationResultIfNotLoading(data, setProductLists, isLoading);
    }, [data, isLoading, isFetching]);

    return {
        productLists,
        isLoading,
    };
};

export default useFindProductListsQuery;
