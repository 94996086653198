import React, {useState} from 'react';
import {Description, Dialog, DialogPanel, DialogTitle} from '@headlessui/react';

interface PromptDialogProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: (inputValue: string) => void;
    title?: string;
    message?: string;
    label?: string;
    inputValue?: string;
    cancelText?: string;
    confirmText?: string;
}

const PromptDialog: React.FC<PromptDialogProps> = ({
    open,
    onCancel,
    onConfirm,
    title = 'Prompt',
    message = 'Are you sure you want to proceed?',
    label = 'Reason:',
    inputValue: initialInputValue = '',
    cancelText = 'Cancel',
    confirmText = 'OK',
}) => {
    const [inputValue, setInputValue] = useState<string>(initialInputValue);

    const handleConfirmClick = () => {
        onConfirm(inputValue);
        onCancel();
    };

    const handleChange = (value: string) => {
        setInputValue(value);
    };

    return (
        <Dialog open={open} onClose={onCancel} className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen bg-black bg-opacity-50">
                <DialogPanel className="max-w-lg w-full bg-white rounded-lg shadow-lg">
                    <DialogTitle className="text-lg font-semibold text-gray-900 p-4">{title}</DialogTitle>
                    <div className="px-4 pb-4">
                        <Description className="text-gray-700">{message}</Description>
                        <div className="mt-4">
                            <label htmlFor="reason" className="block text-sm font-medium text-gray-700">{label}</label>
                            <textarea
                                id="reason"
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full h-24 resize-none"
                                value={inputValue}
                                onChange={(e) => handleChange(e.target.value)}
                            />
                        </div>
                        <div className="mt-4 flex justify-end space-x-4">
                            <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300" onClick={onCancel}>{cancelText}</button>
                            <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700" onClick={handleConfirmClick}>{confirmText}</button>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
};

export default PromptDialog;
