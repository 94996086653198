import {ForwardRefExoticComponent, PropsWithoutRef, ReactNode, RefAttributes, SVGProps} from 'react';
import PageHeader from '@/components/PageHeader';
import {Link} from 'react-router-dom';

export type Icon = ForwardRefExoticComponent<PropsWithoutRef<SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
} & RefAttributes<SVGSVGElement>>

export interface Section {
    href: string;
    title: string;
    icon: Icon;
}

interface SettingsProps {
    children: ReactNode;
    sections: Section[];
}

const Settings = ({children, sections}: SettingsProps) => {
    return (
        <>
            <PageHeader title="Settings" height={10}/>

            <div className="flex flex-col md:flex-row mt-4">
                <nav className="w-full md:w-64 flex-shrink-0 mb-4 md:mb-0 md:mr-8">
                    <ul className="flex md:flex-col flex-wrap md:flex-nowrap gap-2">
                        {sections.map((section) => (
                            <li key={section.title}>
                                <Link
                                    to={section.href}
                                    className="flex p-3 py-2 rounded-lg bg-gray-200 text-black hover:bg-gray-300 text-sm gap-4"
                                >
                                    <section.icon className="w-6 h-6"/>
                                    {section.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>

                <div className="flex-grow">
                    <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Settings;
