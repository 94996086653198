import {createContext, useContext} from 'react';
import {DeliveryWindow} from '@/models/DeliveryWindow';
import {PickupWindow} from '@/models/PickupWindow';
import {Cart} from '@/modules/retailer/models/Cart';
import {CartItem} from '@/modules/retailer/models/CartItem';

export interface CartContextProps {
    getCarts: () => Cart[];
    getSelectedCartsAndItems: () => Cart[];
    removeSelectedCartsAndItems: () => void;
    cartItemCount: number;
    addedSkuIds: string[];
    currentSkuIds: string[];
    addToCart: (
        supplierId: string,
        item: CartItem | CartItem[],
        deliveryWindow?: DeliveryWindow,
        pickupWindow?: PickupWindow,
    ) => void;
    updateItemFromCart: (supplierId: string, itemId: number, quantity: number) => void;
    removeItemFromCart: (supplierId: string, itemId: number) => void;
    updateDeliveryWindow: (supplierId: string, deliveryWindow: DeliveryWindow | null) => void;
    updateSelectedCartItems: (items: number[], supplierId: string) => void;
    resetCart: () => void;
    isInitialized: boolean;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

export default CartContext;
