import {OrganizationsApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {Organization, OrganizationCreate} from '@/models/Organization';
import OrganizationForm from '@/modules/admin/components/OrganizationForm';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const OrganizationEdit = () => {
    const {organizationId} = useParams();

    const organizationsApi = new OrganizationsApi();

    const {
        isLoading,
        data: organization,
    } = useQuery({
        queryKey: ['organization', organizationId],
        queryFn: async () => {
            return organizationId
                ? organizationsApi.getOrganization(organizationId)
                : null;
        },
    });

    const handleOnSubmit = (organization: OrganizationCreate | Organization): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(organization);
                resolve();
            }, 2000);
        });
    };

    const handleOnDelete = () => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(organization);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!organization) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Organization">
                <Button
                    onClick={handleOnDelete}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Organization
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <OrganizationForm
                    onSubmit={handleOnSubmit}
                    organization={organization}
                />
            </div>
        </div>
    );
};

export default OrganizationEdit;
