import useGetSuppliersQuery from '@/api/queries/useGetSuppliersQuery';
import CheckLottieIcon from '@/components/icons/CheckLottieIcon';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {OrderDetailsPath, OrderListPath} from '@/modules/retailer/paths';
import {HomePath} from '@/paths';
import {Link, useLocation} from 'react-router-dom';

const OrderSuccess = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderIds = queryParams.getAll('id');
    const supplierIds = queryParams.getAll('supplierId');

    const {
        isLoading,
        suppliers,
    } = useGetSuppliersQuery(supplierIds);

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    return (
        <div className="flex flex-col flex-1 items-center justify-center">
            <div className="text-center pb-32">
                <div className="w-40 h-40 mb-10 mx-auto text-green-600">
                    <CheckLottieIcon/>
                </div>

                <p className="text-lg mb-4">Your order(s) has been successfully sent to the supplier for their review and fulfillment.</p>

                <ul className="mb-12">
                    {orderIds.map((orderId, index) => (
                        <li key={orderId} className="mb-1">
                            <Link to={OrderDetailsPath.withId(orderId)} className="text-blue-500 hover:underline">
                                #{orderId} - {suppliers.find(supplier => supplier.id === supplierIds[index])?.name}
                            </Link>
                        </li>
                    ))}
                </ul>

                <div className="space-x-4">
                    <Link to={OrderListPath} className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md">
                        Orders
                    </Link>
                    <Link to={HomePath} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">
                        Home
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default OrderSuccess;
