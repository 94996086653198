import React, {useState} from 'react';
import {MinusIcon, PlusIcon} from '@heroicons/react/24/solid';

interface NumberInputProps {
    id?: string;
    value: number | string;
    min?: number;
    max?: number;
    onChange: (value: number) => void;
    className?: string;
}

const NumberInput: React.FC<NumberInputProps> = ({
    id,
    value,
    min = 0,
    max,
    onChange,
    className = '',
}) => {
    if (typeof value !== 'string' && isNaN(value)) {
        value = '';
    }

    if (max !== undefined && typeof value !== 'string' && value > max) {
        value = max;
    }

    const [valueInput, setValueInput] = useState(value);

    const handleIncrement: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        const value = typeof valueInput !== 'string' ? valueInput : 0;
        if (max === undefined || value < max) {
            onChange(value + 1);
            setValueInput(value + 1);
        }
    };

    const handleDecrement: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        const value = typeof valueInput !== 'string' ? valueInput : 0;
        if (min === undefined || value > min) {
            onChange(value - 1);
            setValueInput(value - 1);
        }
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.target.value = e.target.value.replace(/^0+(?!$)/, '');
        const value = parseInt(e.target.value);
        if (max !== undefined && value > max) {
            return;
        }
        if (min !== undefined && value < min) {
            return;
        }
        if (isNaN(value)) {
            onChange(0);
            setValueInput('');
        } else {
            onChange(value);
            setValueInput(value);
        }
    };

    return (
        <div className="flex items-center">
            <button
                className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-2 h-10 focus:ring-gray-100 focus:ring-2 focus:outline-none"
                onClick={handleDecrement}
            >
                <MinusIcon className="h-4 w-4"/>
            </button>
            <input
                id={id}
                type="number"
                value={valueInput}
                min={min}
                max={max}
                onChange={handleChange}
                className={`${className} input-no-spinner w-16 px-1 py-2 text-center border border-x-0 focus:outline-none focus:ring focus:border-blue-300`}
            />
            <button
                className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-2 h-10 focus:ring-gray-100 focus:ring-2 focus:outline-none"
                onClick={handleIncrement}
            >
                <PlusIcon className="h-4 w-4"/>
            </button>
        </div>
    );
};

export default NumberInput;
