import {DeliveryWindow} from '@/models/DeliveryWindow';
import {PickupWindow} from '@/models/PickupWindow';
import {Sku} from '@/models/Sku';
import {Supplier} from '@/models/Supplier';
import {Cart, CartDetails, CartImpl} from '@/modules/retailer/models/Cart';
import {CartItem, CartItemDetails} from '@/modules/retailer/models/CartItem';
import CartStorage, {LocalStorageCartStorage} from '@/modules/retailer/services/CartStorage';
import PriceService from '@/modules/retailer/services/PriceService';

interface AddToCartResult {
    carts: Cart[];
    currentSkuIds: string[];
    nextCartItemId: number;
}

interface CartService {
    addToCart(
        carts: Cart[],
        supplierId: string,
        item: CartItem | CartItem[],
        deliveryWindow?: DeliveryWindow,
        pickupWindow?: PickupWindow,
        nextItemId?: number,
    ): AddToCartResult;
    updateItemFromCart(carts: Cart[], supplierId: string, itemId: number, quantity: number): Cart[];
    removeItemFromCart(carts: Cart[], supplierId: string, itemId: number): Cart[];
    getDeliveryWindow(carts: Cart[], supplierId: string): DeliveryWindow | undefined;
    updateDeliveryWindow(carts: Cart[], supplierId: string, deliveryWindow: DeliveryWindow | null): Cart[];
    getSelectedItems(carts: Cart[], supplierId: string): number[];
    updateSelectedItems(carts: Cart[], supplierId: string, items: number[]): Cart[];
    getSkuIds(carts: Cart[]): string[];
    nextItemId(carts: Cart[]): number;
    loadCart(): Promise<Cart[]>;
    storeCart(carts: Cart[]): Promise<void>;
    removeCart(): Promise<void>;
    getSelectedCartsAndItems(carts: Cart[]): Cart[];
    removeSelectedCartsAndItems(carts: Cart[]): Cart[];
}

class CartServiceImpl implements CartService {
    private readonly storage: CartStorage;
    private readonly priceService: PriceService;

    constructor(storage?: CartStorage, priceService?: PriceService) {
        this.storage = storage || new LocalStorageCartStorage();
        this.priceService = priceService || new PriceService();
    }

    getDeliveryWindow(carts: Cart[], supplierId: string): DeliveryWindow | undefined {
        for (const cart of carts) {
            if (cart.supplierId === supplierId) {
                return cart.deliveryWindow;
            }
        }
        return undefined;
    }

    updateDeliveryWindow(carts: Cart[], supplierId: string, deliveryWindow: DeliveryWindow | null): Cart[] {
        return carts.map(cart => {
            if (cart.supplierId === supplierId) {
                return {
                    ...cart,
                    deliveryWindow: deliveryWindow ? {...deliveryWindow} : undefined,
                };
            }
            return cart;
        });
    }

    addToCart(
        carts: Cart[],
        supplierId: string,
        item: CartItem | CartItem[],
        deliveryWindow?: DeliveryWindow,
        pickupWindow?: PickupWindow,
        nextItemId: number = -1,
    ): AddToCartResult {
        if (nextItemId < 0) {
            nextItemId = this.nextItemId(carts);
        }

        const items = Array.isArray(item) ? item : [item];
        const cartsMap = new Map<string, Cart>();

        carts.forEach(cart => cartsMap.set(cart.supplierId, cart));

        items.forEach((item: CartItem) => {
            item.id = nextItemId++;

            const existingCart = cartsMap.get(supplierId);

            if (existingCart) {
                existingCart.addItem(item);
                return;
            }

            const newCart = new CartImpl(
                supplierId,
                item,
                deliveryWindow,
                pickupWindow,
            );

            cartsMap.set(supplierId, newCart);
        });

        const newCarts = Array.from(cartsMap.values());

        const currentSkuIds = Array.from(
            new Set(
                newCarts.flatMap(cart => {
                    return cart.items.map(item => {
                        return item.skuId;
                    });
                }),
            ),
        );

        return {
            carts: newCarts,
            currentSkuIds: currentSkuIds,
            nextCartItemId: nextItemId,
        };
    }

    updateItemFromCart(carts: Cart[], supplierId: string, itemId: number, quantity: number): Cart[] {
        return carts.map(cart => {
            if (cart.supplierId === supplierId) {
                const updatedItems = cart.items.map(item => {
                    if (item.id === itemId) {
                        return {...item, quantity};
                    }
                    return item;
                });
                return {...cart, items: updatedItems};
            }
            return cart;
        });
    }

    removeItemFromCart(carts: Cart[], supplierId: string, itemId: number): Cart[] {
        return carts.map(cart => {
            if (cart.supplierId === supplierId) {
                return {
                    ...cart,
                    items: cart.items.filter(item => item.id !== itemId),
                };
            }
            return cart;
        });
    }

    getSelectedItems(carts: Cart[], supplierId: string): number[] {
        return carts.flatMap(cart =>
            cart.items
                .filter(item => item.supplierId === supplierId && item.checked)
                .map(item => item.id),
        );
    }

    updateSelectedItems(carts: Cart[], supplierId: string, items: number[]): Cart[] {
        return carts.map(cart => {
            if (cart.supplierId === supplierId) {
                cart.items = cart.items.map(item => {
                    item.checked = items.includes(item.id);
                    return item;
                });
            }
            return cart;
        });
    }

    async loadCart(): Promise<Cart[]> {
        return this.storage.loadCart();
    }

    async storeCart(carts: Cart[]): Promise<void> {
        return this.storage.storeCart(carts);
    }

    async removeCart(): Promise<void> {
        return this.storage.removeCart();
    }

    getSelectedCartsAndItems(carts: Cart[]): Cart[] {
        const selectedCarts = carts.filter(cart => cart.items.some(item => item.checked));
        return selectedCarts.map(cart => CartImpl.fromObject(cart).withItems(cart.items.filter(item => item.checked)));
    }

    removeSelectedCartsAndItems(carts: Cart[]): Cart[] {
        const updatedSupplierCarts = carts.map(cart => {
            const updatedItems = cart.items.filter(item => !item.checked);
            return {...cart, items: updatedItems};
        });

        return updatedSupplierCarts.filter(cart => cart.items.length > 0);
    }

    getSkuIds(carts: Cart[]): string[] {
        return Array.from(new Set(
            carts.flatMap(cart => {
                return cart.items.map(item => item.skuId);
            }),
        ));
    }

    nextItemId(carts: Cart[]): number {
        return carts
            .flatMap(cart => cart.items)
            .reduce((maxId, cartItem) => Math.max(maxId, cartItem.id), 0) + 1;
    }

    getCartDetailsForCart(
        cart: Cart,
        skus: Sku[],
        supplier: Supplier,
        currencyCode: string,
    ): CartDetails | undefined {
        const items: CartItemDetails[] = [];

        for (const item of cart.items) {
            const sku = skus.find(sku => sku.id === item.skuId);
            if (sku) {
                const itemDetails = this.getCartItemDetails(item, sku);
                if (itemDetails) {
                    items.push(itemDetails);
                }
            }
        }

        if (items.length === 0) {
            return;
        }

        const subtotal = this.priceService.calculateOrderSubtotal(items);
        const grandTotal = this.priceService.calculateOrderGrandTotal(items);

        if (subtotal === undefined || grandTotal === undefined) {
            return;
        }

        return {
            deliveryWindow: cart.deliveryWindow,
            pickupWindow: cart.pickupWindow,
            currencyCode: currencyCode,
            supplierId: cart.supplierId,
            supplierName: supplier.name,
            items: items,
            subtotal: subtotal,
            grandTotal: grandTotal,
        };
    }

    getCartDetails(
        carts: Cart[],
        skus: Sku[],
        suppliers: Supplier[],
        currencyCode: string,
    ): CartDetails[] {
        const items: CartDetails[] = [];

        for (const cart of carts) {
            const supplier = suppliers?.find(supplier => supplier.id === cart.supplierId);
            if (supplier) {
                const itemDetails = this.getCartDetailsForCart(cart, skus, supplier, currencyCode);
                if (itemDetails) {
                    items.push(itemDetails);
                }
            }
        }

        return items;
    }

    private getCartItemDetails(cartItem: CartItem, sku: Sku): CartItemDetails | undefined {
        const unitPrice = this.priceService.calculateUnitPrice(sku);
        const lineItemPrice = this.priceService.calculateLineItemPrice(sku, cartItem.quantity);

        if (unitPrice === undefined || lineItemPrice === undefined) {
            return;
        }

        const vatRate = sku.product?.vatRate || 0;
        const vatAmount = unitPrice.amount * (vatRate / 100);

        return {
            id: cartItem.id,
            supplierId: cartItem.supplierId,
            skuId: cartItem.skuId,
            sku: {
                code: sku.code,
                name: sku.name,
                image: sku.images[0],
            },
            quantity: cartItem.quantity,
            price: unitPrice,
            unitPrice: unitPrice,
            lineItemPrice: lineItemPrice,
            vatAmount: {
                currencyCode: unitPrice.currencyCode,
                amount: vatAmount,
            },
            vatRate: vatRate,
        };
    }
}

export default CartServiceImpl;
