import {createCalendar} from '@internationalized/date';
import React, {useRef} from 'react';
import DateSegment from '@/components/datepicker/DateSegment';
import {DateValue} from '@react-aria/calendar';
import {AriaDatePickerProps, useDateField} from '@react-aria/datepicker';
import {useLocale} from '@react-aria/i18n';
import {useDateFieldState} from '@react-stately/datepicker';

export const DateField: React.FC<AriaDatePickerProps<DateValue>> = (props) => {
    const {locale} = useLocale();
    const state = useDateFieldState({
        ...props,
        locale,
        createCalendar,
    });

    const ref = useRef<HTMLDivElement>(null);
    const {fieldProps} = useDateField(props, state, ref);

    return (
        <div {...fieldProps} ref={ref} className="flex">
            {state.segments.map((segment, i) => (
                <DateSegment key={i} segment={segment} state={state}/>
            ))}
        </div>
    );
};

export default DateField;
