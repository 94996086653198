import React, {ReactElement, ReactNode} from 'react';
import useSelectBox, {MultiSelectProps, SingleSelectProps} from '@/hooks/useSelectBox';
import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Transition,
} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';

export type {MultiSelectProps, SingleSelectProps} from '@/hooks/useSelectBox';

export interface Item {
    id: string;
    name: string;
}

export interface OtherSelectBoxProps<T extends Item> {
    name?: string;
    label?: string | ReactElement;
    hideLabel?: true;
    placeholder?: string;
    queryKey?: string[];
    queryFn?: () => Promise<T[]>;
    allowEmptySelection?: true;
    requireSelection?: true;
    disabled?: boolean;
    defaultValue?: 'first' | 'all' | 'none';
    itemRender?: (item: T, selected?: boolean) => ReactElement;
}

export type SelectBoxProps<T extends Item> =
    | (SingleSelectProps<T> & OtherSelectBoxProps<T> & { multiple?: false })
    | (MultiSelectProps<T> & OtherSelectBoxProps<T> & { multiple: true });

const SelectBox = <T extends Item>({
    name,
    value,
    label,
    hideLabel,
    placeholder,
    queryKey = name ? [name] : [],
    queryFn,
    onSelect,
    allowEmptySelection = true,
    requireSelection,
    disabled = false,
    multiple,
    defaultValue = requireSelection ? 'first' : 'none',
    itemRender,
}: SelectBoxProps<T>): ReactNode => {
    const {
        isLoading,
        data,
    } = useQuery({
        queryKey,
        queryFn,
    });

    const props = multiple
        ? {value, multiple, onSelect}
        : {value, multiple, onSelect};

    const {
        handleChange,
        handleDeselect,
        state,
    } = useSelectBox<T>(data, props, defaultValue);

    const {
        selected,
        multiple: multipleSelectedItems,
    } = state;

    const items = data ? data : [];

    return (
        <Listbox
            value={selected}
            onChange={handleChange}
            disabled={disabled}
            multiple={multiple}
        >
            {!hideLabel && (
                <Label className={label && React.isValidElement(label) ? '' : 'block mb-1'}>
                    {label}
                </Label>
            )}
            <div className="relative">
                <ListboxButton
                    className="relative w-full cursor-default border rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                    <span className="block truncate">{
                        isLoading
                            ? 'Loading ...'
                            : multipleSelectedItems && selected?.length > 0
                                ? selected.map((item) => item.name).join(', ')
                                : !multipleSelectedItems && selected
                                    ? (itemRender ? itemRender(selected) : selected.name)
                                    : (placeholder ? placeholder : '')}

                        {!itemRender ? ' ' : ''}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </ListboxButton>

                <Transition
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <ListboxOptions
                        className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {allowEmptySelection && !requireSelection ? (
                            <ListboxOption
                                key=''
                                value={null}
                                onClick={() => handleDeselect()}
                                className={({focus}) => `${focus ? 'text-white bg-blue-600' : 'text-gray-900'} cursor-pointer select-none relative py-4`}
                            >
                                {({focus, selected}) => (
                                    <>
                                        {selected ? (
                                            <span className={`${focus ? 'text-white' : 'text-gray-900'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                        </span>
                                        ) : null}
                                    </>
                                )}
                            </ListboxOption>
                        ) : ''}
                        {items.map((item, index) => (
                            <ListboxOption
                                key={item.id+index}
                                value={item}
                                className={({focus}) => `${focus ? 'text-white bg-blue-600' : 'text-gray-900'} cursor-pointer select-none relative ${item.name === '' ? 'py-4' : 'py-2'} pl-10 pr-4`}
                            >
                                {({focus, selected}) => (
                                    <>
                                        {itemRender ? itemRender(item, selected) : <span
                                            className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                            {item.name}
                                        </span>}

                                        {selected ? (
                                            <span className={`${focus ? 'text-white' : 'text-gray-900'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </Transition>
            </div>
        </Listbox>
    );
};

export default SelectBox;
