import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {User} from '@/models/User';
import UserForm from '@/modules/admin/components/UserForm';

const UserAdd = () => {
    const handleOnSubmit = (user: User): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(user);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create User"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <UserForm
                    onSubmit={handleOnSubmit}
                />
            </div>
        </div>
    );
};

export default UserAdd;
