import {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Configuration, NotificationsApi} from '@/api';
import useScreenVisibility from '@/hooks/useScreenVisibility';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import NotificationContext from '@/modules/notification/contexts/NotificationContext';
import {Notification} from '@/modules/notification/models/Notification';
import NotificationService from '@/modules/notification/services/NotificationService';
import NotificationStorage from '@/modules/notification/services/NotificationStorage';
import {useIdleTimer} from 'react-idle-timer';

interface NotificationState {
    notifications: Notification[];
    hasLoadedFromLocalStorage: boolean;
    hasRefreshed: boolean;
}

interface NotificationsProviderProps {
    idleTimeout?: number;
    idleDebounce?: number;
    refreshInterval?: number;
    children: ReactNode;
}

const minute = 1000 * 60;
const second = 1000;

const NotificationsProvider = ({
    idleTimeout = minute * 5,
    idleDebounce = second,
    refreshInterval = minute * 2,
    children,
}: NotificationsProviderProps) => {
    const auth = useAuth();
    const userId = auth.currentUser()?.id;

    const [state, setState] = useState<NotificationState>({
        notifications: [],
        hasLoadedFromLocalStorage: false,
        hasRefreshed: false,
    });

    const notificationsApi =  useMemo(() =>
        new NotificationsApi(new Configuration(auth)),
        [auth],
    );

    const notificationService = useMemo(() =>
        new NotificationService(
            notificationsApi,
            new NotificationStorage({key: userId}),
        ),
        [notificationsApi, userId],
    );

    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const addNotification = (notification: Notification) => {
        setState(prevState => ({
            ...prevState,
            notifications: [
                ...prevState.notifications,
                notification,
            ],
        }));
    };

    const markNotificationAsRead = (id: string) => {
        try {
            void notificationsApi.markNotificationsAsRead(id);
        } catch (error) {
            console.error('Error while marking notifications as read:', error);
        }

        setState(prevState => ({
            ...prevState,
            notifications: prevState.notifications.map(notification =>
                notification.id === id ? {...notification, read: true} : notification,
            ),
        }));
    };

    const {
        notifications,
        hasLoadedFromLocalStorage,
        hasRefreshed,
    } = state;

    const refreshNotifications = useCallback(async () => {
        try {
            const newNotifications = await notificationService.loadNotifications(true);

            setState(prevState => ({
                ...prevState,
                notifications: newNotifications,
                hasRefreshed: true,
            }));
        } catch (error) {
            console.error('Failed to refresh notifications:', error);
        }
    }, [notificationService]);

    useEffect(() => {
        const loadNotificationsFromLocalStorage = async () => {
            try {
                const notifications = await notificationService.loadNotifications();

                setState(prevState => ({
                    ...prevState,
                    notifications: prevState.notifications.length > 0 && notifications.length === 0
                        ? prevState.notifications
                        : notifications,
                    hasLoadedFromLocalStorage: true,
                }));
            } catch (error) {
                console.error('Failed to load notifications:', error);
            }
        };

        if (!hasLoadedFromLocalStorage) {
            void loadNotificationsFromLocalStorage();
            return;
        }

        if (!hasRefreshed) {
            void refreshNotifications();
            return;
        }

        void notificationService.storeNotifications(notifications);
    }, [notifications, hasLoadedFromLocalStorage, hasRefreshed, notificationService, refreshNotifications]);

    const startInterval = () => {
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                void refreshNotifications();
            }, refreshInterval);

            void refreshNotifications();
        }
    };

    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const {reset} = useIdleTimer({
        timeout: idleTimeout,
        onIdle: () => stopInterval(),
        onActive: () => startInterval(),
        onAction: () => reset(),
        debounce: idleDebounce,
    });

    useScreenVisibility({
        onVisible: () => {
            void refreshNotifications();
            reset();
        },
    });

    return (
        <NotificationContext.Provider
            value={{
                addNotification,
                markNotificationAsRead,
                refreshNotifications,
                ...state,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationsProvider;
