import {
    CustomerAdd,
    CustomerEdit,
    CustomerList,
    OrganizationAdd,
    OrganizationEdit,
    OrganizationList,
    StoreAdd,
    StoreEdit,
    StoreList,
    SupplierAdd,
    SupplierEdit,
    SupplierList,
    SupplierToCustomerAdd,
    SupplierToCustomerList,
    SupplierToStoreAdd,
    SupplierToStoreList,
    UserAdd,
    UserEdit,
    UserList,
} from '@/modules/admin/pages';
import {
    CustomerAddPath,
    CustomerEditPath,
    CustomerListPath,
    CustomerStoreAddPath,
    CustomerStoreEditPath,
    CustomerStoreListPath,
    OrganizationAddPath,
    OrganizationEditPath,
    OrganizationListPath,
    SupplierAddPath,
    SupplierCalendarAddPath,
    SupplierCalendarEditPath,
    SupplierCalendarEventAddPath,
    SupplierCalendarEventEditPath,
    SupplierCalendarEventListPath,
    SupplierCalendarListPath,
    SupplierEditPath,
    SupplierListPath,
    SupplierToCustomerAddPath,
    SupplierToCustomerListPath,
    SupplierToStoreAddPath,
    SupplierToStoreListPath,
    SupplierWarehouseAddPath,
    SupplierWarehouseEditPath,
    SupplierWarehouseListPath,
    UserAddPath,
    UserEditPath,
    UserListPath,
} from '@/modules/admin/paths';
import PrivateRoute from '@/modules/auth/components/PrivateRoute';
import {
    CalendarAdd,
    CalendarEdit,
    CalendarEventAdd,
    CalendarEventEdit,
    CalendarEventList,
    CalendarList,
    WarehouseAdd,
    WarehouseEdit,
    WarehouseList,
} from '@/modules/supplier/pages';
import {Routes as ReactRoutes, Route} from 'react-router-dom';

const Routes = () => (
    <ReactRoutes>
        <Route element={<PrivateRoute/>}>
            <Route path={OrganizationListPath} Component={OrganizationList}/>
            <Route path={OrganizationAddPath} Component={OrganizationAdd}/>
            <Route path={OrganizationEditPath.path} Component={OrganizationEdit}/>
            <Route path={UserListPath} Component={UserList}/>
            <Route path={UserAddPath} Component={UserAdd}/>
            <Route path={UserEditPath.path} Component={UserEdit}/>
            <Route path={CustomerListPath} Component={CustomerList}/>
            <Route path={CustomerAddPath} Component={CustomerAdd}/>
            <Route path={CustomerEditPath.path} Component={CustomerEdit}/>
            <Route path={SupplierListPath} Component={SupplierList}/>
            <Route path={SupplierAddPath} Component={SupplierAdd}/>
            <Route path={SupplierEditPath.path} Component={SupplierEdit}/>
            <Route path={SupplierWarehouseListPath.path} Component={WarehouseList}/>
            <Route path={SupplierWarehouseAddPath.path} Component={WarehouseAdd}/>
            <Route path={SupplierWarehouseEditPath.path} Component={WarehouseEdit}/>
            <Route path={SupplierCalendarListPath.path} Component={CalendarList}/>
            <Route path={SupplierCalendarAddPath.path} Component={CalendarAdd}/>
            <Route path={SupplierCalendarEditPath.path} Component={CalendarEdit}/>
            <Route path={SupplierCalendarEventListPath.path} Component={CalendarEventList}/>
            <Route path={SupplierCalendarEventAddPath.path} Component={CalendarEventAdd}/>
            <Route path={SupplierCalendarEventEditPath.path} Component={CalendarEventEdit}/>
            <Route path={CustomerStoreListPath.path} Component={StoreList}/>
            <Route path={CustomerStoreAddPath.path} Component={StoreAdd}/>
            <Route path={CustomerStoreEditPath.path} Component={StoreEdit}/>
            <Route path={SupplierToCustomerListPath.path} Component={SupplierToCustomerList}/>
            <Route path={SupplierToCustomerAddPath.path} Component={SupplierToCustomerAdd}/>
            <Route path={SupplierToStoreListPath.path} Component={SupplierToStoreList}/>
            <Route path={SupplierToStoreAddPath.path} Component={SupplierToStoreAdd}/>
        </Route>
    </ReactRoutes>
);

export {Routes as AdminRoutes};

export default Routes;
