import {UserRole} from '@/models/User';
import {OrganizationListPath} from '@/modules/admin/paths';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {LoginPath} from '@/modules/auth/paths';
import {OrderListPath, ShopPath} from '@/modules/retailer/paths';
import {Navigate} from 'react-router-dom';

const Home = () => {
    const {isLoggedIn, role} = useAuth();

    if (isLoggedIn) {
        switch (role) {
            case UserRole.Customer:
            case UserRole.Retailer:
                return <Navigate to={ShopPath.path} replace/>;
            case UserRole.Supplier:
                return <Navigate to={OrderListPath} replace/>;
            case UserRole.Admin:
                return <Navigate to={OrganizationListPath} replace/>;
        }
    }

    return <Navigate to={LoginPath} replace/>;
};

export default Home;
