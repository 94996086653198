import React from 'react';
import SelectBox, {
    Item,
    MultiSelectProps,
    OtherSelectBoxProps,
    SingleSelectProps,
} from '@/components/SelectBox';
import {getTimezones} from '@/utils/getTimezones';

type TimezoneSelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        countryCode?: string;
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        countryCode?: string;
    }
);

export interface TimezoneItem {
    id: string;
    name: string;
}

const TimezoneSelectBox: React.FC<TimezoneSelectBoxProps<TimezoneItem>> = (props) => {
    const {
        label = 'Timezone:',
        placeholder= 'Select timezone',
        countryCode,
    } = props;

    const listTimezones = (): Promise<TimezoneItem[]> => {
        return new Promise((resolve, reject) => {
            if (countryCode === undefined || countryCode === '') {
                reject('Country code is missing');
                return;
            }
            const timezones = getTimezones(countryCode);
            const timezoneItems = timezones.map((timezoneInfo): TimezoneItem => {
                return {
                    id: timezoneInfo.name,
                    name: timezoneInfo.name,
                };
            });
            resolve(timezoneItems);
        });
    };

    return (
        <SelectBox<TimezoneItem>
            {...props}
            name="timezone"
            label={label}
            placeholder={placeholder}
            queryKey={['timezone', String(countryCode)]}
            queryFn={listTimezones}
        />
    );
};

export default TimezoneSelectBox;
