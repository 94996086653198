import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {Organization, OrganizationCreate} from '@/models/Organization';
import OrganizationForm from '@/modules/admin/components/OrganizationForm';

const OrganizationAdd = () => {
    const handleOnSubmit = (organization: OrganizationCreate | Organization): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(organization);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Organization"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <OrganizationForm onSubmit={handleOnSubmit}/>
            </div>
        </div>
    );
};

export default OrganizationAdd;
