import React from 'react';
import {PriceListsApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {Price, PriceCreate} from '@/models/Price';
import PriceForm from '@/modules/supplier/components/PriceForm';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const PriceAdd: React.FC = () => {
    const {priceListId} = useParams();

    const priceListsApi = new PriceListsApi();

    const {
        isLoading,
        data: priceList,
    } = useQuery({
        queryKey: ['priceList', priceListId],
        queryFn: async () => {
            return priceListId
                ? priceListsApi.getPriceList(priceListId)
                : null;
        },
    });

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!priceList) {
        return (
            <div className="flex justify-center py-20">
                Something went wrong ...
            </div>
        );
    }

    const handleOnSubmit = (price: PriceCreate | Price): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(price);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Price"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <PriceForm
                    onSubmit={handleOnSubmit}
                    priceList={priceList}
                />
            </div>
        </div>
    );
};

export default PriceAdd;
