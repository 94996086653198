import {ChangeEvent, FormEvent, useState} from 'react';
import Button from '@/components/Button';
import ProductComboBox from '@/components/ProductComboBox';
import ToggleSwitch from '@/components/ToggleSwitch';
import {Product} from '@/models/Product';
import {SkuCreate, SkuUpdate} from '@/models/Sku';
import {isStatusActive} from '@/models/Status';
import {CheckIcon} from '@heroicons/react/24/outline';

interface ProductVariantFormProps {
    onSubmit: (product: SkuCreate | SkuUpdate) => void;
    productId?: string;
    supplierId?: string;
    sku?: SkuCreate | SkuUpdate;
}

const ProductVariantForm = ({
    onSubmit,
    sku,
    productId,
    supplierId,
}: ProductVariantFormProps) => {
    const [formData, setFormData] = useState<SkuCreate | SkuUpdate>(
        sku
            ? {...sku}
            : {
                id: '',
                code: '',
                productId: productId || '',
                supplierId: supplierId || '',
                name: '',
                description: '',
                quantity: 0,
                moq: 0,
                status: 'ACTIVE',
            },
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevProduct => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    const handleProductChange = (product?: Product | null) => {
        setFormData(prevData => ({...prevData, productId: product?.id || ''}));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                {!productId && <div className="col-span-2">
                    <ProductComboBox
                        value={formData.productId || null}
                        onSelect={handleProductChange}
                    />
                </div>}
                <div className="col-span-2">
                    <label htmlFor="externalCode" className="block font-medium text-gray-700">
                        External Code:
                    </label>
                    <input
                        type="text"
                        id="externalCode"
                        name="externalCode"
                        value={formData.externalCode}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="description" className="block font-medium text-gray-700">
                        Description:
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="quantity" className="block font-medium text-gray-700">
                        Quantity:
                    </label>
                    <input
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="moq" className="block font-medium text-gray-700">
                        Minimum Order Quantity (MOQ):
                    </label>
                    <input
                        type="number"
                        id="moq"
                        name="moq"
                        value={formData.moq}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <ToggleSwitch
                        checked={isStatusActive(formData)}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default ProductVariantForm;
