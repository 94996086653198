import Button from '@/components/Button';
import AutoOrderButton from '@/modules/retailer/components/AutoOrderButton';
import {ShopPath} from '@/modules/retailer/paths';
import {ShoppingCartIcon} from '@heroicons/react/24/outline';

const CartEmpty = () => {
    return (
        <div className="flex flex-col flex-1 items-center justify-center">
            <div className="text-center pb-40">
                <ShoppingCartIcon className="h-20 w-20 mb-8 mx-auto"/>

                <p className="mb-4 font-medium">Your cart is currently empty.</p>

                <p className="mb-12">Continue shopping to add products manually or use the order prediction to populate the cart for you.</p>

                <div className="flex flex-col-reverse sm:flex-row gap-4 items-center justify-center">
                    <Button to={ShopPath.path} variant="secondary" className="w-full sm:w-auto">
                        Continue Shopping
                    </Button>

                    <AutoOrderButton
                        variant="success"
                        loadingIconClassName="w-5 h-5 mr-1 fill-green-600"
                        className="w-full sm:w-auto"
                    >
                        Auto-Order
                    </AutoOrderButton>
                </div>
            </div>
        </div>
    );
};

export default CartEmpty;
