import {createContext, useContext} from 'react';
import {Notification} from '@/modules/notification/models/Notification';

interface NotificationContextProps {
    notifications: Notification[];
    addNotification: (notification: Notification) => void;
    markNotificationAsRead: (id: string) => void;
    refreshNotifications: () => void;
}

const NotificationContext = createContext<NotificationContextProps>({
    notifications: [],
    addNotification: () => {},
    markNotificationAsRead: () => {},
    refreshNotifications: () => {},
});

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationContext');
    }
    return context;
};

export default NotificationContext;
