export const ProductListPath = '/products';
export const ProductAddPath = '/products/add';
export const ProductEditPath = {
    path: '/products/:productId',
    withId: (id: string): string => {
        return ProductEditPath.path.replace(':productId', id);
    },
};
export const ProductProductVariantListPath = {
    path: '/products/:productId/variants',
    withProductId: (id: string): string => {
        return ProductProductVariantListPath.path.replace(':productId', id);
    },
};
export const ProductProductVariantAddPath = {
    path: '/products/:productId/variants/add',
    withProductId: (productId: string): string => {
        return ProductProductVariantAddPath.path.replace(':productId', productId);
    },
};
export const ProductProductVariantEditPath = {
    path: '/products/:productId/variants/:skuId',
    withParams: (productId: string, skuId: string): string => {
        return ProductProductVariantEditPath.path
            .replace(':productId', productId)
            .replace(':skuId', skuId);
    },
};
export const ProductVariantListPath = '/products/variants';
export const ProductVariantAddPath = '/products/variants/add';
export const ProductVariantEditPath = {
    path: '/products/variants/:skuId',
    withId: (id: string): string => {
        return ProductVariantEditPath.path.replace(':skuId', id);
    },
};
export const InventoryListPath = '/inventory';
export const ProductCategoryListPath = '/products/categories';
export const ProductCategoryAddPath = '/products/categories/add';
export const ProductCategoryEditPath = {
    path: '/products/categories/:productCategoryId',
    withId: (id: string): string => {
        return ProductCategoryEditPath.path.replace(':productCategoryId', id);
    },
};
export const ProductCategoryProductListPath = {
    path: '/products/categories/:productCategoryId/products',
    withId: (id: string): string => {
        return ProductCategoryProductListPath.path.replace(':productCategoryId', id);
    },
};
export const ProductListListPath = '/products/lists';
export const ProductListAddPath = '/products/lists/add';
export const ProductListEditPath = {
    path: '/products/lists/:productListId',
    withId: (id: string): string => {
        return ProductListEditPath.path.replace(':productListId', id);
    },
};
export const ProductListProductListPath = {
    path: '/products/lists/:productListId/products',
    withProductListId: (productListId: string): string => {
        return ProductListProductListPath.path.replace(':productListId', productListId);
    },
};
export const ProductListProductAddPath = {
    path: '/products/lists/:productListId/products/add',
    withProductListId: (productListId: string): string => {
        return ProductListProductAddPath.path.replace(':productListId', productListId);
    },
};
export const PriceListPath = '/prices';
export const PriceAddPath = '/prices/add';
export const PriceEditPath = {
    path: '/prices/:priceId',
    withId: (id: string): string => {
        return PriceEditPath.path.replace(':priceId', id);
    },
};
export const PriceListListPath = '/pricing/lists';
export const PriceListAddPath = '/pricing/lists/add';
export const PriceListEditPath = {
    path: '/pricing/lists/:priceListId',
    withId: (id: string): string => {
        return PriceListEditPath.path.replace(':priceListId', id);
    },
};
export const PriceListPriceListPath = {
    path: '/pricing/lists/:priceListId/prices',
    withId: (id: string): string => {
        return PriceListPriceListPath.path.replace(':priceListId', id);
    },
};
export const PriceListPriceAddPath = {
    path: '/pricing/lists/:priceListId/prices/add',
    withId: (id: string): string => {
        return PriceListPriceAddPath.path.replace(':priceListId', id);
    },
};
export const PriceListPriceEditPath = {
    path: '/pricing/lists/:priceListId/prices/:priceId',
    withPriceListIdAndPriceId: (priceListId: string, priceId: string): string => {
        return PriceListPriceEditPath.path
            .replace(':priceListId', priceListId)
            .replace(':priceId', priceId);
    },
};
export const PromotionListPath = '/promotions';
export const PromotionAddPath = 'promotions/add';
export const PromotionEditPath = {
    path: '/promotions/:promotionId',
    withId: (promotionId: string): string => {
        return PromotionEditPath.path.replace(':promotionId', promotionId);
    },
};
export const OrganizationEditPath = {
    path: '/organizations/:organizationId',
    withId: (id: string): string => {
        return OrganizationEditPath.path.replace(':organizationId', id);
    },
};
export const UserEditPath = {
    path: '/users/:userId',
    withId: (id: string): string => {
        return UserEditPath.path.replace(':userId', id);
    },
};
export const CustomerAddPath = '/customers/add';
export const CustomerEditPath = {
    path: '/customers/:customerId',
    withId: (id: string): string => {
        return CustomerEditPath.path.replace(':customerId', id);
    },
};
export const SupplierEditPath = {
    path: '/suppliers/:supplierId',
    withId: (id: string): string => {
        return SupplierEditPath.path.replace(':supplierId', id);
    },
};
export const StoreEditPath = {
    path: '/stores/:storeId',
    withId: (id: string): string => {
        return StoreEditPath.path.replace(':storeId', id);
    },
};

export const WarehouseListPath = '/warehouses';
export const WarehouseAddPath = 'warehouses/add';
export const WarehouseEditPath = {
    path: '/warehouses/:warehouseId',
    withId: (warehouseId: string): string => {
        return WarehouseEditPath.path.replace(':warehouseId', warehouseId);
    },
};

export const CalendarListPath = '/calendars';
export const CalendarAddPath = 'calendars/add';
export const CalendarEditPath = {
    path: '/calendars/:calendarId',
    withId: (calendarId: string): string => {
        return CalendarEditPath.path.replace(':calendarId', calendarId);
    },
};

export const CalendarEventListPath = {
    path: '/calendars/:calendarId/events',
    withCalendarId: (calendarId: string): string => {
        return CalendarEventListPath.path.replace(':calendarId', calendarId);
    },
};
export const CalendarEventAddPath = {
    path: '/calendars/:calendarId/events/add',
    withCalendarId: (calendarId: string): string => {
        return CalendarEventAddPath.path.replace(':calendarId', calendarId);
    },
};
export const CalendarEventEditPath = {
    path: '/calendars/:calendarId/events/:calendarEventId',
    withParams: (calendarId: string, calendarEventId: string): string => {
        return CalendarEventEditPath.path
            .replace(':calendarId', calendarId)
            .replace(':calendarEventId', calendarEventId);
    },
};

export const SettingsProductAddPath = '/settings/products/add';
export const SettingsProductEditPath = {
    path: '/settings/products/:skuId',
    withId: (skuId: string): string => {
        return SettingsProductEditPath.path.replace(':skuId', skuId);
    },
};
export const SettingsProductImportPath = '/settings/products/import';

export const SettingsPath = {
    profile: '/settings/profile',
    address: '/settings/address',
    businessAddress: '/settings/business-address',
    language: '/settings/language',
    notification: '/settings/notifications',
    password: '/settings/password',
    product: '/settings/products',
};
