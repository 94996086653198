import {Configuration, SkusApi} from '@/api';
import CustomToast from '@/components/CustomToast';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import ProductForm from '@/modules/settings/components/ProductForm';
import {SkuCreate, SkuUpdate} from '@/modules/settings/models/Sku';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {SettingsProductEditPath, SettingsProductListPath} from '@/modules/settings/paths';
import {CubeIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

const ProductAdd = () => {
    const navigate = useNavigate();

    const skusApi = new SkusApi(new Configuration(useAuth));

    const handleSubmit = async (sku: SkuCreate | SkuUpdate) => {
        try {
            console.log(sku);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Product created successfully!'}
                    visible={t.visible}
                />
            ));

            const newSku = await skusApi.createSku(sku);

            if (newSku?.id !== undefined) {
                navigate(SettingsProductEditPath.withId(newSku.id));
            } else {
                navigate(SettingsProductListPath);
            }
        } catch (error) {
            console.error('Error while creating product:', error);
        }
    };

    return (
        <>
            <SettingsHeader
                title="Add Product"
                icon={<CubeIcon className="w-6 h-6"/>}
            />

            <ProductForm
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default ProductAdd;
