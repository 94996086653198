import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {ProductCategoryCreate, ProductCategoryUpdate} from '@/models/ProductCategory';
import ProductCategoryForm from '@/modules/supplier/components/ProductCategoryForm';

const ProductCategoryAdd: React.FC = () => {
    const handleOnSubmit = (productCategory: ProductCategoryCreate | ProductCategoryUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(productCategory);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Product Category"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <ProductCategoryForm onSubmit={handleOnSubmit}/>
            </div>
        </div>
    );
};

export default ProductCategoryAdd;
