interface TimeValue {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    second: number;
}

const parseDateWithTimeZone = (date: Date, timeZone: string): TimeValue | null => {
    const options: Intl.DateTimeFormatOptions = {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    const formattedDateString = date.toLocaleString('en-US', options);

    const matches = formattedDateString.match(/(\d+)/g);

    if (matches && matches.length >= 6) {
        const [
            month,
            day,
            year,
            hour,
            minute,
            second,
        ] = matches.map(match => parseInt(match, 10));

        let hour24 = hour;
        if (hour === 12 && formattedDateString.includes('AM')) {
            hour24 = 0;
        }

        return {
            year: year,
            month: month - 1, // Month is 0-based in Date objects
            day: day,
            hour: hour24,
            minute: minute,
            second: second || 0,
        };
    }

    return null;
};



export default parseDateWithTimeZone;
