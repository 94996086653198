import {CalculatedPrice} from '@/modules/retailer/models/Price';
import formatPrice from '@/utils/formatPrice';

interface PriceProps {
    price?: CalculatedPrice;
}

const Price = ({price}: PriceProps) => {
    if (price && price.discountedPrice) {
        return (
            <>
                <span className="inline-block">{formatPrice(price.discountedPrice)}</span>{' '}
                <span className="inline-block strikethrough-diagonal">
                    {formatPrice(price.originalPrice)}
                </span>
            </>
        );
    }

    return <span>{formatPrice(price)}</span>;
};

export default Price;
