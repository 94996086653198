import React from 'react';
import SelectBox, {
    Item,
    MultiSelectProps,
    OtherSelectBoxProps,
    SingleSelectProps,
} from '@/components/SelectBox';
import {currencyCodes as defaultCurrencyCodes} from '@/config';

type CurrencySelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        currencyCodes?: string[];
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        currencyCodes?: string[];
    }
);

export interface CurrencyItem {
    id: string;
    name: string;
}

const CurrencySelectBox: React.FC<CurrencySelectBoxProps<CurrencyItem>> = (props) => {
    const {
        label = 'Currency:',
        placeholder= 'Select currency',
        currencyCodes = defaultCurrencyCodes,
    } = props;

    const listCountries = (): Promise<CurrencyItem[]> => {
        return new Promise((resolve) => {
            const items: CurrencyItem[] = currencyCodes.map(currencyCode => {
                return {
                    id: currencyCode,
                    name: currencyCode,
                };
            });

            resolve(items);
        });
    };

    return (
        <SelectBox<CurrencyItem>
            {...props}
            name="currency"
            label={label}
            placeholder={placeholder}
            queryFn={listCountries}
        />
    );
};

export default CurrencySelectBox;
