import {Configuration, ProductListsApi} from '@/api';
import {ProductList} from '@/models/ProductList';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery} from '@tanstack/react-query';

interface GetProductListQueryResult {
    productList?: ProductList | null;
    isLoading: boolean;
}

const useGetProductListQuery = (productListId?: string): GetProductListQueryResult => {
    const productListsApi = new ProductListsApi(new Configuration(useAuth));

    const {
        isLoading,
        data: productList,
    } = useQuery({
        queryKey: ['productList', productListId],
        queryFn: () => {
            return productListId
                ? productListsApi.getProductList(productListId)
                : null;
        },
    });

    return {
        isLoading,
        productList,
    };
};

export default useGetProductListQuery;
