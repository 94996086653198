import React, {useState} from 'react';
import {Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';

type AlertType = 'success' | 'error' | 'info' | 'warning';

interface AlertProps {
    type: AlertType;
    message: string;
}

const Alert: React.FC<AlertProps> = ({type, message}) => {
    const [showAlert, setShowAlert] = useState(true);

    const getAlertClasses = () => {
        switch (type) {
            case 'success':
                return 'bg-green-100 text-green-700 border border-green-300';
            case 'error':
                return 'bg-red-100 text-red-700 border border-red-300';
            case 'info':
                return 'bg-blue-100 text-blue-700 border border-blue-600';
            case 'warning':
                return 'bg-yellow-100 text-yellow-700 border border-yellow-300';
            default:
                return '';
        }
    };

    return (
        <Transition
            show={showAlert}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className={`p-4 rounded-md ${getAlertClasses()}`}>
                <div className="flex justify-between">
                    <div className="flex items-center px-3">
                        <p>{message}</p>
                    </div>
                    <button
                        className="text-sm font-medium focus:outline-none"
                        onClick={() => setShowAlert(false)}
                    >
                        <XMarkIcon className="h-5 w-5"/>
                    </button>
                </div>
            </div>
        </Transition>
    );
};

export default Alert;
