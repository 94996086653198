import {formatDate} from 'date-fns/format';
import {useEffect, useState} from 'react';
import {PromotionsApi} from '@/api';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import StatusActiveIndicator from '@/components/StatusActiveIndicator';
import {SearchProps} from '@/hooks/useSearch';
import {EmptyResult} from '@/models/Pagination';
import {Promotion, Promotions} from '@/models/Promotion';
import {isStatusActive} from '@/models/Status';
import {PromotionEditPath} from '@/modules/supplier/paths';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

const initialState = EmptyResult<Promotion>();

const PromotionTable = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
}: SearchProps) => {
    const [promotions, setPromotions] = useState<Promotions>(initialState);

    const promotionsApi = new PromotionsApi();

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['promotions', page, searchQuery],
        queryFn: async () => {
            const limit = 10;
            return promotionsApi.findPromotions(
                searchQuery,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setPromotions((prevState): Promotions => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {promotions.items.map((promotion, index) => {
                        if (promotion.id === undefined) {
                            return;
                        }

                        return (
                            <tr key={index}>
                                <td>{promotion.id}</td>
                                <td>
                                    <Link to={PromotionEditPath.withId(promotion.id)}
                                          className="text-blue-500 hover:underline">
                                        {promotion.name}
                                    </Link>
                                </td>
                                <td>{promotion.createdAt && formatDate(promotion.createdAt, 'd MMM yyyy')}</td>
                                <td><StatusActiveIndicator isActive={isStatusActive(promotion)}/></td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={promotions.totalCount || 0}
                    estimatedTotalCount={promotions.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default PromotionTable;
