import {useEffect} from 'react';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {LoginPath} from '@/modules/auth/paths';
import {Navigate} from 'react-router-dom';

const Logout = () => {
    const {logout} = useAuth();

    useEffect(() => {
        void logout();
    }, [logout]);

    return <Navigate to={LoginPath} replace/>;
};

export default Logout;
