import {
    CustomerAddPath,
    CustomerEditPath,
    CustomerListPath,
    CustomerStoreAddPath,
    CustomerStoreEditPath,
    CustomerStoreListPath,
    OrganizationAddPath,
    OrganizationEditPath,
    OrganizationListPath,
    SupplierAddPath,
    SupplierCalendarAddPath,
    SupplierCalendarEditPath,
    SupplierCalendarEventAddPath,
    SupplierCalendarEventEditPath,
    SupplierCalendarEventListPath,
    SupplierCalendarListPath,
    SupplierEditPath,
    SupplierListPath,
    SupplierToCustomerAddPath,
    SupplierToCustomerListPath,
    SupplierToStoreAddPath,
    SupplierToStoreListPath,
    SupplierWarehouseAddPath,
    SupplierWarehouseEditPath,
    SupplierWarehouseListPath,
    UserAddPath,
    UserEditPath,
    UserListPath,
} from '@/modules/admin/paths';
import {
    HomePath,
} from '@/paths';

const home = {label: 'Home', path: HomePath};

const organizationList = {label: 'Organizations', path: OrganizationListPath};
const organizationAdd = {label: 'Add', path: OrganizationAddPath};
const organizationEdit = {label: 'Edit', path: OrganizationEditPath.path};
const customerList = {label: 'Customers', path: CustomerListPath};
const customerAdd = {label: 'Add', path: CustomerAddPath};
const customerEdit = {label: 'Edit', path: CustomerEditPath.path};
const supplierList = {label: 'Suppliers', path: SupplierListPath};
const supplierAdd = {label: 'Add', path: SupplierAddPath};
const supplierEdit = {label: 'Edit', path: SupplierEditPath.path};
const supplierWarehouseList = {label: 'Warehouses', path: SupplierWarehouseListPath.path};
const supplierWarehouseAdd = {label: 'Create', path: SupplierWarehouseAddPath.path};
const supplierWarehouseEdit = {label: 'Create', path: SupplierWarehouseEditPath.path};
const userList = {label: 'Users', path: UserListPath};
const userAdd = {label: 'Add', path: UserAddPath};
const userEdit = {label: 'Edit', path: UserEditPath.path};
const customerStoreList = {label: 'Stores', path: CustomerStoreListPath.path};
const customerStoreAdd = {label: 'Create', path: CustomerStoreAddPath.path};
const customerStoreEdit = {label: 'Edit', path: CustomerStoreEditPath.path};
const supplierCalendarList = {label: 'Calendars', path: SupplierCalendarListPath.path};
const supplierCalendarAdd = {label: 'Create', path: SupplierCalendarAddPath.path};
const supplierCalendarEdit = {label: 'Edit', path: SupplierCalendarEditPath.path};
const supplierCalendarEventList = {label: 'Events', path: SupplierCalendarEventListPath.path};
const supplierCalendarEventAdd = {label: 'Create', path: SupplierCalendarEventAddPath.path};
const supplierCalendarEventEdit = {label: 'Edit', path: SupplierCalendarEventEditPath.path};
const supplierToCustomerList = {label: 'Customers', path: SupplierToCustomerListPath.path};
const supplierToCustomerAdd = {label: 'Add', path: SupplierToCustomerAddPath.path};
const supplierToStoreList = {label: 'Stores', path: SupplierToStoreListPath.path};
const supplierToStoreAdd = {label: 'Add', path: SupplierToStoreAddPath.path};

const breadcrumbs = [
    {
        path: OrganizationListPath,
        items: [home, organizationList],
    },
    {
        path: OrganizationAddPath,
        items: [home, organizationList, organizationAdd],
    },
    {
        path: OrganizationEditPath.path,
        items: [home, organizationList, organizationEdit],
    },
    {
        path: CustomerListPath,
        items: [home, customerList],
    },
    {
        path: CustomerAddPath,
        items: [home, customerList, customerAdd],
    },
    {
        path: CustomerEditPath.path,
        items: [home, customerList, customerEdit],
    },
    {
        path: CustomerStoreListPath.path,
        items: [home, customerList, customerEdit, customerStoreList],
    },
    {
        path: CustomerStoreAddPath.path,
        items: [home, customerList, customerEdit, customerStoreList, customerStoreAdd],
    },
    {
        path: CustomerStoreEditPath.path,
        items: [home, customerList, customerEdit, customerStoreList, customerStoreEdit],
    },
    {
        path: SupplierListPath,
        items: [home, supplierList],
    },
    {
        path: SupplierAddPath,
        items: [home, supplierList, supplierAdd],
    },
    {
        path: SupplierEditPath.path,
        items: [home, supplierList, supplierEdit],
    },
    {
        path: SupplierWarehouseListPath.path,
        items: [home, supplierList, supplierEdit, supplierWarehouseList],
    },
    {
        path: SupplierWarehouseAddPath.path,
        items: [home, supplierList, supplierEdit, supplierWarehouseList, supplierWarehouseAdd],
    },
    {
        path: SupplierWarehouseEditPath.path,
        items: [home, supplierList, supplierEdit, supplierWarehouseList, supplierWarehouseEdit],
    },
    {
        path: SupplierCalendarListPath.path,
        items: [home, supplierList, supplierEdit, supplierCalendarList],
    },
    {
        path: SupplierCalendarAddPath.path,
        items: [home, supplierList, supplierEdit, supplierCalendarList, supplierCalendarAdd],
    },
    {
        path: SupplierCalendarEditPath.path,
        items: [home, supplierList, supplierEdit, supplierCalendarList, supplierCalendarEdit],
    },
    {
        path: SupplierCalendarEventListPath.path,
        items: [home, supplierList, supplierEdit, supplierCalendarList, supplierCalendarEdit, supplierCalendarEventList],
    },
    {
        path: SupplierCalendarEventAddPath.path,
        items: [home, supplierList, supplierEdit, supplierCalendarList, supplierCalendarEdit, supplierCalendarEventList, supplierCalendarEventAdd],
    },
    {
        path: SupplierCalendarEventEditPath.path,
        items: [home, supplierList, supplierEdit, supplierCalendarList, supplierCalendarEdit, supplierCalendarEventList, supplierCalendarEventEdit],
    },
    {
        path: UserListPath,
        items: [home, userList],
    },
    {
        path: UserAddPath,
        items: [home, userList, userAdd],
    },
    {
        path: UserEditPath.path,
        items: [home, userList, userEdit],
    },
    {
        path: SupplierToCustomerListPath.path,
        items: [home, supplierList, supplierEdit, supplierToCustomerList],
    },
    {
        path: SupplierToCustomerAddPath.path,
        items: [home, supplierList, supplierEdit, supplierToCustomerList, supplierToCustomerAdd],
    },
    {
        path: SupplierToStoreListPath.path,
        items: [home, supplierList, supplierEdit, supplierToStoreList],
    },
    {
        path: SupplierToStoreAddPath.path,
        items: [home, supplierList, supplierEdit, supplierToStoreList, supplierToStoreAdd],
    },
];

export default breadcrumbs;
