class CookieService {
    createCookie(name: string, data: object, maxAge: number = 86400): void {
        document.cookie = `${name}=${encodeURIComponent(JSON.stringify(data))}; max-age=${maxAge}`;
    }

    removeCookie(name: string): void {
        document.cookie = `${name}=; max-age=0`;
    }

    getCookie<T>(name: string): T | undefined {
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookiePairs = decodedCookie.split(';');
        for (const pair of cookiePairs) {
            const [key, value] = pair.trim().split('=');
            if (key === name) {
                const cookieValue = decodeURIComponent(value);
                return JSON.parse(cookieValue) as T;
            }
        }
        return undefined;
    }
}

export default CookieService;
