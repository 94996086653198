import {ChangeEvent, FormEvent, useState} from 'react';
import Button from '@/components/Button';
import ProductCategorySelectBox from '@/components/ProductCategorySelectBox';
import ToggleSwitch from '@/components/ToggleSwitch';
import {ProductCreate, ProductUpdate} from '@/models/Product';
import {isStatusActive} from '@/models/Status';
import {CheckIcon} from '@heroicons/react/24/outline';

interface ProductFormProps {
    onSubmit: (product: ProductCreate | ProductUpdate) => void;
    product?: ProductCreate | ProductUpdate;
}

const ProductForm = ({onSubmit, product}: ProductFormProps) => {
    const [formData, setFormData] = useState<ProductCreate | ProductUpdate>(
        product ? {...product} : {
            name: '',
            description: '',
            status: 'ACTIVE',
            supplierId: '',
        },
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevProduct => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="description" className="block font-medium text-gray-700">
                        Description
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="gtin" className="block font-medium text-gray-700">
                        Global Trade Item Number (GTIN)
                    </label>
                    <input
                        type="text"
                        id="gtin"
                        name="gtin"
                        value={formData.gtin}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="vatRate" className="block font-medium text-gray-700">
                        VAT Rate
                    </label>
                    <div className="relative flex items-center">
                        <input
                            type="text"
                            id="vatRate"
                            name="vatRate"
                            value={formData.vatRate}
                            onChange={handleChange}
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full pr-12"
                        />
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
                    </div>
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="externalCode" className="block font-medium text-gray-700">
                        External Code
                    </label>
                    <input
                        type="text"
                        id="externalCode"
                        name="externalCode"
                        value={formData.externalCode}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <ProductCategorySelectBox
                        value={formData.productCategoryId || null}
                        onSelect={(product) => {console.log(product);}}
                    />
                </div>
                <div className="col-span-2">
                    <ToggleSwitch
                        checked={isStatusActive(formData)}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default ProductForm;
