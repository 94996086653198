import {ElementType} from 'react';
import {Order as OrderModel} from '@/models/Order';
import {OrderStatus as OrderStatusModel} from '@/models/OrderStatus';
import {UserRole} from '@/models/User';
import {useAuth} from '@/modules/auth/contexts/AuthContext';

interface Step {
    title: string;
    status: OrderStatusModel;
    description?: string;
    date?: string;
    by?: string;
    edited?: boolean;
    visible?: (order: OrderModel) => boolean;
    bgColor?: string;
    icon?: ElementType;
}

const CheckIcon = () => {
    return (
        <svg className="w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
        </svg>
    );
};

const RejectedIcon = () => {
    return (
        <svg className="w-3.5 h-3.5 text-red-500 dark:text-red-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 2l12 12M2 14L14 2"/>
        </svg>
    );
};

const isRejected = (order: OrderModel) => order.status === OrderStatusModel.Rejected;
const isNotRejected = (order: OrderModel) => !isRejected(order);
const isEditedBySupplier = (order: OrderModel) => !isRejected(order) && !!order.editedBySupplier;
const isNotEditedBySupplier = (order: OrderModel) => !isRejected(order) && !order.editedBySupplier;

const retailerSteps: Step[] = [
    {title: 'Order Created', status: OrderStatusModel.New},
    {title: 'Order Rejected', status: OrderStatusModel.Rejected, visible: isRejected, bgColor: 'bg-red-200', icon: RejectedIcon},
    {title: 'Submitted to Supplier', status: OrderStatusModel.Submitted, visible: isNotRejected},
    {title: 'Accepted by Supplier', status: OrderStatusModel.Accepted, visible: isNotEditedBySupplier},
    {title: 'Edited & Accepted by Supplier', status: OrderStatusModel.Accepted, visible: isEditedBySupplier},
    {title: 'Order Dispatched', status: OrderStatusModel.Dispatched, visible: isNotRejected},
    {title: 'Received Order', status: OrderStatusModel.Received, visible: isNotRejected},
    {title: 'Completed by Supplier', status: OrderStatusModel.Completed, visible: isNotRejected},
];

const supplierSteps: Step[] = [
    {title: 'Order Received', status: OrderStatusModel.Submitted},
    {title: 'Order Rejected', status: OrderStatusModel.Rejected, visible: isRejected, bgColor: 'bg-red-200', icon: RejectedIcon},
    {title: 'Order Accepted', status: OrderStatusModel.Accepted, visible: isNotRejected},
    {title: 'Order Dispatched', status: OrderStatusModel.Dispatched, visible: isNotRejected},
    {title: 'Order Received by Store', status: OrderStatusModel.Received, visible: isNotRejected},
    {title: 'Order Completed', status: OrderStatusModel.Completed, visible: isNotRejected},
];

interface OrderStatusProps {
    order?: OrderModel;
}

const OrderStatus = ({order}: OrderStatusProps) => {
    const {role} = useAuth();
    const status = order?.status;

    const steps = role === UserRole.Supplier ? supplierSteps : retailerSteps;

    let isCurrentOrPastStatus = false;

    return (
        <ol className="relative text-gray-500 border-s border-gray-400 dark:border-gray-700 dark:text-gray-400 ml-4">
            {steps.map((step, index) => {
                const isCurrentStatus = step.status === status;

                isCurrentOrPastStatus = isCurrentOrPastStatus || step.status === status;

                if (step.visible && status && !step.visible(order)) {
                    return;
                }

                const bgColor = !isCurrentOrPastStatus || isCurrentStatus ? step.bgColor || 'bg-green-200' : '';

                return (
                    <li key={index} className="flex mb-6 ms-7">
                        <span
                            className={`absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700 ${bgColor}`}>
                            {isCurrentStatus ? (step.icon && <step.icon/> || <CheckIcon/>) : (
                                index + 1
                            )}
                        </span>
                        <div className="flex flex-col items-center">
                            <h3 className="font-medium leading-tight py-1.5">{step.title}</h3>
                            {step.description && <p className="text-sm">{step.description}</p>}
                            {step.date && <p className="text-sm">{step.date}</p>}
                            {step.by && <p className="text-sm">by {step.by}</p>}
                        </div>
                    </li>
                );
            })}
        </ol>
    );
};

export default OrderStatus;
