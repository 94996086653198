import React from 'react';
import Price from '@/modules/retailer/components/Price';
import {CartDetails} from '@/modules/retailer/models/Cart';
import formatPrice from '@/utils/formatPrice';

interface OrderReviewTableProps {
    order: CartDetails;
}

const OrderReviewTable: React.FC<OrderReviewTableProps> = ({order}) => {
    return (
        <div className="table-container table-custom">
            <div className="table-wrapper">
                <table className="min-w-full sm:w-[700px] lg:w-full table-fixed">
                    <thead>
                    <tr className="bg-gray-50 border-b border-gray-300 text-gray-500 font-medium text-sm">
                        <th className="w-[50%] px-6 py-3 text-left text-sm">
                            SKU
                        </th>
                        <th className="w-[12%] px-3 py-3 text-left">
                            Price (ex-VAT)
                        </th>
                        <th className="w-[11%] px-3 py-3 text-left">
                            VAT Rate
                        </th>
                        <th className="w-[11%] px-3 py-3 text-left">
                            Order Quantity
                        </th>
                        <th className="w-[14%] px-3 py-3 text-right">
                            Subtotal (ex-VAT)
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {order.items.map((item, index) => (
                        <tr key={index} className="text-sm lg:text-base">
                            <td className="px-1 py-1">
                                <div className="flex gap-4 items-center">
                                    {item.sku.image && <div className="w-20 min-w-20 min-h-20">
                                        <img src={item.sku.image.url} alt="Product Image"/>
                                    </div>}
                                    <div>
                                        {item.sku.name}
                                    </div>
                                </div>
                            </td>
                            <td className="px-3 py-1">
                                <Price price={item.unitPrice}/>
                            </td>
                            <td className="px-3 py-1">{item.vatRate ? item.vatRate + '%' : '0%'}</td>
                            <td className="px-3 py-1">{item.quantity}</td>
                            <td className="px-3 py-1 text-right">{formatPrice(item.lineItemPrice)}</td>
                            <td></td>
                        </tr>
                    ))}
                    </tbody>
                    <tbody className="border-t border-gray-300">
                    <tr>
                        <td colSpan={2} className="px-3 py-1"></td>
                        <td colSpan={2} className="text-right px-6 py-1 pt-6 font-semibold text-sm">Subtotal (ex-VAT)</td>
                        <td className="px-3 pt-6 py-1 text-right">
                            {formatPrice(order.subtotal, order.subtotal?.currencyCode)}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={4} className="text-right px-6 py-1 font-semibold text-sm">Total (ex-VAT)</td>
                        <td className="px-3 py-1 text-right">
                            {formatPrice(order.subtotal, order.subtotal?.currencyCode)}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={4} className="text-right px-6 py-1 pb-6 font-semibold text-sm">
                            Grand Total (VAT inclusive)
                        </td>
                        <td className="px-3 py-1 pb-6 text-right">
                            {formatPrice(order.grandTotal, order.grandTotal?.currencyCode)}
                        </td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderReviewTable;
