interface TimezoneInfo {
    country: string;
    name: string;
    long: string;
}

export const timezoneData: ReadonlyArray<TimezoneInfo> = [
    {country: 'US', name: 'America/New_York', long: 'Eastern Standard Time'},
    {country: 'US', name: 'America/Chicago', long: 'Central Standard Time'},
    {country: 'US', name: 'America/Denver', long: 'Mountain Standard Time'},
    {country: 'US', name: 'America/Los_Angeles', long: 'Pacific Standard Time'},
    {country: 'US', name: 'America/Anchorage', long: 'Alaska Standard Time'},
    {country: 'US', name: 'America/Adak', long: 'Hawaii-Aleutian Standard Time'},
    {country: 'SG', name: 'Asia/Singapore', long: 'Singapore Standard Time'},
    {country: 'PH', name: 'Asia/Manila', long: 'Philippine Standard Time'},
    {country: 'TH', name: 'Asia/Bangkok', long: 'Indochina Time'},
    {country: 'ID', name: 'Asia/Jakarta', long: 'Western Indonesia Time'},
    {country: 'ID', name: 'Asia/Makassar', long: 'Central Indonesia Time'},
    {country: 'ID', name: 'Asia/Jayapura', long: 'Eastern Indonesia Time'},
    {country: 'MY', name: 'Asia/Kuala_Lumpur', long: 'Malaysia Standard Time'},
    {country: 'VN', name: 'Asia/Ho_Chi_Minh', long: 'Indochina Time'},
    {country: 'KH', name: 'Asia/Phnom_Penh', long: 'Indochina Time'},
    {country: 'MM', name: 'Asia/Yangon', long: 'Myanmar Time'},
    {country: 'LA', name: 'Asia/Vientiane', long: 'Indochina Time'},
    {country: 'BN', name: 'Asia/Brunei', long: 'Brunei Darussalam Time'},
    {country: 'TL', name: 'Asia/Dili', long: 'East Timor Time'},
    {country: 'JP', name: 'Asia/Tokyo', long: 'Japan Standard Time'},
    {country: 'KR', name: 'Asia/Seoul', long: 'Korea Standard Time'},
    {country: 'CN', name: 'Asia/Shanghai', long: 'China Standard Time'},
    {country: 'HK', name: 'Asia/Hong_Kong', long: 'Hong Kong Standard Time'},
    {country: 'TW', name: 'Asia/Taipei', long: 'Taiwan Standard Time'},
    {country: 'AU', name: 'Australia/Sydney', long: 'Australian Eastern Standard Time'},
    {country: 'NZ', name: 'Pacific/Auckland', long: 'New Zealand Standard Time'},
];

const countryTimezones: Record<string, TimezoneInfo[]> = {};

export const getTimezones = (countryCode: string): TimezoneInfo[] => {
    if (!countryTimezones[countryCode]) {
        countryTimezones[countryCode] = timezoneData
            .filter((timezone: TimezoneInfo) => timezone.country === countryCode);
    }

    return countryTimezones[countryCode];
};
