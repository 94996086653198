export interface SignupUser {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    mobileCountryCode: string;
    mobileNumber: string;
    password: string;
    confirmPassword: string;
    termsAccepted: boolean;
}

export interface SignupAccount {
    businessType: 'retailer' | 'supplier';
    action: 'create' | 'join';
    businessName: string;
    street1: string;
    street2: string;
    city: string;
    countryCode?: string;
}

export enum UserRole {
    Retailer = 'RETAILER',
    Supplier = 'SUPPLIER',
}

export interface User {
    email: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    mobileCountryCode: string;
    mobileNumber: string;
    password: string;
    confirmPassword: string;
    businessName: string;
    street1: string;
    street2: string;
    city: string;
    countryCode: string;
}
