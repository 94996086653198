import React, {useState} from 'react';
import Button from '@/components/Button';
import OrganizationSelectBox from '@/components/OrganizationSelectBox';
import ToggleSwitch from '@/components/ToggleSwitch';
import {Customer, CustomerCreate, CustomerUpdate} from '@/models/Customer';
import {CheckIcon} from '@heroicons/react/24/solid';

interface CustomerFormProps {
    customer?: Customer;
    onSubmit: (customer: CustomerCreate | CustomerUpdate) => Promise<void>;
}

const CustomerForm: React.FC<CustomerFormProps> = ({customer, onSubmit}) => {
    const [formData, setFormData] = useState<CustomerCreate | CustomerUpdate>(
        customer ? {...customer} : {
            organizationId: '',
            name: '',
            status: 'ACTIVE',
            externalCode: '',
        },
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <OrganizationSelectBox
                        value={formData.organizationId}
                        onSelect={() => {}}
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="externalCode" className="block font-medium text-gray-700">
                        External Code:
                    </label>
                    <input
                        type="text"
                        id="externalCode"
                        name="externalCode"
                        value={formData.externalCode || ''}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <ToggleSwitch
                        checked={formData.status === 'ACTIVE'}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default CustomerForm;
