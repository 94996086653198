import {useEffect, useState} from 'react';
import {PricesApi} from '@/api';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import {SearchProps} from '@/hooks/useSearch';
import {EmptyResult} from '@/models/Pagination';
import {Price, PriceAssociation, Prices} from '@/models/Price';
import {PriceEditPath, PriceListPriceEditPath} from '@/modules/supplier/paths';
import formatPrice from '@/utils/formatPrice';
import {PencilIcon, TrashIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

interface PricingTableProps extends SearchProps {
    priceListId?: string;
}

const initialState = EmptyResult<Price>();

const PriceTable = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    priceListId,
}: PricingTableProps) => {
    const [prices, setPrices] = useState<Prices>(initialState);

    const pricesApi = new PricesApi();

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['prices', page, searchQuery, priceListId],
        queryFn: async () => {
            const limit = 10;
            return pricesApi.findPrices(
                searchQuery,
                priceListId,
                page * limit - limit,
                limit,
                [
                    PriceAssociation.PRICE_LIST,
                    PriceAssociation.SKU,
                    PriceAssociation.SUPPLIER,
                ],
            );
        },
    });

    useEffect(() => {
        setPrices(prevState => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>SKU Name</th>
                        <th>SKU ID</th>
                        <th>Price</th>
                        <th>Currency</th>
                        {priceListId ? '' : <th>Price List</th>}
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {prices.items.map((price, index) => {
                        if (price.id === undefined) {
                            return;
                        }

                        const editPath = priceListId
                            ? PriceListPriceEditPath.withPriceListIdAndPriceId(priceListId, price.id)
                            : PriceEditPath.withId(price.id);

                        return (
                            <tr key={index}>
                                <td>{price.id}</td>
                                <td>
                                    <Link to={editPath}
                                          className="text-blue-500 hover:underline">
                                        {price.sku?.name || price.skuId}
                                    </Link>
                                </td>
                                <td>{price.skuId}</td>
                                <td>{formatPrice(price.price)}</td>
                                <td>{price.price.currencyCode}</td>
                                {priceListId ? '' : <td>{price.priceList && price.priceList.name}</td>}
                                <td className="flex gap-2">
                                    <Button
                                        to={editPath}
                                        icon={<PencilIcon className="h-5 w-5" aria-hidden="true"/>}
                                        loadingIconClassName="h-5 w-5"
                                        variant="secondary"
                                        size="xs"
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        onClick={() => {}}
                                        icon={<TrashIcon className="h-5 w-5" aria-hidden="true"/>}
                                        loadingIconClassName="h-5 w-5"
                                        variant="delete"
                                        size="xs"
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={prices.totalCount || 0}
                    estimatedTotalCount={prices.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default PriceTable;
