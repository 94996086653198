import React from 'react';
import {CalendarsApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {CalendarEventCreate, CalendarEventUpdate} from '@/models/CalendarEvent';
import CalendarEventForm from '@/modules/supplier/components/CalendarEventForm';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const CalendarEventEdit: React.FC = () => {
    const {calendarEventId} = useParams();

    const calendarsApi = new CalendarsApi();

    const {
        isLoading,
        data: calendarEvent,
    } = useQuery({
        queryKey: ['calendarEvent', calendarEventId],
        queryFn: async () => {
            return calendarEventId
                ? calendarsApi.getCalendarEvent(calendarEventId)
                : null;
        },
    });

    const handleOnSubmit = (event: CalendarEventCreate | CalendarEventUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(event);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!calendarEvent) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Event">
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Event
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <CalendarEventForm
                    onSubmit={handleOnSubmit}
                    calendarEvent={calendarEvent}
                />
            </div>
        </div>
    );
};

export default CalendarEventEdit;
