import {useEffect, useState} from 'react';
import {Configuration, ProductsApi} from '@/api';
import {ProductFilters} from '@/api/ProductsApi';
import {EmptyResult} from '@/models/Pagination';
import {Product, Products} from '@/models/Product';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {setPaginationResultIfNotLoading} from '@/utils/pagination';
import {useQuery, useQueryClient} from '@tanstack/react-query';

const initialState = EmptyResult<Product>();

interface FindProductsQueryResult {
    products: Products;
    isLoading: boolean;
    invalidateProductsQuery: () => Promise<void>;
}

const useFindProductsQuery = (page: number, query?: string, filters?: Partial<ProductFilters>): FindProductsQueryResult => {
    const queryClient = useQueryClient();

    const [products, setProducts] = useState<Products>(initialState);

    const productsApi = new ProductsApi(new Configuration(useAuth));

    const queryKey = ['products', page, query, filters];

    const {
        data,
        isLoading,
        isFetching,
    } = useQuery({
        queryKey: queryKey,
        queryFn: () => {
            const limit = 10;
            return productsApi.findProducts(query, filters, page * limit - limit, limit);
        },
    });

    const invalidateProductsQuery = async () => {
        await queryClient.invalidateQueries({
            queryKey: queryKey,
        });
    };

    useEffect(() => {
        setPaginationResultIfNotLoading(data, setProducts, isLoading);
    }, [data, isLoading, isFetching]);

    return {
        products,
        isLoading,
        invalidateProductsQuery,
    };
};

export default useFindProductsQuery;
