import React, {useRef} from 'react';
import {useDialog} from '@react-aria/dialog';
import {FocusScope} from '@react-aria/focus';
import {DismissButton, useModal, useOverlay} from '@react-aria/overlays';
import {mergeProps} from '@react-aria/utils';

interface PopoverProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export const Popover: React.FC<PopoverProps> = (props) => {
    const popoverRef = useRef<HTMLDivElement>(null);
    const {isOpen, onClose, children, ...otherProps} = props;

    // Handle events that should cause the popup to close,
    // e.g. blur, clicking outside, or pressing the escape key.
    const {overlayProps} = useOverlay(
        {
            isOpen,
            onClose,
            shouldCloseOnBlur: true,
            isDismissable: true,
        },
        popoverRef,
    );

    const {modalProps} = useModal();
    const {dialogProps} = useDialog(otherProps, popoverRef);

    // Add a hidden <DismissButton> component at the end of the popover
    // to allow screen reader users to dismiss the popup easily.
    return (
        <FocusScope contain restoreFocus>
            <div
                {...mergeProps(overlayProps, modalProps, dialogProps)}
                ref={popoverRef}
                className="absolute top-full bg-white border border-gray-300 rounded-md shadow-lg mt-2 p-8 z-10"
            >
                {children}
                <DismissButton onDismiss={onClose}/>
            </div>
        </FocusScope>
    );
};

export default Popover;
