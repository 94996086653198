import {forwardRef, useEffect, useState} from 'react';
import Button from '@/components/Button';
import CountrySelectBox, {CountryItem} from '@/components/CountrySelectBox';
import {NullIsland} from '@/models/Geolocation';
import {StoreAddress, StoreAddressType} from '@/models/Store';
import {withMinimumDelay} from '@/utils/delay';
import {withLoading} from '@/utils/loading';
import {Controller, useForm} from 'react-hook-form';

interface StoreAddressProps {
    address?: StoreAddress;
    onSubmit: (address: StoreAddress) => Promise<void>;
    loading?: boolean;
    onChangeLoading?: (loading: boolean) => void;
    storeId?: string;
    storeAddressType?: StoreAddressType;
}

const StoreAddressForm = forwardRef<HTMLFormElement, StoreAddressProps>(function StoreAddressForm({
    address: initialAddress,
    onSubmit,
    loading: loadingProp,
    onChangeLoading,
    storeId,
    storeAddressType,
}, ref) {
    const {
        control,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm<StoreAddress>({
        defaultValues: initialAddress || {
            storeId: storeId || '',
            type: storeAddressType || StoreAddressType.UNKNOWN,
            location: NullIsland,
            additionalDescription: '',
        },
    });

    const countryCode = watch('countryCode');

    const [loading, setLoading] = useState(false);
    const uniqueId = storeAddressType || Date.now().toString(36);

    useEffect(() => {
        setLoading(loadingProp || false);
    }, [loadingProp]);

    useEffect(() => {
        if (onChangeLoading) {
            onChangeLoading(loading);
        }
    }, [loading, onChangeLoading]);

    const handleSubmitForm = withLoading(withMinimumDelay(onSubmit, 500), setLoading);

    return (
        <form ref={ref} onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="grid grid-cols-2 gap-4 lg:gap-6">
                <div className="col-span-2">
                    <label htmlFor={`building-${uniqueId}`} className="block font-medium text-gray-700">
                        Building:
                    </label>
                    <Controller
                        name="building"
                        control={control}
                        render={({field}) => (
                            <input
                                type="text"
                                id={`building-${uniqueId}`}
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor={`street1-${uniqueId}`} className="block font-medium text-gray-700">
                        Street 1:
                    </label>
                    <Controller
                        name="street1"
                        control={control}
                        rules={{required: 'Street 1 is required'}}
                        render={({field}) => (
                            <input
                                type="text"
                                id={`street1-${uniqueId}`}
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                    {errors.street1 && (
                        <p className="mt-1 text-sm text-red-600">{errors.street1.message}</p>
                    )}
                </div>
                <div className="col-span-2">
                    <label htmlFor={`street2-${uniqueId}`} className="block font-medium text-gray-700">
                        Street 2:
                    </label>
                    <Controller
                        name="street2"
                        control={control}
                        render={({field}) => (
                            <input
                                type="text"
                                id={`street2-${uniqueId}`}
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                </div>
                <div className="col-span-1">
                    <label htmlFor={`postalCode-${uniqueId}`} className="block font-medium text-gray-700">
                        Postal Code:
                    </label>
                    <Controller
                        name="postalCode"
                        control={control}
                        rules={{required: 'Postal code is required'}}
                        render={({field}) => (
                            <input
                                type="text"
                                id={`postalCode-${uniqueId}`}
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                    {errors.postalCode && (
                        <p className="mt-1 text-sm text-red-600">{errors.postalCode.message}</p>
                    )}
                </div>
                <div className="col-span-1">
                    <label htmlFor={`region-${uniqueId}`} className="block font-medium text-gray-700">
                        Region:
                    </label>
                    <Controller
                        name="region"
                        control={control}
                        render={({field}) => (
                            <input
                                type="text"
                                id={`region-${uniqueId}`}
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                </div>
                <div className="col-span-1">
                    <label htmlFor={`city-${uniqueId}`} className="block font-medium text-gray-700">
                        City:
                    </label>
                    <Controller
                        name="city"
                        control={control}
                        rules={{required: 'City is required'}}
                        render={({field}) => (
                            <input
                                type="text"
                                id={`city-${uniqueId}`}
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                    {errors.city && (
                        <p className="mt-1 text-sm text-red-600">{errors.city.message}</p>
                    )}
                </div>
                <div className="col-span-1">
                    <Controller
                        name="countryCode"
                        control={control}
                        rules={{required: 'Country code is required'}}
                        render={({field}) => (
                            <CountrySelectBox
                                value={countryCode}
                                onSelect={(country:CountryItem | null) => field.onChange(country?.id || '')}
                            />
                        )}
                    />
                    {errors.countryCode && (
                        <p className="mt-1 text-sm text-red-600">{errors.countryCode.message}</p>
                    )}
                </div>
                <div className="col-span-2">
                    <label htmlFor={`additionalDescription-${uniqueId}`} className="block font-medium text-gray-700">
                        Additional Description:
                    </label>
                    <Controller
                        name="additionalDescription"
                        control={control}
                        render={({field}) => (
                            <textarea
                                id={`additionalDescription-${uniqueId}`}
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full h-24 resize-none"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    type="submit"
                    variant="primary"
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
});

export default StoreAddressForm;
