import React from 'react';
import {ProductsApi} from '@/api';
import ComboBox, {ComboBoxProps} from '@/components/ComboBox';
import {Product} from '@/models/Product';

const ProductComboBox: React.FC<ComboBoxProps<Product>> = (props) => {
    const productsApi = new ProductsApi();

    const findProducts = async (query: string = ''): Promise<Product[]> => {
        const response = await productsApi.findProducts(query, undefined, 0, 10);
        return response.items;
    };

    const {
        label = 'Product:',
        placeholder= 'Select product',
    } = props;

    return (
        <ComboBox<Product>
            {...props}
            name="product"
            label={label}
            placeholder={placeholder}
            queryFn={findProducts}
        />
    );
};

export default ProductComboBox;
