interface PaginationResult<T> {
    items: T[];
    totalCount: number;
    estimatedTotalCount: number;
}

export const EmptyResult: <T>() => PaginationResult<T> = () => ({
    items: [],
    totalCount: 0,
    estimatedTotalCount: 0,
});
