import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import PriceListTable from '@/modules/supplier/components/PriceListTable';
import {PriceListAddPath} from '@/modules/supplier/paths';
import {PlusIcon} from '@heroicons/react/24/solid';

const PriceListList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Price Lists">
                <Button
                    to={PriceListAddPath}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Price List
                </Button>
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by name"
            />

            <PriceListTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
            />
        </div>
    );
};

export default PriceListList;
