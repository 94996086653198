import {Configuration, SkusApi} from '@/api';
import ComboBox, {ComboBoxProps} from '@/components/ComboBox';
import {Sku} from '@/models/Sku';
import {useAuth} from '@/modules/auth/contexts/AuthContext';

export interface ProductVariantItem {
    id: string;
    name: string;
    sku: Sku;
}

const ProductVariantComboBox = (props: ComboBoxProps<ProductVariantItem>) => {
    const skusApi = new SkusApi(new Configuration(useAuth));

    const findSkus = async (query: string = ''): Promise<ProductVariantItem[]> => {
        const response = await skusApi.findSkus(
            query,
            undefined,
            0,
            10,
        );
        return response.items.map(sku => ({
            id: sku.id,
            name: sku.name,
            sku: sku,
        }));
    };

    const {
        label = 'Product:',
        placeholder= 'Select product',
    } = props;

    return (
        <ComboBox<ProductVariantItem>
            {...props}
            name="sku"
            label={label}
            placeholder={placeholder}
            queryFn={findSkus}
        />
    );
};

export default ProductVariantComboBox;
