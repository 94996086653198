import {CalendarDate, CalendarDateTime} from '@internationalized/date';
import parseDateWithTimeZone from '@/utils/parseDateWithTimeZone';
import {DateValue} from '@react-aria/calendar';
import {TimeValue} from '@react-aria/datepicker';

export const toDateValue = (date?: Date, timeZone?: string): DateValue | null => {
    if (!date) {
        return null;
    }

    if (timeZone) {
        const time = parseDateWithTimeZone(date, timeZone);

        if (time) {
            return new CalendarDate(
                time.year,
                time.month,
                time.day,
            );
        }
    }

    return new CalendarDate(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
    );
};

export const toTimeValue = (date?: Date, timeZone?: string): TimeValue | null => {
    if (!date) {
        return null;
    }

    if (timeZone) {
        const time = parseDateWithTimeZone(date, timeZone);

        if (time) {
            return new CalendarDateTime(
                time.year,
                time.month,
                time.day,
                time.hour,
                time.minute,
                time.second,
            );
        }
    }

    return new CalendarDateTime(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
    );
};
