import {useEffect, useState} from 'react';
import {arrayEqual} from '@/utils/arrayEqual';
import {
    filterObjectOrNull,
    filterObjects,
    filterObjectsIfNoneNull,
    filterStringOrNull,
    filterStrings,
} from '@/utils/filter';

interface Item {
    id: string;
}

interface SingleSelectState<T extends Item> {
    selected: T | null;
    isInitialized: boolean;
}

interface MultiSelectState<T extends Item> {
    selected: T[];
    isInitialized: boolean;
}

export type SelectBoxState<T extends Item> =
    | (SingleSelectState<T> & { multiple?: false })
    | (MultiSelectState<T> & { multiple: true });

export interface SingleSelectProps<T extends Item> {
    value: T | string | null;
    onSelect: (item: T | null) => void;
}

export interface MultiSelectProps<T extends Item> {
    value: T[] | string[];
    onSelect: (items: T[]) => void;
}

export type SelectBoxProps<T extends Item> =
    | (SingleSelectProps<T> & { multiple?: false })
    | (MultiSelectProps<T> & { multiple: true });

interface SelectBox<T extends Item> {
    handleChange: (value: T | (T | null)[] | null) => void;
    handleDeselect: () => void;
    state: SelectBoxState<T>;
}

const useSelectBox = <T extends Item>(
    data: T[] | undefined,
    {
        value,
        multiple,
        onSelect,
    }: SelectBoxProps<T>,
    defaultValue?: 'first' | 'all' | 'none',
): SelectBox<T> => {
    let initialState: SelectBoxState<T>;

    if (multiple) {
        initialState = {
            selected: filterObjects<T>(value),
            isInitialized: false,
            multiple: true,
        };
    } else {
        initialState = {
            selected: filterObjectOrNull<T>(value),
            isInitialized: false,
        };
    }

    const [state, setState] = useState<SelectBoxState<T>>(initialState);

    let handleChange;
    let handleDeselect;

    if (state.multiple) {
        handleChange = (value: T | (T | null)[] | null) => {
            const items: T[] = filterObjectsIfNoneNull<T>(value);
            setState({...state, selected: items});
            if (multiple) {
                onSelect(items);
            }
        };
        handleDeselect = () => {
            setState({...state, selected: []});
            if (multiple) {
                onSelect([]);
            }
        };
    } else {
        handleChange = (value: T | (T | null)[] | null) => {
            const item: T | null = !Array.isArray(value) ? value : null;
            setState({...state, selected: item});
            if (!multiple) {
                onSelect(item);
            }
        };
        handleDeselect = () => {
            setState({...state, selected: null});
            if (!multiple) {
                onSelect(null);
            }
        };
    }

    useEffect(() => {
        // Handle multi select
        if (!data || data.length === 0 || !multiple || !state.multiple) {
            return;
        }

        const itemIds = filterStrings<T>(value);

        if (itemIds.length > 0) {
            const items = data.filter(item => itemIds.includes(item.id)) || [];
            if (items.length > 0 && !arrayEqual<T>(items, state.selected)) {
                setState({
                    selected: items,
                    isInitialized: true,
                    multiple: true,
                });
                onSelect(items);
                return;
            }
        }

        const objects = filterObjects<T>(value);

        if (objects && objects.length > 0) {
            if (!arrayEqual<T>(objects, state.selected)) {
                setState({
                    selected: objects,
                    isInitialized: true,
                    multiple: true,
                });
                onSelect(objects);
                return;
            }
        }

        if (state.isInitialized) {
            return;
        }

        if (defaultValue === 'all') {
            setState({
                selected: data,
                isInitialized: true,
                multiple: true,
            });
            onSelect(data);
            return;
        }

        setState(prevState => {
            return {
                ...prevState,
                isInitialized: true,
            };
        });
    }, [data, value, onSelect, multiple, defaultValue, state.selected, state.multiple, state.isInitialized]);

    useEffect(() => {
        // Handle single select
        if (!data || multiple || state.multiple) {
            return;
        }

        if (value === null && state.selected) {
            setState({
                selected: null,
                isInitialized: true,
            });
            onSelect(null);
            return;
        }

        const itemId = filterStringOrNull<T>(value);

        if (itemId) {
            const item = data.find(item => item.id === itemId) || null;
            if (item && item !== state.selected) {
                setState({
                    selected: item,
                    isInitialized: true,
                });
                onSelect(item);
                return;
            } else if (item === null && state.selected) {
                setState({
                    selected: null,
                    isInitialized: true,
                });
                onSelect(item);
                return;
            }
        }

        const object = filterObjectOrNull(value);

        if (object) {
            const item = data.find(item => item.id === object.id) || null;
            if (item && item !== state.selected) {
                setState({
                    selected: item,
                    isInitialized: true,
                });
                onSelect(item);
                return;
            } else if (item === null && state.selected) {
                setState({
                    selected: null,
                    isInitialized: true,
                });
                onSelect(item);
                return;
            }
        }

        if (state.isInitialized) {
            return;
        }

        if (defaultValue === 'first') {
            const item = data[0];
            setState({
                selected: item,
                isInitialized: true,
            });
            onSelect(item);
            return;
        }

        setState(prevState => {
            return {
                ...prevState,
                isInitialized: true,
            };
        });
    }, [data, value, onSelect, multiple, defaultValue, state.selected, state.multiple, state.isInitialized]);

    return {
        handleChange,
        handleDeselect,
        state,
    };
};

export default useSelectBox;
