import {useEffect, useState} from 'react';
import {Configuration, SuppliersApi} from '@/api';
import {EmptyResult} from '@/models/Pagination';
import {Supplier, Suppliers} from '@/models/Supplier';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {setPaginationResultIfNotLoading} from '@/utils/pagination';
import {useQuery, useQueryClient} from '@tanstack/react-query';

const initialState = EmptyResult<Supplier>();

interface FindSuppliersQueryResult {
    suppliers: Suppliers;
    isLoading: boolean;
    error: Error | null;
    itemsPerPage: number;
    invalidateQuery: () => Promise<void>;
}

const useFindSuppliersQuery = (page: number, searchQuery?: string): FindSuppliersQueryResult => {
    const queryClient = useQueryClient();

    const [suppliers, setSuppliers] = useState<Suppliers>(initialState);

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const itemsPerPage = 10;

    const {
        isLoading,
        data,
        isFetching,
        error,
    } = useQuery({
        queryKey: ['suppliers', page, searchQuery],
        queryFn: () => {
            return suppliersApi.findSuppliers(
                searchQuery,
                undefined,
                page * itemsPerPage - itemsPerPage,
                itemsPerPage,
            );
        },
    });

    useEffect(() => {
        setPaginationResultIfNotLoading(data, setSuppliers, isLoading);
    }, [data, isLoading, isFetching]);

    const invalidateQuery = async () => {
        return queryClient.invalidateQueries({
            predicate: query => query.queryKey[0] === 'suppliers',
        });
    };

    return {
        suppliers,
        isLoading,
        error,
        itemsPerPage,
        invalidateQuery,
    };
};

export default useFindSuppliersQuery;
