import {useEffect, useState} from 'react';
import {CustomersApi} from '@/api';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import {SearchProps} from '@/hooks/useSearch';
import {Customer, Customers} from '@/models/Customer';
import {EmptyResult} from '@/models/Pagination';
import {Supplier} from '@/models/Supplier';
import {CustomerEditPath} from '@/modules/admin/paths';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

const initialState = EmptyResult<Customer>();

interface SupplierToCustomerTableProps extends SearchProps {
    supplier: Supplier;
}

const SupplierToCustomerTable = ({
    supplier,
    searchQuery,
    page = 1,
    onChangePage = () => {},
}: SupplierToCustomerTableProps) => {
    const [customers, setCustomers] = useState<Customers>(initialState);

    const customersApi = new CustomersApi();

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['customers', supplier.id, page, searchQuery],
        queryFn: async () => {
            const limit = 10;
            return customersApi.findCustomersBySupplier(
                supplier.id,
                searchQuery,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setCustomers((prevState): Customers => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Supplier</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {customers.items.map((customer, index) => {
                        if (customer.id === undefined) {
                            return;
                        }
                        return (
                            <tr key={index}>
                                <td>
                                    <Link to={CustomerEditPath.withId(customer.id)}
                                          className="text-blue-500 hover:underline">
                                        {customer.name}
                                    </Link>
                                </td>
                                <td>{supplier.name}</td>
                                <td>
                                    <Button
                                        onClick={() => {}}
                                        variant="delete"
                                        size="xs"
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={customers.totalCount || 0}
                    estimatedTotalCount={customers.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default SupplierToCustomerTable;
