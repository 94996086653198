import {Configuration, SuppliersApi} from '@/api';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery, useQueryClient} from '@tanstack/react-query';

const useGetSupplierQuery = (supplierId?: string) => {
    const queryClient = useQueryClient();

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const {
        isLoading,
        data: supplier,
        error,
    } = useQuery({
        queryKey: ['supplier', supplierId],
        queryFn: () => {
            return supplierId
                ? suppliersApi.getSupplier(supplierId)
                : null;
        },
        enabled: !!supplierId, // Disable the query if supplierId is not available
    });

    const invalidateQuery = async () => {
        return queryClient.invalidateQueries({queryKey: ['supplier', supplierId]});
    };

    return {
        supplier,
        isLoading,
        error,
        invalidateQuery,
    };
};

export default useGetSupplierQuery;
