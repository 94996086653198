import {forwardRef, useState} from 'react';
import Button from '@/components/Button';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import {User} from '@/models/User';
import {withMinimumDelay} from '@/utils/delay';
import {withLoading} from '@/utils/loading';
import {useForm} from 'react-hook-form';

interface UserProfileFormProps {
    user?: User;
    onSubmit: (user: User) => Promise<void>;
}

const UserProfileForm = forwardRef<HTMLFormElement, UserProfileFormProps>(function UserProfileForm({
    user,
    onSubmit,
}, ref) {
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<User>({defaultValues: user});

    const [loading, setLoading] = useState(false);

    const handleSubmitWithLoading = withLoading(withMinimumDelay(onSubmit, 500), setLoading);

    return (
        <form ref={ref} onSubmit={handleSubmit(handleSubmitWithLoading)}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="username" className="block font-medium text-gray-700">
                        Username:
                    </label>
                    <input
                        id="username"
                        {...register('username', {required: true})}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.username && <p className="text-red-700 text-sm">Invalid username.</p>}
                </div>
                <div className="col-span-2">
                    <label htmlFor="email" className="block font-medium text-gray-700">
                        Email:
                    </label>
                    <input
                        id="email"
                        {...register('email', {
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        })}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.email && <p className="text-red-700 text-sm">Invalid email address.</p>}
                </div>
                <div className="col-span-1">
                    <label htmlFor="firstName" className="block font-medium text-gray-700">
                        First Name:
                    </label>
                    <input
                        id="firstName"
                        {...register('firstName')}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.firstName && <p className="text-red-700 text-sm">Invalid first name.</p>}
                </div>
                <div className="col-span-1">
                    <label htmlFor="lastName" className="block font-medium text-gray-700">
                        Last Name:
                    </label>
                    <input
                        id="lastName"
                        {...register('lastName')}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.lastName && <p className="text-red-700 text-sm">Invalid last name.</p>}
                </div>
                <div className="col-span-2">
                    <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-600">
                        Mobile Number:
                    </label>
                    <PhoneNumberInput
                        onChange={(countryCode) => setValue('mobileCountryCode', countryCode)}
                        id="mobileNumber"
                        inputProps={register('mobileNumber', {required: true})}
                    />
                    {errors.mobileNumber && (
                        <p className="text-red-700 text-sm">Invalid mobile number.</p>
                    )}
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    type="submit"
                    variant="primary"
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
});

export default UserProfileForm;
