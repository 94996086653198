import React, {useState} from 'react';
import Button from '@/components/Button';
import CountrySelectBox, {CountryItem} from '@/components/CountrySelectBox';
import {Calendar, CalendarCreate, CalendarUpdate} from '@/models/Calendar';
import {CheckIcon} from '@heroicons/react/24/solid';

interface CalendarFormProps {
    calendar?: Calendar;
    onSubmit: (calendar: CalendarCreate | CalendarUpdate) => Promise<void>;
}

const CalendarForm: React.FC<CalendarFormProps> = ({calendar, onSubmit}) => {
    const [formData, setFormData] = useState<CalendarCreate | CalendarUpdate>(
        calendar ? {...calendar} : {
            name: '',
            countryCode: '',
        },
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleCountryCodeChange = (country: CountryItem | null) => {
        setFormData(prevData => ({
            ...prevData,
            countryCode: country?.id,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <CountrySelectBox
                        value={formData.countryCode || null}
                        onSelect={handleCountryCodeChange}
                    />
                </div>
            </div>
            <div className="mt-12 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default CalendarForm;
