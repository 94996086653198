import {createCalendar} from '@internationalized/date';
import React, {useRef} from 'react';
import {CalendarButton} from '@/components/datepicker/Button';
import CalendarGrid from '@/components/datepicker/CalendarGrid';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/outline';
import {CalendarProps, DateValue, useCalendar} from '@react-aria/calendar';
import {useLocale} from '@react-aria/i18n';
import {useCalendarState} from '@react-stately/calendar';

export const Calendar: React.FC<CalendarProps<DateValue>> = (props) => {
    const {locale} = useLocale();
    const state = useCalendarState({
        ...props,
        locale,
        createCalendar,
    });

    const ref = useRef<HTMLDivElement>(null);
    const {
        calendarProps,
        prevButtonProps,
        nextButtonProps,
        title,
    } = useCalendar(
        props,
        state,
    );

    return (
        <div {...calendarProps} ref={ref} className="inline-block text-gray-800">
            <div className="flex items-center pb-4">
                <h2 className="flex-1 font-bold text-xl ml-2">{title}</h2>
                <CalendarButton {...prevButtonProps}>
                    <ChevronLeftIcon className="h-6 w-6"/>
                </CalendarButton>
                <CalendarButton {...nextButtonProps}>
                    <ChevronRightIcon className="h-6 w-6"/>
                </CalendarButton>
            </div>
            <CalendarGrid state={state}/>
        </div>
    );
};

export default Calendar;
