import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import {SupplierWarehouseAddPath} from '@/modules/admin/paths';
import WarehouseTable from '@/modules/supplier/components/WarehouseTable';
import {PlusIcon} from '@heroicons/react/24/solid';
import {useParams} from 'react-router-dom';

const WarehouseList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const {supplierId} = useParams();

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Warehouses">
                {supplierId && <Button
                    to={SupplierWarehouseAddPath.withSupplierId(supplierId)}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Warehouse
                </Button>}
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by name"
            />

            <WarehouseTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                supplierId={supplierId}
            />
        </div>
    );
};

export default WarehouseList;
