import {useMemo} from 'react';
import NumberInput from '@/components/NumberInput';
import {Order} from '@/models/Order';
import formatPrice from '@/utils/formatPrice';

interface OrderItemQuantities {
    [orderItemId: string]: number;
}

interface OrderItemEditTableProps {
    order: Order;
    confirmedQuantities?: OrderItemQuantities;
    onChangeConfirmedQuantity?: (quantity: number, orderItemId: string) => void;
}

const OrderItemEditTable = ({
    order,
    confirmedQuantities = {},
    onChangeConfirmedQuantity = () => {},
}: OrderItemEditTableProps) => {
    const {
        subtotal,
        grandTotal,
    } = useMemo(() => {
        let subtotal = 0;
        let totalVatAmount = 0;

        order.items.forEach((item) => {
            const quantity = confirmedQuantities[item.id] || 0;
            const unitPrice = item.unitPrice?.amount || 0;
            const lineItemAmount = unitPrice * quantity;
            const vatRate = item.vatRate || 0;

            subtotal += lineItemAmount;
            totalVatAmount += lineItemAmount * vatRate;
        });

        const grandTotal = subtotal + totalVatAmount;

        return {
            subtotal,
            grandTotal,
        };
    }, [order.items, confirmedQuantities]);

    return (
        <div className="shadow-md rounded-lg">
            <div className="relative overflow-x-auto border rounded-t-lg">
                <table className="min-w-full">
                    <thead>
                    <tr className="bg-gray-50 border-b border-gray-300 text-gray-500 font-medium text-sm">
                        <th className="px-6 py-3 pr-3 h-12 w-20 text-left">
                            #
                        </th>
                        <th className="px-6 py-3 text-left w-96">
                            SKU
                        </th>
                        <th className="px-6 py-3 text-left w-40">
                            SKU Code
                        </th>
                        <th className="px-6 py-3 text-left">
                            Ordered Quantity
                        </th>
                        <th className="px-6 py-3 text-left">
                            Confirmed Quantity
                        </th>
                        <th className="px-6 py-3 text-left">
                            VAT Rate
                        </th>
                        <th className="px-3 py-3 pr-6 text-right w-40">
                            Subtotal (ex-VAT)
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {order.items.map((orderItem, index) => {
                        const quantity = confirmedQuantities[orderItem.id] || 0;
                        const lineItemAmount = (orderItem.unitPrice?.amount || 0) * quantity;

                        return (
                            <tr key={index} className="text-sm lg:text-base">
                                <td className="px-6 py-4">
                                    {orderItem.lineNumber}
                                </td>
                                <td className="px-6 py-4">
                                    {orderItem.skuName}
                                </td>
                                <td className="px-6 py-4">
                                    {orderItem.skuCode}
                                </td>
                                <td className="px-6 py-4">
                                    {orderItem.quantity}
                                </td>
                                <td className="px-3 py-1">
                                    <NumberInput
                                        value={quantity}
                                        min={0}
                                        max={1_000_000}
                                        onChange={(quantity: number) =>
                                            onChangeConfirmedQuantity(
                                                quantity,
                                                orderItem.id,
                                            )}
                                    />
                                </td>
                                <td className="px-6 py-4">
                                    {(orderItem.vatRate || 0) * 100}%
                                </td>
                                <td className="px-3 py-4 pr-6 text-right">
                                    {formatPrice(lineItemAmount, orderItem.lineItemPrice?.currencyCode)}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                    <tbody className="border-t border-gray-300">
                    <tr>
                        <td colSpan={6} className="text-right px-3 pr-1 py-1 pt-6 font-semibold text-sm">
                            Subtotal (ex-VAT)
                        </td>
                        <td className="px-3 pt-6 pr-6 text-right">{formatPrice(subtotal, order.subtotal?.currencyCode)}</td>
                    </tr>
                    <tr>
                        <td colSpan={6} className="text-right px-3 pr-1 py-1 font-semibold text-sm">Total (ex-VAT)</td>
                        <td className="px-3 py-1 pr-6 text-right">{formatPrice(subtotal, order.subtotal?.currencyCode)}</td>
                    </tr>
                    <tr>
                        <td colSpan={6} className="text-right px-3 pr-1 py-1 pb-6 font-semibold text-sm">
                            Grand Total (VAT inclusive)
                        </td>
                        <td className="px-3 py-1 pb-6 pr-6 text-right">{formatPrice(grandTotal, order.grandTotal?.currencyCode)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrderItemEditTable;
