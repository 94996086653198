import {createContext, useContext} from 'react';
import {User, UserRole} from '@/modules/auth/models/User';

interface AuthorizationContextType {
    isLoggedIn: boolean;
    accessToken: string | undefined;
    idToken: string | undefined;
    role?: UserRole;
    roles: UserRole[];
    currentUser: () => User | undefined;
    changeRole : (role: UserRole) => void;
    login: (username: string, password: string) => Promise<boolean>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthorizationContextType>({
    isLoggedIn: false,
    accessToken: undefined,
    idToken: undefined,
    role: undefined,
    roles: [],
    currentUser: () => undefined,
    changeRole: () => {},
    login: async () => false,
    logout: async () => {},
});

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
