import {formatDate} from 'date-fns/format';
import React, {useEffect, useState} from 'react';
import {WarehousesApi} from '@/api';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import StatusActiveIndicator from '@/components/StatusActiveIndicator';
import {SearchProps} from '@/hooks/useSearch';
import {EmptyResult} from '@/models/Pagination';
import {isStatusActive} from '@/models/Status';
import {Warehouse, Warehouses} from '@/models/Warehouse';
import {SupplierWarehouseEditPath} from '@/modules/admin/paths';
import {WarehouseEditPath} from '@/modules/supplier/paths';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

interface WarehouseTableProps extends SearchProps {
    supplierId?: string;
}

const initialState = EmptyResult<Warehouse>();

const WarehouseTable: React.FC<WarehouseTableProps> = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    supplierId,
}) => {
    const [warehouses, setWarehouses] = useState<Warehouses>(initialState);

    const warehousesApi = new WarehousesApi();

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['warehouses', page, searchQuery, supplierId],
        queryFn: async () => {
            const limit = 10;
            return warehousesApi.findWarehouses(
                searchQuery,
                supplierId,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setWarehouses((prevState): Warehouses => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {warehouses.items.map((warehouse, index) => {
                        if (warehouse.id === undefined) {
                            return;
                        }

                        const editPath = supplierId
                            ? SupplierWarehouseEditPath.withParams(supplierId, warehouse.id)
                            : WarehouseEditPath.withId(warehouse.id);

                        return (
                            <tr key={index}>
                                <td>{warehouse.id}</td>
                                <td>
                                    <Link to={editPath}
                                          className="text-blue-500 hover:underline">
                                        {warehouse.name}
                                    </Link>
                                </td>
                                <td>{warehouse.createdAt && formatDate(warehouse.createdAt, 'd MMM yyyy')}</td>
                                <td><StatusActiveIndicator isActive={isStatusActive(warehouse)}/></td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={warehouses.totalCount || 0}
                    estimatedTotalCount={warehouses.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default WarehouseTable;
