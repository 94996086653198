import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import ProductVariantTable from '@/modules/supplier/components/ProductVariantTable';
import {ProductProductVariantAddPath, ProductVariantAddPath} from '@/modules/supplier/paths';
import {PlusIcon} from '@heroicons/react/24/solid';
import {useParams} from 'react-router-dom';

const ProductVariantList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const {productId} = useParams();

    const addPath = productId
        ? ProductProductVariantAddPath.withProductId(productId)
        : ProductVariantAddPath;

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Product Variants">
                <Button
                    to={addPath}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Product Variant
                </Button>
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by name"
            />

            <ProductVariantTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                productId={productId}
            />
        </div>
    );
};

export default ProductVariantList;
