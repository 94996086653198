import {Configuration, SuppliersApi} from '@/api';
import CustomToast from '@/components/CustomToast';
import {SupplierCreate, SupplierUpdate} from '@/models/Supplier';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import SupplierForm from '@/modules/retailer/components/SupplierForm';
import {SettingsPath} from '@/modules/retailer/paths';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {TruckIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

const SupplierAdd = () => {
    const navigate = useNavigate();

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const handleSubmit = async (supplier: SupplierCreate | SupplierUpdate) => {
        try {
            await suppliersApi.createSupplier(supplier);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Supplier created successfully!'}
                    visible={t.visible}
                />
            ));

            navigate(SettingsPath.supplier);
        } catch (error) {
            console.error('Error while creating supplier:', error);
        }
    };

    return (
        <>
            <SettingsHeader
                title="Add Supplier"
                icon={<TruckIcon className="w-6 h-6"/>}
            />

            <SupplierForm
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default SupplierAdd;
