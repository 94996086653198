import breadcrumbs from '@/breadcrumbs';
import {getCurrentPath} from '@/utils/getCurrentPath';
import {ChevronRightIcon} from '@heroicons/react/24/outline';
import {HomeIcon} from '@heroicons/react/24/solid';
import {generatePath, Link, useLocation, useParams} from 'react-router-dom';

const Breadcrumbs = () => {
    const location = useLocation();
    const params = useParams();
    const currentPath = getCurrentPath(location);

    const result = breadcrumbs.find(item => item.path === currentPath);
    if (!result) {
        return;
    }

    const {items} = result;

    return (
        <nav aria-label="breadcrumb">
            <ol className="flex items-center mb-4 space-x-1 md:space-x-1.5">
                <li className="inline-flex items-center">
                    <HomeIcon className="w-4 h-4 text-gray-400"/>
                </li>
                {items.map((item, index) => {
                    const url = generatePath(item.path, params);

                    return (
                        <li key={index} className="inline-flex items-center">
                            <ChevronRightIcon className="w-4 h-4 text-gray-400 mr-1.5"/>
                            {index === items.length - 1 ? (
                                <span className="text-sm font-medium text-gray-500">{item.label}</span>
                            ) : (
                                <Link to={url} className="text-sm font-medium text-gray-700 hover:text-blue-600 ">{item.label}</Link>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
