import React, {useState} from 'react';
import Button from '@/components/Button';
import CurrencySelectBox, {CurrencyItem} from '@/components/CurrencySelectBox';
import CustomerSelectBox from '@/components/CustomerSelectBox';
import DatePicker from '@/components/datepicker/DatePicker';
import {toDateValue, toTimeValue} from '@/components/datepicker/mappers';
import TimeField from '@/components/datepicker/TimeField';
import NumberInput from '@/components/NumberInput';
import PriceInput from '@/components/PriceInput';
import ProductCategorySelectBox from '@/components/ProductCategorySelectBox';
import ProductListSelectBox from '@/components/ProductListSelectBox';
import StoreSelectBox from '@/components/StoreSelectBox';
import ToggleSwitch from '@/components/ToggleSwitch';
import {useSettings} from '@/contexts/SettingsContext';
import {Customer} from '@/models/Customer';
import {ProductCategory} from '@/models/ProductCategory';
import {ProductList} from '@/models/ProductList';
import {
    DiscountType,
    DiscountTypeLabels,
    parseDiscountType,
    Promotion,
    PromotionCreate,
} from '@/models/Promotion';
import {isStatusActive} from '@/models/Status';
import {Store} from '@/models/Store';
import {CheckIcon} from '@heroicons/react/24/outline';
import {DateValue} from '@react-aria/calendar';
import {TimeValue} from '@react-aria/datepicker';

interface PromotionFormProps {
    onSubmit: (promotion: PromotionCreate | Promotion) => void;
    promotion?: PromotionCreate | Promotion;
}

interface FormData extends PromotionCreate, Promotion {
    startDate?: DateValue | null;
    startTime?: TimeValue | null;
    endDate?: DateValue | null;
    endTime?: TimeValue | null;
    currencyCode: string | null;
}

const PromotionForm = ({onSubmit, promotion}: PromotionFormProps) => {
    const {timezone} = useSettings();

    const [formData, setFormData] = useState<FormData>(
        promotion ? {
            id: '',
            createdAt: new Date(),
            startDate: toDateValue(promotion.effectivePeriod?.start, timezone),
            startTime: toTimeValue(promotion.effectivePeriod?.start, timezone),
            endDate: toDateValue(promotion.effectivePeriod?.end, timezone),
            endTime: toTimeValue(promotion.effectivePeriod?.end, timezone),
            currencyCode: '',
            ...promotion,
        } : {
            id: '',
            name: '',
            status: 'ACTIVE',
            supplierId: '',
            discountType: DiscountType.FixedAmount,
            minimumAmount: undefined,
            minimumQuantity: 0,
            createdAt: new Date(),
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            currencyCode: '',
        },
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevPromotion => ({
            ...prevPromotion,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    const handleDateChange = (name: 'startDate' | 'endDate', value: DateValue) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleCurrencyChange = (currency: CurrencyItem | null) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            currencyCode: currency?.id || null,
        }));
    };

    const handleDiscountTypeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const parsedDiscountType = parseDiscountType(e.target.value);
        if (parsedDiscountType) {
            setFormData(prevFormData => ({
                ...prevFormData,
                discountType: parsedDiscountType,
            }));
        }
    };

    const handleFixedAmountDiscountChange = (amount: number) => {
        setFormData(prevFormData => {
            if (!prevFormData.currencyCode) {
                return prevFormData;
            }
            return {
                ...prevFormData,
                fixedAmounts: [{
                    amount: {
                        currencyCode: prevFormData.currencyCode,
                        amount: amount,
                    },
                }],
                percentages: [],
            };
        });
    };

    const handlePercentageDiscountChange = (percentage: number) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            fixedAmounts: [],
            percentages: [{
                percentage: percentage,
            }],
        }));
    };

    const handleMinimumAmountChange = (amount: number) => {
        setFormData(prevFormData => {
            if (!prevFormData.currencyCode) {
                return prevFormData;
            }
            return {
                ...prevFormData,
                minimumAmount: {
                    currencyCode: prevFormData.currencyCode,
                    amount: amount,
                },
            };
        });
    };

    const handleMinimumQuantityChange = (quantity: number) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            minimumQuantity: quantity,
        }));
    };

    const handleProductCategoryChange = (productCategory?: ProductCategory | null) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            productCategoryId: productCategory?.id,
        }));
    };

    const handleProductListChange = (productList?: ProductList | null) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            productListId: productList?.id,
        }));
    };

    const handleCustomerChange = (customer?: Customer | null) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            customerId: customer?.id,
        }));
    };

    const handleStoreChange = (store?: Store | null) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            storeId: store?.id,
        }));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>

                <div className="col-span-1">
                    <CurrencySelectBox
                        value={formData.currencyCode}
                        onSelect={handleCurrencyChange}
                        requireSelection
                    />
                </div>

                <div className="col-span-2">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Discount
                    </span>

                    <div className="flex gap-4">
                        {[DiscountType.FixedAmount, DiscountType.Percentage].map(discountType => (
                            <div key={discountType}
                                 className="flex flex-1 items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                <input
                                    id={discountType}
                                    type="radio"
                                    value={discountType}
                                    name="discountType"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    checked={formData.discountType === discountType}
                                    onChange={handleDiscountTypeChange}
                                />
                                <label
                                    htmlFor={discountType}
                                    className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    {DiscountTypeLabels[discountType]}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                {formData.discountType === DiscountType.FixedAmount && <>
                    <div className="col-span-2 md:col-span-1">
                        <label htmlFor="fixedAmount" className="block font-medium text-gray-700 mb-1">
                            Fixed Amount:
                        </label>
                        <PriceInput
                            id="fixedAmount"
                            value={formData.fixedAmounts?.[0]?.amount.amount || ''}
                            currencyCode={formData.currencyCode || ''}
                            onChange={handleFixedAmountDiscountChange}
                        />
                    </div>
                </>}

                {formData.discountType === DiscountType.Percentage && <>
                    <div className="col-span-2 md:col-span-1">
                        <label htmlFor="percentage" className="block font-medium text-gray-700 mb-1">
                            Percentage:
                        </label>
                        <NumberInput
                            id="percentage"
                            value={formData.percentages?.[0]?.percentage || 0}
                            onChange={handlePercentageDiscountChange}
                            className="w-full"
                        />
                    </div>
                </>}

                <div className="col-span-2">
                    <span className="block font-bold text-xl pb-4 border-b border-gray-300">
                        Conditions
                    </span>
                </div>

                <div className="col-span-2 md:col-span-1">
                    <label htmlFor="minimumAmount" className="block font-medium text-gray-700 mb-1">
                        Minimum Amount:
                    </label>
                    <PriceInput
                        id="minimumAmount"
                        value={formData.minimumAmount?.amount || ''}
                        currencyCode={formData.currencyCode || ''}
                        onChange={handleMinimumAmountChange}
                    />
                </div>
                <div className="col-span-2 md:col-span-1">
                    <label htmlFor="minimumQuantity" className="block font-medium text-gray-700 mb-1">
                        Minimum Quantity:
                    </label>
                    <NumberInput
                        id="minimumQuantity"
                        value={formData.minimumQuantity || ''}
                        onChange={handleMinimumQuantityChange}
                        className="w-full"
                    />
                </div>

                <div className="col-span-2 my-4">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Products
                    </span>
                    <div className="flex flex-col sm:flex-row gap-4 lg:gap-8">
                        <div className="flex-1">
                            <ProductCategorySelectBox<ProductCategory>
                                value={formData.productCategoryId || null}
                                onSelect={handleProductCategoryChange}
                            />
                        </div>
                        <div className="flex-1">
                            <ProductListSelectBox
                                value={formData.productListId || null}
                                onSelect={handleProductListChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-2 mb-4">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Effective Period
                    </span>
                    <div className="md:grid md:grid-cols-2 flex flex-col gap-4 lg:gap-8">
                        <div className="flex flex-row items-end colspan-1 gap-4">
                            <div className="flex-1">
                                <DatePicker
                                    label="Start:"
                                    onChange={(value) => handleDateChange('startDate', value)}
                                    value={formData.startDate}
                                />
                            </div>
                            <div className="flex-1">
                                <TimeField
                                    value={formData.startTime}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-end colspan-1 gap-4">
                            <div className="flex-1">
                                <DatePicker
                                    label="End:"
                                    onChange={(value) => handleDateChange('endDate', value)}
                                    value={formData.endDate}
                                />
                            </div>
                            <div className="flex-1">
                                <TimeField
                                    value={formData.endTime}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-2 mb-4">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Target
                    </span>
                    <div className="flex flex-col sm:flex-row gap-4 lg:gap-8">
                        <div className="flex-1">
                            <CustomerSelectBox
                                value={formData.customerId || null}
                                onSelect={handleCustomerChange}
                            />
                        </div>
                        <div className="flex-1">
                            <StoreSelectBox
                                value={formData.storeId || null}
                                onSelect={handleStoreChange}
                                customerId={formData.customerId}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-2">
                    <ToggleSwitch
                        checked={isStatusActive(formData)}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default PromotionForm;
