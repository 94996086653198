import {useEffect, useState} from 'react';
import {Configuration, UsersApi} from '@/api';
import Button from '@/components/Button';
import CustomToast from '@/components/CustomToast';
import CheckLottieIcon from '@/components/icons/CheckLottieIcon';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {HomePath} from '@/paths';
import toast from 'react-hot-toast';
import {useParams, useSearchParams} from 'react-router-dom';

const Verification = () => {
    const {id: verificationId} = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const [verified, setVerified] = useState<boolean | null>(null);

    const verificationCode = searchParams.get('code');

    useEffect(() => {
        const verifyUser = async (verificationId: string, verificationCode: string) => {
            const usersApi = new UsersApi(new Configuration());

            try {
                await usersApi.verifyUser(verificationId, verificationCode);

                setVerified(true);
            } catch (error) {
                console.error('Error during verification:', error);

                setVerified(false);

                toast.custom(t => (
                    <CustomToast
                        id={t.id}
                        message={'Verification failed. Please try again later.'}
                        variant={'error'}
                        visible={t.visible}
                    />
                ));
            }
        };
        if (verificationId && verificationCode) {
            void verifyUser(verificationId, verificationCode);
        }
    }, [verificationId, verificationCode]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-20 pb-32">
            <div>
                {verified === true && (
                    <div className="flex flex-col justify-center gap-8">
                        <div className="w-40 h-40 mb-10 mx-auto text-green-600">
                            <CheckLottieIcon/>
                        </div>

                        <p>Verification successfully completed.</p>

                        <Button
                            to={HomePath}
                            rounded="full"
                        >
                            Continue
                        </Button>
                    </div>
                )}
                {verified === false && (
                    <>
                        <p className="text-center text-red-600">Verification failed. Please try again later.</p>
                    </>
                )}
                {verified === null && (
                    <div className="flex flex-col items-center justify-center">
                        <div className="m-4">
                            <SpinnerIcon/>
                        </div>
                        <p>Verifying...</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Verification;
