import {Configuration, UsersApi} from '@/api';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery, useQueryClient} from '@tanstack/react-query';

const useUserQuery = (userId?: string) => {
    const queryClient = useQueryClient();
    const {currentUser} = useAuth();

    const usersApi = new UsersApi(new Configuration(useAuth));

    const {id: currentUserId} = currentUser() || {};

    const localUserId = userId || currentUserId;

    const queryKey = ['user', localUserId];

    const invalidateQuery = async () => {
        return queryClient.invalidateQueries({queryKey});
    };

    const {
        isLoading,
        data: user,
    } = useQuery({
        queryKey,
        queryFn: async () =>
            localUserId
                ? usersApi.getUser(localUserId)
                : null,
    });

    return {
        isLoading,
        user,
        invalidateQuery,
    };
};

export default useUserQuery;
