import CountrySelectBox, {CountryItem} from '@/components/CountrySelectBox';
import {SignupAccount} from '@/modules/auth/models/Signup';
import {FieldErrors, UseFormRegister} from 'react-hook-form';

interface SignupAccountCreateFormProps {
    register: UseFormRegister<SignupAccount>;
    errors: FieldErrors<SignupAccount>;
    countryCode?: string;
    setCountryCode: (countryCode?: string) => void;
}

const SignupAccountCreateForm = ({
    register,
    errors,
    countryCode,
    setCountryCode,
}: SignupAccountCreateFormProps) => {
    const handleCountryChange = (country?: CountryItem | null) => {
        setCountryCode(country?.id);
    };

    return (
        <>
            <div className="col-span-2">
                <label htmlFor="businessName" className="block text-sm font-medium text-gray-600">
                    Business Name:
                </label>
                <input
                    id="businessName"
                    {...register('businessName', {required: true})}
                    className="mt-1 p-2 w-full border rounded-md"
                />
                {errors.businessName && (
                    <p className="text-red-700 text-sm">Business name is required.</p>
                )}
            </div>

            <div className="col-span-2">
                <label htmlFor="street" className="block text-sm font-medium text-gray-600">
                    Street 1:
                </label>
                <input
                    id="street"
                    {...register('street1', {required: true})}
                    className="mt-1 p-2 w-full border rounded-md"
                />
                {errors.street1 && (
                    <p className="text-red-700 text-sm">Street is required.</p>
                )}
            </div>

            <div className="col-span-2">
                <label htmlFor="street" className="block text-sm font-medium text-gray-600">
                    Street 2:
                </label>
                <input
                    id="street2"
                    {...register('street2', {required: false})}
                    className="mt-1 p-2 w-full border rounded-md"
                />
                {errors.street2 && (
                    <p className="text-red-700 text-sm">Street is required.</p>
                )}
            </div>

            <div className="col-span-2">
                <label htmlFor="city" className="block text-sm font-medium text-gray-600">
                    City:
                </label>
                <input
                    id="city"
                    {...register('city', {required: true})}
                    className="mt-1 p-2 w-full border rounded-md"
                />
                {errors.city && (
                    <p className="text-red-700 text-sm">City is required.</p>
                )}
            </div>

            <div className="col-span-2">
                <CountrySelectBox
                    label={<span className="text-sm">Country:</span>}
                    value={countryCode || null}
                    onSelect={handleCountryChange}
                />
                {errors.countryCode && (
                    <p className="text-red-700 text-sm">Country is required.</p>
                )}
            </div>
        </>
    );
};

export default SignupAccountCreateForm;
