import {Cart, CartImpl} from '@/modules/retailer/models/Cart';

interface CartStorage {
    loadCart(): Promise<Cart[]>;
    storeCart(carts: Cart[]): Promise<void>;
    removeCart(): Promise<void>;
}

export class LocalStorageCartStorage implements CartStorage {
    private readonly storageKey: string;

    constructor(storageKey: string = '_cart') {
        this.storageKey = storageKey;
    }

    async loadCart(): Promise<Cart[]> {
        const storedCart = localStorage.getItem(this.storageKey);
        let carts: Cart[] = [];
        if (storedCart) {
            const {carts: parsedCarts} = JSON.parse(storedCart);
            if (Array.isArray(parsedCarts) && parsedCarts.every(this.isValidCart)) {
                carts = parsedCarts.map(CartImpl.fromObject);
            } else {
                void this.removeCart();
            }
        }
        return carts;
    }

    async storeCart(carts: Cart[]): Promise<void> {
        localStorage.setItem(this.storageKey, JSON.stringify({carts}));
    }

    async removeCart(): Promise<void> {
        localStorage.removeItem(this.storageKey);
    }

    private isValidCart(cart: unknown): cart is Cart {
        if (typeof cart !== 'object' || cart === null) {
            return false;
        }

        const obj = cart as Record<string, unknown>;

        return obj && typeof obj.supplierId === 'string' && Array.isArray(obj.items);
    }
}

export default CartStorage;
