import React from 'react';
import useGetSupplierQuery from '@/api/queries/useGetSupplierQuery';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import SupplierToCustomerTable from '@/modules/admin/components/SupplierToCustomerTable';
import {SupplierToCustomerAddPath} from '@/modules/admin/paths';
import {PlusIcon} from '@heroicons/react/24/solid';
import {useParams} from 'react-router-dom';

const SupplierToCustomerList: React.FC = () => {
    const {supplierId} = useParams();

    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const {
        isLoading,
        supplier,
    } = useGetSupplierQuery(supplierId);

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!supplier) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Supplier &#128073; Customer">
                <Button
                    to={SupplierToCustomerAddPath.withSupplierId(supplier.id)}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Customer
                </Button>
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by name"
            />

            <SupplierToCustomerTable
                supplier={supplier}
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
            />
        </div>
    );
};

export default SupplierToCustomerList;
