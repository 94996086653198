import {InputHTMLAttributes, useRef, useState} from 'react';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/react';

interface Country {
    code: string;
    name: string;
    flag: string;
    prefix: string;
}

const countries: Country[] = [
    {code: 'SG', name: 'Singapore', flag: '/flags/SG.svg', prefix: '+65'},
    {code: 'MY', name: 'Malaysia', flag: '/flags/MY.svg', prefix: '+60'},
    {code: 'ID', name: 'Indonesia', flag: '/flags/ID.svg', prefix: '+62'},
    {code: 'PH', name: 'Philippines', flag: '/flags/PH.svg', prefix: '+63'},
    {code: 'TH', name: 'Thailand', flag: '/flags/TH.svg', prefix: '+66'},
    {code: 'US', name: 'United States', flag: '/flags/US.svg', prefix: '+1'},
];

interface PhoneNumberInputProps {
    id?: string;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    onChange: (countryCode: string, number?: string) => void;
}

const PhoneNumberInput = ({id, inputProps, onChange}: PhoneNumberInputProps) => {
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);

    const ref = useRef<HTMLInputElement>(null);

    const handleCountryChange = (country: Country) => {
        onChange(country.prefix, ref.current?.value);
        setSelectedCountry(country);
    };

    return (
        <div className="flex items-center space-x-2 w-full mt-1">
            <Listbox value={selectedCountry} onChange={handleCountryChange}>
                <div className="flex relative w-full border border-gray-200 rounded-lg">
                    <ListboxButton
                        className="flex items-center space-x-2 p-2 bg-gray-100 w-28 border-r border-gray-200">
                        <img
                            src={selectedCountry.flag}
                            alt={selectedCountry.name}
                            width={24}
                            height={24}
                            className="shadow drop-shadow"
                        />
                        <span>{selectedCountry.prefix}</span>
                    </ListboxButton>
                    <ListboxOptions className="absolute z-20 top-full bg-white border border-gray-300 rounded shadow-lg w-full">
                        {countries.map((country) => (
                            <ListboxOption key={country.code} value={country}>
                                {({selected, focus}) => (
                                    <div
                                        className={`cursor-pointer select-none flex items-center space-x-2 px-2 py-1 ${
                                            selected ? 'bg-blue-600 text-white' : focus ? 'bg-gray-200' : ''
                                        }`}
                                    >
                                        <img
                                            src={country.flag}
                                            alt={country.name}
                                            width={24}
                                            height={24}
                                            className="shadow drop-shadow"
                                        />
                                        <span>{country.prefix}</span>
                                        <span>{country.name}</span>
                                    </div>
                                )}
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                    <input
                        ref={ref}
                        id={id}
                        className="px-2 py-1 w-full rounded-r-lg"
                        {...inputProps}
                    />
                </div>
            </Listbox>
        </div>
    );
};

export default PhoneNumberInput;
