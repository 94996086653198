import {FormEvent, useState} from 'react';
import Alert from '@/components/Alert';
import Checkbox from '@/components/Checkbox';
import PageHeader from '@/components/PageHeader';
import ProductCategorySelectBox from '@/components/ProductCategorySelectBox';
import SearchForm from '@/components/SearchForm';
import SupplierSelectBox from '@/components/SupplierSelectBox';
import useSearch from '@/hooks/useSearch';
import {ProductCategory} from '@/models/ProductCategory';
import {Sku} from '@/models/Sku';
import {Supplier} from '@/models/Supplier';
import AutoOrderButton from '@/modules/retailer/components/AutoOrderButton';
import ProductCatalogTable from '@/modules/retailer/components/ProductCatalogTable';
import {useCart} from '@/modules/retailer/contexts/CartContext';
import {CartItemImpl} from '@/modules/retailer/models/CartItem';
import {CartPath} from '@/modules/retailer/paths';
import {useNavigate, useSearchParams} from 'react-router-dom';

const Shop = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        searchQuery,
        currentSearchQuery,
        page,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const supplierIdParam = searchParams.get('supplierId');
    const productCategoryIdParam = searchParams.get('productCategoryId');
    const promotionsParam = searchParams.get('promotions') === 'yes';

    const {addToCart} = useCart();

    const search = (searchQuery: string | null, supplierId: string | null, productCategoryId: string | null, promotions: boolean) => {
        setSearchParams(new URLSearchParams({
            page: '1',
            query: searchQuery || '',
            supplierId: supplierId || '',
            productCategoryId: productCategoryId || '',
            promotions: promotions ? 'yes' : '',
        }));
    };

    const [supplier, setSupplier] = useState<Supplier | null>();
    const [productCategory, setProductCategory] = useState<ProductCategory | null>();
    const [promotions, setPromotions] = useState<boolean>(promotionsParam);

    const handleSupplierSelect = (item?: Supplier | null) => {
        if (item?.id === supplier?.id) {
            return;
        }

        setSupplier(item);

        if (item?.id === supplierIdParam) {
            return;
        }

        search(
            currentSearchQuery,
            item?.id || null,
            productCategory?.id || null,
            promotions,
        );
    };

    const handleProductCategorySelect = (item?: ProductCategory | null) => {
        if (item?.id === productCategory?.id) {
            return;
        }

        setProductCategory(item);

        if (item?.id === productCategoryIdParam) {
            return;
        }

        search(
            currentSearchQuery,
            item ? item?.supplierId || supplierIdParam : null,
            item?.id || null,
            promotions,
        );
    };

    const handlePromotionsChange = (value: boolean) => {
        if (value === promotions) {
            return;
        }

        setPromotions(value);

        if (value === promotionsParam) {
            return;
        }

        search(
            currentSearchQuery,
            supplierIdParam,
            productCategoryIdParam,
            value,
        );
    };

    const handleSearchSubmit = (e: FormEvent) => {
        e.preventDefault();

        search(
            currentSearchQuery,
            supplierIdParam || '',
            productCategoryIdParam || '',
            promotionsParam,
        );
    };

    const handleOnAddToCart = (sku: Sku, quantity: number) => {
        addToCart(sku.supplierId, new CartItemImpl(
            sku.id,
            sku.supplierId,
            quantity,
            sku.productId,
        ));
    };

    const handleAutoOrderSuccess = () => {
        navigate(CartPath);
    };

    return (
        <div>
            <PageHeader
                title="Products"
                alert={<Alert type="info" message="&#128075; Welcome! How can we assist you today? It's great to have you here!"/>}
            >
                <AutoOrderButton
                    variant="success"
                    loadingIconClassName="w-5 h-5 mr-1 fill-green-600"
                    onSuccess={handleAutoOrderSuccess}
                >
                    Auto-Order
                </AutoOrderButton>
            </PageHeader>

            <div className="flex flex-col xl:flex-row mb-10 xl:mb-8 gap-4">
                <div className="flex-1 flex-row">
                    <SearchForm
                        as="Fragment"
                        value={searchQuery}
                        onSubmit={handleSearchSubmit}
                        onChange={setSearchQuery}
                        placeholder="Search products"
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-4 xl:grid xl:grid-cols-2">
                    <div className="flex-1 lg:w-80">
                        <SupplierSelectBox
                            value={supplierIdParam || null}
                            onSelect={handleSupplierSelect}
                        />
                    </div>
                    <div className="flex-1 lg:w-80">
                        <ProductCategorySelectBox<ProductCategory>
                            value={productCategoryIdParam || null}
                            onSelect={handleProductCategorySelect}
                            supplierIds={supplier ? [supplier.id] : []}
                        />
                    </div>
                    <div className="flex-1 lg:w-80 flex flex-row items-center gap-2 py-2 md:pt-8 xl:py-1">
                        <Checkbox
                            id="promotions"
                            checked={promotions}
                            onChange={handlePromotionsChange}
                        />
                        <label htmlFor="promotions" className="flex items-center gap-1">
                            Promotions
                            <img src="/discount.svg" alt="" className="w-8 h-8"/>
                        </label>
                    </div>
                </div>
            </div>

            <ProductCatalogTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                supplierId={supplierIdParam}
                productCategoryId={productCategoryIdParam}
                promotions={promotionsParam}
                onAddToCart={handleOnAddToCart}
            />
        </div>
    );
};

export default Shop;
