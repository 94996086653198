import React from 'react';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Confirm from '@/components/Confirm';
import NumberInput from '@/components/NumberInput';
import useCheckboxes from '@/hooks/useCheckboxes';
import Price from '@/modules/retailer/components/Price';
import {CartDetails} from '@/modules/retailer/models/Cart';
import {ShopPath} from '@/modules/retailer/paths';
import formatPrice from '@/utils/formatPrice';
import {TrashIcon} from '@heroicons/react/24/outline';

interface CartTableProps {
    cart: CartDetails;
    onChangeOrderQuantity: (quantity: number, supplierId: string, itemId: number) => void;
    onChangeCheckedItems: (items: number[], supplierId: string) => void;
    removeItem: (supplierId: string, itemId: number) => void;
}

const CartTable: React.FC<CartTableProps> = ({
    cart,
    onChangeOrderQuantity,
    onChangeCheckedItems,
    removeItem,
}) => {
    const {
        checkboxes,
        handleMasterToggle,
        handleRowToggle,
        areAllChecked,
    } = useCheckboxes<number>({
        checkedItems: cart.items,
        onChange: (items) => onChangeCheckedItems(items, cart.supplierId),
    });

    const handleOrderQuantityChange = (quantity: number, supplierId: string, itemId: number) => {
        onChangeOrderQuantity(quantity, supplierId, itemId);
    };

    const handleRemoveItem = (supplierId: string, itemId: number) => {
        removeItem(supplierId, itemId);
    };

    return (
        <div className="table-container table-custom">
            <div className="table-wrapper">
                <table className="min-w-full w-[900px] lg:w-full table-fixed">
                    <thead>
                    <tr className="bg-gray-50 border-b border-gray-300 text-gray-500 font-medium text-sm">
                        <th className="w-[6%] cell-checkbox min-w-14">
                            <Checkbox
                                checked={areAllChecked()}
                                onChange={handleMasterToggle}
                            />
                        </th>
                        <th className="w-[32%] px-6 py-3 text-left text-sm">
                            SKU
                        </th>
                        <th className="w-[12%] px-3 py-3 text-left">
                            Price (ex-VAT)
                        </th>
                        <th className="w-[8%] px-3 py-3 text-left">
                            VAT Rate
                        </th>
                        <th className="w-[10%] px-3 py-3 text-left">
                            Predicted Sales
                        </th>
                        <th className="lg:w-[12%] w-[160px] px-3 py-3 text-left">
                            Order Quantity
                        </th>
                        <th className="w-[14%] px-3 py-3 text-right">
                            Subtotal (ex-VAT)
                        </th>
                        <th className="w-[6%]"></th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {cart.items.map((item, index) => (
                        <tr key={item.skuId + index} className="text-sm lg:text-base">
                            <td className="table-cell-checkbox">
                                <Checkbox
                                    checked={checkboxes.checked(item.id)}
                                    onChange={() => handleRowToggle(item.id)}
                                />
                            </td>
                            <td className="px-1 pr-3 py-1">
                                <div className="flex gap-4 items-center">
                                    {item.sku.image && <div className="w-20 min-w-20 min-h-20">
                                        <img src={item.sku.image.url} alt="Product Image"/>
                                    </div>}
                                    <div>
                                        {item.sku.name}
                                    </div>
                                </div>
                            </td>
                            <td className="px-3 py-1">
                                <Price price={item.unitPrice}/>
                            </td>
                            <td className="px-3 py-1">{item.vatRate ? item.vatRate + '%' : '0%'}</td>
                            <td className="px-3 py-1">{item.prediction?.predictedSales}</td>
                            <td className="px-3 py-1">
                                <Confirm
                                    onConfirm={() => handleRemoveItem(item.supplierId, item.id)}
                                    message={`Do you want to delete your order of ${item.sku.name}?`}
                                >
                                    {(handleClick) => (
                                        <NumberInput
                                            value={item.quantity}
                                            min={0}
                                            max={1_000_000}
                                            onChange={(quantity: number) => {
                                                if (quantity < 1) {
                                                    handleClick();
                                                }
                                                handleOrderQuantityChange(
                                                    quantity,
                                                    item.supplierId,
                                                    item.id,
                                                );
                                            }}
                                        />
                                    )}
                                </Confirm>
                            </td>
                            <td className="px-3 py-1 text-right">{formatPrice(item.lineItemPrice)}</td>
                            <td className="px-3 py-1">
                                <div className="flex items-center justify-center">
                                    <Confirm
                                        onConfirm={() => handleRemoveItem(item.supplierId, item.id)}
                                        message={`Do you want to delete your order of ${item.sku.name}?`}
                                    >
                                        {(handleClick, loading) => (
                                            <Button
                                                icon={<TrashIcon className="w-5 h-5 cursor-pointer text-black hover:text-red-500 transition duration-300"/>}
                                                onClick={handleClick}
                                                variant="plain"
                                                loading={loading}
                                            />
                                        )}
                                    </Confirm>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    <tbody className="border-t border-gray-300">
                    <tr>
                        <td colSpan={3} rowSpan={3} className="px-3 py-1">
                            <div>
                                <Button to={ShopPath.withSupplierId(cart.supplierId)} variant="primary">
                                    Add More Items
                                </Button>
                            </div>
                        </td>
                        <td colSpan={3} className="text-right px-6 py-1 pt-6 font-semibold text-sm">Subtotal (ex-VAT)</td>
                        <td className="px-3 py-1 pt-6 text-right">{formatPrice(cart.subtotal, cart.subtotal?.currencyCode)}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="text-right px-6 py-1 font-semibold text-sm">Total (ex-VAT)</td>
                        <td className="px-3 py-1 text-right">{formatPrice(cart.subtotal, cart.subtotal?.currencyCode)}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="text-right px-6 py-1 pb-6 font-semibold text-sm">Grand Total (VAT inclusive)</td>
                        <td className="px-3 py-1 pb-6 text-right">{formatPrice(cart.grandTotal, cart.grandTotal?.currencyCode)}</td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CartTable;
