import {useEffect} from 'react';

interface ScreenVisibilityProps {
    onVisible?: () => void;
    onHidden?: () => void;
}

const useScreenVisibility = ({
    onVisible = () => {},
    onHidden = () => {},
}: ScreenVisibilityProps) => {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                onVisible();
            } else {
                onHidden();
            }
        };

        const handleFocus = () => {
            onVisible();
        };

        const handleBlur = () => {
            onHidden();
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, [onVisible, onHidden]);
};

export default useScreenVisibility;
