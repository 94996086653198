import {CSSProperties, ReactNode, useEffect, useState} from 'react';
import {CheckIcon, XMarkIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

interface CustomToastProps {
    id: string;
    message: string;
    onClose?: () => void;
    visible: boolean;
    variant?: 'success' | 'error';
    style?: CSSProperties;
    icon?: ReactNode;
    dismissible?: boolean;
}

const CustomToast = ({
    id,
    message,
    onClose,
    visible: visibleProp,
    variant = 'success',
    style: styleProp,
    icon: iconProp,
    dismissible = true,
}: CustomToastProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(visibleProp);
    }, [visibleProp]);

    if (!onClose) {
        onClose = () => {
            toast.dismiss(id);
        };
    }

    const defaultIcon = variant === 'success' ? (
        <div className="flex items-center justify-center w-6 h-6 bg-green-500 rounded-full">
            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>
        </div>
    ) : variant === 'error' ? (
        <div className="flex items-center justify-center w-6 h-6 bg-red-500 rounded-full">
            <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true"/>
        </div>
    ) : null;

    const icon = iconProp || defaultIcon;

    const defaultStyle: CSSProperties = {
        background: 'rgba(51, 51, 51, 0.9)',
    };

    const style = {...defaultStyle, ...styleProp};

    return (
        <div className={`transition-all duration-500 ${
            visible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2'
        } max-w-md w-full shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`} style={style}>
            <div className="flex-1 w-0 p-4">
                <div className="flex items-center">
                    {icon && <div className="flex-shrink-0 pt-0.5">{icon}</div>}
                    <div className="ml-3 flex-1">
                        <p className="text-sm font-medium text-white">{message}</p>
                    </div>
                </div>
            </div>
            {dismissible && <div className="flex border-l border-white border-opacity-30">
                <button
                    onClick={onClose}
                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-white hover:text-gray-300"
                >
                    <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                </button>
            </div>}
        </div>
    );
};

export default CustomToast;
