import {useEffect, useState} from 'react';
import Button from '@/components/Button';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import TermsAndConditionsModal from '@/modules/auth/components/TermsAndConditionsModal';
import {SignupUser} from '@/modules/auth/models/Signup';
import {LoginPath} from '@/modules/auth/paths';
import {withMinimumDelay} from '@/utils/delay';
import {withLoading} from '@/utils/loading';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';

interface SignupUserFormProps {
    data?: SignupUser;
    onSubmit(data: SignupUser): Promise<void>;
}

const SignupUserForm = ({onSubmit, data}: SignupUserFormProps) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        watch,
    } = useForm<SignupUser>({
        defaultValues: data,
    });

    const [loading, setLoading] = useState(false);

    const email = watch('email', data?.email);
    const password = watch('password', data?.password);
    const termsAccepted = watch('termsAccepted', false);

    const handleSignup = withLoading(withMinimumDelay(onSubmit, 500), setLoading);

    useEffect(() => {
        setValue('username', email);
    }, [email, setValue]);

    return (
        <form onSubmit={handleSubmit(handleSignup)} className="w-full px-3">
            <div className="w-full sm:w-[580px] mx-auto">
                <h2 className="text-2xl font-semibold mb-8 text-center">Create an account</h2>
                <div
                    className="flex flex-col gap-4 bg-white p-6 rounded-lg shadow-md w-full sm:w-full sm:p-16 sm:gap-8">
                    <div className="grid grid-cols-2 gap-4 lg:gap-6">
                        <div className="col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                                Email:
                            </label>
                            <input
                                id="email"
                                {...register('email', {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                })}
                                className="mt-1 p-2 w-full border rounded-md"
                            />
                            {errors.email && <p className="text-red-700 text-sm">Invalid email address.</p>}
                        </div>
                        <div className="col-span-1">
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-600">
                                First Name:
                            </label>
                            <input
                                id="firstName"
                                {...register('firstName', {required: true})}
                                className="mt-1 p-2 w-full border rounded-md"
                            />
                            {errors.firstName && (
                                <p className="text-red-700 text-sm">First name is required.</p>
                            )}
                        </div>
                        <div className="col-span-1">
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-600">
                                Last Name:
                            </label>
                            <input
                                id="lastName"
                                {...register('lastName', {required: true})}
                                className="mt-1 p-2 w-full border rounded-md"
                            />
                            {errors.lastName && <p className="text-red-700 text-sm">Last name is required.</p>}
                        </div>
                        <div className="col-span-2">
                            <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-600">
                                Mobile Number:
                            </label>
                            <PhoneNumberInput
                                onChange={(countryCode) => setValue('mobileCountryCode', countryCode)}
                                id="mobileNumber"
                                inputProps={register('mobileNumber', {required: true})}
                            />
                            {errors.mobileNumber && (
                                <p className="text-red-700 text-sm">Invalid mobile number.</p>
                            )}
                        </div>
                        <div className="col-span-1">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                                Password:
                            </label>
                            <input
                                id="password"
                                type="password"
                                {...register('password', {
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must be at least 8 characters long',
                                    },
                                })}
                                className="mt-1 p-2 w-full border rounded-md"
                            />
                            {errors.password && (
                                <p className="text-red-700 text-sm">{errors.password.message}</p>
                            )}
                        </div>
                        <div className="col-span-1">
                            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-600">
                                Confirm Password:
                            </label>
                            <input
                                id="confirmPassword"
                                type="password"
                                {...register('confirmPassword', {
                                    validate: (value) => {
                                        return value === password || 'The passwords do not match';
                                    },
                                })}
                                className="mt-1 p-2 w-full border rounded-md"
                            />
                            {errors.confirmPassword && (
                                <p className="text-red-700 text-sm">{errors.confirmPassword.message}</p>
                            )}
                        </div>
                        <div className="col-span-2">
                            <div className="flex items-center gap-2">
                                <TermsAndConditionsModal
                                    termsAccepted={termsAccepted}
                                    onTermsAcceptedChange={(value) => setValue('termsAccepted', value)}
                                />
                            </div>
                            {errors.termsAccepted && (
                                <p className="text-red-700 text-sm">You must agree to the Terms and Conditions.</p>
                            )}
                        </div>
                    </div>
                    <Button type="submit" variant="primary" loading={loading} className="mt-4">
                        Sign Up
                    </Button>

                    <div className="text-center">
                        <p className="text-sm">Already have an account? Click {
                            <Link to={LoginPath} className="text-blue-500">
                                here
                            </Link>
                        } to sign in!</p>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SignupUserForm;
