import React from 'react';
import {PricesApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {Price, PriceCreate} from '@/models/Price';
import PriceForm from '@/modules/supplier/components/PriceForm';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const PriceEdit: React.FC = () => {
    const {priceId} = useParams();

    const pricesApi = new PricesApi();

    const {
        isLoading,
        data: price,
    } = useQuery({
        queryKey: ['prices', priceId],
        queryFn: async () => {
            return priceId
                ? pricesApi.getPrice(priceId)
                : null;
        },
    });

    const handleOnSubmit = (product: PriceCreate | Price): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(product);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!price) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Price">
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Price
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <PriceForm
                    onSubmit={handleOnSubmit}
                    price={price}
                />
            </div>
        </div>
    );
};

export default PriceEdit;
