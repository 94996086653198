import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {Store, StoreCreate} from '@/models/Store';
import StoreForm from '@/modules/admin/components/StoreForm';

const StoreAdd = () => {
    const handleOnSubmit = (store: StoreCreate | Store): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(store);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Store"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <StoreForm
                    onSubmit={handleOnSubmit}
                />
            </div>
        </div>
    );
};

export default StoreAdd;
