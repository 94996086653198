import {useState} from 'react';
import CustomToast from '@/components/CustomToast';
import SignupAccountForm from '@/modules/auth/components/SignupAccountForm';
import SignupUserForm from '@/modules/auth/components/SignupUserForm';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {SignupAccount, SignupUser} from '@/modules/auth/models/Signup';
import {SignupStepPath} from '@/modules/auth/paths';
import AuthService from '@/modules/auth/services/AuthService';
import {HomePath} from '@/paths';
import toast from 'react-hot-toast';
import {useNavigate, useParams} from 'react-router-dom';

interface State {
    user?: SignupUser;
    account?: SignupAccount;
}

const Signup = () => {
    const {login} = useAuth();
    const {step: stepParam} = useParams<{ step: string }>();
    const navigate = useNavigate();

    const [state, setState] = useState<State>({});

    const signup = async (user: SignupUser, account: SignupAccount) => {
        const authService = new AuthService();

        try {
            await authService.signUp(user, account);
        } catch (error) {
            console.error('Error while signup:', error);

            toast.custom(t => (
                <CustomToast
                    id={t.id}
                    message={'Unable to complete the signup. Please try again later.'}
                    variant={'error'}
                    visible={t.visible}
                />
            ));
        }

        try {
            await login(user.username, user.password);

            navigate(HomePath);
        } catch (error) {
            console.error('Error while login after signup:', error);
        }
    };

    const handleSignupUserFormSubmit = async (data: SignupUser) => {
        setState(prevState => ({
            ...prevState,
            user: {...data},
        }));
        navigate(SignupStepPath.withStep('account'));
    };

    const handleSignupAccountSubmit = async (data: SignupAccount) => {
        setState(prevState => ({
            ...prevState,
            account: {...data},
        }));

        if (state.user) {
            await signup(state.user, data);
        }
    };

    const step = stepParam || 'user';

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-20 pb-32">
            {(step === 'user') && (
                <SignupUserForm
                    data={state.user}
                    onSubmit={handleSignupUserFormSubmit}
                />
            )}
            {(step === 'account') && (
                <SignupAccountForm
                    onSubmit={handleSignupAccountSubmit}
                />
            )}
        </div>
    );
};

export default Signup;
