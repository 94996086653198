import React, {forwardRef, useEffect, useState} from 'react';
import Button from '@/components/Button';
import ToggleSwitch from '@/components/ToggleSwitch';

export interface NotificationFormData {
    email: boolean;
    sms: boolean;
    orders: boolean;
    promotions: boolean;
}

interface NotificationFormProps {
    value?: NotificationFormData;
    onSubmit: (formData: NotificationFormData) => Promise<void>;
    loading?: boolean;
    onChangeLoading?: (loading: boolean) => void;
}

const NotificationForm = forwardRef<HTMLFormElement, NotificationFormProps>(function NotificationPreferencesForm({
    value,
    onSubmit,
    loading: loadingProp,
    onChangeLoading,
}, ref) {
    const [formData, setFormData] = useState<NotificationFormData>(value ?? {
        email: true,
        sms: false,
        orders: true,
        promotions: true,
    });

    const handleToggleChange = (
        name: keyof NotificationFormData,
        checked: boolean,
    ) => {
        setFormData((prevData) => ({...prevData, [name]: checked}));
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(loadingProp || false);
    }, [loadingProp]);

    useEffect(() => {
        if (onChangeLoading) {
            onChangeLoading(loading);
        }
    }, [loading, onChangeLoading]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        await onSubmit(formData);
        setLoading(false);
    };

    return (
        <form ref={ref} onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <div className="flex flex-col gap-4 lg:gap-6">
                        <div className="sm:flex items-center">
                            <ToggleSwitch
                                id="emailNotifications"
                                as="Fragment"
                                checked={formData.email}
                                onChange={(checked) => handleToggleChange('email', checked)}
                                label={<label htmlFor="emailNotifications" className="block font-medium text-gray-700 mb-1 sm:w-96">
                                    Email Notifications:
                                    <p className="text-sm text-gray-500">Receive emails for important updates.</p>
                                </label>}
                            />
                        </div>

                        <div className="sm:flex items-center">
                            <ToggleSwitch
                                id="smsNotifications"
                                as="Fragment"
                                checked={formData.sms}
                                onChange={(checked) => handleToggleChange('sms', checked)}
                                label={<label htmlFor="smsNotifications" className="block font-medium text-gray-700 mb-1 sm:w-96">
                                    SMS Notifications:
                                    <p className="text-sm text-gray-500">Get SMS alerts for urgent notifications.</p>
                                </label>}
                            />
                        </div>

                        <div className="sm:flex items-center">
                            <ToggleSwitch
                                id="orders"
                                as="Fragment"
                                checked={formData.orders}
                                onChange={(checked) => handleToggleChange('orders', checked)}
                                label={<label htmlFor="orders" className="block font-medium text-gray-700 mb-1 sm:w-96">
                                    Order Updates:
                                    <p className="text-sm text-gray-500">Receive updates on your order status.</p>
                                </label>}
                            />
                        </div>

                        <div className="sm:flex items-center">
                            <ToggleSwitch
                                id="promotions"
                                as="Fragment"
                                checked={formData.promotions}
                                onChange={(checked) => handleToggleChange('promotions', checked)}
                                label={<label htmlFor="promotions" className="block font-medium text-gray-700 mb-1 sm:w-96">
                                    Promotions:
                                    <p className="text-sm text-gray-500">Get notified about exclusive promotions and offers.</p>
                                </label>}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <Button
                    type="submit"
                    variant="primary"
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
});

export default NotificationForm;
