import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import {Sku} from '@/models/Sku';
import ProductListProductTable from '@/modules/supplier/components/ProductListProductTable';
import {ProductListProductAddPath} from '@/modules/supplier/paths';
import {PlusIcon} from '@heroicons/react/24/solid';
import {useParams} from 'react-router-dom';

const ProductListProductList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const {productListId} = useParams();

    if (!productListId) {
        return;
    }

    const handleDeleteProduct = (sku: Sku) => {
        console.log(sku);
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Products">
                <Button
                    to={ProductListProductAddPath.withProductListId(productListId)}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Product
                </Button>
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by SKU"
            />

            <ProductListProductTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                productListId={productListId}
                onDelete={handleDeleteProduct}
            />
        </div>
    );
};

export default ProductListProductList;
