import React, {ReactElement, ReactNode, useEffect, useState} from 'react';
import ConfirmDialog from '@/components/ConfirmDialog';
import {handleWithLoading} from '@/utils/loading';
import {useNavigate} from 'react-router-dom';

interface DialogProps {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    cancelText: string;
    confirmText: string;
}

type ConfirmProps = {
    onConfirm?: (() => Promise<void>) | (() => void);
    to?: string;
    onCancel?: () => void;
    title?: string;
    message: string;
    condition?: boolean;
    dialog?: ReactElement<DialogProps>;
    children: ((handleClick: () => void, loading: boolean) => ReactNode) | ReactNode;
    cancelText?: string;
    confirmText?: string;
};

const Confirm: React.FC<ConfirmProps> = ({
    onConfirm,
    to,
    onCancel,
    title = 'Confirm',
    message,
    condition = false,
    dialog,
    children,
    cancelText = 'No',
    confirmText = 'Yes',
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (condition) {
            setShowDialog(true);
        }
    }, [condition]);

    const handleClick = () => {
        setShowDialog(true);
    };

    const navigate = useNavigate();

    const handleConfirm = async () => {
        setShowDialog(false);
        if (to !== undefined) {
            navigate(to);
            return;
        }
        if (onConfirm) {
            await handleWithLoading(
                onConfirm,
                setLoading,
            );
        }
    };

    const handleCancel = () => {
        onCancel?.();
        setShowDialog(false);
    };

    return (
        <>
            {typeof children === 'function' ? (
                children(handleClick, loading)
            ) : (
                children
            )}
            {dialog ? (
                <>
                    {React.cloneElement(dialog, {
                        open: showDialog,
                        message: message,
                        onConfirm: handleConfirm,
                        onCancel: handleCancel,
                        cancelText: cancelText,
                        confirmText: confirmText,
                    })}
                </>
            ) : (
                <ConfirmDialog
                    open={showDialog}
                    title={title}
                    message={message}
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    cancelText={cancelText}
                    confirmText={confirmText}
                />
            )}
        </>
    );
};

export default Confirm;
