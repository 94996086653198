import {Configuration, SkusApi, StoresApi, SuppliersApi} from '@/api';
import {useSettings} from '@/contexts/SettingsContext';
import {Sku, SkuAssociation} from '@/models/Sku';
import {Store} from '@/models/Store';
import {Supplier} from '@/models/Supplier';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery} from '@tanstack/react-query';

interface CartQueryResult {
    isLoading: boolean;
    skus: Sku[];
    suppliers: Supplier[];
    store?: Store | null;
}

const initialData = {
    skus: [],
    suppliers: [],
    store: null,
};

const useCartQuery = (addedSkuIds?: string[], currentSkuIds?: string[], storeId?: string): CartQueryResult => {
    const {currencyCode, languageCode} = useSettings();
    const skusApi = new SkusApi(new Configuration(useAuth), {currencyCode, languageCode});
    const suppliersApi = new SuppliersApi(new Configuration(useAuth));
    const storesApi = new StoresApi(new Configuration(useAuth));

    const {
        isLoading,
        data = initialData,
    } = useQuery({
        queryKey: ['cart', addedSkuIds, storeId, currencyCode, languageCode],
        queryFn: async () => {
            if (addedSkuIds === undefined || currentSkuIds === undefined) {
                return initialData;
            }

            const skuPromise = skusApi.getSkus(
                currentSkuIds,
                [
                    SkuAssociation.PRODUCT,
                    SkuAssociation.PRICES,
                    SkuAssociation.PROMOTIONS,
                ],
            );

            const suppliersPromise = suppliersApi.listSuppliers();

            const storePromise: Promise<Store | null | undefined> = storeId
                ? storesApi.getStore(storeId)
                : Promise.resolve(null);

            const [skus, suppliersResponse, store] = await Promise.all([
                skuPromise,
                suppliersPromise,
                storePromise,
            ]);

            const suppliers = suppliersResponse.items;

            return {
                skus,
                suppliers,
                store,
            };
        },
    });

    return {
        isLoading,
        ...data,
    };
};

export default useCartQuery;
