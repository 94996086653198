import {useEffect, useState} from 'react';
import data from '@/assets/lottie/cart.json';
import Lottie from 'lottie-light-react';

interface CartLottieIconProps {
    delay?: number;
}

const CartLottieIcon = ({delay = 2000}: CartLottieIconProps) => {
    const [play, setPlay] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPlay(true);
        }, delay);

        return () => clearTimeout(timeout);
    }, [play, delay]);

    const handleLoopComplete = () => {
        setPlay(false);
    };

    return (
        <Lottie
            animationData={data}
            autoplay={play}
            loop={play}
            onLoopComplete={handleLoopComplete}
        />
    );
};

export default CartLottieIcon;
