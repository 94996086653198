const isAsyncFunction = <T, P extends unknown[]>(
    handler: ((...args: P) => Promise<T>) | ((...args: P) => T),
): handler is (...args: P) => Promise<T> => {
    return handler.constructor.name === 'AsyncFunction';
};

export const withLoading = <P extends unknown[]>(
    func: ((...args: P) => Promise<void>) | ((...args: P) => void),
    setLoading?: (value: boolean) => void,
): (...args: P) => Promise<void> => {
    return async (...args: P) => {
        try {
            if (setLoading) {
                setLoading(true);
            }

            if (isAsyncFunction(func)) {
                await func(...args);
            } else {
                func(...args);
            }
        } finally {
            if (setLoading) {
                setLoading(false);
            }
        }
    };
};

export const handleWithLoading = async <T, P extends unknown[]>(
    func: ((...args: P) => Promise<T>) | ((...args: P) => T),
    setLoading?: (value: boolean) => void,
    ...args: P
): Promise<void> => {
    const wrappedFunc = withLoading(func, setLoading);
    await wrappedFunc(...args);
};
