import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {LoginPath} from '@/modules/auth/paths';
import {Navigate, Outlet} from 'react-router-dom';

const PrivateRoute = () => {
    const {isLoggedIn} = useAuth();
    if (!isLoggedIn) return <Navigate to={LoginPath}/>;
    return <Outlet/>;
};

export default PrivateRoute;
