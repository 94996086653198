import {Configuration, StoresApi} from '@/api';
import CustomToast from '@/components/CustomToast';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {findStoreAddress, Store, StoreAddress, StoreAddressType} from '@/models/Store';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import StoreAddressForm from '@/modules/retailer/components/StoreAddressForm';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {BuildingOfficeIcon, BuildingStorefrontIcon} from '@heroicons/react/24/outline';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import toast from 'react-hot-toast';

interface AddressProps {
    addressType?: StoreAddressType;
}

const Address = ({addressType}: AddressProps) => {
    const queryClient = useQueryClient();

    const {currentUser} = useAuth();

    const {storeId} = currentUser() || {};

    const storesApi = new StoresApi(new Configuration(useAuth));

    const queryKey = ['store', storeId];

    const invalidateQuery = async () => {
        return queryClient.invalidateQueries({queryKey});
    };

    const {
        isLoading,
        data: store,
    } = useQuery({
        queryKey,
        queryFn: async () => {
            return storeId ? await storesApi.getStore(storeId) : null;
        },
    });

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!store) {
        return;
    }

    const handleSubmitStoreAddress = async (store: Store, address: StoreAddress) => {
        const messages = {
            [StoreAddressType.BUSINESS]: 'Business address updated successfully!',
            [StoreAddressType.DELIVERY]: 'Delivery address updated successfully!',
            [StoreAddressType.BILLING]: 'Billing address updated successfully!',
            [StoreAddressType.UNKNOWN]: 'Address updated successfully!',
        };

        try {
            const updatedAddresses = store.addresses.map((addr) =>
                addr.id === address.id ? {...addr, ...address} : addr,
            );

            await storesApi.updateStore(store.id, {
                ...store,
                addresses: updatedAddresses,
            });

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={messages[address.type] || 'Address updated successfully!'}
                    visible={t.visible}
                />
            ));

            await invalidateQuery();
        } catch (error) {
            console.error('Error while updating store address:', error);
        }
    };

    const businessAddress = findStoreAddress(store.addresses, [StoreAddressType.BUSINESS]);
    const deliveryAddress = findStoreAddress(store.addresses, [StoreAddressType.DELIVERY]);

    return (
        <>
            {(!addressType || addressType === StoreAddressType.BUSINESS) && (
                <>
                    <SettingsHeader
                        title="Business Address"
                        icon={<BuildingOfficeIcon className="w-6 h-6"/>}
                    />

                    <StoreAddressForm
                        address={businessAddress}
                        onSubmit={(value) => handleSubmitStoreAddress(store, value)}
                        storeId={store.id}
                        storeAddressType={StoreAddressType.BUSINESS}
                    />
                </>
            )}

            {(!addressType || addressType === StoreAddressType.DELIVERY) && (
                <>
                    <SettingsHeader
                        title="Delivery Address"
                        icon={<BuildingStorefrontIcon className="w-6 h-6"/>}
                    />

                    <StoreAddressForm
                        address={deliveryAddress}
                        onSubmit={(value) => handleSubmitStoreAddress(store, value)}
                        storeId={store.id}
                        storeAddressType={StoreAddressType.DELIVERY}
                    />
                </>
            )}
        </>
    );
};

export default Address;
