import React, {ReactNode, useState} from 'react';
import SettingsContext, {Settings, SettingsContextProps} from '@/contexts/SettingsContext';

interface SettingsProviderProps {
    initialSettings?: Settings;
    children: ReactNode;
}

const defaultSettings = {
    currencyCode: 'USD',
    timezone: 'UTC',
    languageCode: 'en',
    locale: 'en-SG',
};

const SettingsProvider: React.FC<SettingsProviderProps> = ({children, initialSettings}) => {
    const [settings, setSettings] = useState<Settings>(initialSettings || defaultSettings || {} as Settings);

    const updateSettings = (updatedSettings: Partial<Settings>) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            ...updatedSettings,
        }));
    };

    const contextValue: SettingsContextProps = {
        updateSettings,
        ...settings,
    };

    return (
        <SettingsContext.Provider value={contextValue}>
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsProvider;
