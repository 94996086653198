import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {SkuCreate, SkuUpdate} from '@/models/Sku';
import ProductVariantForm from '@/modules/supplier/components/ProductVariantForm';
import {useParams} from 'react-router-dom';

const ProductVariantAdd: React.FC = () => {
    const {productId} = useParams();

    const handleOnSubmit = (sku: SkuCreate | SkuUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(sku);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Product Variant"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <ProductVariantForm
                    onSubmit={handleOnSubmit}
                    supplierId="1"
                    productId={productId}
                />
            </div>
        </div>
    );
};

export default ProductVariantAdd;
