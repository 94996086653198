import {FormEvent, useState} from 'react';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {SignupPath} from '@/modules/auth/paths';
import {HomePath} from '@/paths';
import {Link, useNavigate} from 'react-router-dom';

const Login = () => {
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('demo@example.com');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState<string | null>(null); // Error state for displaying error messages
    const navigate = useNavigate();

    const handleLogin = async () => {
        if (!username || !password) {
            setError('Please enter both username and password.');
            return;
        }

        setIsLoading(true);
        const isLoggedIn = await auth.login(username, password);
        setIsLoading(false);

        if (!isLoggedIn) {
            setError('Incorrect username or password. Please try again.');
            return;
        }

        navigate(HomePath);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        void handleLogin();
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <form onSubmit={handleSubmit} className="w-full px-3">
                <div className="w-full sm:w-[580px] mb-24 mx-auto">
                    <h2 className="text-2xl font-semibold mb-8 text-center">Sign in to your account</h2>
                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
                            {error}
                        </div>
                    )}
                    <div className="flex flex-col gap-4 bg-white p-6 rounded-lg shadow-md w-full sm:w-full sm:p-16 sm:gap-8">
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium text-gray-600">
                                Username
                            </label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                className="mt-1 p-2 w-full border rounded-md"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                className="mt-1 p-2 w-full border rounded-md"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                            />
                        </div>
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="rememberMe"
                                    name="rememberMe"
                                    className="mr-2"
                                    checked={rememberMe}
                                    onChange={() => setRememberMe(!rememberMe)}
                                />
                                <label htmlFor="rememberMe" className="text-sm text-gray-600">
                                    Remember me
                                </label>
                            </div>
                            <div className="text-sm text-blue-500">
                                <a href="#">Forgot your password?</a>
                            </div>
                        </div>
                        <Button
                            type="submit"
                            width="full"
                            rounded="full"
                            size="lg"
                            loading={isLoading}
                            loadingIcon={<SpinnerIcon className="w-5 h-5 fill-blue-800"/>}
                        >
                            Sign In
                        </Button>

                        <div className="text-center">
                            <p className="text-sm">New here? <Link to={SignupPath} className="text-blue-500">Create an account</Link> to get started!</p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login;
