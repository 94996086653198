import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {CalendarCreate, CalendarUpdate} from '@/models/Calendar';
import CalendarForm from '@/modules/supplier/components/CalendarForm';

const CalendarAdd: React.FC = () => {
    const handleOnSubmit = (calendar: CalendarCreate | CalendarUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(calendar);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Calendar"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <CalendarForm
                    onSubmit={handleOnSubmit}
                />
            </div>
        </div>
    );
};

export default CalendarAdd;
