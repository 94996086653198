import {format} from 'date-fns';

export interface TimeWindow {
    start: Date;
    end: Date;
}

export const formatTimeWindow = (
    timeWindow?: TimeWindow,
    formatStart?: string,
    formatEnd?: string,
): string => {
    if (!timeWindow) return '';

    formatStart = formatStart ? formatStart : 'EEEE d-MMM-yyyy HH:mm';
    formatEnd = formatEnd ? formatEnd : 'HH:mm';

    return `${format(timeWindow.start, formatStart)} - ${format(timeWindow.end, formatEnd)}`;
};
