import React from 'react';
import SelectBox, {Item, MultiSelectProps, OtherSelectBoxProps, SingleSelectProps} from '@/components/SelectBox';
import {languages as supportedLanguages} from '@/config';
import {Language} from '@/models/Language';

type LanguageSelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        languages?: Language[];
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        languages?: Language[];
    }
);

export interface LanguageItem {
    id: string;
    name: string;
    flag: string;
}

const LanguageSelectBox: React.FC<LanguageSelectBoxProps<LanguageItem>> = (props) => {
    const {
        label = 'Language:',
        placeholder = 'Select language',
        languages = supportedLanguages,
    } = props;

    const listLanguages = (): Promise<LanguageItem[]> => {
        return new Promise((resolve) => {
            const items: LanguageItem[] = languages.map(language => ({
                id: language.code,
                name: language.name,
                flag: language.flag,
            }));

            resolve(items);
        });
    };

    return (
        <SelectBox<LanguageItem>
            {...props}
            name="language"
            label={label}
            placeholder={placeholder}
            queryFn={listLanguages}
            itemRender={(item: LanguageItem, selected?: boolean) => (
                <div className="flex items-center">
                    <img src={item.flag} alt={item.name} className="w-6 h-4 mr-2"/>
                    <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>{item.name}</span>
                </div>
            )}
        />
    );
};

export default LanguageSelectBox;
