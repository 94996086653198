import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import ProductListTable from '@/modules/supplier/components/ProductListTable';
import {ProductListAddPath} from '@/modules/supplier/paths';
import {PlusIcon} from '@heroicons/react/24/solid';

const ProductListList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Product Lists">
                <Button
                    to={ProductListAddPath}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Product List
                </Button>
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by name"
            />

            <ProductListTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
            />
        </div>
    );
};

export default ProductListList;
