import {ProductListsApi} from '@/api';
import SelectBox, {
    Item,
    MultiSelectProps,
    OtherSelectBoxProps,
    SingleSelectProps,
} from '@/components/SelectBox';
import {ProductList} from '@/models/ProductList';

type ProductListSelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
    }
);

const ProductListSelectBox = (props: ProductListSelectBoxProps<ProductList>) => {
    const productCategoriesApi = new ProductListsApi();

    const listProductLists = async (): Promise<ProductList[]> => {
        const response = await productCategoriesApi.listProductLists();
        return response.items;
    };

    const {
        label = 'Product List:',
        placeholder= 'Select product list',
    } = props;

    return (
        <SelectBox<ProductList>
            {...props}
            name="productList"
            label={label}
            placeholder={placeholder}
            queryFn={listProductLists}
            queryKey={['productList']}
        />
    );
};

export default ProductListSelectBox;
