import React, {Fragment, ReactElement, ReactNode, useEffect, useRef, useState} from 'react';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import useSelectBox, {MultiSelectProps, SingleSelectProps} from '@/hooks/useSelectBox';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Label,
    Transition,
} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';

export type {SingleSelectProps, MultiSelectProps} from '@/hooks/useSelectBox';

export interface Item {
    id: string;
    name: string;
}

export interface OtherComboBoxSelectProps<T extends Item> {
    name?: string;
    label?: string | ReactElement;
    hideLabel?: true;
    placeholder?: string;
    queryKey?: string[];
    queryFn?: (query?: string) => Promise<T[]>;
    allowEmptySelection?: true;
    requireSelection?: true;
    disabled?: boolean;
    delay?: number;
}

export type ComboBoxProps<T extends Item> =
    | (SingleSelectProps<T> & OtherComboBoxSelectProps<T> & { multiple?: false })
    | (MultiSelectProps<T> & OtherComboBoxSelectProps<T> & { multiple: true });

const ComboBox = <T extends Item>({
    name,
    value,
    label,
    hideLabel,
    queryKey = name ? [name] : [],
    queryFn,
    onSelect,
    allowEmptySelection = true,
    requireSelection,
    disabled = false,
    delay = 500,
    multiple,
}: ComboBoxProps<T>): ReactNode => {
    const [query, setQuery] = useState('');

    const {
        isLoading,
        data,
    } = useQuery({
        queryKey: [...queryKey, query],
        queryFn: () => queryFn?.(query),
    });

    const props = multiple
        ? {value, multiple, onSelect}
        : {value, multiple, onSelect};

    const {
        handleChange,
        handleDeselect,
        state,
    } = useSelectBox<T>(data, props);

    const {
        selected: selectedItems,
        isInitialized,
    } = state;

    const selected = selectedItems ?? {} as Item;

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (query === '' && typeof value === 'string' && !isInitialized) {
            setQuery(value);
            return;
        }

        const current = inputRef.current;

        let timeoutId: null | ReturnType<typeof setTimeout> = null;

        const handleInputChange = () => {
            const inputValue = inputRef.current?.value;
            if (
                isInitialized &&
                inputValue !== undefined &&
                inputValue.length >= 3
            ) {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }

                timeoutId = setTimeout(() => {
                    setQuery(inputValue);
                }, delay);
            }
        };

        current?.addEventListener('input', handleInputChange);

        return () => {
            current?.removeEventListener('input', handleInputChange);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [query, value, delay, isInitialized]);

    const handleAfterLeave = () => {
        // setQuery('');
    };

    const items = data ? data : [];

    return (
        <Combobox
            value={selected}
            onChange={(value) => handleChange(value)}
            disabled={disabled}
            multiple={multiple || false}
        >
            {!hideLabel && (
                <Label className={label && React.isValidElement(label) ? '' : 'block mb-1'}>
                    {label}
                </Label>
            )}
            <div className="relative">
                <div
                    className="relative w-full cursor-default border rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 flex items-center">
                    <ComboboxInput
                        ref={inputRef}
                        className="w-full border-none py-0.5 pl-1 pr-10 leading-5 text-gray-900 focus:ring-0 focus:outline-none"
                        displayValue={(item: Item) => {
                            return item ? item.name : '';
                        }}
                        placeholder={isLoading ? 'Loading ...' : ''}
                    />
                    <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </ComboboxButton>
                    {isLoading && <SpinnerIcon className="w-5 h-5 absolute right-10"/>}
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => handleAfterLeave()}
                >
                    <ComboboxOptions
                        className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {items.length === 0 && query !== '' ? (
                            <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                                {isLoading
                                    ? 'Loading ...'
                                    : 'Nothing found'}
                            </div>
                        ) : (
                            <>
                                {allowEmptySelection && !requireSelection ? (
                                    <ComboboxOption
                                        key=''
                                        value={null}
                                        onClick={() => handleDeselect()}
                                        className={({active}) => `${active ? 'text-white bg-blue-600' : 'text-gray-900'} cursor-pointer select-none relative py-4`}
                                    >
                                        {({active, selected}) => (
                                            <>
                                                {selected ? (
                                                    <span className={`${active ? 'text-white' : 'text-gray-900'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </ComboboxOption>
                                ) : ''}
                                {items.map((item) => (
                                    <ComboboxOption
                                        key={item.id}
                                        className={({focus}) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                focus ? 'bg-blue-600 text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={item}
                                    >
                                        {({selected, focus}) => (
                                            <>
                                                <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>
                                                    {item.name}
                                                </span>

                                                {selected ? (
                                                    <span className={`${focus ? 'text-white' : 'text-gray-900'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </ComboboxOption>
                                ))}
                            </>
                        )}
                    </ComboboxOptions>
                </Transition>
            </div>
        </Combobox>
    );
};

export default ComboBox;
