import React, {ChangeEvent, useEffect, useState} from 'react';
import {MagnifyingGlassIcon, XMarkIcon} from '@heroicons/react/24/outline';

interface SearchBoxProp {
    value?: string | null;
    placeholder?: string;
    onChange?: (value: string) => void;
}

const SearchBox: React.FC<SearchBoxProp> = ({value, placeholder, onChange}) => {
    const [inputValue, setInputValue] = useState(value || '');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        onChange && onChange(event.target.value);
    };

    const clearInput = () => {
        setInputValue('');
        onChange && onChange('');
    };

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    return (
        <>
            <label htmlFor="search" className="block mb-1">Search:</label>
            <div className="relative">
                <input
                    type="text"
                    id="search"
                    value={inputValue}
                    onChange={handleChange}
                    placeholder={placeholder}
                    className="w-full border shadow rounded-md py-2 pl-10 pr-10 focus:outline-none focus:border-blue-500"
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-500"/>
                </div>
                {inputValue && (
                    <button
                        type="button"
                        className="absolute inset-y-0 right-0 pr-3 flex items-center focus:outline-none"
                        onClick={clearInput}
                    >
                        <XMarkIcon className="h-5 w-5 text-gray-500"/>
                    </button>
                )}
            </div>
        </>
    );
};

export default SearchBox;
