import {useState} from 'react';
import Button from '@/components/Button';
import {withMinimumDelay} from '@/utils/delay';
import {withLoading} from '@/utils/loading';
import {useForm} from 'react-hook-form';

export interface ChangePassword {
    oldPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
}

interface ChangePasswordFormProps {
    onSubmit: (formData: ChangePassword) => Promise<void>;
}

const ChangePasswordForm = ({onSubmit}: ChangePasswordFormProps) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm<ChangePassword>();

    const newPassword = watch('newPassword');

    const [loading, setLoading] = useState(false);

    const handleSubmitForm = withLoading(withMinimumDelay(onSubmit, 500), setLoading);

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="flex flex-col gap-4 lg:gap-6">
                <div>
                    <label htmlFor="oldPassword" className="block font-medium text-gray-700">
                        Old Password:
                    </label>
                    <input
                        type="password"
                        id="oldPassword"
                        {...register('oldPassword', {required: 'Old password is required'})}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.oldPassword && (
                        <p className="text-red-600 text-sm">{errors.oldPassword.message}</p>
                    )}
                </div>
                <div>
                    <label htmlFor="newPassword" className="block font-medium text-gray-700">
                        New Password:
                    </label>
                    <input
                        type="password"
                        id="newPassword"
                        {...register('newPassword', {required: 'New password is required'})}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.newPassword && (
                        <p className="text-red-600 text-sm">{errors.newPassword.message}</p>
                    )}
                </div>
                <div>
                    <label htmlFor="confirmPassword" className="block font-medium text-gray-700">
                        Confirm Password:
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        {...register('confirmPassword', {
                            required: 'Please confirm your password',
                            validate: (value) => value === newPassword || 'Passwords do not match',
                        })}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                    {errors.confirmPassword && (
                        <p className="text-red-600 text-sm">{errors.confirmPassword.message}</p>
                    )}
                </div>
            </div>
            <div className="mt-8 flex justify-end">
                <Button
                    type="submit"
                    variant="primary"
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default ChangePasswordForm;
