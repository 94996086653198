import React, {useState} from 'react';
import Button from '@/components/Button';
import PriceInput from '@/components/PriceInput';
import {Price, PriceCreate} from '@/models/Price';
import {PriceList} from '@/models/PriceList';
import {CheckIcon} from '@heroicons/react/24/outline';

interface PriceFormProps {
    onSubmit: (price: PriceCreate | Price) => void;
    price?: Price;
    priceList?: PriceList | null;
}

const PriceForm = ({onSubmit, price, priceList}: PriceFormProps) => {
    const [formData, setFormData] = useState<PriceCreate | Price>(
        price ? {...price} : {
            supplierId: '',
            skuId: '',
            price: priceList
                ? {currencyCode: priceList.currencyCode, amount: 0}
                : {currencyCode: '', amount: 0},
            quantityFrom: undefined,
            quantityTo: undefined,
        },
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSkuIdChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    const handlePriceChange = (amount: number) => {
        setFormData(prevData => ({
            ...prevData,
            price: {
                currencyCode: prevData.price.currencyCode,
                amount: amount,
            },
        }));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4 lg:gap-8 mb-12">
                <div className="col-span-2">
                    <label htmlFor="skuId" className="block font-medium text-gray-700 mb-1">
                        SKU ID:
                    </label>
                    <input
                        type="text"
                        id="skuId"
                        name="skuId"
                        value={formData.skuId}
                        onChange={handleSkuIdChange}
                        className="p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="price" className="block font-medium text-gray-700 mb-1">
                        Price:
                    </label>
                    <PriceInput
                        id="price"
                        value={formData.price.amount}
                        currencyCode={formData.price.currencyCode}
                        onChange={handlePriceChange}
                    />
                </div>
                <div className="col-span-1">
                    <label htmlFor="quantityFrom" className="block font-medium text-gray-700">
                        Quantity From:
                    </label>
                    <input
                        type="number"
                        id="quantityFrom"
                        name="quantityFrom"
                        value={formData.quantityFrom || ''}
                        onChange={handleChange}
                        className="input-no-spinner mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-1">
                    <label htmlFor="quantityTo" className="block font-medium text-gray-700">
                        Quantity To:
                    </label>
                    <input
                        type="number"
                        id="quantityTo"
                        name="quantityTo"
                        value={formData.quantityTo || ''}
                        onChange={handleChange}
                        className="input-no-spinner mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    type="submit"
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default PriceForm;
