import SelectBox, {SelectBoxProps} from '@/components/SelectBox';
import {CalendarEventType, CalendarEventTypeTitles} from '@/models/CalendarEvent';

export interface CalendarEventTypeItem {
    id: string;
    name: string;
    type: CalendarEventType;
}

const CalendarEventTypeSelectBox = (props: SelectBoxProps<CalendarEventTypeItem>) => {
    const {
        label = 'Type:',
        placeholder= 'Select type',
    } = props;

    const listCalendarEventTypes = (): Promise<CalendarEventTypeItem[]> => {
        return new Promise((resolve) => {
            const types = Object
                .entries(CalendarEventTypeTitles)
                .map(([eventType, title]) => ({eventType, title}));

            const items = types
                .map(({eventType, title}) => ({
                    id: eventType,
                    name: title,
                    type: eventType as CalendarEventType,
                }))
                .filter(item => item.name.trim() !== '');

            resolve(items);
        });
    };

    return (
        <SelectBox<CalendarEventTypeItem>
            {...props}
            name="calendarEventTypes"
            label={label}
            placeholder={placeholder}
            queryFn={listCalendarEventTypes}
        />
    );
};

export default CalendarEventTypeSelectBox;
