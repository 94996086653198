import {AnimationEventHandler, useRef} from 'react';
import data from '@/assets/lottie/check.json';
import Lottie, {LottieRefCurrentProps} from 'lottie-light-react';

interface CartLottieIconProps {
    delay?: number;
}

const CartLottieIcon = ({delay = 5000}: CartLottieIconProps) => {
    const lottieRef = useRef<LottieRefCurrentProps>(null);

    const handleEnterFrame: AnimationEventHandler = (e) => {
        if ('currentTime' in e) {
            const currentTime = e.currentTime;
            if (typeof currentTime === 'number' && currentTime > 90) {
                lottieRef.current?.pause();
                setTimeout(() => lottieRef.current?.goToAndPlay(0), delay);
            }
        }
    };

    return (
        <Lottie
            lottieRef={lottieRef}
            animationData={data}
            autoplay={true}
            loop={true}
            onEnterFrame={handleEnterFrame}
        />
    );
};

export default CartLottieIcon;
