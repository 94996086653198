import React, {useState} from 'react';
import Button from '@/components/Button';
import SearchBox from '@/components/SearchBox';
import {useNavigate} from 'react-router-dom';

interface SearchFormProps {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    onChange: (value: string) => void;
    value?: string;
    placeholder?: string;
    className?: string;
    as?: 'div' | 'Fragment';
}

const SearchForm: React.FC<SearchFormProps> = ({
    onSubmit,
    onChange,
    value = '',
    placeholder,
    className,
    as = 'div',
}) => {
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState(value || '');

    const Component = as === 'Fragment' ? React.Fragment : as;
    const props = as !== 'Fragment'
        ? {className: className ? className : 'mb-10'}
        : {};

    const handleClear = () => {
        setSearchQuery('');
        onChange(value);
        navigate('.', {replace: true});
    };

    const handleSearchChange = (value: string) => {
        setSearchQuery(value);
        onChange(value);
    };

    return (
        <Component {...props}>
            <form onSubmit={onSubmit}>
                <div className="flex flex-row items-end gap-3">
                    <div className="w-96">
                        <SearchBox
                            value={searchQuery}
                            placeholder={placeholder}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className="flex flex-row items-end gap-3">
                        <Button type="submit">Search</Button>
                        <Button variant="secondary" onClick={handleClear}>Clear</Button>
                    </div>
                </div>
            </form>
        </Component>
    );
};

export default SearchForm;
