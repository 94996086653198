import {formatDate} from 'date-fns/format';
import {useEffect, useState} from 'react';
import {Configuration, SkusApi} from '@/api';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import ToggleSwitch from '@/components/ToggleSwitch';
import {SearchProps} from '@/hooks/useSearch';
import {EmptyResult} from '@/models/Pagination';
import {Sku, Skus} from '@/models/Sku';
import {Status} from '@/models/Status';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {ProductVariantEditPath} from '@/modules/supplier/paths';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

interface InventoryListTableProps extends SearchProps {
    onChangeStatus: (status: Status, skuId: string) => void;
}

const initialState = EmptyResult<Sku>();

const InventoryListTable = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    onChangeStatus,
}: InventoryListTableProps) => {
    const [skus, setSkus] = useState<Skus>(initialState);

    const skusApi = new SkusApi(new Configuration(useAuth));

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['inventory', page, searchQuery],
        queryFn: async () => {
            const limit = 10;
            return skusApi.findSkus(
                searchQuery,
                undefined,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setSkus((prevState): Skus => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    const handleStatusChange = (value: boolean, sku: Sku): void => {
        const newStatus = value ? 'ACTIVE' : 'INACTIVE';
        setSkus((prevState): Skus => {
            const updatedItems: Sku[] = prevState.items.map(item => {
                if (item.id === sku.id) {
                    return {...item, status: newStatus};
                }
                return item;
            });
            return {...prevState, items: updatedItems};
        });
        onChangeStatus(newStatus, sku.id);
    };

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {skus.items.map((sku, index) => {
                        if (sku.id === undefined) {
                            return;
                        }

                        return (
                            <tr key={index}>
                                <td>{sku.id}</td>
                                <td>
                                    <Link to={ProductVariantEditPath.withId(sku.id)}
                                          className="text-blue-500 hover:underline">
                                        {sku.name}
                                    </Link>
                                </td>
                                <td>{sku.createdAt && formatDate(sku.createdAt, 'd MMM yyyy')}</td>
                                <td>
                                    <ToggleSwitch
                                        checked={sku.status === 'ACTIVE'}
                                        onChange={(value) => handleStatusChange(value, sku)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={skus.totalCount || 0}
                    estimatedTotalCount={skus.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default InventoryListTable;
