import {formatDate} from 'date-fns/format';
import React, {useEffect, useState} from 'react';
import {CalendarsApi} from '@/api';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import {SearchProps} from '@/hooks/useSearch';
import {Calendar, Calendars} from '@/models/Calendar';
import {EmptyResult} from '@/models/Pagination';
import {SupplierCalendarEditPath, SupplierCalendarEventListPath} from '@/modules/admin/paths';
import {CalendarEditPath, CalendarEventListPath} from '@/modules/supplier/paths';
import {getCountryName} from '@/utils/getCountryName';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

interface CalendarTableProps extends SearchProps {
    supplierId?: string;
}

const initialState = EmptyResult<Calendar>();

const CalendarTable: React.FC<CalendarTableProps> = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    supplierId,
}) => {
    const [calendars, setCalendars] = useState<Calendars>(initialState);

    const calendarsApi = new CalendarsApi();

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['calendars', page, searchQuery, supplierId],
        queryFn: async () => {
            const limit = 10;
            return calendarsApi.findCalendars(
                searchQuery,
                supplierId,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setCalendars((prevState): Calendars => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Country</th>
                        <th>Created At</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {calendars.items.map((calendar, index) => {
                        if (calendar.id === undefined) {
                            return;
                        }

                        const editPath = supplierId
                            ? SupplierCalendarEditPath.withParams(supplierId, calendar.id)
                            : CalendarEditPath.withId(calendar.id);

                        const eventListPath = supplierId
                            ? SupplierCalendarEventListPath.withParams(supplierId, calendar.id)
                            : CalendarEventListPath.withCalendarId(calendar.id);

                        return (
                            <tr key={index}>
                                <td>{calendar.id}</td>
                                <td>
                                    <Link to={editPath}
                                          className="text-blue-500 hover:underline">
                                        {calendar.name}
                                    </Link>
                                </td>
                                <td>{calendar.countryCode ? getCountryName(calendar.countryCode) : '-'}</td>
                                <td>{calendar.createdAt && formatDate(calendar.createdAt, 'd MMM yyyy')}</td>
                                <td>
                                    <Button
                                        to={eventListPath}
                                        variant="secondary"
                                        size="xs"
                                    >
                                        View Events
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={calendars.totalCount || 0}
                    estimatedTotalCount={calendars.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default CalendarTable;
