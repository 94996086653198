import React from 'react';
import SelectBox, {Item, MultiSelectProps, OtherSelectBoxProps, SingleSelectProps} from '@/components/SelectBox';
import {countryCodes as defaultCountryCodes} from '@/config';
import {getCountryName} from '@/utils/getCountryName';

type CountrySelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        countryCodes?: string[];
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        countryCodes?: string[];
    }
);

export interface CountryItem {
    id: string;
    name: string;
}

const CountrySelectBox: React.FC<CountrySelectBoxProps<CountryItem>> = (props) => {
    const {
        label = 'Country:',
        placeholder= 'Select country',
        countryCodes = defaultCountryCodes,
    } = props;

    const listCountries = (): Promise<CountryItem[]> => {
        return new Promise((resolve) => {
            const items: CountryItem[] = countryCodes.map(countryCode => {
                return {
                    id: countryCode,
                    name: getCountryName(countryCode) || countryCode,
                };
            });

            resolve(items);
        });
    };

    return (
        <SelectBox<CountryItem>
            {...props}
            name="country"
            label={label}
            placeholder={placeholder}
            queryFn={listCountries}
        />
    );
};

export default CountrySelectBox;
