import {useEffect, useState} from 'react';
import {Configuration, SkusApi} from '@/api';
import {SkuFilters} from '@/api/SkusApi';
import {useSettings} from '@/contexts/SettingsContext';
import {EmptyResult} from '@/models/Pagination';
import {Sku, Skus} from '@/models/Sku';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {setPaginationResultIfNotLoading} from '@/utils/pagination';
import {useQuery, useQueryClient} from '@tanstack/react-query';

const initialState = EmptyResult<Sku>();

const useFindSkusQuery = (page: number, searchQuery?: string, filters?: Partial<SkuFilters>) => {
    const queryClient = useQueryClient();

    const [skus, setSkus] = useState<Skus>(initialState);

    const {currencyCode, languageCode} = useSettings();
    const skusApi = new SkusApi(new Configuration(useAuth), {currencyCode, languageCode});

    const itemsPerPage = 10;

    const {
        data,
        isLoading,
        error,
        isFetching,
    } = useQuery({
        queryKey: ['skus', page, searchQuery, filters, currencyCode, languageCode],
        queryFn: () => {
            const offset = page * itemsPerPage - itemsPerPage;
            return skusApi.findSkus(
                searchQuery,
                filters,
                offset,
                itemsPerPage,
            );
        },
    });

    const invalidateQuery = async () => {
        await queryClient.invalidateQueries({
            queryKey: ['skus', page, searchQuery, filters],
        });
    };

    useEffect(() => {
        setPaginationResultIfNotLoading(data, setSkus, isLoading);
    }, [data, isLoading, isFetching]);

    return {
        skus,
        isLoading,
        error,
        itemsPerPage,
        invalidateQuery,
    };
};

export default useFindSkusQuery;
