import {Configuration, SkusApi} from '@/api';
import useGetSkuQuery from '@/api/queries/useGetSkuQuery';
import CustomToast from '@/components/CustomToast';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {SkuAssociation} from '@/models/Sku';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import ProductForm from '@/modules/settings/components/ProductForm';
import {SkuCreate, SkuUpdate} from '@/modules/settings/models/Sku';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import {TruckIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import {useParams} from 'react-router-dom';

const ProductEdit = () => {
    const {skuId} = useParams();

    const {
        sku,
        isLoading,
        error,
        invalidateQuery,
    } = useGetSkuQuery(skuId, [
        SkuAssociation.PRODUCT,
    ]);

    const skusApi = new SkusApi(new Configuration(useAuth));

    const handleSubmit = async (skuUpdate: SkuCreate | SkuUpdate) => {
        if (!sku) {
            return;
        }

        const updatedSku: SkuUpdate = {
            ...skuUpdate,
            id: sku.id,
            productId: sku.productId,
        };

        try {
            await skusApi.updateSku(sku.id, updatedSku);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Product updated successfully!'}
                    visible={t.visible}
                />
            ));

            await invalidateQuery();
        } catch (error) {
            console.error('Error while updating product:', error);
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!sku || error) {
        return;
    }

    return (
        <>
            <SettingsHeader
                title="Edit Product"
                icon={<TruckIcon className="w-6 h-6"/>}
            />

            <ProductForm
                sku={sku}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default ProductEdit;
