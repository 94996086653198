import {formatDate} from 'date-fns/format';
import useFindSkusQuery from '@/api/queries/useFindSkusQuery';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import StatusActiveIndicator from '@/components/StatusActiveIndicator';
import {SearchProps} from '@/hooks/useSearch';
import {Sku} from '@/models/Sku';
import {isStatusActive} from '@/models/Status';
import {ProductProductVariantEditPath} from '@/modules/supplier/paths';
import {TrashIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

interface ProductListProductTableProps extends SearchProps {
    productListId: string;
    onDelete?: (sku: Sku) => void | Promise<void>;
}

const ProductListProductTable = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
    productListId,
    onDelete = () => {},
}: ProductListProductTableProps) => {
    const {
        skus,
        isLoading,
        invalidateQuery,
    } = useFindSkusQuery(page, searchQuery, {productListId});

    const handleDeleteProduct = async (sku: Sku) => {
        await onDelete(sku);
        await invalidateQuery();
    };

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {skus.items.map((sku, index) => {
                        if (sku.id === undefined) {
                            return;
                        }

                        return (
                            <tr key={index}>
                                <td>{sku.id}</td>
                                <td>
                                    <Link to={ProductProductVariantEditPath.withParams(sku.productId, sku.id)}
                                          className="text-blue-500 hover:underline">
                                        {sku.name}
                                    </Link>
                                </td>
                                <td>{sku.createdAt && formatDate(sku.createdAt, 'd MMM yyyy')}</td>
                                <td><StatusActiveIndicator isActive={isStatusActive(sku)}/></td>
                                <td>
                                    <Button
                                        onClick={() => handleDeleteProduct(sku)}
                                        icon={<TrashIcon className="h-5 w-5" aria-hidden="true"/>}
                                        loadingIconClassName="h-5 w-5"
                                        variant="delete"
                                        size="xs"
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={skus.totalCount || 0}
                    estimatedTotalCount={skus.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default ProductListProductTable;
