import React, {useState} from 'react';
import AccountButton from '@/components/Header/AccountButton';
import CartButton from '@/components/Header/CartButton';
import MobileMenu from '@/components/Header/MobileMenu';
import NotificationButton from '@/components/Header/NotificationButton';
import InventoryIcon from '@/components/icons/InventoryIcon';
import WarehouseIcon from '@/components/icons/WarehouseIcon';
import PopoverMenuButton from '@/components/PopoverMenuButton';
import {UserRole} from '@/models/User';
import {CustomerListPath, OrganizationListPath, SupplierListPath, UserListPath} from '@/modules/admin/paths';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {LogoutPath} from '@/modules/auth/paths';
import {useCart} from '@/modules/retailer/contexts/CartContext';
import {OrderListPath, SettingsPath, ShopPath} from '@/modules/retailer/paths';
import {
    InventoryListPath,
    PriceListListPath,
    ProductCategoryListPath,
    ProductListListPath,
    ProductListPath,
    ProductVariantListPath,
    PromotionListPath,
    WarehouseListPath,
} from '@/modules/supplier/paths';
import {HomePath} from '@/paths';
import {PopoverGroup} from '@headlessui/react';
import {
    ArrowRightStartOnRectangleIcon,
    ArrowsRightLeftIcon,
    Bars3Icon,
    Cog6ToothIcon,
    CubeIcon,
    CubeTransparentIcon,
    CurrencyDollarIcon,
    FolderIcon,
    PercentBadgeIcon,
    RectangleStackIcon,
} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

interface UserProfileNavigation {
    name: string;
    href: string;
    onClick?: () => void;
    icon: HeroIcon;
    role?: UserRole;
}

const userProfile: UserProfileNavigation[] = [
    {name: 'Settings', href: SettingsPath.profile, icon: Cog6ToothIcon},
];

const userProfileWithSignOut: UserProfileNavigation[] = [
    ...userProfile,
    {name: 'Sign out', href: LogoutPath, icon: ArrowRightStartOnRectangleIcon},
];

const supplierAdmin: SubNavigation[] = [
    {name: 'Product Information', description: 'Manage product details', href: ProductListPath, icon: CubeIcon},
    {name: 'Product Variants', description: 'Organize stock keeping units (SKUs)', href: ProductVariantListPath, icon: CubeTransparentIcon},
    {name: 'Product Categories', description: 'Categorize and organize products', href: ProductCategoryListPath, icon: FolderIcon},
    {name: 'Product Lists', description: 'Create and manage product lists', href: ProductListListPath, icon: RectangleStackIcon},
    {name: 'Pricing', description: 'Manage pricing information', href: PriceListListPath, icon: CurrencyDollarIcon},
    {name: 'Promotions', description: 'Manage promotional campaigns', href: PromotionListPath, icon: PercentBadgeIcon},
    {name: 'Inventory', description: 'Manage inventory information', href: InventoryListPath, icon: InventoryIcon},
    {name: 'Warehouses', description: 'Manage warehouse information', href: WarehouseListPath, icon: WarehouseIcon},
];

type HeroIcon = React.ComponentType<
    React.PropsWithoutRef<React.ComponentProps<'svg'>> & {
    title?: string | undefined;
    titleId?: string | undefined;
}
>;

interface Navigation {
    name: string;
    href?: string;
    items?: SubNavigation[];
}

interface SubNavigation {
    name: string;
    description: string;
    href: string;
    icon: HeroIcon | React.ElementType;
}

const customerNavigation: Navigation[] = [
    {name: 'Shop', href: ShopPath.path},
    {name: 'Orders', href: OrderListPath},
];

const supplierNavigation: Navigation[] = [
    {name: 'Orders', href: OrderListPath},
    {name: 'Admin', items: supplierAdmin},
];

const adminNavigation: Navigation[] = [
    {name: 'Organizations', href: OrganizationListPath},
    {name: 'Users', href: UserListPath},
    {name: 'Customers', href: CustomerListPath},
    {name: 'Suppliers', href: SupplierListPath},
];

const Header = () => {
    const {role, roles, changeRole} = useAuth();
    const {cartItemCount} = useCart();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const isRetailer = role === UserRole.Retailer;
    const isSupplier = role === UserRole.Supplier;
    const isAdmin = role === UserRole.Admin;

    const otherRoles = roles?.filter(value => value !== role) || [];

    const localUserProfile= [...userProfile];
    const localUserProfileWithSignOut = [...userProfileWithSignOut];

    otherRoles.forEach((otherRole) => {
        const item = {
            name: `Change to ${otherRole.toLowerCase()} role`,
            onClick: () => changeRole(otherRole),
            href: HomePath,
            icon: ArrowsRightLeftIcon,
        };
        localUserProfile.push(item);
        localUserProfileWithSignOut.splice(localUserProfileWithSignOut.length - 1, 0, item);
    });

    const navigation = isAdmin
        ? adminNavigation
        : (isSupplier ? supplierNavigation : customerNavigation);

    const openMobileMenu = () => {
        setMobileMenuOpen(true);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <header className="bg-white shadow">
            <nav className="mx-auto flex max-w-7xl items-center justify-between px-4 lg:py-1 lg:px-8 min-h-14" aria-label="Global">
                <div className="flex lg:flex-1 items-center">
                    <Link to={HomePath} className="flex gap-2">
                        <img className="h-8 w-auto" src="/logo.svg" alt=""/>
                        <div className="flex">
                            <span className="font-semibold self-center text-[16px]">Simpler</span>
                            <span className="font-semibold self-center text-[16px] ml-0.5">Supply</span>
                        </div>
                    </Link>
                </div>
                <PopoverGroup className={`${navigation.length > 3 ? 'lg:gap-x-4' : 'lg:gap-x-8'} hidden lg:flex`}>
                    {navigation.map((item) => (
                        item.items ? (
                            <PopoverMenuButton key={item.name} item={item} items={item.items}/>
                        ) : item.href ? (
                            <Link
                                to={item.href}
                                key={item.name}
                                className="text-sm font-semibold leading-6 text-gray-900 rounded-md hover:bg-gray-100 transition duration-300 ease-in-out px-3 py-1.5"
                            >
                                {item.name}
                            </Link>
                        ) : (
                            <span key={item.name}>{item.name}</span>
                        )
                    ))}
                </PopoverGroup>
                <div className="flex flex-1 justify-end gap-3 md:gap-1">
                    <div className="md:mr-1.5">
                        <NotificationButton/>
                    </div>

                    <div className="hidden md:block">
                        <AccountButton menuItems={localUserProfileWithSignOut}/>
                    </div>

                    {isRetailer && <CartButton count={cartItemCount}/>}
                </div>
                <div className="flex lg:hidden ml-4">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md text-gray-700"
                        onClick={openMobileMenu}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                </div>
            </nav>

            <MobileMenu
                open={mobileMenuOpen}
                close={closeMobileMenu}
                menu={navigation}
                userMenu={localUserProfile}
                cartItemCount={cartItemCount}
            />
        </header>
    );
};


export default Header;
