export const filterObjectOrNull = <T>(value: T | T[] | string | string[] | null): T | null => {
    if (Array.isArray(value) || typeof value !== 'object' || value === null) {
        return null;
    }
    return value;
};

export const filterObjects = <T>(value: T | T[] | string | string[] | null): T[] => {
    if (!Array.isArray(value) || !value.every(item => typeof item === 'object')) {
        return [];
    }
    return value as T[];
};

export const filterObjectsIfNoneNull = <T>(value: T | (T | null)[] | null): T[] => {
    if (!Array.isArray(value) || value.includes(null)) {
        return [];
    }
    return value as T[];
};

export const filterStringOrNull = <T>(value: T | T[] | string | string[] | null): string | null => {
    return typeof value === 'string' ? value : null;
};

export const filterStrings = <T>(value: T | T[] | string | string[] | null): string[] => {
    if (!Array.isArray(value) || !value.every(item => typeof item === 'object')) {
        return [];
    }
    return value as string[];
};
