import {useCallback, useEffect, useState} from 'react';
import {ArrowUpTrayIcon} from '@heroicons/react/24/outline';
import {useDropzone} from 'react-dropzone';

const mimeTypeAliases: Record<string, string> = {
    csv: 'text/csv',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf: 'application/pdf',
    images: 'image/*',
};

const getSupportedFormats = (accept: (keyof typeof mimeTypeAliases)[] | string[]): string => {
    const formats = accept.map(type => {
        return Object.keys(mimeTypeAliases).find(key => mimeTypeAliases[key] === type) || type;
    });

    return formats.length > 1
        ? `${formats.slice(0, -1).join(', ')} and ${formats.slice(-1)}`
        : formats[0];
};

interface FileUploadProps {
    selectedFiles?: File[];
    onFileChange: (file: File[]) => void;
    multiple?: boolean;
    accept: (keyof typeof mimeTypeAliases)[] | string[];
}

const FileUpload = ({
    onFileChange,
    multiple = true,
    accept,
    selectedFiles: selectedFilesProp = [],
}: FileUploadProps) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>(selectedFilesProp);

    useEffect(() => {
        setSelectedFiles(selectedFilesProp);
    }, [selectedFilesProp]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setSelectedFiles(acceptedFiles);
            onFileChange(acceptedFiles);
        },
        [onFileChange],
    );

    const acceptedTypes = accept.reduce((acc, type) => {
        const mimeType = mimeTypeAliases[type] || type;
        acc[mimeType] = [];
        return acc;
    }, {} as Record<string, []>);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        onFileDialogCancel: () => onDrop([]),
        multiple,
        accept: acceptedTypes,
    });

    const supportedFormats = getSupportedFormats(accept);

    return (
        <div
            {...getRootProps()}
            className={`flex flex-col items-center justify-center w-full p-4 border-2 border-dashed rounded-lg transition-all duration-300 cursor-pointer
                ${isDragActive ? 'border-blue-500 text-blue-500' : 'border-gray-300 text-gray-500'}
                hover:border-blue-500 hover:text-blue-500`}
        >
            <input {...getInputProps()}/>
            <ArrowUpTrayIcon className="h-12 w-12"/>
            <p className="mt-2 text-sm">
                {isDragActive ? 'Drop the file here...' : 'Drag and drop a file here, or click to select a file'}
            </p>
            <p className="text-xs text-gray-400">
                Supported formats: {supportedFormats}
            </p>

            {selectedFiles.length > 0 && (
                <div className="mt-3 w-full text-center">
                    <p className="text-sm font-semibold text-gray-700">
                        {selectedFiles.length === 1
                            ? 'Selected file:'
                            : 'Selected files:'
                        }
                    </p>
                    <ul className="mt-1 text-xs text-gray-700">
                        {selectedFiles.map((file) => (
                            <li key={file.name}>{file.name}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
