import {OrderItem, OrderItemCreate, OrderItemUpdate} from '@/models/OrderItem';
import {OrderStatus} from '@/models/OrderStatus';
import {Amount} from '@/models/Price';
import {ProofOfPayment} from '@/models/ProofOfPayment';
import {Store} from '@/models/Store';
import {Supplier} from '@/models/Supplier';
import {TimeWindow} from '@/models/TimeWindow';
import {Warehouse} from '@/models/Warehouse';

export enum OrderAssociation {
    SUPPLIER = 'supplier',
    STORE = 'store',
    WAREHOUSE = 'warehouse',
    ALL = 'all',
}

export interface DeliveryAddress {
    building: string;
    street1: string;
    street2: string;
    postalCode: string;
    region: string;
    city: string;
    countryCode: string;
    additionalDescription: string;
}

export interface Order {
    id: string;
    code: string;
    status: OrderStatus;
    externalCode?: string;
    deliveryWindow?: TimeWindow;
    pickupWindow?: TimeWindow;
    pickupWindowCutoffTime?: Date;
    actualDeliveryDate?: TimeWindow;
    deliveryAddress: DeliveryAddress;
    source?: string;
    currencyCode: string;
    paymentDueDate?: Date;
    proofOfPayment?: ProofOfPayment;
    note: string;
    customData?: { [key: string]: string };
    supplierId: string;
    warehouseId?: string;
    storeId?: string;
    poNumber?: string;
    createdAt: Date;
    items: OrderItem[];
    supplier?: Supplier;
    store?: Store;
    warehouse?: Warehouse;
    editedBySupplier?: boolean;
    grandTotal?: Amount;
    subtotal?: Amount;
    totalVatAmount?: Amount;
    totalDiscountAmount?: Amount;
}

export interface Orders {
    items: Order[];
    totalCount?: number;
    estimatedTotalCount?: number;
}

export interface OrderCreate {
    externalCode?: string;
    deliveryWindow?: TimeWindow;
    pickupWindow?: TimeWindow;
    pickupWindowCutoffTime?: Date;
    currencyCode: string;
    note?: string;
    customData?: { [key: string]: string };
    supplierId: string;
    warehouseId?: string;
    storeId: string;
    poNumber?: string;
    items: OrderItemCreate[];
}

export interface OrderCreateResult {
    id: string;
    code: string;
}

export interface OrderUpdate {
    confirmedDeliveryWindow: TimeWindow;
    items: OrderItemUpdate[];
}
