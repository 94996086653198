import {createContext, useContext} from 'react';

export interface Settings {
    currencyCode: string;
    timezone: string;
    languageCode: string;
    locale: string;
}

export interface SettingsContextProps {
    currencyCode: string;
    timezone: string;
    languageCode: string;
    locale: string;
    updateSettings: (updatedSettings: Partial<Settings>) => void;
}

const defaultSettings: Settings = {
    currencyCode: 'USD',
    timezone: 'UTC',
    languageCode: 'en',
    locale: 'en-SG',
};

const SettingsContext = createContext<SettingsContextProps>({
    updateSettings: () => {},
    ...defaultSettings,
});

export const useSettings = () => {
    const context = useContext(SettingsContext);
    if (!context) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
};

export default SettingsContext;
