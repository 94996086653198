import React from 'react';
import useGetSupplierQuery from '@/api/queries/useGetSupplierQuery';
import Breadcrumbs from '@/components/Breadcrumbs';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import SupplierToCustomerForm, {SupplierToCustomer} from '@/modules/admin/components/SupplierToCustomerForm';
import {useParams} from 'react-router-dom';

const SupplierToCustomerAdd: React.FC = () => {
    const {supplierId} = useParams();

    const {
        isLoading,
        supplier,
    } = useGetSupplierQuery(supplierId);

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!supplier) {
        return;
    }

    const handleOnSubmit = (data: SupplierToCustomer): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(data);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Add Customer"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <SupplierToCustomerForm
                    supplier={supplier}
                    onSubmit={handleOnSubmit}
                />
            </div>
        </div>
    );
};

export default SupplierToCustomerAdd;
