import React, {useState} from 'react';
import Button from '@/components/Button';
import StoreSelectBox from '@/components/StoreSelectBox';
import {Store} from '@/models/Store';
import {Supplier} from '@/models/Supplier';
import {Warehouse} from '@/models/Warehouse';
import WarehouseSelectBox from '@/modules/admin/components/WarehouseSelectBox';
import {CheckIcon} from '@heroicons/react/24/solid';

export interface SupplierToStore {
    store?: Store | null;
    warehouse?: Warehouse | null;
    supplier: Supplier;
}

interface SupplierToStoreFormProps {
    supplier: Supplier;
    onSubmit: (value: SupplierToStore) => Promise<void>;
}

const SupplierToStoreForm: React.FC<SupplierToStoreFormProps> = ({supplier, onSubmit}) => {
    const [formData, setFormData] = useState<SupplierToStore>({
        supplier,
    });

    const handleStoreChange = (store?: Store | null) => {
        setFormData(prevData => ({...prevData, store: store}));
    };

    const handleWarehouseChange = (warehouse?: Warehouse | null) => {
        setFormData(prevData => ({...prevData, warehouse: warehouse}));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label className="block font-medium text-gray-700 mb-1">
                        Supplier:
                    </label>
                    <div className="w-full border shadow rounded-md py-2 pl-3 cursor-default">
                        {supplier.name}
                    </div>
                </div>
                <div className="col-span-2">
                    <StoreSelectBox
                        value={formData.store || null}
                        onSelect={handleStoreChange}
                    />
                </div>
                <div className="col-span-2">
                    <WarehouseSelectBox
                        value={formData.warehouse || null}
                        onSelect={handleWarehouseChange}
                    />
                </div>
            </div>
            <div className="mt-8 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default SupplierToStoreForm;
