import {Configuration, SkusApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {SkuCreate, SkuUpdate} from '@/models/Sku';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import ProductVariantForm from '@/modules/supplier/components/ProductVariantForm';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const ProductVariantEdit = () => {
    const {skuId = '', productId} = useParams();

    const skusApi = new SkusApi(new Configuration(useAuth));

    const {
        isLoading,
        data: sku,
    } = useQuery({
        queryKey: ['sku', skuId],
        queryFn: async () => {
            return skuId
                ? skusApi.getSku(skuId)
                : null;
        },
    });

    const handleOnSubmit = (sku: SkuCreate | SkuUpdate): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(sku);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!sku) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Product Variant">
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Product Variant
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <ProductVariantForm
                    onSubmit={handleOnSubmit}
                    sku={sku}
                    productId={productId}
                />
            </div>
        </div>
    );
};

export default ProductVariantEdit;
