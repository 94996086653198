import {Fragment, useEffect, useState} from 'react';
import remarkGfm from 'remark-gfm';
import terms from '@/assets/terms-and-conditions.md';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import {Dialog, Transition, TransitionChild} from '@headlessui/react';
import Markdown from 'react-markdown';
import '@/modules/auth/components/TermsAndConditions.css';

interface TermsAndConditionsModalProps {
    termsAccepted: boolean;
    onTermsAcceptedChange: (accepted: boolean) => void;
}

const TermsAndConditionsModal = ({termsAccepted, onTermsAcceptedChange}: TermsAndConditionsModalProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchMarkdown = async () => {
            try {
                const response = await fetch(terms);
                const text = await response.text();
                setContent(text);
            } catch (error) {
                console.error('Error fetching Markdown file:', error);
            }
        };

        void fetchMarkdown();
    }, []);

    const handleAccept = () => {
        onTermsAcceptedChange(true);
        closeModal();
    };

    return (
        <>
            <div className="flex items-center space-x-2">
                <Checkbox
                    checked={termsAccepted}
                    onChange={(value) => onTermsAcceptedChange(value)}
                    id="termsAccepted"
                />
                <label htmlFor="termsAccepted" className="text-sm">
                    I agree to the{' '}
                    <button
                        type="button"
                        onClick={openModal}
                        className="text-blue-500 underline"
                    >
                        Terms and Conditions
                    </button>
                    .
                </label>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div
                                className="fixed inset-0 bg-black/30"
                                aria-hidden="true"
                                onClick={closeModal}
                            />
                        </TransitionChild>

                        <span className="inline-block h-screen align-middle" aria-hidden="true">
                            &#8203;
                        </span>

                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                                onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
                            >
                                <div className="max-h-[80vh] overflow-y-auto p-4">
                                    <Markdown
                                        remarkPlugins={[remarkGfm]}
                                        className="terms-and-conditions"
                                    >
                                        {content}
                                    </Markdown>
                                </div>

                                <div className="flex justify-end gap-4 mt-4">
                                    <Button
                                        variant="secondary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </Button>

                                    <Button
                                        variant="primary"
                                        onClick={handleAccept}
                                    >
                                        Accept
                                    </Button>
                                </div>
                            </div>
                        </TransitionChild>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default TermsAndConditionsModal;
