import React, {useEffect, useState} from 'react';
import {Configuration, StoresApi} from '@/api';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import {SearchProps} from '@/hooks/useSearch';
import {EmptyResult} from '@/models/Pagination';
import {Store, StoreAssociation, Stores} from '@/models/Store';
import {Supplier} from '@/models/Supplier';
import {CustomerStoreEditPath} from '@/modules/admin/paths';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

interface SupplierToStoreTableProps extends SearchProps {
    supplier: Supplier;
}

const initialState = EmptyResult<Store>();

const SupplierToStoreTable: React.FC<SupplierToStoreTableProps> = ({
    supplier,
    searchQuery,
    page = 1,
    onChangePage = () => {},
}) => {
    const [stores, setStores] = useState<Stores>(initialState);

    const storesApi = new StoresApi(new Configuration(useAuth));

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['stores', supplier.id, page, searchQuery],
        queryFn: async () => {
            const limit = 10;
            return storesApi.findStores(
                searchQuery,
                {supplierId: supplier.id},
                page * limit - limit,
                limit,
                [StoreAssociation.WAREHOUSE],
            );
        },
    });

    useEffect(() => {
        setStores((prevState): Stores => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th>Store</th>
                        <th>Warehouse</th>
                        <th>Supplier</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stores.items.map((store, index) => {
                        if (store.id === undefined) {
                            return;
                        }

                        return (
                            <tr key={index}>
                                <td>
                                    <Link to={CustomerStoreEditPath.withParams(store.customerId, store.id)}
                                          className="text-blue-500 hover:underline">
                                        {store.name}
                                    </Link>
                                </td>
                                <td>{store.warehouse ? store.warehouse.name : ''}</td>
                                <td>{supplier.name}</td>
                                <td>
                                    <Button
                                        onClick={() => {
                                        }}
                                        variant="delete"
                                        size="xs"
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={stores.totalCount || 0}
                    estimatedTotalCount={stores.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default SupplierToStoreTable;
