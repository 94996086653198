import {Fragment} from 'react';
import {UserRole} from '@/models/User';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {classNames} from '@/utils/classNames';
import {Menu, MenuButton, MenuItem, MenuItems, Transition} from '@headlessui/react';
import {UserCircleIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

interface MenuItem {
    name: string;
    href: string;
    onClick?: () => void;
    role?: UserRole;
}

interface AccountButtonProps {
    menuItems: MenuItem[];
}

const AccountButton = ({menuItems}: AccountButtonProps) => {
    const {role} = useAuth();

    return (
        <Menu as="div" className="relative">
            <div>
                <MenuButton
                    className="relative flex rounded-full text-sm px-1 pr-2 py-1 hover:bg-gray-100 transition duration-300 ease-in-out outline-none"
                >
                    <span className="absolute -inset-1.5"/>
                    <span className="sr-only">Open user menu</span>
                    <div className="flex items-center gap-2">
                        <UserCircleIcon className="h-8 w-8 text-black"/>
                        <span>Account</span>
                    </div>
                </MenuButton>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 outline-none"
                >
                    {menuItems.filter(item => !item.role || item.role === role).map((item) => (
                        <MenuItem key={item.name}>
                            {({focus}) => (
                                <Link
                                    onClick={item.onClick}
                                    to={item.href}
                                    className={classNames(focus ? 'bg-gray-100' : '', 'block px-4 py-4 text-sm text-gray-700')}
                                >
                                    {item.name}
                                </Link>
                            )}
                        </MenuItem>
                    ))}
                </MenuItems>
            </Transition>
        </Menu>
    );
};

export default AccountButton;
