import {ChangeEvent, FormEvent, useState} from 'react';
import Button from '@/components/Button';
import CountrySelectBox, {CountryItem} from '@/components/CountrySelectBox';
import TimezoneSelectBox, {TimezoneItem} from '@/components/TimezoneSelectBox';
import ToggleSwitch from '@/components/ToggleSwitch';
import {countryCodes} from '@/config';
import {Organization, OrganizationCreate} from '@/models/Organization';
import {CheckIcon} from '@heroicons/react/24/solid';

interface OrganizationFormProps {
    organization?: Organization;
    onSubmit: (organization: OrganizationCreate | Organization) => Promise<void>;
}

const OrganizationForm = ({organization, onSubmit}: OrganizationFormProps) => {
    const [formData, setFormData] = useState<OrganizationCreate | Organization>(
        organization ? {...organization} : {
            name: '',
            countryCode: '',
            timezone: '',
            status: 'ACTIVE',
        },
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    const handleCountryOnSelect = (country: CountryItem | null) => {
        if (country) {
            setFormData(prevData => ({...prevData, country: country.id}));
        }
    };

    const handleTimezoneOnSelect = (timezone: TimezoneItem | null) => {
        if (timezone) {
            setFormData(prevData => ({...prevData, timezone: timezone.name}));
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div>
                    <CountrySelectBox
                        value={formData.countryCode}
                        countryCodes={countryCodes}
                        onSelect={handleCountryOnSelect}
                        requireSelection
                    />
                </div>
                <div>
                    <TimezoneSelectBox
                        value={formData.timezone}
                        countryCode={formData.countryCode}
                        onSelect={handleTimezoneOnSelect}
                        requireSelection
                        disabled={!formData.countryCode}
                    />
                </div>
                <div className="col-span-2">
                    <ToggleSwitch
                        checked={formData.status === 'ACTIVE'}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default OrganizationForm;
