import React, {useRef, useState} from 'react';
import DateSegment from '@/components/datepicker/DateSegment';
import {TimeValue, useTimeField} from '@react-aria/datepicker';
import {useLocale} from '@react-aria/i18n';
import {useTimeFieldState} from '@react-stately/datepicker';

export interface TimeFieldProps {
    onChange?: (time: TimeValue) => void;
    granularity?: 'minute';
    label?: string;
    value?: TimeValue | null;
}

const TimeField: React.FC<TimeFieldProps> = (props) => {
    const {value} = props;
    const {locale} = useLocale();
    const [timeValue, setTimeValue] = useState<TimeValue | undefined | null>(value);
    const state = useTimeFieldState({
        ...props,
        locale,
        value: value,
    });
    const ref = useRef(null);
    const {onChange} = props;

    const handleOnChange = (timeValue: TimeValue) => {
        setTimeValue(timeValue);
        onChange && onChange(timeValue);
    };

    const {
        fieldProps,
        labelProps,
    } = useTimeField({
        value: timeValue,
        onChange: handleOnChange,
        'aria-label': 'hidden',
    }, state, ref);

    return (
        <div>
            {props.label && <label {...labelProps} className="block mb-1">{props.label}</label>}
            <div
                className="flex flex-1 rounded-lg bg-white border border-gray-300 group-hover:border-gray-400 transition-colors rounded-l-md group-focus-within:border-blue-600 group-focus-within:group-hover:border-blue-600 pl-2 p-1 py-2 relative">
                <div {...fieldProps} ref={ref} style={{display: 'flex'}}>
                    {state.segments.map((segment, i) => (
                        <DateSegment key={i} segment={segment} state={state}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TimeField;
