import {formatDate} from 'date-fns/format';
import {Fragment} from 'react';
import {UserRole} from '@/models/User';
import {useNotification} from '@/modules/notification/contexts/NotificationContext';
import NotificationService from '@/modules/notification/services/NotificationService';
import {Popover, PopoverButton, PopoverPanel, Transition} from '@headlessui/react';
import {BellIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

interface NotificationProps {
    onClick?: () => void;
}

const notificationService = new NotificationService();

const NotificationButton = ({onClick}: NotificationProps) => {
    const {notifications, markNotificationAsRead} = useNotification();

    const handleClick = (id: string) => {
        markNotificationAsRead(id);
        onClick?.();
    };

    return (
        <Popover as="div" className="md:relative">
            <PopoverButton
                className="flex items-center justify-center relative rounded-full text-sm p-2 hover:bg-gray-100 transition duration-300 ease-in-out focus:outline-none"
                disabled={notifications.length === 0}
            >
                <BellIcon className="h-7 w-7"/>
                {notifications.some((notification) => !notification.read) && (
                    <span className="bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs absolute top-0 right-0">
                        {notifications.filter((notification) => !notification.read).length}
                    </span>
                )}
            </PopoverButton>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-150"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <PopoverPanel className="absolute z-10 right-0 w-full md:w-96 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1.5 py-1.5">
                        {notifications.map((notification) => {
                            const notificationText = notificationService.generateText(notification);
                            const notificationHref = notificationService.generateHref(notification);
                            const props = notificationHref ? {to: notificationHref} : {};
                            return (
                                <PopoverButton
                                    onClick={() => handleClick(notification.id)}
                                    key={notification.id} as={notificationHref ? Link : 'div'}
                                    className={`${!notification.read ? 'bg-yellow-50' : ''} mb-0.5 hover:bg-gray-100 justify-between px-4 py-3 text-sm leading-5 text-gray-900 relative block transition duration-300 ease-in-out`}
                                    {...props}
                                >
                                    <div>
                                        <p className="font-medium">{notificationText}</p>
                                        {notification.actionByName && (
                                            <p className="text-xs text-gray-500">
                                                {notification.actionByRole == UserRole.Supplier ? 'Supplier' : notification.actionByRole == UserRole.Retailer ? 'Retailer' : ''}: {notification.actionByName}
                                            </p>
                                        )}
                                        <p className="text-xs text-gray-500">{formatDate(notification.createdAt, 'd MMM yyyy, hh:mm a')}</p>
                                    </div>
                                    {!notification.read && (
                                        <div className="w-2 h-2 bg-red-500 rounded-full absolute top-3 right-3"></div>
                                    )}
                                </PopoverButton>
                            );
                        })}
                    </div>
                </PopoverPanel>
            </Transition>
        </Popover>
    );
};

export default NotificationButton;
