import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {Product, ProductCreate} from '@/models/Product';
import ProductForm from '@/modules/supplier/components/ProductForm';

const ProductAdd: React.FC = () => {
    const handleOnSubmit = (warehouse: ProductCreate | Product): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(warehouse);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Product"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <ProductForm
                    onSubmit={handleOnSubmit}
                />
            </div>
        </div>
    );
};

export default ProductAdd;
