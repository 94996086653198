import {formatDate} from 'date-fns/format';
import React, {useEffect, useState} from 'react';
import {CalendarsApi} from '@/api';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import {SearchProps} from '@/hooks/useSearch';
import {CalendarEvent, CalendarEvents} from '@/models/CalendarEvent';
import {EmptyResult} from '@/models/Pagination';
import {SupplierCalendarEventEditPath} from '@/modules/admin/paths';
import {CalendarEventEditPath} from '@/modules/supplier/paths';
import {useQuery} from '@tanstack/react-query';
import {Link} from 'react-router-dom';

interface CalendarEventTableProps extends SearchProps {
    calendarId: string;
    supplierId?: string;
}

const initialState = EmptyResult<CalendarEvent>();

const CalendarEventTable: React.FC<CalendarEventTableProps> = ({
    calendarId,
    searchQuery,
    page = 1,
    onChangePage = () => {},
    supplierId,
}) => {
    const [calendarEvents, setCalendarEvents] = useState<CalendarEvents>(initialState);

    const calendarsApi = new CalendarsApi();

    const {
        isLoading,
        data,
        isFetching,
    } = useQuery({
        queryKey: ['calendarEvents', calendarId, page, searchQuery],
        queryFn: async () => {
            const limit = 10;
            return calendarsApi.findCalendarEvents(
                searchQuery,
                calendarId,
                page * limit - limit,
                limit,
            );
        },
    });

    useEffect(() => {
        setCalendarEvents((prevState): CalendarEvents => {
            if (isLoading || (isLoading && isFetching)) {
                return prevState;
            }

            return {
                items: data?.items || [],
                totalCount: data?.totalCount || 0,
                estimatedTotalCount: data?.estimatedTotalCount || 0,
            };
        });
    }, [data, isLoading, isFetching]);

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th className="w-20 min-w-20">ID</th>
                        <th>Name</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Repeats</th>
                        <th>Created At</th>
                    </tr>
                    </thead>
                    <tbody>
                    {calendarEvents.items.map((event, index) => {
                        if (event.id === undefined) {
                            return;
                        }

                        const editPath = supplierId
                            ? SupplierCalendarEventEditPath.withParams(supplierId, event.calendarId, event.id)
                            : CalendarEventEditPath.withParams(event.calendarId, event.id);

                        return (
                            <tr key={index}>
                                <td>{event.id}</td>
                                <td>
                                    <Link to={editPath}
                                          className="text-blue-500 hover:underline">
                                        {event.name}
                                    </Link>
                                </td>
                                <td>{formatDate(event.start, 'd MMM yyyy')}</td>
                                <td>{formatDate(event.end, 'd MMM yyyy')}</td>
                                <td>{event.frequency || event.occurrence ? 'Yes' : 'No'}</td>
                                <td>{event.createdAt && formatDate(event.createdAt, 'd MMM yyyy')}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={10}
                    totalCount={calendarEvents.totalCount || 0}
                    estimatedTotalCount={calendarEvents.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default CalendarEventTable;
