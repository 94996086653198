import {ChangeEvent, FormEvent, useState} from 'react';
import Button from '@/components/Button';
import ToggleSwitch from '@/components/ToggleSwitch';
import {NullIsland} from '@/models/Geolocation';
import {isStatusActive} from '@/models/Status';
import {
    Store,
    StoreAddress,
    StoreAddressType,
    StoreCreate,
    StoreReceivingWindow,
} from '@/models/Store';
import StoreAddressForm from '@/modules/admin/components/StoreAddressForm';
import StoreReceivingWindowsForm from '@/modules/admin/components/StoreReceivingWindowsForm';
import {CheckIcon} from '@heroicons/react/24/solid';

interface StoreFormProps {
    store?: Store | null;
    onSubmit: (store: StoreCreate | Store) => Promise<void>;
}

const StoreForm = ({store, onSubmit}: StoreFormProps) => {
    const [formData, setFormData] = useState<StoreCreate | Store>(
        store
            ? {...store}
            : {
                code: '',
                customerId: '',
                name: '',
                email: '',
                description: '',
                externalCode: '',
                contactNumber: '',
                addresses: [{
                    storeId: '',
                    type: StoreAddressType.BUSINESS,
                    building: '',
                    street1: '',
                    street2: '',
                    postalCode: '',
                    region: '',
                    city: '',
                    countryCode: '',
                    location: {...NullIsland},
                    additionalDescription: '',
                }],
                receivingWindows: [],
                customData: {},
                dynamicFields: {},
                status: 'ACTIVE',
            },
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleAddressChange = (address: StoreAddress, index: number) => {
        setFormData(prevData => {
            const newAddresses = [...prevData.addresses];
            newAddresses[index] = address;
            return {...prevData, addresses: newAddresses};
        });
    };

    const handleReceivingWindowsChange = (windows: StoreReceivingWindow[]) => {
        setFormData(prevData => ({...prevData, receivingWindows: windows}));
    };

    const handleStatusChange = (checked: boolean) => {
        setFormData(prevData => ({...prevData, status: checked ? 'ACTIVE' : 'INACTIVE'}));
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4 lg:gap-8">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="description" className="block font-medium text-gray-700">
                        Description:
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full h-24 resize-none"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="externalCode" className="block font-medium text-gray-700">
                        External Code:
                    </label>
                    <input
                        type="text"
                        id="externalCode"
                        name="externalCode"
                        value={formData.externalCode}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>
                <div className="col-span-2">
                    <label htmlFor="contactNumber" className="block font-medium text-gray-700">
                        Contact Number:
                    </label>
                    <input
                        type="text"
                        id="contactNumber"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    />
                </div>

                <div className="col-span-2">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Address
                    </span>
                    {formData.addresses.map((address, index) => (
                        <StoreAddressForm
                            key={index}
                            address={address}
                            onChange={() => handleAddressChange(address, index)}
                        />
                    ))}
                </div>

                <div className="col-span-2">
                    <span className="block font-bold text-xl mb-6 pb-4 border-b border-gray-300">
                        Receiving Windows
                    </span>
                    <div className="pb-6 border-b border-gray-300">
                        <StoreReceivingWindowsForm
                            receivingWindows={formData.receivingWindows}
                            onChange={handleReceivingWindowsChange}
                        />
                    </div>
                </div>

                <div className="col-span-2">
                    <ToggleSwitch
                        checked={isStatusActive(formData)}
                        onChange={handleStatusChange}
                        labelText="Status:"
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    onClick={() => onSubmit(formData)}
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default StoreForm;
