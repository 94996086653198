import React from 'react';
import {Configuration, UsersApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {User} from '@/models/User';
import UserForm from '@/modules/admin/components/UserForm';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const UserEdit: React.FC = () => {
    const {userId} = useParams();

    const usersApi = new UsersApi(new Configuration(useAuth));

    const {
        isLoading,
        data: user,
    } = useQuery({
        queryKey: ['user', userId],
        queryFn: async () => {
            return userId
                ? usersApi.getUser(userId)
                : null;
        },
    });

    const handleOnSubmit = (user: User): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(user);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!user) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit User">
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete User
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <UserForm
                    onSubmit={handleOnSubmit}
                    user={user}
                />
            </div>
        </div>
    );
};

export default UserEdit;
