import React, {ReactNode} from 'react';
import Button, {Variant} from '@/components/Button';
import CartLottieIcon from '@/components/icons/CartLottieIcon';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {Order} from '@/models/Order';
import {useCart} from '@/modules/retailer/contexts/CartContext';
import {CartItemImpl} from '@/modules/retailer/models/CartItem';
import {delay} from '@/utils/delay';

interface ReorderButtonProps {
    onSuccess?: () => void;
    variant?: Variant;
    children?: ReactNode;
    className?: string;
    loadingIconClassName?: string;
    order: Order;
}

const ReorderButton: React.FC<ReorderButtonProps> = ({
    children,
    onSuccess,
    order,
    ...props
}) => {
    const {addToCart, resetCart} = useCart();

    const handleClick = async () => {
        await delay(2000);

        resetCart();

        order.items.forEach(orderItem => {
            addToCart(
                order.supplierId,
                new CartItemImpl(
                    orderItem.skuId,
                    order.supplierId,
                    orderItem.quantity,
                ),
            );
        });

        onSuccess?.();
    };

    return (
        <Button
            {...props}
            onClick={handleClick}
            icon={<div className="-ml-1 -mb-0.5 mr-0.5 w-8">
                <CartLottieIcon/>
            </div>}
            loadingIcon={<div className="flex -ml-1 mr-0.5 w-8 pl-1 items-center justify-center">
                <SpinnerIcon className="w-5 h-5 fill-blue-800"/>
            </div>}
            rounded="lg"
            className="w-full sm:w-auto min-w-32 text-sm py-1"
        >
            {children ? children : 'Reorder'}
        </Button>
    );
};

export default ReorderButton;
