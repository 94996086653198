import React, {ReactNode, useEffect, useState} from 'react';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {handleWithLoading} from '@/utils/loading';
import {TrashIcon} from '@heroicons/react/24/outline';
import {useNavigate} from 'react-router-dom';

type Size = 'xs' | 'sm' | 'md' | 'lg';
type Rounded = 'md' | 'lg' | 'full';
export type Variant = 'primary' | 'secondary' | 'danger' | 'delete' | 'success' | 'plain' | 'disabled';
type Width = 'full';

type Render = ({loading}: { loading: boolean }) => ReactNode;

interface ButtonProps {
    type?: 'button' | 'submit';
    className?: string;
    onClick?: (() => Promise<void>) | (() => void);
    to?: string;
    buttonText?: string;
    loadingText?: string;
    icon?: ReactNode;
    loadingIcon?: ReactNode;
    loadingIconClassName?: string;
    children?: Render | ReactNode;
    size?: Size;
    variant?: Variant;
    rounded?: Rounded;
    width?: Width;
    nowrap?: boolean;
    tooltip?: string;
    tooltipPlacement?: 'bottom' | 'top';
    confirm?: true | string;
    download?: boolean;
    loading?: boolean;
    disabled?: boolean;
}

const variants: Readonly<Record<Variant, string>> = {
    primary: 'bg-blue-600 text-white hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300',
    secondary: 'bg-white text-gray-900 border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200',
    danger: 'bg-red-600 text-white hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300',
    delete: 'bg-white text-gray-900 border border-gray-300 hover:bg-red-50 hover:border-red-400 focus:ring-4 focus:outline-none focus:ring-gray-200',
    success: 'bg-green-600 text-white hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-300',
    plain: 'text-gray-900 hover:text-gray-600 focus:outline-none',
    disabled: 'bg-gray-300 text-gray-500 cursor-not-allowed opacity-50',
};

const Button: React.FC<ButtonProps> = ({
    type = 'button',
    children,
    icon,
    loadingIcon,
    loadingIconClassName,
    className,
    onClick = () => {},
    to,
    buttonText,
    loadingText,
    size = 'sm',
    variant = 'primary',
    rounded = 'md',
    width,
    nowrap,
    tooltip,
    tooltipPlacement = 'bottom',
    download,
    disabled,
    loading: loadingProp,
}) => {
    const [loading, setLoading] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    useEffect(() => {
        setLoading(loadingProp || false);
    }, [loadingProp]);

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    const navigate = useNavigate();

    const handleClick = async () => {
        if (to !== undefined) {
            navigate(to);
            return;
        }

        if (onClick === undefined) {
            return;
        }

        await handleWithLoading(onClick, setLoading);
    };

    if (loading) {
        if (!loadingIconClassName) {
            loadingIconClassName =
                size === 'xs' ||
                size === 'sm' ||
                size === 'md'
                    ? 'w-5 h-5 mr-1'
                    : 'w-8 h-8';
        }

        icon = loadingIcon
            ? loadingIcon
            : <SpinnerIcon className={loadingIconClassName}/>;
    }

    if (variant === 'delete' && icon === undefined) {
        icon = <TrashIcon className="h-5 w-5 my-1 mr-1" aria-hidden="true"/>;
    }

    const paddingClass =
        size === 'xs' ? 'px-3 py-1.5'
            : size === 'sm' ? 'px-4 py-2'
            : size === 'md' ? 'px-5 py-2.5'
            : size === 'lg' ? 'px-6 py-3'
            : size === 'xl' ? 'px-8 py-4' : 'px-10 py-5';

    const textSizeClass =
        size === 'xs' ? 'text-sm'
            : size === 'sm' ? 'text-sm'
            : size === 'md' ? 'text-sm'
            : size === 'lg' ? 'text-base'
            : size === 'xl' ? 'text-lg' : `text-${size}`;

    const roundedClass = `rounded-${rounded}`;

    const gapClass =
        size === 'xs' ? 'gap-1'
            : size === 'sm' ? 'gap-1'
            : size === 'md' ? 'gap-1'
            : size === 'lg' ? 'gap-2.5'
            : size === 'xl' ? 'gap-2.5' : 'gap-3';

    const heightClass =
        size === 'xs' ? 'min-h-8'
            : size === 'sm' ? 'min-h-10'
            : size === 'md' ? 'min-h-10'
            : size === 'lg' ? 'min-h-12'
            : size === 'xl' ? 'min-h-12' : 'min-h-14';

    const text = loadingText
        ? loadingText
        : buttonText
            ? buttonText
            : (children && typeof children === 'function'
                ? children({loading})
                : children);

    const widthClass = width ? `w-${width}` : '';

    const nowrapClass = nowrap ? 'whitespace-nowrap' : '';

    const tooltipClass = tooltip
        ? (tooltipPlacement === 'bottom'
            ? 'transform -bottom-1.5 -translate-x-1/2 translate-y-full'
            : 'transform bottom-1.5 -translate-x-1/2 -translate-y-full')
        : '';

    const hasFontClass = (className?.split(' ').filter(c => c.startsWith('font-')) ?? []).length > 0;
    const hasPaddingClass = ['px-', 'py-', 'p-'].some(paddingClass => className?.includes(paddingClass));
    const hasTextSizeClass = (className?.split(' ').filter(c => c.startsWith('text-')) ?? []).length > 0;

    if (disabled) {
        variant = 'disabled';
    }

    className = `${className || ''}
        ${variants[variant]}
        ${hasPaddingClass ? '' : paddingClass}
        ${hasTextSizeClass ? '' : textSizeClass}
        ${roundedClass}
        ${gapClass}
        ${widthClass}
        ${heightClass}
        ${nowrapClass}
        ${tooltip ? 'relative' : ''}
        ${!hasFontClass ? 'font-medium' : ''}
        text-center
        flex
        items-center
        justify-center`
            .replace(/\s+/g, ' ')
            .trim();

    if (to && download) {
        return (
            <a
                href={to}
                download
                className={className}
                onMouseEnter={tooltip ? handleMouseEnter : undefined}
                onMouseLeave={tooltip ? handleMouseLeave : undefined}
            >
                {icon && <span>{icon}</span>}
                {text}
            </a>
        );
    }

    return (
        <>
            <button
                type={type}
                onClick={handleClick}
                className={className}
                disabled={disabled || loading}
                onMouseEnter={tooltip ? handleMouseEnter : undefined}
                onMouseLeave={tooltip ? handleMouseLeave : undefined}
            >
                {icon && <span>{icon}</span>}
                {text && <span>{text}</span>}

                {tooltip && isTooltipVisible && <div
                    role="tooltip"
                    className={`${tooltipClass} absolute z-20 left-1/2 inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 opacity-90`}
                >
                    {tooltip}
                    <div className="tooltip-arrow" data-popper-arrow=""></div>
                </div>}
            </button>
        </>
    );
};

export default Button;
