import {Time} from '@internationalized/date';
import React, {useState} from 'react';
import Button from '@/components/Button';
import TimeField from '@/components/datepicker/TimeField';
import DayOfTheWeekSelectBox, {DayOfTheWeekItem} from '@/components/DayOfTheWeekSelectBox';
import {DayOfTheWeek} from '@/models/DayOfTheWeek';
import {TimeImpl} from '@/models/Time';
import {WarehouseOperatingWindow} from '@/models/Warehouse';
import {TimeValue} from '@react-aria/datepicker';

interface WarehouseOperatingWindowsFormProps {
    operatingWindow: WarehouseOperatingWindow[];
    onChange: (windows: WarehouseOperatingWindow[]) => void;
}

const toTime = (t: TimeImpl): Time => {
    return new Time(t.hours24, t.minutes, t.seconds, 0);
};

const toTimeImpl = (t: TimeValue): TimeImpl | undefined => {
    return t && TimeImpl.from24Hour(t.hour, t.minute, t.second);
};

const WarehouseOperatingWindowsForm: React.FC<WarehouseOperatingWindowsFormProps> = ({
    operatingWindow,
    onChange,
}) => {
    const [windows, setWindows] = useState<WarehouseOperatingWindow[]>(operatingWindow);

    const handleAddWindow = () => {
        setWindows(prevWindows => [...prevWindows, {
            day: DayOfTheWeek.Sunday,
            startTime: new TimeImpl(9, 0, 0, 'AM'),
            endTime: new TimeImpl(5, 0, 0, 'PM'),
            cutoffTime: new TimeImpl(4, 0, 0, 'PM'),
        }]);
        onChange(windows);
    };

    const handleRemoveWindow = (index: number) => {
        setWindows(prevWindows => prevWindows.filter((_, i) => i !== index));
        onChange(windows);
    };

    const handleDayOfTheWeekOnChange = (day: DayOfTheWeek, index: number) => {
        setWindows(prevWindows => {
            const updatedWindows = [...prevWindows];
            updatedWindows[index].day = day;
            return updatedWindows;
        });
        onChange(windows);
    };

    const handleTimeOnChange = (time: TimeValue, key: 'startTime' | 'endTime' | 'cutoffTime', index: number) => {
        setWindows(prevWindows => {
            const updatedWindows = [...prevWindows];
            const t = toTimeImpl(time);
            if (t) updatedWindows[index][key] = t;
            return updatedWindows;
        });
        onChange(windows);
    };

    return (
        <div className="flex flex-col gap-4">
            {windows.map((window, index) => {
                return (
                    <div key={index} className="flex flex-col sm:grid sm:grid-cols-3 md:grid-cols-5 gap-4 sm:gap-8">
                        <div className="col-span-1 sm:col-span-3 md:col-span-1">
                            <DayOfTheWeekSelectBox
                                onSelect={(item: DayOfTheWeekItem | null) => item && handleDayOfTheWeekOnChange(item.dayOfTheWeek, index)}
                                value={window.day}
                                requireSelection
                            />
                        </div>
                        <div>
                            <TimeField
                                onChange={(timeValue) => handleTimeOnChange(timeValue, 'startTime', index)}
                                label="Start Time:"
                                value={toTime(window.startTime)}
                            />
                        </div>
                        <div>
                            <TimeField
                                onChange={(timeValue) => handleTimeOnChange(timeValue, 'endTime', index)}
                                label="End Time:"
                                value={toTime(window.endTime)}
                            />
                        </div>
                        <div>
                            <TimeField
                                onChange={(timeValue) => handleTimeOnChange(timeValue, 'cutoffTime', index)}
                                label="Cut-Off Time:"
                                value={toTime(window.cutoffTime)}
                            />
                        </div>
                        <div className="col-span-3 md:col-span-1 flex justify-end">
                            <button
                                type="button"
                                onClick={() => handleRemoveWindow(index)}
                                className="md:mt-6 py-2 px-4 border border-red-500 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                );
            })}
            <div className="pt-6">
                <Button
                    onClick={handleAddWindow}
                    className="py-2 px-4 border border-green-500 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                    Add Window
                </Button>
            </div>
        </div>
    );
};

export default WarehouseOperatingWindowsForm;
