import {WarehousesApi} from '@/api';
import SelectBox, {SelectBoxProps} from '@/components/SelectBox';
import {Warehouse} from '@/models/Warehouse';

const WarehouseSelectBox = (props: SelectBoxProps<Warehouse>) => {
    const warehousesApi = new WarehousesApi();

    const listWarehouses = async (): Promise<Warehouse[]> => {
        const response = await warehousesApi.listWarehouses();
        return response.items;
    };

    const {
        label = 'Warehouse:',
        placeholder= 'Select warehouse',
    } = props;

    return (
        <SelectBox<Warehouse>
            {...props}
            name="warehouse"
            label={label}
            placeholder={placeholder}
            queryFn={listWarehouses}
            queryKey={['warehouse']}
        />
    );
};

export default WarehouseSelectBox;
