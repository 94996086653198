import React from 'react';
import {Popover, PopoverButton, PopoverPanel, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

interface MenuItem {
    name: string;
    href: string;
    description: string;
    icon?: React.ElementType;
}

interface PopoverMenuButtonProps {
    item: {name: string};
    items: MenuItem[];
}

const PopoverMenuButton: React.FC<PopoverMenuButtonProps> = ({item, items}) => {
    return (
        <Popover key={item.name} className="relative">
            <PopoverButton
                className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 -mr-3 px-3 py-1.5 rounded-md hover:bg-gray-100 transition duration-300 ease-in-out outline-none"
            >
                {item.name}
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
            </PopoverButton>

            <Transition
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <PopoverPanel
                    className="absolute -left-20 max-w-lg xl:-left-24 xl:max-w-xl top-full z-10 mt-3 w-screen overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
                >
                    <div className="p-4 grid grid-cols-2 gap-2">
                        {items.map((menuItem) => (
                            <PopoverButton
                                as={Link}
                                key={menuItem.name}
                                to={menuItem.href}
                                className="group flex items-center gap-x-4 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
                            >
                                <div
                                    className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
                                >
                                    {menuItem.icon && <menuItem.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true"/>}
                                </div>
                                <div className="flex-auto">
                                    <div className="font-semibold text-gray-900">
                                        {menuItem.name}
                                    </div>
                                    <p className="mt-1 text-gray-600">{menuItem.description}</p>
                                </div>
                            </PopoverButton>
                        ))}
                    </div>
                </PopoverPanel>
            </Transition>
        </Popover>
    );
};

export default PopoverMenuButton;
