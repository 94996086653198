import {ReactElement, ReactNode} from 'react';

interface SettingsHeaderProps {
    title: string;
    icon: ReactElement;
    children?: ReactNode;
}

const SettingsHeader = ({title, icon, children}: SettingsHeaderProps) => {
    return (
        <span className="flex items-center gap-2 font-bold text-xl mb-6 pb-4 border-b border-gray-300 h-12">
            <div className="flex flex-1 items-center gap-2">
                {icon}
                <span>{title}</span>
            </div>
            {children}
        </span>
    );
};

export default SettingsHeader;
