import React from 'react';
import {PromotionsApi} from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {Promotion, PromotionCreate} from '@/models/Promotion';
import PromotionForm from '@/modules/supplier/components/PromotionForm';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

const PromotionEdit: React.FC = () => {
    const {promotionId} = useParams();

    const promotionsApi = new PromotionsApi();

    const {
        isLoading,
        data: promotion,
    } = useQuery({
        queryKey: ['promotions', promotionId],
        queryFn: async () => {
            return promotionId
                ? promotionsApi.getPromotion(promotionId)
                : null;
        },
    });

    const handleOnSubmit = (product: PromotionCreate | Promotion): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(product);
                resolve();
            }, 2000);
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!promotion) {
        return;
    }

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Edit Promotion">
                <Button
                    onClick={() => {}}
                    icon={<XMarkIcon className="h-5 w-5 mr-1" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="delete"
                >
                    Delete Promotion
                </Button>
            </PageHeader>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <PromotionForm
                    onSubmit={handleOnSubmit}
                    promotion={promotion}
                />
            </div>
        </div>
    );
};

export default PromotionEdit;
