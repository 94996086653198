import React, {ReactNode, useEffect, useState} from 'react';
import AuthContext from '@/modules/auth/contexts/AuthContext';
import {User, UserRole} from '@/modules/auth/models/User';
import AuthService, {IdToken} from '@/modules/auth/services/AuthService';

interface AuthState {
    isLoggedIn: boolean;
    accessToken?: string;
    idToken?: string;
    role?: UserRole;
    roles: UserRole[];
    isInitialized?: boolean;
}

interface AuthorizationProviderProps {
    children: ((state: AuthState) => ReactNode) | ReactNode;
}

const authService = new AuthService();

export const AuthProvider: React.FC<AuthorizationProviderProps> = ({children}) => {
    const [state, setState] = useState<AuthState>({
        isLoggedIn: false,
        accessToken: undefined,
        idToken: undefined,
        role: undefined,
        roles: [],
        isInitialized: false,
    });

    const login = async (username: string, password: string) => {
        const token = await authService.login(username, password);
        if (!token) {
            return false;
        }

        const {role, roles} = token;

        setState(prevState => {
            return {
                ...prevState,
                isLoggedIn: true,
                accessToken: token.accessToken,
                idToken: token.idToken,
                role: role,
                roles: roles,
            };
        });

        return true;
    };

    const logout = async () => {
        if (!state.isLoggedIn) {
            return;
        }

        await authService.logout();
        setState(prevState => {
            return {
                ...prevState,
                isLoggedIn: false,
                role: undefined,
                roles: [],
            };
        });
    };

    const changeRole = async (newRole: UserRole) => {
        if (!state.roles.includes(newRole)) {
            return;
        }

        await authService.changeRole(newRole);
        setState(prevState => ({
            ...prevState,
            role: newRole,
        }));
    };

    const convertIdTokenToUser = (idToken: IdToken): User | undefined => {
        const role = determineUserRole(idToken.roles);
        if (!role) {
            return;
        }

        return {
            id: idToken.sub || '',
            username: idToken.username ?? '',
            email: idToken.email ?? '',
            firstName: idToken.firstName ?? '',
            lastName: idToken.lastName ?? '',
            fullName: idToken.fullName ?? '',
            role: role,
            status: 'ACTIVE',
            currencyCode: '',
            languageCode: '',
            timezone: '',
            organizationId: idToken.organizationId,
            customerId: idToken.customerId,
            storeId: idToken.storeId,
            supplierId: idToken.supplierId,
        };
    };

    const determineUserRole = (roles?: string[]): UserRole | undefined => {
        if (roles && roles.includes(UserRole.Retailer)) {
            return UserRole.Retailer;
        } else if (roles && roles.includes(UserRole.Supplier)) {
            return UserRole.Supplier;
        } else if (roles && roles.includes(UserRole.Admin)) {
            return UserRole.Admin;
        }
    };

    const currentUser = (): User | undefined => {
        if (!state.isLoggedIn || !state.idToken) {
            return undefined;
        }

        const idToken = authService.decodeIdToken(state.idToken);

        return convertIdTokenToUser(idToken);
    };

    const {isInitialized} = state;

    useEffect(() => {
        if (isInitialized) {
            return;
        }

        let isMounted = true;

        const getToken = async () => {
            const token = await authService.getToken();

            const {role, roles = []} = token || {};

            if (isMounted) {
                setState(prevState => ({
                    isLoggedIn: !!token,
                    accessToken: token?.accessToken,
                    idToken: token?.idToken,
                    role: prevState.role || role,
                    roles: roles,
                    isInitialized: true,
                }));
            }
        };

        void getToken();

        return () => {
            isMounted = false;
        };
    }, [isInitialized]);

    const {
        isLoggedIn,
        accessToken,
        idToken,
        role,
        roles,
    } = state;

    return (
        <AuthContext.Provider value={{
            isLoggedIn,
            accessToken,
            idToken,
            role,
            roles,
            changeRole,
            currentUser,
            login,
            logout}}
        >
            {isInitialized && typeof children === 'function'
                ? children(state)
                : isInitialized && typeof children !== 'function'
                    ? children
                    : undefined}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
