import {useEffect, useState} from 'react';
import {Configuration, SuppliersApi} from '@/api';
import {Supplier} from '@/models/Supplier';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery} from '@tanstack/react-query';

const useGetSuppliersQuery = (supplierIds?: string[]) => {
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const {
        isLoading,
        data,
    } = useQuery({
        queryKey: ['suppliers', supplierIds],
        queryFn: async () => {
            if (supplierIds && supplierIds.length > 0) {
                return suppliersApi.getSuppliers(supplierIds);
            }
            return [];
        },
    });

    useEffect(() => {
        if (!isLoading && data) {
            setSuppliers(data);
        }
    }, [data, isLoading]);

    return {
        suppliers,
        isLoading,
    };
};

export default useGetSuppliersQuery;
