import React, {useRef} from 'react';
import {FieldButton} from '@/components/datepicker/Button';
import {DateField} from '@/components/datepicker/DateField';
import {Popover} from '@/components/datepicker/Popover';
import {RangeCalendar} from '@/components/datepicker/RangeCalendar';
import {CalendarIcon, ExclamationCircleIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {DateValue} from '@react-aria/calendar';
import {AriaDateRangePickerProps, useDateRangePicker} from '@react-aria/datepicker';
import {I18nProvider} from '@react-aria/i18n';
import {useDateRangePickerState} from '@react-stately/datepicker';

interface DateRangePickerProps extends AriaDateRangePickerProps<DateValue> {
    label: string;
    locale?: string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
    const state = useDateRangePickerState(props);
    const ref = useRef<HTMLDivElement>(null);
    const {
        groupProps,
        labelProps,
        startFieldProps,
        endFieldProps,
        buttonProps,
        dialogProps,
        calendarProps,
    } = useDateRangePicker(props, state, ref);

    const {locale = 'en-US'} = props;

    const hasValue = !state.isInvalid && (state.value?.start || state.value?.end);

    if (hasValue) {
        buttonProps.onPress = () => {
            state.setValue(null);
        };
    }

    return (
        <I18nProvider locale={locale}>
            <div className="relative flex-col text-left">
                <span {...labelProps} className="block mb-1">
                    {props.label}
                </span>
                <div {...groupProps} ref={ref} className="flex group rounded-md shadow">
                    <div
                        className="flex flex-1 bg-white border border-gray-300 group-hover:border-gray-400 transition-colors rounded-l-md group-focus-within:border-blue-600 group-focus-within:group-hover:border-blue-600 pl-2 p-1 py-2 relative">
                        <DateField {...startFieldProps}/>
                        <span aria-hidden="true" className="px-2">
                            –
                        </span>
                        <DateField {...endFieldProps}/>
                        {state.isInvalid && (
                            <ExclamationCircleIcon className="w-6 h-6 text-red-500 absolute right-1"/>
                        )}
                    </div>
                    <FieldButton {...buttonProps} isPressed={state.isOpen}>
                        {hasValue
                            ? <XMarkIcon className="w-5 h-5 text-gray-700 group-focus-within:text-blue-700"/>
                            : <CalendarIcon className="w-5 h-5 text-gray-700 group-focus-within:text-blue-700"/>
                        }
                    </FieldButton>
                </div>
                {state.isOpen && (
                    <Popover
                        {...dialogProps}
                        isOpen={state.isOpen}
                        onClose={() => state.setOpen(false)}
                    >
                        <RangeCalendar {...calendarProps}/>
                    </Popover>
                )}
            </div>
        </I18nProvider>
    );
};

export default DateRangePicker;
