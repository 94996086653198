import useCartQuery from '@/api/queries/useCartQuery';
import Button from '@/components/Button';
import DeliveryWindowSelectBox, {DeliveryWindowItem} from '@/components/DeliveryWindowSelectBox';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import PageHeader from '@/components/PageHeader';
import {useSettings} from '@/contexts/SettingsContext';
import CartTable from '@/modules/retailer/components/CartTable';
import {useCart} from '@/modules/retailer/contexts/CartContext';
import CartEmpty from '@/modules/retailer/pages/CartEmpty';
import {OrderReviewPath, ShopPath} from '@/modules/retailer/paths';
import CartService from '@/modules/retailer/services/CartService';
import {Link} from 'react-router-dom';

const Cart = () => {
    const {currencyCode} = useSettings();

    const {
        getCarts,
        cartItemCount,
        updateItemFromCart,
        removeItemFromCart,
        updateDeliveryWindow,
        updateSelectedCartItems,
        resetCart,
        addedSkuIds,
        currentSkuIds,
    } = useCart();

    const {
        isLoading,
        skus,
        suppliers,
    } = useCartQuery(
        addedSkuIds,
        currentSkuIds,
    );

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!isLoading && cartItemCount == 0) {
        return <CartEmpty/>;
    }

    const handleOrderQuantityChange = (quantity: number, supplierId: string, itemId: number) => {
        updateItemFromCart(supplierId, itemId, quantity);
    };

    const handleCheckedCartItemsChange = (items: number[], supplierId: string) => {
        updateSelectedCartItems(items, supplierId);
    };

    const handleDeliveryWindowChange = (deliveryWindow: DeliveryWindowItem | null, supplierId: string) => {
        updateDeliveryWindow(supplierId, deliveryWindow?.deliveryWindow || null);
    };

    const handleRemoveItemFromCart = (supplierId: string, itemId: number) => {
        removeItemFromCart(supplierId, itemId);
    };

    const handleResetCart = () => {
        resetCart();
    };

    const carts = getCarts();

    const cartService = new CartService();

    const cartDetails = cartService.getCartDetails(carts, skus, suppliers, currencyCode);

    return (
        <>
            <PageHeader title="Cart">
                <Button onClick={handleResetCart} variant="secondary">
                    Clear Cart
                </Button>
                <Button to={ShopPath.path} variant="secondary">
                    Continue Shopping
                </Button>
                <Button to={OrderReviewPath}>
                    Checkout
                </Button>
            </PageHeader>

            {cartDetails.map((cart, index) => (
                <div key={index} className="mb-8">
                    <h2 className="text-xl font-bold mb-4">
                        <Link to={ShopPath.withSupplierId(cart.supplierId)}>
                            {cart.supplierName}
                        </Link>
                    </h2>
                    <div className="mb-4 lg:w-96">
                        <DeliveryWindowSelectBox
                            value={cart.deliveryWindow?.id || null}
                            onSelect={(item) => handleDeliveryWindowChange(item, cart.supplierId)}
                            supplierId={cart.supplierId}
                            defaultValue="first"
                        />
                    </div>

                    <CartTable
                        cart={cart}
                        onChangeOrderQuantity={handleOrderQuantityChange}
                        onChangeCheckedItems={handleCheckedCartItemsChange}
                        removeItem={handleRemoveItemFromCart}
                    />
                </div>
            ))}

            <div className="flex flex-col gap-2 mt-6 mb-4 sm:flex-row sm:items-center sm:justify-end">
                <Button to={ShopPath.path} variant="secondary">
                    Continue Shopping
                </Button>

                <Button to={OrderReviewPath}>
                    Checkout
                </Button>
            </div>
        </>
    );
};

export default Cart;
