import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import SearchForm from '@/components/SearchForm';
import useSearch from '@/hooks/useSearch';
import {SupplierCalendarAddPath} from '@/modules/admin/paths';
import CalendarTable from '@/modules/supplier/components/CalendarTable';
import {PlusIcon} from '@heroicons/react/24/solid';
import {useParams} from 'react-router-dom';

const CalendarList: React.FC = () => {
    const {
        searchQuery,
        page,
        handleSearchSubmit,
        handlePageChange,
        setSearchQuery,
    } = useSearch();

    const {supplierId} = useParams();

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Calendars">
                {supplierId && <Button
                    to={SupplierCalendarAddPath.withSupplierId(supplierId)}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Calendar
                </Button>}
            </PageHeader>

            <SearchForm
                onSubmit={handleSearchSubmit}
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder="Search by name"
            />

            <CalendarTable
                searchQuery={searchQuery}
                page={page}
                onChangePage={handlePageChange}
                supplierId={supplierId}
            />
        </div>
    );
};

export default CalendarList;
