import React from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageHeader from '@/components/PageHeader';
import {PriceList, PriceListCreate} from '@/models/PriceList';
import PriceListForm from '@/modules/supplier/components/PriceListForm';

const PriceListAdd: React.FC = () => {
    const handleOnSubmit = (productList: PriceListCreate | PriceList): Promise<void> => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log(productList);
                resolve();
            }, 2000);
        });
    };

    return (
        <div>
            <Breadcrumbs/>

            <PageHeader title="Create Price List"/>

            <div className="bg-white mx-auto rounded-lg shadow p-6 lg:p-12">
                <PriceListForm onSubmit={handleOnSubmit}/>
            </div>
        </div>
    );
};

export default PriceListAdd;
