import {useState} from 'react';
import Button from '@/components/Button';
import {Supplier, SupplierCreate, SupplierUpdate} from '@/models/Supplier';
import {withMinimumDelay} from '@/utils/delay';
import {withLoading} from '@/utils/loading';
import {CheckIcon} from '@heroicons/react/24/solid';
import {Controller, useForm} from 'react-hook-form';

interface SupplierFormProps {
    supplier?: Supplier;
    onSubmit: (supplier: SupplierCreate | SupplierUpdate) => Promise<void>;
}

const SupplierForm = ({supplier, onSubmit}: SupplierFormProps) => {
    const [loading, setLoading] = useState(false);

    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<SupplierCreate | SupplierUpdate>({
        defaultValues: supplier ? {
            ...supplier,
            customerCode: supplier.customerCode || '',
        } : {
            name: '',
            email: '',
            customerCode: '',
        },
    });

    const onSubmitForm = withLoading(
        withMinimumDelay(async (data: SupplierCreate | SupplierUpdate) => {
            try {
                await onSubmit(data);
            } catch (error) {
                console.error('Submission error:', error);
            }
        }, 500),
        setLoading,
    );

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="grid grid-cols-2 gap-4 lg:gap-6">
                <div className="col-span-2">
                    <label htmlFor="name" className="block font-medium text-gray-700">
                        Name:
                    </label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: 'Name is required'}}
                        render={({field}) => (
                            <input
                                id="name"
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                    {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
                </div>
                <div className="col-span-2">
                    <label htmlFor="email" className="block font-medium text-gray-700">
                        Email:
                    </label>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({field}) => (
                            <input
                                id="email"
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                </div>
                <div className="col-span-2">
                    <label htmlFor="customerCode" className="block font-medium text-gray-700">
                        Customer Code:
                    </label>
                    <Controller
                        name="customerCode"
                        control={control}
                        rules={{required: 'Customer code is required'}}
                        render={({field}) => (
                            <input
                                type="text"
                                id="customerCode"
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                    {errors.customerCode && <p className="text-red-500 text-sm">{errors.customerCode.message}</p>}
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    type="submit"
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default SupplierForm;
