import React, {ReactNode} from 'react';
import {OrderPredictionsApi} from '@/api';
import Button, {Variant} from '@/components/Button';
import CartLottieIcon from '@/components/icons/CartLottieIcon';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {useCart} from '@/modules/retailer/contexts/CartContext';
import {Cart} from '@/modules/retailer/models/Cart';

interface AutoOrderButtonProps {
    onSuccess?: () => void;
    variant?: Variant;
    children?: ReactNode;
    className?: string;
    loadingIconClassName?: string;
}

const AutoOrderButton: React.FC<AutoOrderButtonProps> = ({
    children,
    onSuccess,
    ...props
}) => {
    const {addToCart, resetCart} = useCart();

    const handleClick = async () => {
        const orderPredictionsApi = new OrderPredictionsApi();

        const carts = await orderPredictionsApi.generateCart(
            null,
            null,
            null,
            null,
        );

        resetCart();

        carts.forEach((cart: Cart) => {
            addToCart(
                cart.supplierId,
                cart.items,
            );
        });

        onSuccess?.();
    };

    return (
        <Button
            {...props}
            onClick={handleClick}
            icon={<div className="-ml-1 -mb-0.5 mr-0.5 w-10">
                    <CartLottieIcon/>
                </div>}
            loadingIcon={<div className="flex -ml-1 mr-0.5 w-10 pl-1 items-center justify-center">
                    <SpinnerIcon className="w-6 h-6 fill-green-800"/>
                </div>}
            rounded="full"
            className="w-full sm:w-auto min-h-12 min-w-48 font-bold text-md py-1"
        >
            {children ? children : 'Auto-Order'}
        </Button>
    );
};

export default AutoOrderButton;
